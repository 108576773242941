define("ilios/templates/course/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "oOQicKk2",
    "block": "{\"symbols\":[],\"statements\":[[5,\"course-sessions\",[],[[\"@course\",\"@canCreateSession\",\"@canUpdateCourse\",\"@sortBy\",\"@setSortBy\",\"@filterBy\",\"@setFilterBy\"],[[22,\"model\"],[22,\"canCreateSession\"],[22,\"canUpdateCourse\"],[22,\"sortSessionsBy\"],[28,\"action\",[[23,0,[]],[28,\"mut\",[[24,[\"sortSessionsBy\"]]],null]],null],[22,\"filterSessionsBy\"],[28,\"action\",[[23,0,[]],[28,\"mut\",[[24,[\"filterSessionsBy\"]]],null]],null]]]]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ilios/templates/course/index.hbs"
    }
  });

  _exports.default = _default;
});