define("ilios/helpers/is-empty", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.isEmpty = isEmpty;
  _exports.default = void 0;
  const {
    once
  } = Ember.run;

  function isEmpty(params) {
    return Ember.isBlank(params[0]);
  }

  var _default = Ember.Helper.extend({
    value1: null,

    compute([value1]) {
      this.setProperties({
        value1
      });
      return isEmpty([value1]);
    },

    /* eslint ember/no-observers: 0 */
    recomputeOnArrayChange: Ember.observer('value1', function () {
      once(this, () => {
        this.recompute();
      });
    })
  });

  _exports.default = _default;
});