define("ilios/components/flash-messages", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    flashMessages: Ember.inject.service(),
    classNameBindings: [':flash-messages']
  });

  _exports.default = _default;
});