define("ilios/components/user-profile-learnergroups", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNameBindings: [':user-profile-learnergroups', ':large-component'],
    user: null,
    selectedLearnerGroups: Ember.computed('user.learnerGroups.[]', async function () {
      const user = this.user;

      if (Ember.isEmpty(user)) {
        return [];
      }

      const learnerGroups = await user.get('learnerGroups');
      const groupObjects = await Ember.RSVP.map(learnerGroups.toArray(), async learnerGroup => {
        const cohort = await learnerGroup.get('cohort');
        const program = await cohort.get('program');
        const school = await program.get('school');
        const allParentsTitle = await learnerGroup.get('allParentsTitle');
        const title = learnerGroup.get('title');
        const schoolTitle = school.get('title');
        const programTitle = program.get('title');
        const cohortTitle = cohort.get('title');
        return Ember.Object.create({
          allParentsTitle,
          title,
          schoolTitle,
          programTitle,
          cohortTitle,
          sortTitle: schoolTitle + programTitle + cohortTitle + allParentsTitle + title
        });
      });
      return groupObjects.sortBy('sortTitle');
    })
  });

  _exports.default = _default;
});