define("ilios/templates/components/program-details", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "N9B89/gG",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"backtolink\"],[8],[0,\"\\n  \"],[5,\"link-to\",[],[[\"@route\"],[\"programs\"]],{\"statements\":[[0,\"\\n    \"],[1,[28,\"t\",[\"general.backToPrograms\"],null],false],[0,\"\\n  \"]],\"parameters\":[]}],[0,\"\\n\"],[9],[0,\"\\n\"],[5,\"program-header\",[],[[\"@program\",\"@canUpdate\"],[[22,\"program\"],[22,\"canUpdate\"]]]],[0,\"\\n\"],[5,\"program-overview\",[],[[\"@program\",\"@canUpdate\"],[[22,\"program\"],[22,\"canUpdate\"]]]],[0,\"\\n\"],[4,\"if\",[[24,[\"leadershipDetails\"]]],null,{\"statements\":[[0,\"  \"],[5,\"program-leadership-expanded\",[],[[\"@program\",\"@canUpdate\",\"@collapse\",\"@expand\",\"@isManaging\",\"@setIsManaging\"],[[22,\"program\"],[22,\"canUpdate\"],[28,\"action\",[[23,0,[]],[24,[\"setLeadershipDetails\"]],false],null],[28,\"action\",[[23,0,[]],[24,[\"setLeadershipDetails\"]],true],null],[22,\"manageLeadership\"],[22,\"setManageLeadership\"]]]],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[5,\"leadership-collapsed\",[],[[\"@title\",\"@showAdministrators\",\"@directorsCount\",\"@expand\"],[[28,\"t\",[\"general.programLeadership\"],null],false,[28,\"has-many-length\",[[24,[\"program\"]],\"directors\"],null],[28,\"action\",[[23,0,[]],[24,[\"setLeadershipDetails\"]],true],null]]]],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ilios/templates/components/program-details.hbs"
    }
  });

  _exports.default = _default;
});