define("ilios/components/selectable-terms-list-item", ["exports", "ilios-common/components/selectable-terms-list-item"], function (_exports, _selectableTermsListItem) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _selectableTermsListItem.default;
    }
  });
});