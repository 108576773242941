define("ilios/controllers/print-course", ["exports", "ilios-common/mixins/print-course-controller"], function (_exports, _printCourseController) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend(_printCourseController.default);

  _exports.default = _default;
});