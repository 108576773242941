define("ilios/components/new-learnergroup", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    multiModeSupported: false,
    fillModeSupported: false,
    singleMode: true,
    classNames: ['new-learnergroup'],
    actions: {
      generateNewLearnerGroups(num) {
        this.generateNewLearnerGroups(num);
      }

    }
  });

  _exports.default = _default;
});