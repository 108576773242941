define("ilios/components/new-user", ["exports", "ilios/mixins/newuser", "ember-cp-validations"], function (_exports, _newuser, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const Validations = (0, _emberCpValidations.buildValidations)({
    firstName: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('length', {
      max: 50
    })],
    middleName: [(0, _emberCpValidations.validator)('length', {
      max: 20
    })],
    lastName: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('length', {
      max: 50
    })],
    username: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('length', {
      max: 100
    })],
    password: [(0, _emberCpValidations.validator)('presence', true)],
    campusId: [(0, _emberCpValidations.validator)('length', {
      max: 16
    })],
    otherId: [(0, _emberCpValidations.validator)('length', {
      max: 16
    })],
    email: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('length', {
      max: 100
    }), (0, _emberCpValidations.validator)('format', {
      type: 'email'
    })],
    phone: [(0, _emberCpValidations.validator)('length', {
      max: 20
    })]
  });

  var _default = Ember.Component.extend(_newuser.default, Validations, {
    classNames: ['new-user'],

    keyUp(event) {
      const keyCode = event.keyCode;
      const target = event.target;

      if ('text' !== target.type) {
        return;
      }

      if (13 === keyCode) {
        this.save.perform();
        return;
      }

      if (27 === keyCode) {
        this.close();
      }
    }

  });

  _exports.default = _default;
});