define("ilios/templates/course-materials", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "QOKSBTpf",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"backtolink\"],[8],[0,\"\\n  \"],[5,\"link-to\",[],[[\"@route\",\"@model\"],[\"course\",[22,\"model\"]]],{\"statements\":[[0,\"\\n    \"],[1,[28,\"t\",[\"general.backToCourse\"],null],false],[0,\"\\n  \"]],\"parameters\":[]}],[0,\"\\n\"],[9],[0,\"\\n\"],[5,\"course-summary-header\",[],[[\"@course\"],[[22,\"model\"]]]],[0,\"\\n\"],[5,\"course-materials\",[],[[\"@course\",\"@courseSort\",\"@sessionSort\",\"@onCourseSort\",\"@onSessionSort\"],[[22,\"model\"],[22,\"courseSort\"],[22,\"sessionSort\"],[28,\"action\",[[23,0,[]],[28,\"mut\",[[24,[\"courseSort\"]]],null]],null],[28,\"action\",[[23,0,[]],[28,\"mut\",[[24,[\"sessionSort\"]]],null]],null]]]]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ilios/templates/course-materials.hbs"
    }
  });

  _exports.default = _default;
});