define("ilios/components/programyear-details", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['programyear-details'],
    canUpdate: false,
    program: null,
    programYear: null,
    pyCompetencyDetails: null,
    pyObjectiveDetails: null,
    pyTaxonomyDetails: null
  });

  _exports.default = _default;
});