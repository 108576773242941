define("ilios/templates/components/school-vocabularies-expanded", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "UsK53zhW",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",false],[12,\"class\",[29,[\"title \",[28,\"if\",[[24,[\"showCollapsible\"]],\"collapsible clickable\"],null]]]],[3,\"action\",[[23,0,[]],\"collapse\"]],[8],[0,\"\\n  \"],[1,[28,\"t\",[\"general.vocabularies\"],null],false],[0,\"\\n  (\"],[1,[28,\"get\",[[28,\"await\",[[24,[\"school\",\"vocabularies\"]]],null],\"length\"],null],false],[0,\")\\n\"],[9],[0,\"\\n\"],[7,\"div\",true],[10,\"class\",\"school-vocabularies-expanded-content\"],[8],[0,\"\\n\"],[4,\"if\",[[24,[\"managedTermId\"]]],null,{\"statements\":[[0,\"    \"],[5,\"school-vocabulary-term-manager\",[],[[\"@vocabulary\",\"@term\",\"@manageTerm\",\"@manageVocabulary\",\"@canUpdate\",\"@canDelete\",\"@canCreate\"],[[22,\"managedVocabulary\"],[22,\"managedTerm\"],[22,\"setSchoolManagedVocabularyTerm\"],[22,\"setSchoolManagedVocabulary\"],[22,\"canUpdateTerm\"],[22,\"canDeleteTerm\"],[22,\"canCreateTerm\"]]]],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[24,[\"managedVocabularyId\"]]],null,{\"statements\":[[0,\"    \"],[5,\"school-vocabulary-manager\",[],[[\"@vocabulary\",\"@manageTerm\",\"@manageVocabulary\",\"@canUpdate\",\"@canCreate\"],[[22,\"managedVocabulary\"],[22,\"setSchoolManagedVocabularyTerm\"],[22,\"setSchoolManagedVocabulary\"],[22,\"canUpdateVocabulary\"],[22,\"canCreateTerm\"]]]],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[5,\"school-vocabularies-list\",[],[[\"@school\",\"@manageVocabulary\",\"@canDelete\",\"@canCreate\"],[[22,\"school\"],[22,\"setSchoolManagedVocabulary\"],[22,\"canDeleteVocabulary\"],[22,\"canCreateVocabulary\"]]]],[0,\"\\n  \"]],\"parameters\":[]}]],\"parameters\":[]}],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ilios/templates/components/school-vocabularies-expanded.hbs"
    }
  });

  _exports.default = _default;
});