define("ilios/templates/course", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "CbSsqhXM",
    "block": "{\"symbols\":[],\"statements\":[[1,[28,\"page-title\",[[28,\"t\",[\"general.coursesAndSessions\"],null]],null],false],[0,\"\\n\"],[5,\"ilios-course-details\",[],[[\"@course\",\"@editable\",\"@showDetails\",\"@setShowDetails\",\"@courseLeadershipDetails\",\"@courseObjectiveDetails\",\"@courseTaxonomyDetails\",\"@courseCompetencyDetails\",\"@courseManageLeadership\",\"@setCourseLeadershipDetails\",\"@setCourseObjectiveDetails\",\"@setCourseTaxonomyDetails\",\"@setCourseCompetencyDetails\",\"@setCourseManageLeadership\"],[[22,\"model\"],[22,\"editable\"],[22,\"details\"],[28,\"action\",[[23,0,[]],[28,\"mut\",[[24,[\"details\"]]],null]],null],[22,\"courseLeadershipDetails\"],[22,\"courseObjectiveDetails\"],[22,\"courseTaxonomyDetails\"],[22,\"courseCompetencyDetails\"],[22,\"courseManageLeadership\"],[28,\"action\",[[23,0,[]],[28,\"mut\",[[24,[\"courseLeadershipDetails\"]]],null]],null],[28,\"action\",[[23,0,[]],[28,\"mut\",[[24,[\"courseObjectiveDetails\"]]],null]],null],[28,\"action\",[[23,0,[]],[28,\"mut\",[[24,[\"courseTaxonomyDetails\"]]],null]],null],[28,\"action\",[[23,0,[]],[28,\"mut\",[[24,[\"courseCompetencyDetails\"]]],null]],null],[28,\"action\",[[23,0,[]],[28,\"mut\",[[24,[\"courseManageLeadership\"]]],null]],null]]]],[0,\"\\n\"],[1,[22,\"outlet\"],false]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ilios/templates/course.hbs"
    }
  });

  _exports.default = _default;
});