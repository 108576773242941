define("ilios/components/publish-all-sessions", ["exports", "ilios-common/components/publish-all-sessions"], function (_exports, _publishAllSessions) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _publishAllSessions.default;
    }
  });
});