define("ilios/components/bulk-new-users", ["exports", "ember-concurrency", "ember-cp-validations", "ilios/mixins/newuser", "papaparse"], function (_exports, _emberConcurrency, _emberCpValidations, _newuser, _papaparse) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const UserValidations = (0, _emberCpValidations.buildValidations)({
    firstName: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('length', {
      max: 50
    })],
    middleName: [(0, _emberCpValidations.validator)('length', {
      max: 20
    })],
    lastName: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('length', {
      max: 50
    })],
    username: [(0, _emberCpValidations.validator)('length', {
      max: 100,
      min: 1,
      ignoreBlank: true
    }), (0, _emberCpValidations.validator)('exclusion', {
      dependentKeys: ['model.existingUsernames.[]'],
      in: Ember.computed.reads('model.existingUsernames')
    })],
    password: [(0, _emberCpValidations.validator)('presence', {
      presence: true,
      dependentKeys: ['model.username'],
      disabled: Ember.computed.not('model.username')
    })],
    campusId: [(0, _emberCpValidations.validator)('length', {
      max: 16
    })],
    otherId: [(0, _emberCpValidations.validator)('length', {
      max: 16
    })],
    email: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('length', {
      max: 100
    }), (0, _emberCpValidations.validator)('format', {
      type: 'email'
    })],
    phone: [(0, _emberCpValidations.validator)('length', {
      max: 20
    })]
  });

  var _default = Ember.Component.extend(_newuser.default, {
    flashMessages: Ember.inject.service(),
    iliosConfig: Ember.inject.service(),
    intl: Ember.inject.service(),
    classNames: ['bulk-new-users'],
    file: null,
    fileUploadError: false,
    proposedUsers: null,
    savedUserIds: null,
    savingAuthenticationErrors: null,
    savingUserErrors: null,
    selectedUsers: null,
    host: Ember.computed.reads('iliosConfig.apiHost'),
    namespace: Ember.computed.reads('iliosConfig.apiNameSpace'),
    sampleData: Ember.computed(function () {
      const sampleUploadFields = ['First', 'Last', 'Middle', 'Phone', 'Email', 'CampusID', 'OtherID', 'Username', 'Password'];
      const str = sampleUploadFields.join("\t");
      const encoded = window.btoa(str);
      return encoded;
    }),

    init() {
      this._super(...arguments);

      this.set('selectedUsers', []);
      this.set('proposedUsers', []);
      this.set('savedUserIds', []);
      this.set('savingUserErrors', []);
      this.set('savingAuthenticationErrors', []);
    },

    actions: {
      updateSelectedFile(files) {
        // Check for the various File API support.
        if (window.File && window.FileReader && window.FileList && window.Blob) {
          if (files.length > 0) {
            this.parseFile.perform(files[0]);
          }
        } else {
          const intl = this.intl;
          throw new Error(intl.t('general.unsupportedBrowserFailure'));
        }
      },

      toggleUserSelection(obj) {
        let selectedUsers = this.selectedUsers;

        if (selectedUsers.includes(obj)) {
          selectedUsers.removeObject(obj);
        } else {
          selectedUsers.pushObject(obj);
        }
      }

    },

    async existingUsernames() {
      const authentications = await this.store.findAll('authentication');
      return authentications.mapBy('username');
    },

    /**
     * Extract the contents of a file into an array of user like objects
     * @param Object file
     *
     * @return array
     **/
    getFileContents(file) {
      this.set('fileUploadError', false);
      return new Ember.RSVP.Promise(resolve => {
        let allowedFileTypes = ['text/plain', 'text/csv', 'text/tab-separated-values'];

        if (!allowedFileTypes.includes(file.type)) {
          const intl = this.intl;
          this.set('fileUploadError', true);
          throw new Error(intl.t('general.fileTypeError', {
            fileType: file.type
          }));
        }

        let ProposedUser = Ember.Object.extend(Ember.getOwner(this).ownerInjection(), UserValidations, {
          email: null
        });

        let complete = ({
          data
        }) => {
          let proposedUsers = data.map(arr => {
            return ProposedUser.create({
              firstName: Ember.isPresent(arr[0]) ? arr[0] : null,
              lastName: Ember.isPresent(arr[1]) ? arr[1] : null,
              middleName: Ember.isPresent(arr[2]) ? arr[2] : null,
              phone: Ember.isPresent(arr[3]) ? arr[3] : null,
              email: Ember.isPresent(arr[4]) ? arr[4] : null,
              campusId: Ember.isPresent(arr[5]) ? arr[5] : null,
              otherId: Ember.isPresent(arr[6]) ? arr[6] : null,
              username: Ember.isPresent(arr[7]) ? arr[7] : null,
              password: Ember.isPresent(arr[8]) ? arr[8] : null
            });
          });
          let notHeaderRow = proposedUsers.filter(obj => String(obj.firstName).toLowerCase() !== 'first' || String(obj.lastName).toLowerCase() !== 'last');
          resolve(notHeaderRow);
        };

        _papaparse.default.parse(file, {
          complete
        });
      });
    },

    parseFile: (0, _emberConcurrency.task)(function* (file) {
      let proposedUsers = yield this.getFileContents(file);
      let existingUsernames = yield this.existingUsernames();
      let filledOutUsers = proposedUsers.map(obj => {
        obj.addedViaIlios = true;
        obj.enabled = true;
        obj.existingUsernames = existingUsernames;
        return obj;
      });
      let validUsers = yield Ember.RSVP.filter(filledOutUsers, obj => {
        return obj.validate().then(({
          validations
        }) => {
          return validations.get('isValid');
        });
      });
      this.set('selectedUsers', validUsers);
      this.set('proposedUsers', filledOutUsers);
    }).restartable(),
    save: (0, _emberConcurrency.task)(function* () {
      this.set('savedUserIds', []);
      const store = this.store;
      const nonStudentMode = this.nonStudentMode;
      const selectedSchool = yield this.bestSelectedSchool;
      const selectedCohort = yield this.bestSelectedCohort;
      const roles = yield store.findAll('user-role');
      const studentRole = roles.findBy('id', '4');
      let proposedUsers = this.selectedUsers;
      let validUsers = yield Ember.RSVP.filter(proposedUsers, obj => {
        return obj.validate().then(({
          validations
        }) => {
          return validations.get('isValid');
        });
      });
      let records = validUsers.map(userInput => {
        let user = store.createRecord('user', userInput.getProperties('firstName', 'lastName', 'middleName', 'phone', 'email', 'campusId', 'otherId', 'addedViaIlios', 'enabled'));
        user.set('school', selectedSchool);

        if (!nonStudentMode) {
          user.set('primaryCohort', selectedCohort);
          user.set('roles', [studentRole]);
        }

        let authentication = false;

        if (Ember.isPresent(userInput.get('username'))) {
          authentication = store.createRecord('authentication', userInput.getProperties('username', 'password'));
          authentication.set('user', user);
        }

        let rhett = {
          user,
          userInput
        };

        if (authentication) {
          rhett.authentication = authentication;
        }

        return rhett;
      });
      let parts;

      while (records.get('length') > 0) {
        try {
          parts = records.splice(0, 10);
          let users = parts.mapBy('user');
          yield Ember.RSVP.all(users.invoke('save'));
          let authentications = parts.mapBy('authentication');
          yield Ember.RSVP.all(authentications.invoke('save'));
        } catch (e) {
          let userErrors = parts.filter(obj => obj.user.get('isError'));
          let authenticationErrors = parts.filter(obj => !userErrors.includes(obj) && Ember.isPresent(obj.authentication) && obj.authentication.get('isError'));
          this.savingUserErrors.pushObjects(userErrors);
          this.savingAuthenticationErrors.pushObjects(authenticationErrors);
        } finally {
          this.savedUserIds.pushObjects(parts.mapBy('user').mapBy('id'));
        }
      }

      const flashMessages = this.flashMessages;

      if (this.savingUserErrors.get('length') || this.savingAuthenticationErrors.get('length')) {
        flashMessages.warning('general.newUsersCreatedWarning');
      } else {
        flashMessages.success('general.newUsersCreatedSuccessfully');
      }

      this.set('selectedUsers', []);
      this.set('proposedUsers', []);
    }).drop()
  });

  _exports.default = _default;
});