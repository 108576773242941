define("ilios/components/school-session-type-manager", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    classNames: ['school-session-type-manager'],
    canUpdate: false,
    sessionType: null,
    readonlySessionType: Ember.computed('sessionType', async function () {
      const sessionType = this.sessionType;

      if (!sessionType) {
        return null;
      }

      const {
        title,
        calendarColor,
        assessment,
        active: isActive
      } = sessionType;
      const assessmentOption = await sessionType.get('assessmentOption');
      const selectedAssessmentOptionId = assessmentOption ? assessmentOption.get('id') : null;
      const firstAamcMethod = await sessionType.get('firstAamcMethod');
      const selectedAamcMethodId = firstAamcMethod ? firstAamcMethod.get('id') : null;
      const readonlySessionType = Ember.Object.create({
        title,
        calendarColor,
        assessment,
        selectedAssessmentOptionId,
        selectedAamcMethodId,
        isActive
      });
      return readonlySessionType;
    }),
    save: (0, _emberConcurrency.task)(function* (title, calendarColor, assessment, assessmentOption, aamcMethod, isActive) {
      const sessionType = this.sessionType;
      const closeComponent = this.close;
      let aamcMethods = [];

      if (aamcMethod) {
        aamcMethods.pushObject(aamcMethod);
      }

      sessionType.setProperties({
        title,
        calendarColor,
        assessment,
        assessmentOption,
        aamcMethods,
        active: isActive
      });
      yield sessionType.save();
      closeComponent();
    })
  });

  _exports.default = _default;
});