define("ilios/components/course-header", ["exports", "ilios-common/components/course-header"], function (_exports, _courseHeader) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _courseHeader.default;
    }
  });
});