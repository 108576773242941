define("ilios/controllers/program/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    actions: {
      lockProgramYear(programYear) {
        programYear.set('locked', true);
        return programYear.save();
      },

      unlockProgramYear(programYear) {
        programYear.set('locked', false);
        return programYear.save();
      },

      activateProgramYear(programYear) {
        programYear.set('published', true);
        programYear.set('publishedAsTbd', false);
        return programYear.save();
      }

    }
  });

  _exports.default = _default;
});