define("ilios/components/recently-updated-display", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['recently-updated-display'],
    tagName: 'span',
    lastModified: null,
    recentlyUpdated: Ember.computed('lastModified', {
      get() {
        const lastModified = this.lastModified;

        if (Ember.isEmpty(lastModified)) {
          return false;
        }

        const lastModifiedDate = (0, _moment.default)(lastModified);
        const today = (0, _moment.default)();
        const daysSinceLastUpdate = today.diff(lastModifiedDate, 'days');
        return daysSinceLastUpdate < 6 ? true : false;
      }

    }).readOnly()
  });

  _exports.default = _default;
});