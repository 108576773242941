define("ilios/controllers/school", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    queryParams: ['schoolCompetencyDetails', 'schoolManageCompetencies', 'schoolVocabularyDetails', 'schoolManagedVocabulary', 'schoolManagedVocabularyTerm', 'schoolLeadershipDetails', 'schoolManageLeadership', 'schoolManageSessionAttributes', 'schoolSessionAttributesDetails', 'schoolSessionTypeDetails', 'schoolManagedSessionType', 'schoolNewSessionType', 'schoolManageInstitution'],
    schoolCompetencyDetails: false,
    schoolManageCompetencies: false,
    schoolVocabularyDetails: false,
    schoolManagedVocabulary: null,
    schoolManagedVocabularyTerm: null,
    schoolLeadershipDetails: false,
    schoolManageLeadership: false,
    schoolManageSessionAttributes: false,
    schoolSessionAttributesDetails: false,
    schoolNewSessionType: false,
    schoolSessionTypeDetails: false,
    schoolManagedSessionType: null,
    schoolManageInstitution: false
  });

  _exports.default = _default;
});