define("ilios/components/course-publicationcheck", ["exports", "ilios-common/components/course-publicationcheck"], function (_exports, _coursePublicationcheck) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _coursePublicationcheck.default;
    }
  });
});