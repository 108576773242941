define("ilios/controllers/learner-groups", ["exports", "ember-concurrency", "ilios/utils/clone-learner-group"], function (_exports, _emberConcurrency, _cloneLearnerGroup) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    currentUser: Ember.inject.service(),
    intl: Ember.inject.service(),
    permissionChecker: Ember.inject.service(),
    store: Ember.inject.service(),
    queryParams: {
      programId: 'program',
      programYearId: 'programYear',
      schoolId: 'school',
      sortLearnerGroupsBy: 'sortBy',
      titleFilter: 'filter'
    },
    currentGroupsSaved: 0,
    deletedGroup: null,
    newGroup: null,
    programId: null,
    programYearId: null,
    schoolId: null,
    showNewLearnerGroupForm: false,
    sortLearnerGroupsBy: 'title',
    titleFilter: null,
    totalGroupsToSave: 0,
    sortedSchools: Ember.computed('model.schools', async function () {
      const schools = await this.get('model.schools');
      return schools.sortBy('title');
    }),
    programs: Ember.computed('selectedSchool', async function () {
      const school = await this.selectedSchool;

      if (Ember.isEmpty(school)) {
        return [];
      }

      return await this.store.query('program', {
        filters: {
          school: school.get('id'),
          published: true
        }
      });
    }),
    sortedPrograms: Ember.computed('programs', async function () {
      const programs = await this.programs;
      return programs.sortBy('title');
    }),
    programYears: Ember.computed('selectedProgram', 'selectedProgram.programYears.[]', async function () {
      const program = await this.selectedProgram;

      if (Ember.isEmpty(program)) {
        return [];
      }

      return await this.store.query('programYear', {
        filters: {
          program: program.get('id'),
          published: true
        }
      });
    }),
    sortedProgramYears: Ember.computed('programYears.[]', async function () {
      const programYears = await this.programYears;
      return programYears.sortBy('startYear').reverse();
    }),
    learnerGroups: Ember.computed('selectedProgramYear.cohort.rootLevelLearnerGroups.[]', 'newGroup', 'deletedGroup', async function () {
      const programYear = await this.selectedProgramYear;

      if (Ember.isEmpty(programYear)) {
        return [];
      }

      const cohort = await programYear.get('cohort');
      return await cohort.get('rootLevelLearnerGroups');
    }),
    filteredLearnerGroups: Ember.computed('titleFilter', 'learnerGroups.[]', async function () {
      const titleFilter = this.titleFilter;
      const title = Ember.isBlank(titleFilter) ? '' : titleFilter;
      const learnerGroups = await this.learnerGroups;
      let filteredGroups;

      if (Ember.isEmpty(title)) {
        filteredGroups = learnerGroups.sortBy('title');
      } else {
        filteredGroups = learnerGroups.filter(learnerGroup => {
          return Ember.isPresent(learnerGroup.get('title')) && learnerGroup.get('title').toLowerCase().includes(title.toLowerCase());
        });
      }

      return filteredGroups.sortBy('title');
    }),
    selectedSchool: Ember.computed('model.schools.[]', 'schoolId', async function () {
      let schools = await this.get('model.schools');
      const schoolId = this.schoolId;

      if (Ember.isPresent(schoolId)) {
        const school = schools.findBy('id', schoolId);

        if (school) {
          return school;
        }
      }

      const user = await this.currentUser.get('model');
      return await user.get('school');
    }),
    selectedProgram: Ember.computed('programs.[]', 'programId', async function () {
      const programs = await this.programs;
      let program;
      const programId = this.programId;

      if (Ember.isPresent(programId)) {
        program = programs.findBy('id', programId);
      }

      if (program) {
        return program;
      }

      if (Ember.isEmpty(programs)) {
        return null;
      }

      return programs.sortBy('title').get('firstObject');
    }),
    selectedProgramYear: Ember.computed('programYears.[]', 'programYearId', async function () {
      const programYears = await this.programYears;
      let programYear;
      const programYearId = this.programYearId;

      if (Ember.isPresent(programYearId)) {
        programYear = programYears.findBy('id', programYearId);
      }

      if (programYear) {
        return programYear;
      }

      return programYears.sortBy('startYear').get('lastObject');
    }),
    canCreate: Ember.computed('selectedSchool', async function () {
      const permissionChecker = this.permissionChecker;
      const selectedSchool = await this.selectedSchool;
      return permissionChecker.canCreateLearnerGroup(selectedSchool);
    }),
    canDelete: Ember.computed('selectedSchool', async function () {
      const permissionChecker = this.permissionChecker;
      const selectedSchool = await this.selectedSchool;
      return permissionChecker.canDeleteLearnerGroupInSchool(selectedSchool);
    }),
    actions: {
      editLearnerGroup(learnerGroup) {
        this.transitionToRoute('learnerGroup', learnerGroup);
      },

      async removeLearnerGroup(learnerGroup) {
        const programYear = await this.selectedProgramYear;
        const cohort = await programYear.get('cohort');
        const learnerGroups = await cohort.get('learnerGroups');
        const descendants = await learnerGroup.get('allDescendants');
        descendants.forEach(descendant => {
          learnerGroups.removeObject(descendant);
        });
        learnerGroups.removeObject(learnerGroup);
        await learnerGroup.destroyRecord();
        this.set('deletedGroup', learnerGroup);
        const newGroup = this.newGroup;

        if (newGroup === learnerGroup) {
          this.set('newGroup', null);
        }
      },

      toggleNewLearnerGroupForm() {
        this.set('showNewLearnerGroupForm', !this.showNewLearnerGroupForm);
      },

      async saveNewLearnerGroup(title, fillWithCohort) {
        const store = this.store;
        const selectedProgramYear = await this.selectedProgramYear;
        const cohort = await selectedProgramYear.get('cohort');
        const newLearnerGroup = store.createRecord('learner-group', {
          title,
          cohort
        });

        if (fillWithCohort) {
          const users = await cohort.get('users');
          newLearnerGroup.get('users').pushObjects(users.toArray());
          await newLearnerGroup.save();
          this.set('newGroup', newLearnerGroup);
          this.send('cancel');
        } else {
          await newLearnerGroup.save();
          this.set('newGroup', newLearnerGroup);
          this.send('cancel');
        }
      },

      cancel() {
        this.set('showNewLearnerGroupForm', false);
      },

      async changeSelectedProgram(programId) {
        const programs = await this.programs;
        const program = programs.findBy('id', programId);
        const school = await program.get('school');
        this.set('schoolId', school.get('id'));
        this.set('programId', programId);
        this.set('programYearId', null);
      },

      async changeSelectedProgramYear(programYearId) {
        const programYears = await this.programYears;
        const programYear = programYears.findBy('id', programYearId);
        const program = await programYear.get('program');
        const school = await program.get('school');
        this.set('schoolId', school.get('id'));
        this.set('programId', program.get('id'));
        this.set('programYearId', programYearId);
      },

      changeSelectedSchool(schoolId) {
        this.set('schoolId', schoolId);
        this.set('programId', null);
        this.set('programYearId', null);
      }

    },
    changeTitleFilter: (0, _emberConcurrency.task)(function* (value) {
      this.set('titleFilter', value);
      yield (0, _emberConcurrency.timeout)(250);
      return value;
    }).restartable(),
    copyGroup: (0, _emberConcurrency.task)(function* (withLearners, learnerGroup) {
      const store = this.store;
      const intl = this.intl;
      const cohort = yield learnerGroup.get('cohort');
      const newGroups = yield (0, _cloneLearnerGroup.default)(store, learnerGroup, cohort, withLearners);
      this.set('totalGroupsToSave', newGroups.length); // indicate that the top group is a copy

      newGroups[0].set('title', newGroups[0].get('title') + " (".concat(intl.t('general.copy'), ")")); // save groups one at a time because we need to save in this order so parents are saved before children

      for (let i = 0; i < newGroups.length; i++) {
        yield newGroups[i].save();
        this.set('currentGroupsSaved', i + 1);
      }

      this.set('newGroup', newGroups[0]);
    })
  });

  _exports.default = _default;
});