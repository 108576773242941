define("ilios/components/program-header", ["exports", "ember-cp-validations", "ilios-common/mixins/validation-error-display"], function (_exports, _emberCpValidations, _validationErrorDisplay) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const Validations = (0, _emberCpValidations.buildValidations)({
    programTitle: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('length', {
      min: 3,
      max: 200
    })]
  });

  var _default = Ember.Component.extend(Validations, _validationErrorDisplay.default, {
    classNames: ['program-header'],
    canUpdate: false,
    program: null,
    programTitle: null,

    didReceiveAttrs() {
      this._super(...arguments);

      this.set('programTitle', this.get('program.title'));
    },

    actions: {
      async changeTitle() {
        const program = this.program;
        const newTitle = this.programTitle;
        this.send('addErrorDisplayFor', 'programTitle');
        await this.validate();

        if (this.validations.get('isValid')) {
          this.send('removeErrorDisplayFor', 'programTitle');
          program.set('title', newTitle);
          const newprogram = await program.save();
          this.set('programTitle', newprogram.get('title'));
          this.set('program', newprogram);
          return true;
        }

        return false;
      },

      revertTitleChanges() {
        const program = this.program;
        this.set('programTitle', program.get('title'));
      },

      async activate() {
        const program = this.program;
        program.set('published', true);
        program.set('publishedAsTbd', false);
        await program.save();
      }

    }
  });

  _exports.default = _default;
});