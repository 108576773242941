define("ilios/templates/application", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "A1slehMs",
    "block": "{\"symbols\":[\"version\",\"lastVersion\",\"reload\"],\"statements\":[[5,\"connection-status\",[],[[],[]]],[0,\"\\n\"],[5,\"api-version-notice\",[],[[],[]]],[0,\"\\n\"],[5,\"new-version-notifier\",[],[[],[]],{\"statements\":[[0,\"\\n  \"],[5,\"update-notification\",[],[[\"@reload\"],[[28,\"action\",[[23,0,[]],[23,3,[]]],null]]]],[0,\"\\n\"]],\"parameters\":[1,2,3]}],[0,\"\\n\"],[7,\"div\",true],[11,\"class\",[29,[\"application-wrapper\\n    \",[28,\"if\",[[24,[\"currentUser\",\"performsNonLearnerFunction\"]],\"show-navigation\"],null]]]],[8],[0,\"\\n  \"],[5,\"ilios-header\",[],[[\"@currentlyLoading\"],[[22,\"currentlyLoading\"]]]],[0,\"\\n\"],[4,\"if\",[[24,[\"session\",\"isAuthenticated\"]]],null,{\"statements\":[[0,\"    \"],[5,\"ilios-navigation\",[],[[],[]]],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"  \"],[7,\"main\",true],[8],[0,\"\\n\"],[4,\"if\",[[24,[\"showErrorDisplay\"]]],null,{\"statements\":[[0,\"      \"],[5,\"error-display\",[],[[\"@errors\",\"@clearErrors\"],[[22,\"errors\"],[28,\"action\",[[23,0,[]],\"clearErrors\"],null]]]],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"      \"],[1,[22,\"outlet\"],false],[0,\"\\n\"]],\"parameters\":[]}],[0,\"  \"],[9],[0,\"\\n  \"],[7,\"footer\",true],[10,\"class\",\"ilios-footer\"],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"version\"],[8],[0,\"\\n      \"],[1,[24,[\"apiVersion\",\"version\"]],false],[0,\"\\n      \"],[1,[28,\"app-version\",null,[[\"versionOnly\"],[true]]],false],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n  \"],[5,\"loading-bar\",[],[[\"@isLoading\"],[[22,\"currentlyLoading\"]]]],[0,\"\\n\"],[9],[0,\"\\n\"],[5,\"flash-messages\",[],[[],[]]],[0,\"\\n\"],[5,\"head-layout\",[],[[],[]]],[0,\"\\n\"],[5,\"ember-load-remover\",[],[[],[]]]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ilios/templates/application.hbs"
    }
  });

  _exports.default = _default;
});