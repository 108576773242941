define("ilios/components/instructorgroup-details", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['instructorgroup-details'],
    canUpdate: false,
    instructorGroup: null,
    actions: {
      addUser(user) {
        let instructorGroup = this.instructorGroup;
        instructorGroup.get('users').addObject(user);
        user.get('instructorGroups').addObject(instructorGroup);
        instructorGroup.save();
      },

      removeUser(user) {
        let instructorGroup = this.instructorGroup;
        instructorGroup.get('users').removeObject(user);
        user.get('instructorGroups').removeObject(instructorGroup);
        instructorGroup.save();
      }

    }
  });

  _exports.default = _default;
});