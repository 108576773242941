define("ilios/templates/components/course-search-result", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "BqjEQ4+S",
    "block": "{\"symbols\":[\"session\",\"@course\"],\"statements\":[[5,\"link-to\",[[12,\"class\",\"course-title-link\"]],[[\"@route\",\"@model\"],[\"course\",[23,2,[\"id\"]]]],{\"statements\":[[0,\"\\n  \"],[1,[23,2,[\"title\"]],false],[0,\"\\n\"]],\"parameters\":[]}],[0,\"\\n\"],[7,\"span\",true],[10,\"class\",\"course-flag\"],[8],[0,\"\\n  \"],[1,[28,\"t\",[\"general.course\"],null],false],[0,\"\\n\"],[9],[0,\"\\n\"],[5,\"global-search-tags\",[],[[\"@tags\"],[[23,0,[\"courseTags\"]]]]],[0,\"\\n\"],[7,\"ul\",true],[8],[0,\"\\n  \"],[7,\"li\",true],[10,\"class\",\"sessions\"],[8],[0,\"\\n    \"],[1,[28,\"t\",[\"general.sessions\"],null],false],[0,\":\\n  \"],[9],[0,\"\\n\"],[4,\"each\",[[23,0,[\"sessions\"]]],null,{\"statements\":[[0,\"    \"],[7,\"li\",true],[10,\"class\",\"session-row\"],[8],[0,\"\\n      \"],[5,\"link-to\",[[12,\"class\",\"session-title-link\"]],[[\"@route\",\"@models\"],[\"session\",[28,\"array\",[[23,2,[\"id\"]],[23,1,[\"id\"]]],null]]],{\"statements\":[[0,\"\\n        \"],[1,[23,1,[\"title\"]],false],[0,\"\\n      \"]],\"parameters\":[]}],[0,\"\\n      \"],[5,\"global-search-tags\",[],[[\"@tags\"],[[23,1,[\"matchedIn\"]]]]],[0,\"\\n    \"],[9],[0,\"\\n\"]],\"parameters\":[1]},null],[4,\"if\",[[28,\"and\",[[28,\"not\",[[23,0,[\"showMore\"]]],null],[28,\"gt\",[[23,2,[\"sessions\",\"length\"]],3],null]],null]],null,{\"statements\":[[0,\"    \"],[7,\"span\",false],[12,\"class\",\"show-more clickable link\"],[12,\"role\",\"button\"],[3,\"action\",[[23,0,[]],[28,\"mut\",[[23,0,[\"showMore\"]]],null],true]],[8],[0,\"\\n      \"],[5,\"fa-icon\",[],[[\"@icon\"],[\"angle-down\"]]],[0,\"\\n      \"],[1,[28,\"t\",[\"general.showMore\"],null],false],[0,\"\\n    \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ilios/templates/components/course-search-result.hbs"
    }
  });

  _exports.default = _default;
});