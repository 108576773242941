define("ilios/components/simple-chart-cluster", ["exports", "ember-simple-charts/components/simple-chart-cluster"], function (_exports, _simpleChartCluster) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _simpleChartCluster.default;
    }
  });
});