define("ilios/templates/course-visualize-vocabularies", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "K9oiUhtQ",
    "block": "{\"symbols\":[],\"statements\":[[7,\"section\",true],[10,\"class\",\"course-visualize-vocabularies\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"backtolink\"],[8],[0,\"\\n    \"],[5,\"link-to\",[],[[\"@route\",\"@model\"],[\"course-visualizations\",[22,\"model\"]]],{\"statements\":[[0,\"\\n      \"],[1,[28,\"t\",[\"general.otherVisualizations\"],null],false],[0,\"\\n    \"]],\"parameters\":[]}],[0,\"\\n  \"],[9],[0,\"\\n  \"],[7,\"h2\",true],[8],[0,\"\\n    \"],[1,[28,\"t\",[\"general.vocabularies\"],null],false],[0,\"\\n  \"],[9],[0,\"\\n  \"],[7,\"h3\",true],[10,\"class\",\"clickable\"],[8],[0,\"\\n    \"],[5,\"link-to\",[],[[\"@route\",\"@model\"],[\"course\",[22,\"model\"]]],{\"statements\":[[0,\"\\n      \"],[1,[24,[\"model\",\"title\"]],false],[0,\"\\n      \"],[1,[24,[\"model\",\"academicYear\"]],false],[0,\"\\n    \"]],\"parameters\":[]}],[0,\"\\n  \"],[9],[0,\"\\n  \"],[5,\"visualizer-course-vocabularies\",[],[[\"@course\"],[[22,\"model\"]]]],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ilios/templates/course-visualize-vocabularies.hbs"
    }
  });

  _exports.default = _default;
});