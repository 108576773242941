define("ilios/components/programyear-overview", ["exports", "ilios/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    IliosFeatures: {
      programYearVisualizations
    }
  } = _environment.default;

  var _default = Ember.Component.extend({
    classNames: ['programyear-overview'],
    canUpdate: false,
    directorsSort: null,
    programYear: null,
    programYearVisualizations,
    directorsWithFullName: Ember.computed.filterBy('programYear.directors', 'fullName'),
    sortedDirectors: Ember.computed.sort('directorsWithFullName', 'directorsSort'),

    init() {
      this._super(...arguments);

      this.set('directorsSort', ['lastName', 'firstName']);
    },

    actions: {
      addDirector(user) {
        let programYear = this.programYear;
        programYear.get('directors').then(directors => {
          directors.addObject(user);
          user.get('programYears').addObject(programYear);
          programYear.save();
        });
      },

      removeDirector(user) {
        let programYear = this.programYear;
        programYear.get('directors').then(directors => {
          directors.removeObject(user);
          user.get('programYears').removeObject(programYear);
          programYear.save();
        });
      }

    }
  });

  _exports.default = _default;
});