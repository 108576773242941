define("ilios/templates/components/new-programyear", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "TZNZc9qa",
    "block": "{\"symbols\":[\"obj\"],\"statements\":[[7,\"h4\",true],[10,\"class\",\"title\"],[8],[0,\"\\n  \"],[1,[28,\"t\",[\"general.newProgramYear\"],null],false],[0,\"\\n\"],[9],[0,\"\\n\"],[7,\"div\",true],[10,\"class\",\"form\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"startyear-select\"],[8],[0,\"\\n    \"],[7,\"label\",true],[8],[0,\"\\n      \"],[1,[28,\"t\",[\"general.academicYear\"],null],false],[0,\":\\n    \"],[9],[0,\"\\n    \"],[7,\"select\",true],[11,\"onchange\",[28,\"action\",[[23,0,[]],[28,\"mut\",[[24,[\"year\"]]],null]],[[\"value\"],[\"target.value\"]]]],[8],[0,\"\\n\"],[4,\"each\",[[28,\"sort-by\",[\"value\",[24,[\"availableAcademicYears\"]]],null]],null,{\"statements\":[[0,\"        \"],[7,\"option\",true],[11,\"value\",[23,1,[\"value\"]]],[11,\"selected\",[28,\"eq\",[[23,1,[]],[24,[\"selectedYear\"]]],null]],[8],[0,\"\\n          \"],[1,[23,1,[\"label\"]],false],[0,\"\\n        \"],[9],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"buttons\"],[8],[0,\"\\n    \"],[7,\"button\",false],[12,\"class\",\"done text\"],[3,\"action\",[[23,0,[]],[28,\"perform\",[[24,[\"saveNewYear\"]]],null]]],[8],[0,\"\\n\"],[4,\"if\",[[24,[\"saveNewYear\",\"isRunning\"]]],null,{\"statements\":[[0,\"        \"],[5,\"loading-spinner\",[],[[],[]]],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"        \"],[1,[28,\"t\",[\"general.done\"],null],false],[0,\"\\n\"]],\"parameters\":[]}],[0,\"    \"],[9],[0,\"\\n    \"],[7,\"button\",false],[12,\"class\",\"cancel text\"],[3,\"action\",[[23,0,[]],[24,[\"cancel\"]]]],[8],[0,\"\\n      \"],[1,[28,\"t\",[\"general.cancel\"],null],false],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ilios/templates/components/new-programyear.hbs"
    }
  });

  _exports.default = _default;
});