define("ilios/routes/curriculum-inventory-report/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    store: Ember.inject.service(),
    permissionChecker: Ember.inject.service(),
    canUpdate: false,

    async afterModel(report) {
      await report.get('topLevelSequenceBlocks');
      const permissionChecker = this.permissionChecker;
      const canUpdate = await permissionChecker.canUpdateCurriculumInventoryReport(report);
      this.set('canUpdate', canUpdate);
    },

    setupController(controller, model) {
      this._super(controller, model);

      controller.set('canUpdate', this.canUpdate);
    }

  });

  _exports.default = _default;
});