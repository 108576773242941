define("ilios/components/curriculum-inventory-verification-preview-table2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    'data-test-curriculum-inventory-verification-preview-table2': true,
    classNames: ['curriculum-inventory-verification-preview-table2'],
    methodTotals: Ember.computed('data.methods', function () {
      return this.data.methods.map(method => {
        return (method.total / 60).toFixed(2);
      });
    }),
    nonClerkships: Ember.computed('data.rows', 'data.methods', function () {
      const methods = this.data.methods;
      return this.data.rows.map(row => {
        return {
          title: row.title,
          level: row.level,
          methods: methods.map(method => {
            let minutes = row.instructional_methods[method.title];

            if (minutes) {
              return (minutes / 60).toFixed(2);
            }

            return '';
          }),
          total: (row.total / 60).toFixed(2)
        };
      });
    }),
    sumTotal: Ember.computed('data.methods', function () {
      const sumTotal = this.data.methods.reduce((value, method) => {
        return value + method.total;
      }, 0);
      return (sumTotal / 60).toFixed(2);
    })
  });

  _exports.default = _default;
});