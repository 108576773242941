define("ilios/breakpoints", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    // Ilios Additions from ilios-common/mixins/media.scss
    phoneOnly: '(max-width: 380px)',
    tabletAndUp: '(min-width: 768px)',
    laptopAndUp: '(min-width: 1200px)',
    smallerThanLaptop: '(max-width: 1199px)',
    desktopAndUp: '(min-width: 1920px)'
  };
  _exports.default = _default;
});