define("ilios/components/new-course", ["exports", "moment", "ember-cp-validations", "ilios-common/mixins/validation-error-display", "ember-concurrency"], function (_exports, _moment, _emberCpValidations, _validationErrorDisplay, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const Validations = (0, _emberCpValidations.buildValidations)({
    currentSchool: (0, _emberCpValidations.validator)('presence', true),
    selectedYear: (0, _emberCpValidations.validator)('presence', true),
    title: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('length', {
      min: 3,
      max: 200
    })]
  });
  const THIS_YEAR = parseInt((0, _moment.default)().format('YYYY'), 10);
  const YEARS = [THIS_YEAR - 2, THIS_YEAR - 1, THIS_YEAR, THIS_YEAR + 1, THIS_YEAR + 2];

  var _default = Ember.Component.extend(Validations, _validationErrorDisplay.default, {
    intl: Ember.inject.service(),
    store: Ember.inject.service(),
    classNames: ['new-course'],
    currentSchool: null,
    currentYear: null,
    selectedYear: null,
    title: null,

    cancel() {},

    save() {},

    years: Object.freeze(YEARS),
    isSaving: Ember.computed.reads('saveCourse.isRunning'),
    actions: {
      setYear(year) {
        this.set('selectedYear', parseInt(year, 10));
      }

    },

    keyUp(event) {
      const keyCode = event.keyCode;
      const target = event.target;

      if ('text' !== target.type) {
        return;
      }

      if (13 === keyCode) {
        this.send('save');
        return;
      }

      if (27 === keyCode) {
        this.cancel();
      }
    },

    saveCourse: (0, _emberConcurrency.task)(function* () {
      this.send('addErrorDisplayFor', 'title');
      const {
        validations
      } = yield this.validate();

      if (validations.get('isValid')) {
        this.send('removeErrorDisplayFor', 'title');
        const course = this.store.createRecord('course', {
          level: 1,
          title: this.title,
          school: this.currentSchool,
          year: this.selectedYear
        });
        this.save(course);
      }
    })
  });

  _exports.default = _default;
});