define("ilios/components/file-upload", ["exports", "ember-uploader/components/file-field", "ember-uploader/uploaders/uploader", "ilios/utils/readable-file-size", "ember-concurrency"], function (_exports, _fileField, _uploader, _readableFileSize, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const MAXIMUM_UPLOAD_ATTEMPTS = 3;

  let IliosUploader = _uploader.default.extend({
    iliosHeaders: null,
    ajaxSettings: Ember.computed('iliosHeaders.[]', function () {
      return {
        headers: this.iliosHeaders
      };
    })
  });

  var _default = _fileField.default.extend({
    iliosConfig: Ember.inject.service(),
    intl: Ember.inject.service(),
    session: Ember.inject.service(),
    url: '',
    headers: Ember.computed('session.isAuthenticated', function () {
      const session = this.session;
      const {
        jwt
      } = session.data.authenticated;
      let headers = {};

      if (jwt) {
        headers['X-JWT-Authorization'] = "Token ".concat(jwt);
      }

      return headers;
    }),

    filesDidChange(files) {
      if (Ember.isEmpty(files)) {
        return;
      }

      const file = files[0];
      const iliosConfig = this.iliosConfig;
      iliosConfig.get('maxUploadSize').then(maxUploadSize => {
        if (file.size > maxUploadSize) {
          const intl = this.intl;
          const maxSize = (0, _readableFileSize.default)(maxUploadSize);
          this.setErrorMessage(intl.t('general.fileSizeError', {
            maxSize
          }));
        } else {
          this.startUploading();
          const uploadUrl = this.url;
          const uploader = IliosUploader.create({
            url: uploadUrl,
            iliosHeaders: this.headers
          });
          uploader.on('didUpload', e => {
            this.finishedUploading(e);
          });
          uploader.on('progress', e => {
            this.setUploadPercentage(e.percent);
          });
          return this.upload.perform(uploader, file, 0);
        }
      });
    },

    upload: (0, _emberConcurrency.task)(function* (uploader, file, attempt) {
      try {
        let data = yield uploader.upload(file);
        return data;
      } catch (error) {
        this.setUploadPercentage(0);
        yield (0, _emberConcurrency.timeout)(attempt * 1000);

        if (attempt < MAXIMUM_UPLOAD_ATTEMPTS) {
          this.upload.perform(uploader, file, attempt + 1);
        } else {
          const intl = this.intl;
          throw new Error(intl.t('general.fileUploadError'));
        }
      }
    }).restartable()
  });

  _exports.default = _default;
});