define("ilios/components/curriculum-inventory-report-header", ["exports", "ember-cp-validations", "ilios-common/mixins/validation-error-display", "ember-concurrency", "fetch"], function (_exports, _emberCpValidations, _validationErrorDisplay, _emberConcurrency, _fetch) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const Validations = (0, _emberCpValidations.buildValidations)({
    reportName: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('length', {
      min: 3,
      max: 200
    })]
  });

  var _default = Ember.Component.extend(Validations, _validationErrorDisplay.default, {
    ajax: Ember.inject.service(),
    flashMessages: Ember.inject.service(),
    classNames: ['curriculum-inventory-report-header'],
    canUpdate: false,
    isDownloading: false,
    report: null,
    reportName: null,
    publishTarget: Ember.computed.alias('report'),

    didReceiveAttrs() {
      this._super(...arguments);

      this.set('reportName', this.get('report.name'));
    },

    actions: {
      async changeName() {
        const {
          report,
          reportName
        } = this.getProperties('report', 'reportName');
        this.send('addErrorDisplayFor', 'reportName');
        const {
          validations
        } = await this.validate();

        if (validations.isValid) {
          this.send('removeErrorDisplayFor', 'reportName');
          report.set('name', reportName);
          const newReport = await report.save();
          this.set('reportName', newReport.name);
          this.set('report', newReport);
        } else {
          await Ember.RSVP.reject();
        }
      },

      revertNameChanges() {
        const report = this.report;
        this.set('reportName', report.get('name'));
      },

      finalize() {
        this.finalize();
      }

    },
    downloadReport: (0, _emberConcurrency.task)(function* (report) {
      this.set('isDownloading', true);
      const {
        saveAs
      } = yield emberAutoImportDynamic("file-saver");
      const response = yield (0, _fetch.default)(report.absoluteFileUri);
      const blob = yield response.blob();
      saveAs(blob, 'report.xml');
      this.set('isDownloading', false);
    }).drop()
  });

  _exports.default = _default;
});