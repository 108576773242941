define("ilios/templates/components/user-profile-loading", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "+r7AXR59",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"user-profile-loading\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"blocks\"],[8],[0,\"\\n    \"],[5,\"manage-users-summary\",[],[[\"@canCreate\"],[[22,\"canCreate\"]]]],[0,\"\\n  \"],[9],[0,\"\\n  \"],[7,\"h1\",true],[8],[0,\"\\n    \"],[1,[28,\"t\",[\"general.loading\"],null],false],[0,\"\\n  \"],[9],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"blocks\"],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"loading small-component has-not-saved\"],[8],[9],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"loading small-component has-not-saved\"],[8],[9],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"loading small-component has-not-saved\"],[8],[9],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"loading small-component has-not-saved\"],[8],[9],[0,\"\\n  \"],[9],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ilios/templates/components/user-profile-loading.hbs"
    }
  });

  _exports.default = _default;
});