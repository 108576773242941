define("ilios/components/program-leadership-expanded", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['program-leadership-expanded'],
    canUpdate: null,
    'data-test-program-leadership-expanded': true,
    program: null,
    isManaging: false,

    collapse() {},

    expand() {},

    setIsManaging() {},

    isSaving: Ember.computed.reads('save.isRunning'),
    directors: Ember.computed('directorsToAdd.[]', 'directorsToRemove.[]', 'program', async function () {
      const directors = await this.program.directors;
      return directors.toArray().concat(this.directorsToAdd).filter(user => !this.directorsToRemove.includes(user));
    }),

    init() {
      this._super(...arguments);

      this.setProperties({
        directorsToAdd: [],
        directorsToRemove: []
      });
    },

    actions: {
      addDirector(user) {
        this.directorsToRemove.removeObject(user);
        this.directorsToAdd.pushObject(user);
      },

      removeDirector(user) {
        this.directorsToAdd.removeObject(user);
        this.directorsToRemove.pushObject(user);
      },

      cancel() {
        this.setIsManaging(false);
        this.setProperties({
          directorsToAdd: [],
          directorsToRemove: []
        });
      }

    },
    save: (0, _emberConcurrency.task)(function* () {
      yield (0, _emberConcurrency.timeout)(10);
      const program = this.program;
      const directors = yield this.directors;
      program.set('directors', directors);
      this.expand();
      yield program.save();
      this.setIsManaging(false);
    })
  });

  _exports.default = _default;
});