define("ilios/components/instructorgroup-list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    canDelete: false,
    instructorGroups: null,
    query: null,

    remove() {},

    proxiedInstructorGroups: Ember.computed('instructorGroups.[]', function () {
      const instructorGroups = this.instructorGroups;

      if (!instructorGroups) {
        return [];
      }

      return instructorGroups.map(instructorGroup => {
        return Ember.ObjectProxy.create({
          content: instructorGroup,
          showRemoveConfirmation: false
        });
      });
    }),
    actions: {
      remove(instructorGroupProxy) {
        this.remove(instructorGroupProxy.get('content'));
      },

      cancelRemove(instructorGroupProxy) {
        instructorGroupProxy.set('showRemoveConfirmation', false);
      },

      confirmRemove(instructorGroupProxy) {
        instructorGroupProxy.set('showRemoveConfirmation', true);
      }

    }
  });

  _exports.default = _default;
});