define("ilios/routes/course/index", ["exports", "ilios-common/mixins/course/index-route"], function (_exports, _indexRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_indexRoute.default);

  _exports.default = _default;
});