define("ilios/templates/users", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "77/d0X1/",
    "block": "{\"symbols\":[],\"statements\":[[1,[28,\"page-title\",[[28,\"t\",[\"general.admin\"],null]],null],false],[0,\"\\n\"],[5,\"link-to\",[],[[\"@route\"],[\"admin-dashboard\"]],{\"statements\":[[0,\"\\n  \"],[1,[28,\"t\",[\"general.backToAdminDashboard\"],null],false],[0,\"\\n\"]],\"parameters\":[]}],[0,\"\\n\"],[5,\"ilios-users\",[],[[\"@offset\",\"@setOffset\",\"@limit\",\"@setLimit\",\"@query\",\"@setQuery\",\"@showNewUserForm\",\"@setShowNewUserForm\",\"@showBulkNewUserForm\",\"@setShowBulkNewUserForm\",\"@searchTerms\",\"@setSearchTerms\",\"@transitionToUser\"],[[22,\"offset\"],[28,\"action\",[[23,0,[]],[28,\"mut\",[[24,[\"offset\"]]],null]],null],[22,\"limit\"],[28,\"action\",[[23,0,[]],[28,\"mut\",[[24,[\"limit\"]]],null]],null],[22,\"query\"],[28,\"action\",[[23,0,[]],[28,\"mut\",[[24,[\"query\"]]],null]],[[\"value\"],[\"target.value\"]]],[22,\"showNewUserForm\"],[28,\"action\",[[23,0,[]],[28,\"mut\",[[24,[\"showNewUserForm\"]]],null]],null],[22,\"showBulkNewUserForm\"],[28,\"action\",[[23,0,[]],[28,\"mut\",[[24,[\"showBulkNewUserForm\"]]],null]],null],[22,\"searchTerms\"],[28,\"action\",[[23,0,[]],[28,\"mut\",[[24,[\"searchTerms\"]]],null]],null],[28,\"action\",[[23,0,[]],\"transitionToUser\"],null]]]]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ilios/templates/users.hbs"
    }
  });

  _exports.default = _default;
});