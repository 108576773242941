define("ilios/controllers/instructor-groups", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    currentUser: Ember.inject.service(),
    intl: Ember.inject.service(),
    permissionChecker: Ember.inject.service(),
    queryParams: {
      schoolId: 'school',
      titleFilter: 'filter'
    },
    deletedInstructorGroup: null,
    newInstructorGroup: null,
    schoolId: null,
    showNewInstructorGroupForm: false,
    titleFilter: null,
    hasMoreThanOneSchool: Ember.computed.gt('model.schools.length', 1),
    instructorGroups: Ember.computed('selectedSchool', 'deletedInstructorGroup', 'newInstructorGroup', async function () {
      let schoolId = this.selectedSchool.get('id');

      if (Ember.isEmpty(schoolId)) {
        Ember.RSVP.resolve([]);
      }

      return await this.store.query('instructor-group', {
        filters: {
          school: schoolId
        }
      });
    }),
    filteredInstructorGroups: Ember.computed('titleFilter', 'instructorGroups.[]', async function () {
      const titleFilter = this.titleFilter;
      const title = Ember.isBlank(titleFilter) ? '' : titleFilter;
      const instructorGroups = await this.instructorGroups;
      let filteredInstructorGroups;

      if (Ember.isEmpty(title)) {
        filteredInstructorGroups = instructorGroups;
      } else {
        filteredInstructorGroups = instructorGroups.filter(instructorGroup => {
          return Ember.isPresent(instructorGroup.get('title')) && instructorGroup.get('title').toLowerCase().includes(title.toLowerCase());
        });
      }

      return filteredInstructorGroups.sortBy('title');
    }),
    selectedSchool: Ember.computed('model.schools.[]', 'schoolId', 'primarySchool', function () {
      const schools = this.get('model.schools');
      const primarySchool = this.get('model.primarySchool');
      const schoolId = this.schoolId;

      if (Ember.isPresent(schoolId)) {
        let school = schools.findBy('id', schoolId);

        if (school) {
          return school;
        }
      }

      return primarySchool;
    }),
    canCreate: Ember.computed('selectedSchool', async function () {
      const permissionChecker = this.permissionChecker;
      const selectedSchool = this.selectedSchool;
      return permissionChecker.canCreateInstructorGroup(selectedSchool);
    }),
    canDelete: Ember.computed('selectedSchool', async function () {
      const permissionChecker = this.permissionChecker;
      const selectedSchool = this.selectedSchool;
      return permissionChecker.canDeleteInstructorGroupInSchool(selectedSchool);
    }),
    actions: {
      async removeInstructorGroup(instructorGroup) {
        const school = this.selectedSchool;
        const instructorGroups = await school.get('instructorGroups');
        instructorGroups.removeObject(instructorGroup);
        await instructorGroup.destroyRecord();
        this.set('deletedInstructorGroup', instructorGroup);
        const newInstructorGroup = this.newInstructorGroup;

        if (newInstructorGroup === instructorGroup) {
          this.set('newInstructorGroup', null);
        }
      },

      async saveNewInstructorGroup(newInstructorGroup) {
        const savedInstructorGroup = await newInstructorGroup.save();
        this.set('showNewInstructorGroupForm', false);
        this.set('newInstructorGroup', savedInstructorGroup);
        const school = await this.selectedSchool;
        const instructorGroups = await school.get('instructorGroups');
        instructorGroups.pushObject(savedInstructorGroup);
        return savedInstructorGroup;
      },

      changeSelectedSchool(schoolId) {
        this.set('schoolId', schoolId);
      },

      toggleNewInstructorGroupForm() {
        this.set('showNewInstructorGroupForm', !this.showNewInstructorGroupForm);
      }

    },
    changeTitleFilter: (0, _emberConcurrency.task)(function* (value) {
      this.set('titleFilter', value);
      yield (0, _emberConcurrency.timeout)(250);
      return value;
    }).restartable()
  });

  _exports.default = _default;
});