define("ilios/templates/four-oh-four", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Y8Zp+5pd",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"full-screen-error\"],[8],[0,\"\\n  \"],[5,\"not-found\",[],[[],[]]],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ilios/templates/four-oh-four.hbs"
    }
  });

  _exports.default = _default;
});