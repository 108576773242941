define("ilios/components/new-programyear", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['new-programyear'],
    availableAcademicYears: null,
    year: null,
    selectedYear: Ember.computed('year', 'availableAcademicYears.[]', function () {
      const year = this.year;
      const availableAcademicYears = this.availableAcademicYears;

      if (!year) {
        return availableAcademicYears.firstObject;
      }

      return availableAcademicYears.findBy('value', parseInt(year, 10));
    }),
    saveNewYear: (0, _emberConcurrency.task)(function* () {
      const selectedYear = this.selectedYear;
      const startYear = parseInt(selectedYear.value, 10);
      yield this.save(startYear);
    }).drop()
  });

  _exports.default = _default;
});