define("ilios/templates/components/curriculum-inventory-verification-preview-table3a", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "4NTEv1vf",
    "block": "{\"symbols\":[\"row\",\"@data\"],\"statements\":[[7,\"h4\",true],[10,\"id\",\"verification-preview-table3a\"],[8],[0,\"\\n  \"],[1,[28,\"t\",[\"general.table3aNonClerkshipSequenceBlockInstructionalTime\"],null],false],[0,\"\\n\"],[9],[0,\"\\n\"],[7,\"table\",true],[8],[0,\"\\n  \"],[7,\"thead\",true],[8],[0,\"\\n    \"],[7,\"tr\",true],[8],[0,\"\\n      \"],[7,\"th\",true],[10,\"colspan\",\"2\"],[8],[0,\"\\n        \"],[1,[28,\"t\",[\"general.nonClerkshipSequenceBlocks\"],null],false],[0,\"\\n      \"],[9],[0,\"\\n      \"],[7,\"th\",true],[8],[0,\"\\n        \"],[1,[28,\"t\",[\"general.academicLevel\"],null],false],[0,\"\\n      \"],[9],[0,\"\\n      \"],[7,\"th\",true],[8],[0,\"\\n        \"],[1,[28,\"t\",[\"general.totalWeeks\"],null],false],[0,\"\\n      \"],[9],[0,\"\\n      \"],[7,\"th\",true],[8],[0,\"\\n        \"],[1,[28,\"t\",[\"general.averageHoursOfInstructionPerWeek\"],null],false],[0,\"\\n      \"],[9],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n  \"],[7,\"tbody\",true],[8],[0,\"\\n\"],[4,\"each\",[[23,2,[]]],null,{\"statements\":[[0,\"      \"],[7,\"tr\",true],[8],[0,\"\\n        \"],[7,\"td\",true],[10,\"colspan\",\"2\"],[8],[0,\"\\n          \"],[1,[23,1,[\"title\"]],false],[0,\"\\n        \"],[9],[0,\"\\n        \"],[7,\"td\",true],[8],[0,\"\\n          \"],[1,[23,1,[\"level\"]],false],[0,\"\\n        \"],[9],[0,\"\\n        \"],[7,\"td\",true],[8],[0,\"\\n          \"],[1,[23,1,[\"weeks\"]],false],[0,\"\\n        \"],[9],[0,\"\\n        \"],[7,\"td\",true],[8],[0,\"\\n          \"],[1,[23,1,[\"avg\"]],false],[0,\"\\n        \"],[9],[0,\"\\n      \"],[9],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"  \"],[9],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ilios/templates/components/curriculum-inventory-verification-preview-table3a.hbs"
    }
  });

  _exports.default = _default;
});