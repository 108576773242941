define("ilios/components/school-session-type-form", ["exports", "ember-concurrency", "ilios-common/mixins/validation-error-display", "ember-cp-validations"], function (_exports, _emberConcurrency, _validationErrorDisplay, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const Validations = (0, _emberCpValidations.buildValidations)({
    title: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('length', {
      max: 100
    })],
    calendarColor: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('format', {
      regex: /^#[a-fA-F0-9]{6}$/
    })],
    selectedAamcMethodId: [(0, _emberCpValidations.validator)(function (aamcMethodId, options, model) {
      if (!aamcMethodId) {
        return true;
      }

      const assessment = model.get('assessment');
      const lookup = assessment ? 'AM' : 'IM';

      if (aamcMethodId.indexOf(lookup) !== 0) {
        return this.createErrorMessage(options.messageKey, aamcMethodId, options);
      }

      return true;
    }, {
      dependentKeys: ['model.assessment'],
      descriptionKey: 'general.aamcMethod',
      messageKey: 'general.invalid'
    })]
  });

  var _default = Ember.Component.extend(_validationErrorDisplay.default, Validations, {
    store: Ember.inject.service(),
    classNames: ['school-session-type-form'],
    assessment: false,
    calendarColor: null,
    canEditAamcMethod: false,
    canEditActive: false,
    canEditAssessment: false,
    canEditAssessmentOption: false,
    canEditCalendarColor: false,
    canEditTitle: false,
    canUpdate: false,
    isActive: true,
    selectedAamcMethodId: null,
    selectedAssessmentOptionId: null,
    title: null,
    assessmentOptions: Ember.computed(async function () {
      const store = this.store;
      return await store.findAll('assessment-option');
    }),
    allAamcMethods: Ember.computed(async function () {
      const store = this.store;
      const aamcMethods = await store.findAll('aamc-method');
      return aamcMethods;
    }),
    filteredAamcMethods: Ember.computed('allAamcMethods.[]', 'assessment', 'selectedAamcMethodId', async function () {
      const assessment = this.assessment;
      const aamcMethods = await this.allAamcMethods;
      const selectedAamcMethodId = this.selectedAamcMethodId;
      return aamcMethods.filter(aamcMethod => {
        const id = aamcMethod.get('id');

        if (id !== selectedAamcMethodId && !aamcMethod.get('active')) {
          return false;
        }

        if (assessment) {
          return id.indexOf('AM') === 0;
        } else {
          return id.indexOf('IM') === 0;
        }
      });
    }),
    selectedAamcMethod: Ember.computed('filteredAamcMethods.[]', 'selectedAamcMethodId', async function () {
      const filteredAamcMethods = await this.filteredAamcMethods;
      const selectedAamcMethodId = this.selectedAamcMethodId;

      if (Ember.isPresent(selectedAamcMethodId)) {
        const selectedAamcMethod = filteredAamcMethods.findBy('id', selectedAamcMethodId);

        if (selectedAamcMethod) {
          return selectedAamcMethod;
        }
      }

      return null;
    }),
    selectedAssessmentOption: Ember.computed('assessmentOptions.[]', 'selectedAssessmentOptionId', 'assessment', async function () {
      const assessment = this.assessment;
      const selectedAssessmentOptionId = this.selectedAssessmentOptionId;
      const assessmentOptions = await this.assessmentOptions;
      let assessmentOption = null;

      if (assessment) {
        assessmentOption = selectedAssessmentOptionId ? assessmentOptions.findBy('id', selectedAssessmentOptionId) : assessmentOptions.sortBy('name').get('firstObject');
      }

      return assessmentOption;
    }),
    actions: {
      updateAssessment(value) {
        this.set('selectedAamcMethodId', null);
        this.set('assessment', value);
      }

    },

    keyUp(event) {
      const keyCode = event.keyCode;
      const target = event.target;

      if ('text' !== target.type) {
        return;
      }

      if (13 === keyCode) {
        this.saveSessionType.perform();
        return;
      }

      if (27 === keyCode) {
        this.close();
      }
    },

    saveSessionType: (0, _emberConcurrency.task)(function* () {
      this.send('addErrorDisplaysFor', ['title', 'calendarColor', 'selectedAamcMethodId']);
      let {
        validations
      } = yield this.validate();

      if (validations.get('isInvalid')) {
        return;
      }

      const title = this.title;
      const calendarColor = this.calendarColor;
      const assessment = this.assessment;
      const aamcMethod = yield this.selectedAamcMethod;
      const assessmentOption = yield this.selectedAssessmentOption;
      const isActive = this.isActive;
      const save = this.save;
      yield save(title, calendarColor, assessment, assessmentOption, aamcMethod, isActive);
      this.send('clearErrorDisplay');
    })
  });

  _exports.default = _default;
});