define("ilios/templates/session/copy", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "hmfyARfs",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"session-details\"],[8],[0,\"\\n  \"],[5,\"session-overview\",[],[[\"@session\",\"@editable\",\"@showCheckLink\",\"@sessionTypes\"],[[22,\"model\"],[22,\"canUpdate\"],false,[24,[\"model\",\"sessionTypes\"]]]]],[0,\"\\n  \"],[5,\"session-copy\",[],[[\"@session\",\"@visit\"],[[22,\"model\"],[28,\"action\",[[23,0,[]],\"loadSession\"],null]]]],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ilios/templates/session/copy.hbs"
    }
  });

  _exports.default = _default;
});