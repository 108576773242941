define("ilios/components/learning-materials-sort-manager", ["exports", "ilios-common/components/learning-materials-sort-manager"], function (_exports, _learningMaterialsSortManager) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _learningMaterialsSortManager.default;
    }
  });
});