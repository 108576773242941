define("ilios/controllers/courses", ["exports", "ember-concurrency", "moment"], function (_exports, _emberConcurrency, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    currentUser: Ember.inject.service(),
    intl: Ember.inject.service(),
    permissionChecker: Ember.inject.service(),
    queryParams: {
      schoolId: 'school',
      sortCoursesBy: 'sortBy',
      titleFilter: 'filter',
      yearTitle: 'year',
      userCoursesOnly: 'mycourses'
    },
    deletedCourse: null,
    newCourse: null,
    schoolId: null,
    showNewCourseForm: false,
    sortCoursesBy: 'title',
    sortSchoolsBy: null,
    sortYearsBy: null,
    titleFilter: null,
    userCoursesOnly: false,
    yearTitle: null,
    hasMoreThanOneSchool: Ember.computed.gt('model.schools.length', 1),
    sortedSchools: Ember.computed.sort('model.schools', 'sortSchoolsBy'),
    sortedYears: Ember.computed.sort('model.years', 'sortYearsBy'),
    courses: Ember.computed('selectedSchool', 'selectedYear', 'deletedCourse', 'newCourse', async function () {
      const selectedSchool = this.selectedSchool;
      const selectedYear = this.selectedYear;

      if (Ember.isEmpty(selectedSchool) || Ember.isEmpty(selectedYear)) {
        return [];
      }

      let schoolId = selectedSchool.get('id');
      let yearTitle = selectedYear.get('title');
      return await this.store.query('course', {
        filters: {
          school: schoolId,
          year: yearTitle,
          archived: false
        }
      });
    }),
    allRelatedCourses: Ember.computed('currentUser.model.allRelatedCourses.[]', async function () {
      const currentUser = this.currentUser;
      const user = await currentUser.get('model');
      return await user.get('allRelatedCourses');
    }),
    filteredCourses: Ember.computed('titleFilter', 'courses.[]', 'userCoursesOnly', 'allRelatedCourses.[]', async function () {
      const titleFilter = this.titleFilter;
      const title = Ember.isBlank(titleFilter) ? '' : titleFilter;
      let filterMyCourses = this.userCoursesOnly;
      const courses = await this.courses;
      let filteredCourses;

      if (Ember.isEmpty(title)) {
        filteredCourses = courses.sortBy('title');
      } else {
        filteredCourses = courses.filter(course => {
          return Ember.isPresent(course.get('title')) && course.get('title').toLowerCase().includes(title.toLowerCase()) || Ember.isPresent(course.get('externalId')) && course.get('externalId').toLowerCase().includes(title.toLowerCase());
        }).sortBy('title');
      }

      if (filterMyCourses) {
        const allRelatedCourses = await this.allRelatedCourses;
        filteredCourses = filteredCourses.filter(course => allRelatedCourses.includes(course));
      }

      return filteredCourses;
    }),
    selectedSchool: Ember.computed('model.schools.[]', 'schoolId', 'primarySchool', function () {
      const schools = this.get('model.schools');
      const primarySchool = this.get('model.primarySchool');
      const schoolId = this.schoolId;

      if (Ember.isPresent(schoolId)) {
        let school = schools.findBy('id', schoolId);

        if (school) {
          return school;
        }
      }

      return primarySchool;
    }),
    selectedYear: Ember.computed('model.years.[]', 'yearTitle', function () {
      let years = this.get('model.years');

      if (Ember.isPresent(this.yearTitle)) {
        return years.find(year => year.get('title') === parseInt(this.yearTitle, 10));
      }

      let currentYear = parseInt((0, _moment.default)().format('YYYY'), 10);
      const currentMonth = parseInt((0, _moment.default)().format('M'), 10);

      if (currentMonth < 6) {
        currentYear--;
      }

      let defaultYear = years.find(year => parseInt(year.get('id'), 10) === currentYear);

      if (Ember.isEmpty(defaultYear)) {
        defaultYear = years.get('lastObject');
      }

      return defaultYear;
    }),
    canCreateCourse: Ember.computed('selectedSchool', async function () {
      const permissionChecker = this.permissionChecker;
      const selectedSchool = this.selectedSchool;
      return permissionChecker.canCreateCourse(selectedSchool);
    }),
    actions: {
      async removeCourse(course) {
        const school = await this.selectedSchool;
        const courses = school.get('courses');
        courses.removeObject(course);
        await course.destroyRecord();
        this.set('deletedCourse', course);
        let newCourse = this.newCourse;

        if (newCourse === course) {
          this.set('newCourse', null);
        }
      },

      async saveNewCourse(newCourse) {
        newCourse.setDatesBasedOnYear();
        const savedCourse = await newCourse.save();
        this.set('showNewCourseForm', false);
        this.set('newCourse', savedCourse);
        const school = await this.selectedSchool;
        const courses = await school.get('courses');
        courses.pushObject(savedCourse);
        return savedCourse;
      },

      changeSelectedYear(yearTitle) {
        this.set('yearTitle', yearTitle);
      },

      changeSelectedSchool(schoolId) {
        this.set('schoolId', schoolId);
      },

      toggleNewCourseForm() {
        this.set('showNewCourseForm', !this.showNewCourseForm);
      },

      lockCourse(course) {
        course.set('locked', true);
        return course.save();
      },

      unlockCourse(course) {
        course.set('locked', false);
        return course.save();
      }

    },
    changeTitleFilter: (0, _emberConcurrency.task)(function* (value) {
      this.set('titleFilter', value);
      yield (0, _emberConcurrency.timeout)(250);
      return value;
    }).restartable()
  });

  _exports.default = _default;
});