define("ilios/services/ilios-metrics", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    metrics: Ember.inject.service(),
    currentUser: Ember.inject.service(),
    iliosConfig: Ember.inject.service(),

    async setup() {
      const iliosConfig = this.iliosConfig;
      const metrics = this.metrics;
      const trackingEnabled = await iliosConfig.trackingEnabled;
      const trackingCode = await iliosConfig.trackingCode;

      if (!trackingEnabled || Ember.isEmpty(trackingCode)) {
        return false;
      } else {
        metrics.activateAdapters([{
          name: 'GoogleAnalytics',
          environments: ['all'],
          config: {
            id: trackingCode
          }
        }]);
        return true;
      }
    },

    track(page, title) {
      const setContext = async () => {
        const setupSuccessful = await this.setup();

        if (setupSuccessful) {
          const metrics = this.metrics;
          const user = await this.currentUser.model;

          if (user) {
            metrics.set('context.userId', user.id);
          }

          metrics.trackPage({
            page,
            title
          });
        }
      };

      Ember.run.scheduleOnce('afterRender', this, setContext);
    }

  });

  _exports.default = _default;
});