define("ilios/controllers/course/publishall", ["exports", "ilios-common/mixins/course/publish-all-controller"], function (_exports, _publishAllController) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend(_publishAllController.default, {});

  _exports.default = _default;
});