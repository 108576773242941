define("ilios/components/visualizer-session-type-vocabularies", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    router: Ember.inject.service(),
    classNameBindings: ['isIcon::not-icon', ':visualizer-session-type-vocabularies'],
    tagName: 'span',
    isIcon: false,
    sessionType: null,
    tooltipContent: null,
    tooltipTitle: null,
    data: Ember.computed('sessionType.sessions.[]', async function () {
      const sessionType = this.sessionType;
      const sessions = await sessionType.get('sessions');
      const terms = await Ember.RSVP.map(sessions.toArray(), async session => {
        const sessionTerms = await session.get('terms');
        const course = await session.get('course');
        const courseTerms = await course.get('terms');
        return [].concat(sessionTerms.toArray()).concat(courseTerms.toArray());
      });
      let flat = terms.reduce((flattened, obj) => {
        return flattened.pushObjects(obj.toArray());
      }, []);
      await terms.mapBy('vocabulary');
      let vocabularyObjects = {};

      for (let i = 0; i < flat.length; i++) {
        const term = flat[i];
        const vocabulary = await term.get('vocabulary');
        const id = vocabulary.get('id');

        if (typeof vocabularyObjects[id] !== "undefined") {
          vocabularyObjects[id].data++;
        } else {
          vocabularyObjects[id] = {
            data: 1,
            meta: {
              vocabulary
            }
          };
        }
      }

      const vocabularyData = [];
      Object.keys(vocabularyObjects).forEach(key => {
        vocabularyData.push(vocabularyObjects[key]);
      });
      const totalTerms = vocabularyData.mapBy('data').reduce((total, count) => total + count, 0);
      const data = vocabularyData.map(obj => {
        const percent = (obj.data / totalTerms * 100).toFixed(1);
        obj.label = "".concat(percent, "%");
        return obj;
      });
      return data;
    }),
    vocabulariesWithLinkedTerms: Ember.computed('data.[]', async function () {
      const data = await this.data;
      return data.filter(obj => obj.data !== 0);
    }),
    actions: {
      donutClick(obj) {
        const sessionType = this.sessionType;
        const isIcon = this.isIcon;
        const router = this.router;

        if (isIcon || Ember.isEmpty(obj) || obj.empty || Ember.isEmpty(obj.meta)) {
          return;
        }

        router.transitionTo('session-type-visualize-terms', sessionType.get('id'), obj.meta.vocabulary.get('id'));
      }

    },

    async getTooltipData(obj) {
      const isIcon = this.isIcon;

      if (isIcon || Ember.isEmpty(obj) || obj.empty) {
        return '';
      }

      const {
        meta
      } = obj;
      let vocabularyTitle = meta.vocabulary.get('title');
      const title = Ember.String.htmlSafe(vocabularyTitle);
      return {
        title,
        content: title
      };
    },

    donutHover: (0, _emberConcurrency.task)(function* (obj) {
      yield (0, _emberConcurrency.timeout)(100);
      const data = yield this.getTooltipData(obj);

      if (Ember.isPresent(data)) {
        this.set('tooltipTitle', data.title);
        this.set('tooltipContent', data.content);
      }
    }).restartable()
  });

  _exports.default = _default;
});