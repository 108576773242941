define("ilios/components/school-curriculum-inventory-institution-details", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    "data-test-school-curriculum-inventory-institution-details": true,
    classNames: ['school-curriculum-inventory-institution-details'],
    hasInstitution: Ember.computed('school.curriculumInventoryInstitution', function () {
      return !!this.school.belongsTo('curriculumInventoryInstitution').id();
    }),
    actions: {
      manage() {
        this.manage(true);
      }

    }
  });

  _exports.default = _default;
});