define("ilios/components/dashboard-view-picker", ["exports", "ilios-common/components/dashboard-view-picker"], function (_exports, _dashboardViewPicker) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _dashboardViewPicker.default;
    }
  });
});