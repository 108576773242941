define("ilios/components/visualizer-course-instructor-session-type", ["exports", "ilios-common/components/visualizer-course-instructor-session-type"], function (_exports, _visualizerCourseInstructorSessionType) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _visualizerCourseInstructorSessionType.default;
    }
  });
});