define("ilios/controllers/user", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    store: Ember.inject.service(),
    permissionChecker: Ember.inject.service(),
    currentUser: Ember.inject.service(),
    iliosConfig: Ember.inject.service(),
    queryParams: ['isManagingBio', 'isManagingRoles', 'isManagingCohorts', 'isManagingIcs', 'isManagingSchools'],
    isManagingBio: false,
    isManagingRoles: false,
    isManagingCohorts: false,
    isManagingIcs: false,
    isManagingSchools: false,
    canCreate: Ember.computed('currentUser', async function () {
      const schools = await this.store.findAll('school');
      const schoolsWithCreateUserPermission = await Ember.RSVP.filter(schools.toArray(), async school => {
        return this.permissionChecker.canCreateUser(school);
      });
      return schoolsWithCreateUserPermission.length > 0;
    })
  });

  _exports.default = _default;
});