define("ilios/controllers/program-year/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    queryParams: ['pyObjectiveDetails', 'pyTaxonomyDetails', 'pyCompetencyDetails', 'managePyCompetencies', 'pyStewardDetails', 'managePyStewards'],
    canUpdate: false,
    pyObjectiveDetails: false,
    pyTaxonomyDetails: false,
    pyCompetencyDetails: false,
    managePyCompetencies: false,
    pyStewardDetails: false,
    managePyStewards: false
  });

  _exports.default = _default;
});