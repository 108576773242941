define("ilios/components/school-session-attributes-expanded", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['school-session-attributes-expanded'],
    tagName: 'section',
    canUpdate: false,
    bufferedShowSessionAttendanceRequired: false,
    bufferedShowSessionSpecialAttireRequired: false,
    bufferedShowSessionSpecialEquipmentRequired: false,
    bufferedShowSessionSupplemental: false,
    isManaging: false,
    showSessionAttendanceRequired: false,
    showSessionSpecialAttireRequired: false,
    showSessionSpecialEquipmentRequired: false,
    showSessionSupplemental: false,

    didReceiveAttrs() {
      this._super(...arguments);

      const isManaging = this.isManaging;

      if (isManaging) {
        const showSessionAttendanceRequired = this.showSessionAttendanceRequired;
        const showSessionSupplemental = this.showSessionSupplemental;
        const showSessionSpecialAttireRequired = this.showSessionSpecialAttireRequired;
        const showSessionSpecialEquipmentRequired = this.showSessionSpecialEquipmentRequired;
        this.set('bufferedShowSessionAttendanceRequired', showSessionAttendanceRequired);
        this.set('bufferedShowSessionSupplemental', showSessionSupplemental);
        this.set('bufferedShowSessionSpecialAttireRequired', showSessionSpecialAttireRequired);
        this.set('bufferedShowSessionSpecialEquipmentRequired', showSessionSpecialEquipmentRequired);
      }
    },

    actions: {
      cancel() {
        this.manage(false);
        this.set('bufferedShowSessionAttendanceRequired', false);
        this.set('bufferedShowSessionSupplemental', false);
        this.set('bufferedShowSessionSpecialAttireRequired', false);
        this.set('bufferedShowSessionSpecialEquipmentRequired', false);
      },

      enableSessionAttributeConfig(name) {
        const bufferName = 'buffered' + name.capitalize();
        this.set(bufferName, true);
      },

      disableSessionAttributeConfig(name) {
        const bufferName = 'buffered' + name.capitalize();
        this.set(bufferName, false);
      }

    },
    save: (0, _emberConcurrency.task)(function* () {
      const bufferedShowSessionAttendanceRequired = this.bufferedShowSessionAttendanceRequired;
      const bufferedShowSessionSupplemental = this.bufferedShowSessionSupplemental;
      const bufferedShowSessionSpecialAttireRequired = this.bufferedShowSessionSpecialAttireRequired;
      const bufferedShowSessionSpecialEquipmentRequired = this.bufferedShowSessionSpecialEquipmentRequired;
      const showSessionAttendanceRequired = Ember.isEmpty(bufferedShowSessionAttendanceRequired) ? false : bufferedShowSessionAttendanceRequired;
      const showSessionSupplemental = Ember.isEmpty(bufferedShowSessionSupplemental) ? false : bufferedShowSessionSupplemental;
      const showSessionSpecialAttireRequired = Ember.isEmpty(bufferedShowSessionSpecialAttireRequired) ? false : bufferedShowSessionSpecialAttireRequired;
      const showSessionSpecialEquipmentRequired = Ember.isEmpty(bufferedShowSessionSpecialEquipmentRequired) ? false : bufferedShowSessionSpecialEquipmentRequired;
      const values = Ember.Object.create({
        showSessionAttendanceRequired,
        showSessionSupplemental,
        showSessionSpecialAttireRequired,
        showSessionSpecialEquipmentRequired
      });
      yield this.saveAll(values);
    })
  });

  _exports.default = _default;
});