define("ilios/templates/user", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "/fzM5CKo",
    "block": "{\"symbols\":[],\"statements\":[[1,[28,\"page-title\",[[28,\"t\",[\"general.admin\"],null]],null],false],[0,\"\\n\"],[4,\"if\",[[28,\"and\",[[24,[\"isLoading\"]],[28,\"is-fulfilled\",[[24,[\"canCreate\"]]],null]],null]],null,{\"statements\":[[0,\"  \"],[5,\"user-profile-loading\",[],[[\"@canCreate\"],[[28,\"await\",[[24,[\"canCreate\"]]],null]]]],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[28,\"is-fulfilled\",[[24,[\"canCreate\"]]],null]],null,{\"statements\":[[0,\"  \"],[5,\"user-profile\",[],[[\"@user\",\"@canUpdate\",\"@canCreate\",\"@isManagingBio\",\"@setIsManagingBio\",\"@isManagingRoles\",\"@setIsManagingRoles\",\"@isManagingCohorts\",\"@setIsManagingCohorts\",\"@isManagingIcs\",\"@setIsManagingIcs\",\"@isManagingSchools\",\"@setIsManagingSchools\"],[[22,\"model\"],[22,\"canUpdate\"],[28,\"await\",[[24,[\"canCreate\"]]],null],[22,\"isManagingBio\"],[28,\"action\",[[23,0,[]],[28,\"mut\",[[24,[\"isManagingBio\"]]],null]],null],[22,\"isManagingRoles\"],[28,\"action\",[[23,0,[]],[28,\"mut\",[[24,[\"isManagingRoles\"]]],null]],null],[22,\"isManagingCohorts\"],[28,\"action\",[[23,0,[]],[28,\"mut\",[[24,[\"isManagingCohorts\"]]],null]],null],[22,\"isManagingIcs\"],[28,\"action\",[[23,0,[]],[28,\"mut\",[[24,[\"isManagingIcs\"]]],null]],null],[22,\"isManagingSchools\"],[28,\"action\",[[23,0,[]],[28,\"mut\",[[24,[\"isManagingSchools\"]]],null]],null]]]],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ilios/templates/user.hbs"
    }
  });

  _exports.default = _default;
});