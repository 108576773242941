define("ilios/components/detail-instructors-list", ["exports", "ilios-common/components/detail-instructors-list"], function (_exports, _detailInstructorsList) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _detailInstructorsList.default;
    }
  });
});