define("ilios/components/school-vocabularies-expanded", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    classNames: ['school-vocabularies-expanded'],
    tagName: 'section',
    canCreateTerm: false,
    canCreateVocabulary: false,
    canDeleteTerm: false,
    canDeleteVocabulary: false,
    canUpdateTerm: false,
    canUpdateVocabulary: false,
    managedTerm: null,
    managedTermId: null,
    managedVocabulary: null,
    managedVocabularyId: null,
    school: null,
    isManaging: Ember.computed.notEmpty('managedVocabulary'),
    showCollapsible: Ember.computed('isManaging', 'school.vocabularies.length', function () {
      const isManaging = this.isManaging;
      const school = this.school;
      const competencyIds = school.hasMany('vocabularies').ids();
      return competencyIds.length && !isManaging;
    }),

    didReceiveAttrs() {
      this._super(...arguments);

      const managedVocabularyId = this.managedVocabularyId;
      const managedTermId = this.managedTermId;

      if (Ember.isPresent(managedVocabularyId)) {
        this.get('school.vocabularies').then(vocabularies => {
          let managedVocabulary = vocabularies.findBy('id', managedVocabularyId);
          this.set('managedVocabulary', managedVocabulary);

          if (Ember.isPresent(managedTermId)) {
            managedVocabulary.get('terms').then(terms => {
              let managedTerm = terms.findBy('id', managedTermId);
              this.set('managedTerm', managedTerm);
            });
          } else {
            this.set('managedTerm', null);
          }
        });
      } else {
        this.set('managedVocabulary', null);
      }
    },

    actions: {
      collapse() {
        const collapse = this.collapse;
        const setSchoolManagedVocabulary = this.setSchoolManagedVocabulary;
        const setSchoolManagedVocabularyTerm = this.setSchoolManagedVocabularyTerm;
        this.get('school.vocabularies').then(vocabularies => {
          if (vocabularies.get('length')) {
            collapse();
            setSchoolManagedVocabulary(null);
            setSchoolManagedVocabularyTerm(null);
          }
        });
      },

      cancel() {
        const setSchoolManagedVocabulary = this.setSchoolManagedVocabulary;
        setSchoolManagedVocabulary(null);
        this.set('bufferedTerms', []);
      }

    }
  });

  _exports.default = _default;
});