define("ilios/components/programyear-objective-list-item-expanded", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    objective: null,
    tagName: '',
    courseObjects: Ember.computed('objective.children', async function () {
      const objective = this.objective;
      const children = await objective.children;
      const objectiveObjects = await Ember.RSVP.map(children.toArray(), async courseObjective => {
        const courses = await courseObjective.courses;
        let obj = {
          title: courseObjective.title,
          courseId: null,
          courseTitle: null,
          courseExternalId: null
        };

        if (courses.length) {
          const course = courses.firstObject;
          obj.courseId = course.id;
          obj.courseTitle = course.title;
          obj.courseExternalId = course.externalId;
        }

        return obj;
      });
      return objectiveObjects.reduce((set, obj) => {
        let existing = set.findBy('id', obj.courseId);

        if (!existing) {
          let title = obj.courseTitle;

          if (obj.courseExternalId) {
            title += " (".concat(obj.courseExternalId, ")");
          }

          existing = {
            id: obj.courseId,
            title,
            objectives: []
          };
          set.pushObject(existing);
        }

        existing.objectives.pushObject({
          title: Ember.String.htmlSafe(obj.title)
        });
        return set;
      }, []);
    })
  });

  _exports.default = _default;
});