define("ilios/templates/course-visualize-term", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "V0AiAyoC",
    "block": "{\"symbols\":[],\"statements\":[[7,\"section\",true],[10,\"class\",\"course-visualize-term\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"backtolink\"],[8],[0,\"\\n    \"],[5,\"link-to\",[],[[\"@route\",\"@model\"],[\"course-visualizations\",[24,[\"model\",\"course\"]]]],{\"statements\":[[0,\"\\n      \"],[1,[28,\"t\",[\"general.otherVisualizations\"],null],false],[0,\"\\n    \"]],\"parameters\":[]}],[0,\"\\n  \"],[9],[0,\"\\n  \"],[7,\"h2\",true],[8],[0,\"\\n    \"],[1,[28,\"t\",[\"general.sessionTypesFor\"],[[\"subject\"],[[28,\"concat\",[[24,[\"model\",\"term\",\"vocabulary\",\"title\"]],\" - \",[24,[\"model\",\"term\",\"title\"]]],null]]]],false],[0,\"\\n  \"],[9],[0,\"\\n  \"],[7,\"h3\",true],[10,\"class\",\"clickable\"],[8],[0,\"\\n    \"],[5,\"link-to\",[],[[\"@route\",\"@model\"],[\"course\",[24,[\"model\",\"course\"]]]],{\"statements\":[[0,\"\\n      \"],[1,[24,[\"model\",\"course\",\"title\"]],false],[0,\"\\n      \"],[1,[24,[\"model\",\"course\",\"academicYear\"]],false],[0,\"\\n    \"]],\"parameters\":[]}],[0,\"\\n  \"],[9],[0,\"\\n  \"],[5,\"visualizer-course-term\",[],[[\"@course\",\"@term\"],[[24,[\"model\",\"course\"]],[24,[\"model\",\"term\"]]]]],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ilios/templates/course-visualize-term.hbs"
    }
  });

  _exports.default = _default;
});