define("ilios/templates/curriculum-inventory-report/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "gZwqQs9h",
    "block": "{\"symbols\":[],\"statements\":[[5,\"curriculum-inventory-sequence-block-list\",[],[[\"@report\",\"@sequenceBlocks\",\"@canUpdate\",\"@remove\"],[[28,\"await\",[[24,[\"model\"]]],null],[28,\"await\",[[24,[\"model\",\"topLevelSequenceBlocks\"]]],null],[22,\"canUpdate\"],[28,\"action\",[[23,0,[]],\"removeSequenceBlock\"],null]]]]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ilios/templates/curriculum-inventory-report/index.hbs"
    }
  });

  _exports.default = _default;
});