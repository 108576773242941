define("ilios/templates/program", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "TJcBegUV",
    "block": "{\"symbols\":[],\"statements\":[[1,[28,\"page-title\",[[28,\"t\",[\"general.programs\"],null]],null],false],[0,\"\\n\"],[5,\"program-details\",[],[[\"@program\",\"@canUpdate\",\"@leadershipDetails\",\"@setLeadershipDetails\",\"@manageLeadership\",\"@setManageLeadership\"],[[22,\"model\"],[22,\"canUpdate\"],[22,\"leadershipDetails\"],[28,\"action\",[[23,0,[]],[28,\"mut\",[[24,[\"leadershipDetails\"]]],null]],null],[22,\"manageLeadership\"],[28,\"action\",[[23,0,[]],[28,\"mut\",[[24,[\"manageLeadership\"]]],null]],null]]]],[0,\"\\n\"],[1,[22,\"outlet\"],false]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ilios/templates/program.hbs"
    }
  });

  _exports.default = _default;
});