define("ilios/components/selectable-search-result", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    item: null,
    selectedItems: Ember.A(),
    selected: Ember.computed('item', 'selectedItems.[]', function () {
      return this.selectedItems.includes(this.item);
    }),

    click() {
      this.action(this.item);
    }

  });

  _exports.default = _default;
});