define("ilios/components/learnergroup-calendar", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['learnergroup-calendar'],
    learnerGroup: null,
    selectedDate: null,
    showSubgroupEvents: false,
    offerings: Ember.computed('learnerGroup.offerings.[]', 'learnerGroup.allDescendants.[]', 'showSubgroupEvents', async function () {
      const learnerGroup = this.learnerGroup;
      const showSubgroupEvents = this.showSubgroupEvents;

      if (!learnerGroup) {
        return [];
      }

      let learnerGroups = [learnerGroup];

      if (showSubgroupEvents) {
        const allDescendants = await learnerGroup.get('allDescendants');
        learnerGroups.pushObjects(allDescendants);
      }

      const offerings = await Ember.RSVP.all(learnerGroups.mapBy('offerings'));
      let flat = offerings.reduce((flattened, obj) => {
        return flattened.pushObjects(obj.toArray());
      }, []);
      return flat;
    }),
    calendarEvents: Ember.computed('offerings.[]', async function () {
      const offerings = await this.offerings;
      const events = await Ember.RSVP.map(offerings.toArray(), async offering => {
        const session = await offering.get('session');
        const course = await session.get('course');
        return {
          startDate: offering.get('startDate'),
          endDate: offering.get('endDate'),
          courseTitle: course.get('title'),
          name: session.get('title'),
          offering: offering.get("id"),
          location: offering.get("location"),
          color: "#84c444",
          prerequisites: [],
          postrequisites: []
        };
      });
      return events;
    }),

    init() {
      this._super(...arguments);

      const today = (0, _moment.default)();
      this.set('selectedDate', today.toDate());
    },

    actions: {
      goForward() {
        const selectedDate = this.selectedDate;
        let newDate = (0, _moment.default)(selectedDate).add(1, 'week').toDate();
        this.set('selectedDate', newDate);
      },

      goBack() {
        const selectedDate = this.selectedDate;
        let newDate = (0, _moment.default)(selectedDate).subtract(1, 'week').toDate();
        this.set('selectedDate', newDate);
      },

      gotoToday() {
        let newDate = (0, _moment.default)().toDate();
        this.set('selectedDate', newDate);
      }

    }
  });

  _exports.default = _default;
});