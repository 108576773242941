define("ilios/components/detail-steward-manager", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    classNames: ['detail-steward-manager'],
    tagName: 'section',
    availableSchoolsLoaded: false,
    stewards: null,
    stewardsBySchoolLoaded: false,
    allSchools: Ember.computed(async function () {
      return await this.store.findAll('school');
    }),
    selectedDepartments: Ember.computed('stewards.[]', async function () {
      const stewards = this.stewards;
      const selectedDepartments = await Ember.RSVP.map(stewards.toArray(), async steward => {
        return await steward.get('department');
      });
      return selectedDepartments.filter(department => {
        return Ember.isPresent(department);
      });
    }),
    selectedSchools: Ember.computed('stewards.[]', async function () {
      const stewards = this.stewards;
      const selectedDepartments = await Ember.RSVP.map(stewards.toArray(), async steward => {
        return await steward.get('school');
      });
      return selectedDepartments.filter(department => {
        return Ember.isPresent(department);
      });
    }),
    stewardsBySchool: Ember.computed('stewards.[]', async function () {
      const stewards = this.stewards;
      const stewardObjects = await Ember.RSVP.map(stewards.toArray(), async steward => {
        const school = await steward.get('school');
        const department = await steward.get('department');
        return {
          school,
          department
        };
      });
      const schools = stewardObjects.uniqBy('school.id');
      const stewardsBySchool = schools.map(schoolObj => {
        const departments = stewardObjects.filter(obj => {
          return obj.school.get('id') === schoolObj.school.get('id') && Ember.isPresent(obj.department);
        }).mapBy('department');
        delete schoolObj.department;
        schoolObj.departments = departments;
        return schoolObj;
      });
      return stewardsBySchool;
    }),
    availableSchools: Ember.computed('selectedDepartments.[]', 'selectedSchools.[]', 'allSchools.[]', async function () {
      const allSchools = await this.allSchools;
      const selectedDepartments = await this.selectedDepartments;
      const selectedSchools = await this.selectedSchools;
      const selectedDepartmentIds = selectedDepartments.mapBy('id');
      const selectedSchoolIds = selectedSchools.mapBy('id');
      const unselectedSchoolsAndSchoolsWithUnselectedDepartments = await Ember.RSVP.filter(allSchools.toArray(), async school => {
        const departments = await school.get('departments');
        const unselectedDepartments = departments.filter(department => {
          return !selectedDepartmentIds.includes(department.get('id'));
        });
        return !selectedSchoolIds.includes(school.get('id')) || Ember.isPresent(unselectedDepartments);
      });
      const availableSchools = await Ember.RSVP.map(unselectedSchoolsAndSchoolsWithUnselectedDepartments.toArray(), async school => {
        const allSchoolDepartments = await school.get('departments');
        const departments = allSchoolDepartments.filter(department => {
          return !selectedDepartmentIds.includes(department.get('id'));
        });
        return {
          school,
          departments
        };
      });
      return availableSchools;
    }),

    /**
     * This is a hack because ember-async-helpers flickers when the CPs are evaluated
     * so we cheat and use ember-concurrency to display the values
     */
    didReceiveAttrs() {
      this._super(...arguments);

      this.getStewardsBySchool.perform();
      this.getAvailableSchools.perform();
    },

    getStewardsBySchool: (0, _emberConcurrency.task)(function* () {
      const stewardsBySchool = yield this.stewardsBySchool;
      this.set('stewardsBySchoolLoaded', true);
      return stewardsBySchool;
    }),
    getAvailableSchools: (0, _emberConcurrency.task)(function* () {
      const availableSchools = yield this.availableSchools;
      this.set('availableSchoolsLoaded', true);
      return availableSchools;
    }),
    addSchool: (0, _emberConcurrency.task)(function* (school) {
      const store = this.store;
      const selectedDepartments = yield this.selectedDepartments;
      const steward = store.createRecord('program-year-steward', {
        school
      });
      this.add(steward);
      const departments = yield school.get('departments');
      const newDepartments = departments.filter(department => {
        return !selectedDepartments.includes(department);
      });
      newDepartments.forEach(department => {
        const newSteward = store.createRecord('program-year-steward', {
          school,
          department
        });
        this.add(newSteward);
      });
    }),
    addDepartment: (0, _emberConcurrency.task)(function* (school, department) {
      const store = this.store;
      const selectedDepartments = yield this.selectedDepartments;

      if (!selectedDepartments.includes(department)) {
        const steward = store.createRecord('program-year-steward', {
          school,
          department
        });
        this.add(steward);
      }
    }),
    removeDepartment: (0, _emberConcurrency.task)(function* (school, department) {
      const stewards = this.stewards;
      const stewardToRemove = stewards.find(steward => {
        return department.get('id') === steward.belongsTo('department').id();
      });
      yield this.remove(stewardToRemove);
    }),
    removeSchool: (0, _emberConcurrency.task)(function* (school) {
      const stewards = this.stewards;
      const stewardsToRemove = stewards.filter(steward => {
        return school.get('id') === steward.belongsTo('school').id();
      });

      for (let i = 0; i < stewardsToRemove.length; i++) {
        yield this.remove(stewardsToRemove[i]);
      }
    })
  });

  _exports.default = _default;
});