define("ilios/components/collapsed-objectives-program-years", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['collapsed-objectives-program-years'],
    tagName: 'section',
    subject: null,

    expand() {},

    objectives: Ember.computed('subject.objectives.[]', async function () {
      return await this.subject.objectives;
    }),
    objectivesWithCompentency: Ember.computed('objectives.[]', async function () {
      const objectives = await this.objectives;
      const promises = objectives.mapBy('competency');
      const competencyArray = await Ember.RSVP.all(promises);
      return competencyArray.filter(competency => !!competency);
    }),
    objectivesWithMesh: Ember.computed('objectives.[]', async function () {
      const objectives = await this.objectives;
      const promises = objectives.mapBy('meshDescriptors');
      const meshDescriptorsArray = await Ember.RSVP.all(promises);
      return meshDescriptorsArray.filter(meshDescriptors => {
        return meshDescriptors ? meshDescriptors.length > 0 : false;
      });
    })
  });

  _exports.default = _default;
});