define("ilios/templates/program/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "g8LkTQmt",
    "block": "{\"symbols\":[],\"statements\":[[5,\"programyear-list\",[],[[\"@programYears\",\"@canCreate\",\"@program\",\"@lock\",\"@unlock\",\"@activate\"],[[28,\"await\",[[24,[\"model\",\"programYears\"]]],null],[22,\"canCreate\"],[22,\"model\"],[28,\"action\",[[23,0,[]],\"lockProgramYear\"],null],[28,\"action\",[[23,0,[]],\"unlockProgramYear\"],null],[28,\"action\",[[23,0,[]],\"activateProgramYear\"],null]]]]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ilios/templates/program/index.hbs"
    }
  });

  _exports.default = _default;
});