define("ilios/components/school-vocabularies-list", ["exports", "ember-concurrency", "ember-cp-validations", "ilios-common/mixins/validation-error-display"], function (_exports, _emberConcurrency, _emberCpValidations, _validationErrorDisplay) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const Validations = (0, _emberCpValidations.buildValidations)({
    newVocabularyTitle: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('length', {
      min: 1,
      max: 200
    })]
  });

  var _default = Ember.Component.extend(Validations, _validationErrorDisplay.default, {
    store: Ember.inject.service(),
    classNames: ['school-vocabularies-list'],
    canCreate: false,
    canDelete: false,
    newVocabulary: null,
    newVocabularyTitle: null,
    school: null,
    showNewVocabularyForm: false,
    showRemovalConfirmationFor: null,
    sortedVocabularies: Ember.computed('school.vocabularies.[]', 'newVocabulary', async function () {
      const school = this.school;

      if (!Ember.isPresent(school)) {
        Ember.RSVP.resolve([]);
      }

      const vocabularies = await school.get('vocabularies');
      return vocabularies.filterBy('isNew', false).sortBy('title').toArray();
    }),
    actions: {
      toggleShowNewVocabularyForm() {
        this.set('newVocabularyTitle', null);
        this.set('showNewVocabularyForm', !this.showNewVocabularyForm);
      },

      confirmRemoval(vocabulary) {
        this.set('showRemovalConfirmationFor', vocabulary);
      },

      cancelRemoval() {
        this.set('showRemovalConfirmationFor', null);
      }

    },

    keyUp(event) {
      const keyCode = event.keyCode;
      const target = event.target;

      if ('text' !== target.type) {
        return;
      }

      if (13 === keyCode) {
        this.saveNew.perform(this.newVocabularyTitle);
        return;
      }

      if (27 === keyCode) {
        this.send('toggleShowNewVocabularyForm');
      }
    },

    saveNew: (0, _emberConcurrency.task)(function* (title) {
      this.send('addErrorDisplayFor', 'newVocabularyTitle');
      const {
        validations
      } = yield this.validate();

      if (validations.get('isValid')) {
        const school = this.school;
        const vocabulary = this.store.createRecord('vocabulary', {
          title,
          school,
          active: true
        });
        const savedVocabulary = yield vocabulary.save();
        const vocabularies = yield school.get('vocabularies');
        vocabularies.pushObject(savedVocabulary);
        this.send('removeErrorDisplayFor', 'newVocabularyTitle');
        this.set('showNewVocabularyForm', false);
        this.set('newVocabularyTitle', null);
        this.set('newVocabulary', savedVocabulary);
      }
    }).drop(),
    remove: (0, _emberConcurrency.task)(function* (vocabulary) {
      const school = this.school;
      const vocabularies = yield school.get('vocabularies');
      vocabularies.removeObject(vocabulary);
      yield vocabulary.destroyRecord();
      const newVocabulary = this.newVocabulary;

      if (newVocabulary === vocabulary) {
        this.set('newVocabulary', null);
      }
    }).drop()
  });

  _exports.default = _default;
});