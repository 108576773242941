define("ilios/components/new-directory-user", ["exports", "ember-concurrency", "ember-cp-validations", "ilios/mixins/newuser"], function (_exports, _emberConcurrency, _emberCpValidations, _newuser) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const Validations = (0, _emberCpValidations.buildValidations)({
    username: [(0, _emberCpValidations.validator)('presence', {
      presence: true,
      dependentKeys: ['model.allowCustomUserName'],
      disabled: Ember.computed('model.allowCustomUserName', function () {
        return this.get('model.allowCustomUserName').then(allowCustomUserName => {
          return allowCustomUserName;
        });
      })
    }), (0, _emberCpValidations.validator)('length', {
      max: 100
    })],
    password: [(0, _emberCpValidations.validator)('presence', {
      presence: true,
      dependentKeys: ['model.allowCustomUserName'],
      disabled: Ember.computed('model.allowCustomUserName', function () {
        return this.get('model.allowCustomUserName').then(allowCustomUserName => {
          return allowCustomUserName;
        });
      })
    })],
    otherId: [(0, _emberCpValidations.validator)('length', {
      max: 16
    })]
  });

  var _default = Ember.Component.extend(_newuser.default, Validations, {
    commonAjax: Ember.inject.service(),
    iliosConfig: Ember.inject.service(),
    intl: Ember.inject.service(),
    classNames: ['new-directory-user'],
    isSearching: false,
    searchResults: null,
    searchResultsReturned: false,
    searchTerms: null,
    selectedUser: false,
    tooManyResults: false,
    allowCustomUserName: Ember.computed('iliosConfig.authenticationType', async function () {
      const type = await this.iliosConfig.authenticationType;
      return type === 'form';
    }),

    init() {
      this._super(...arguments);

      this.set('searchResults', []);
      const searchTerms = this.searchTerms;

      if (Ember.isPresent(searchTerms)) {
        this.findUsersInDirectory.perform(searchTerms);
      }
    },

    actions: {
      pickUser(user) {
        this.set('selectedUser', true);
        this.set('firstName', user.firstName);
        this.set('lastName', user.lastName);
        this.set('email', user.email);
        this.set('campusId', user.campusId);
        this.set('phone', user.telephoneNumber);
        this.set('username', user.username);
      },

      unPickUser() {
        this.set('selectedUser', false);
        this.set('firstName', null);
        this.set('lastName', null);
        this.set('email', null);
        this.set('campusId', null);
        this.set('phone', null);
        this.set('username', null);
      }

    },

    keyUp(event) {
      const keyCode = event.keyCode;
      const target = event.target;

      if ('text' === target.type) {
        if (13 === keyCode) {
          this.save.perform();
          return;
        }

        if (27 === keyCode) {
          this.close();
        }

        return;
      }

      if ('search' === target.type) {
        if (13 === keyCode) {
          this.findUsersInDirectory.perform(this.searchTerms);
          return;
        }

        if (27 === keyCode) {
          this.set('searchTerms', '');
        }
      }
    },

    findUsersInDirectory: (0, _emberConcurrency.task)(function* (searchTerms) {
      this.set('searchResultsReturned', false);
      this.set('tooManyResults', false);

      if (!Ember.isEmpty(searchTerms)) {
        this.set('isSearching', true);
        let url = '/application/directory/search?limit=51&searchTerms=' + searchTerms;
        const commonAjax = this.commonAjax;
        let data = yield commonAjax.request(url);
        let mappedResults = data.results.map(result => {
          result.addable = Ember.isPresent(result.firstName) && Ember.isPresent(result.lastName) && Ember.isPresent(result.email) && Ember.isPresent(result.campusId);
          return result;
        });
        this.set('tooManyResults', mappedResults.length > 50);
        this.set('searchResults', mappedResults);
        this.set('isSearching', false);
        this.set('searchResultsReturned', true);
      }
    }).restartable()
  });

  _exports.default = _default;
});