define("ilios/components/curriculum-inventory-report-details", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    classNames: ['curriculum-inventory-report-details'],
    'data-test-curriculum-inventory-report-details': true,
    canUpdate: false,
    isFinalizing: false,
    report: null,
    showFinalizeConfirmation: false,
    actions: {
      cancelFinalization() {
        this.set('showFinalizeConfirmation', false);
      },

      finalize() {
        this.set('isFinalizing', true);
        const report = this.report;
        let repExport = this.store.createRecord('curriculumInventoryExport', {
          report: report
        });
        repExport.save().then(savedExport => {
          report.set('export', savedExport);
        }).finally(() => {
          this.set('showFinalizeConfirmation', false);
          this.set('isFinalizing', false);
        });
      },

      confirmFinalization() {
        this.set('showFinalizeConfirmation', true);
      }

    }
  });

  _exports.default = _default;
});