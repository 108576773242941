define("ilios/routes/learner-groups", ["exports", "ember-simple-auth/mixins/authenticated-route-mixin"], function (_exports, _authenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    store: Ember.inject.service(),
    queryParams: {
      titleFilter: {
        replace: true
      }
    },

    model() {
      let rsvpDefer = Ember.RSVP.defer();
      let model = {};
      this.store.findAll('school').then(schools => {
        model.schools = schools;
        rsvpDefer.resolve(model);
      });
      return rsvpDefer.promise;
    },

    actions: {
      willTransition() {
        this.controller.set('newGroup', null);
      }

    }
  });

  _exports.default = _default;
});