define("ilios/templates/components/user-profile-learnergroups", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "03aEKcrb",
    "block": "{\"symbols\":[\"learnerGroup\"],\"statements\":[[7,\"h2\",true],[10,\"class\",\"title\"],[8],[0,\"\\n  \"],[1,[28,\"t\",[\"general.learnerGroups\"],null],false],[0,\"\\n\"],[9],[0,\"\\n\"],[4,\"if\",[[28,\"is-fulfilled\",[[24,[\"selectedLearnerGroups\"]]],null]],null,{\"statements\":[[0,\"  \"],[7,\"p\",true],[8],[0,\"\\n\"],[4,\"if\",[[28,\"gt\",[[28,\"get\",[[28,\"await\",[[24,[\"selectedLearnerGroups\"]]],null],\"length\"],null],0],null]],null,{\"statements\":[[0,\"      \"],[7,\"ul\",true],[8],[0,\"\\n\"],[4,\"each\",[[28,\"sort-by\",[\"sortTitle\",[28,\"await\",[[24,[\"selectedLearnerGroups\"]]],null]],null]],null,{\"statements\":[[0,\"          \"],[7,\"li\",true],[8],[0,\"\\n            \"],[1,[23,1,[\"schoolTitle\"]],false],[0,\":\\n            \"],[1,[23,1,[\"programTitle\"]],false],[0,\"\\n            \"],[7,\"em\",true],[8],[0,\"\\n              \"],[1,[23,1,[\"cohortTitle\"]],false],[0,\"\\n              —\\n            \"],[9],[0,\"\\n            \"],[1,[23,1,[\"allParentsTitle\"]],false],[0,\"\\n            \"],[7,\"strong\",true],[8],[0,\"\\n              \"],[1,[23,1,[\"title\"]],false],[0,\"\\n            \"],[9],[0,\"\\n          \"],[9],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"      \"],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"      \"],[1,[28,\"t\",[\"general.none\"],null],false],[0,\"\\n\"]],\"parameters\":[]}],[0,\"  \"],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[7,\"p\",true],[8],[0,\"\\n    \"],[5,\"loading-spinner\",[],[[],[]]],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ilios/templates/components/user-profile-learnergroups.hbs"
    }
  });

  _exports.default = _default;
});