define("ilios/routes/learner-group", ["exports", "ember-simple-auth/mixins/authenticated-route-mixin"], function (_exports, _authenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    permissionChecker: Ember.inject.service(),
    editable: false,

    async afterModel(model) {
      const permissionChecker = this.permissionChecker;
      const school = await model.get('school');
      const canUpdate = await permissionChecker.canUpdateLearnerGroup(model);
      const canDelete = await permissionChecker.canDeleteLearnerGroup(model);
      const canCreate = await permissionChecker.canCreateLearnerGroup(school);
      this.set('canUpdate', canUpdate);
      this.set('canDelete', canDelete);
      this.set('canCreate', canCreate); //preload data to speed up rendering later

      return Ember.RSVP.all([model.get('usersOnlyAtThisLevel'), model.get('allInstructors'), model.get('allParents'), model.get('courses')]);
    },

    setupController(controller, model) {
      this._super(controller, model);

      controller.set('canUpdate', this.canUpdate);
      controller.set('canDelete', this.canDelete);
      controller.set('canCreate', this.canCreate);
    }

  });

  _exports.default = _default;
});