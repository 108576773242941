define("ilios/templates/components/selectable-search-result", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "S6Vah2P4",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[7,\"li\",true],[11,\"class\",[28,\"if\",[[24,[\"selected\"]],\"disabled\",\"enabled\"],null]],[8],[0,\"\\n\"],[4,\"unless\",[[24,[\"selected\"]]],null,{\"statements\":[[0,\"    \"],[7,\"span\",false],[12,\"class\",\"add\"],[3,\"translateAttr\",null,[[\"title\"],[\"general.add\"]]],[3,\"action\",[[23,0,[]],\"add\",[23,0,[]]]],[8],[0,\"\\n      \"],[5,\"fa-icon\",[],[[\"@icon\"],[\"plus-circle\"]]],[0,\"\\n    \"],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[5,\"fa-icon\",[],[[\"@icon\"],[\"circle\"]]],[0,\"\\n\"]],\"parameters\":[]}],[0,\"  \"],[14,1],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ilios/templates/components/selectable-search-result.hbs"
    }
  });

  _exports.default = _default;
});