define("ilios/components/school-manager", ["exports", "ember-cp-validations", "ilios-common/mixins/validation-error-display"], function (_exports, _emberCpValidations, _validationErrorDisplay) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const Validations = (0, _emberCpValidations.buildValidations)({
    title: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('length', {
      max: 60,
      descriptionKey: 'general.title'
    })]
  });

  var _default = Ember.Component.extend(_validationErrorDisplay.default, Validations, {
    flashMessages: Ember.inject.service(),
    classNames: ['school-manager'],
    tagName: 'section',
    canCreateCompetency: false,
    canCreateSessionType: false,
    canCreateTerm: false,
    canCreateVocabulary: false,
    canDeleteCompetency: false,
    canDeleteSessionType: false,
    canDeleteTerm: false,
    canDeleteVocabulary: false,
    canUpdateCompetency: false,
    canUpdateSchool: false,
    canUpdateSchoolConfig: false,
    canUpdateSessionType: false,
    canUpdateVocabulary: false,
    canUpdateTerm: false,
    school: null,
    title: null,

    didReceiveAttrs() {
      this._super(...arguments);

      this.set('title', this.get('school.title'));
    },

    actions: {
      async changeTitle() {
        const {
          school,
          title
        } = this.getProperties('school', 'title');
        this.send('addErrorDisplayFor', 'title');
        const {
          validations
        } = await this.validate();

        if (validations.isValid) {
          this.send('clearErrorDisplay');
          school.set('title', title);
          const newSchool = await school.save();
          this.setProperties({
            school: newSchool,
            title: newSchool.title
          });
          this.flashMessages.success('general.savedSuccessfully');
        } else {
          await Ember.RSVP.reject();
        }
      },

      revertTitleChanges() {
        const school = this.school;
        this.set('title', school.get('title'));
      },

      async saveInstitution(institution) {
        if (!institution.belongsTo('school').id()) {
          institution.set('school', this.get("school"));
        }

        await institution.save();
      }

    }
  });

  _exports.default = _default;
});