define("ilios/templates/session/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "+91l1u3P",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"backtolink backtosessionlink\"],[8],[0,\"\\n  \"],[5,\"link-to\",[],[[\"@route\",\"@query\"],[\"course\",[22,\"hash\"]]],{\"statements\":[[0,\"\\n    \"],[1,[28,\"t\",[\"general.backToSessionList\"],null],false],[0,\"\\n  \"]],\"parameters\":[]}],[0,\"\\n\"],[9],[0,\"\\n\"],[5,\"session-details\",[],[[\"@session\",\"@editable\",\"@programs\",\"@sessionTypes\",\"@sessionObjectiveDetails\",\"@sessionTaxonomyDetails\",\"@setSessionObjectiveDetails\",\"@setSessionTaxonomyDetails\",\"@isManagingLearnerGroups\",\"@setIsManagingLearnerGroups\",\"@sessionLearnergroupDetails\",\"@setSessionLearnergroupDetails\",\"@showNewOfferingForm\",\"@toggleShowNewOfferingForm\",\"@sessionLeadershipDetails\",\"@setSessionLeadershipDetails\",\"@sessionManageLeadership\",\"@setSessionManageLeadership\"],[[22,\"model\"],[22,\"canUpdate\"],[28,\"await\",[[24,[\"model\",\"course\"]]],null],[28,\"await\",[[24,[\"model\",\"course\",\"school\",\"sessionTypes\"]]],null],[22,\"sessionObjectiveDetails\"],[22,\"sessionTaxonomyDetails\"],[28,\"action\",[[23,0,[]],[28,\"mut\",[[24,[\"sessionObjectiveDetails\"]]],null]],null],[28,\"action\",[[23,0,[]],[28,\"mut\",[[24,[\"sessionTaxonomyDetails\"]]],null]],null],[22,\"isManagingLearnerGroups\"],[28,\"action\",[[23,0,[]],[28,\"mut\",[[24,[\"isManagingLearnerGroups\"]]],null]],null],[22,\"sessionLearnergroupDetails\"],[28,\"action\",[[23,0,[]],[28,\"mut\",[[24,[\"sessionLearnergroupDetails\"]]],null]],null],[22,\"showNewOfferingForm\"],[28,\"toggle-action\",[\"showNewOfferingForm\",[23,0,[]]],null],[22,\"sessionLeadershipDetails\"],[28,\"action\",[[23,0,[]],[28,\"mut\",[[24,[\"sessionLeadershipDetails\"]]],null]],null],[22,\"sessionManageLeadership\"],[28,\"action\",[[23,0,[]],[28,\"mut\",[[24,[\"sessionManageLeadership\"]]],null]],null]]]]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ilios/templates/session/index.hbs"
    }
  });

  _exports.default = _default;
});