define("ilios/templates/components/learnergroup-calendar", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "0gx3/CeN",
    "block": "{\"symbols\":[],\"statements\":[[7,\"p\",true],[10,\"class\",\"learnergroup-calendar-filter-options\"],[8],[0,\"\\n  \"],[7,\"span\",true],[10,\"class\",\"filter\"],[8],[0,\"\\n    \"],[5,\"toggle-yesno\",[],[[\"@yes\",\"@toggle\"],[[22,\"showSubgroupEvents\"],[28,\"action\",[[23,0,[]],[28,\"mut\",[[24,[\"showSubgroupEvents\"]]],null]],null]]]],[0,\"\\n    \"],[7,\"label\",true],[11,\"onclick\",[28,\"toggle\",[\"showSubgroupEvents\",[23,0,[]]],null]],[8],[0,\"\\n      \"],[1,[28,\"t\",[\"general.showSubgroupEvents\"],null],false],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"],[7,\"ul\",true],[10,\"class\",\"inline learnergroup-calendar-time-picker\"],[8],[0,\"\\n  \"],[7,\"li\",false],[12,\"class\",\"clickable\"],[3,\"action\",[[23,0,[]],\"goBack\"]],[8],[0,\"\\n    \"],[5,\"fa-icon\",[],[[\"@icon\"],[\"backward\"]]],[0,\"\\n  \"],[9],[0,\"\\n  \"],[7,\"li\",false],[12,\"class\",\"clickable\"],[3,\"action\",[[23,0,[]],\"gotoToday\"]],[8],[0,\"\\n    \"],[1,[28,\"t\",[\"general.today\"],null],false],[0,\"\\n  \"],[9],[0,\"\\n  \"],[7,\"li\",false],[12,\"class\",\"clickable\"],[3,\"action\",[[23,0,[]],\"goForward\"]],[8],[0,\"\\n    \"],[5,\"fa-icon\",[],[[\"@icon\"],[\"forward\"]]],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"],[7,\"div\",true],[10,\"class\",\"ilios-calendar\"],[8],[0,\"\\n  \"],[5,\"ilios-calendar-week\",[],[[\"@calendarEvents\",\"@date\",\"@areEventsSelectable\",\"@areDaysSelectable\"],[[28,\"await\",[[24,[\"calendarEvents\"]]],null],[22,\"selectedDate\"],false,false]]],[0,\"\\n\"],[9],[0,\"\\n\"],[7,\"span\",true],[11,\"class\",[29,[\"loding-indicator \",[28,\"if\",[[28,\"is-fulfilled\",[[24,[\"calendarEvents\"]]],null],\"loaded\"],null]]]],[8],[0,\"\\n  \"],[5,\"loading-spinner\",[],[[],[]]],[0,\"\\n  \"],[1,[28,\"t\",[\"general.loadingEvents\"],null],false],[0,\"\\n  ...\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ilios/templates/components/learnergroup-calendar.hbs"
    }
  });

  _exports.default = _default;
});