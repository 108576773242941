define("ilios/components/pagination-links", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['pagination-links'],
    'data-test-pagination-links': true,
    page: null,
    results: null,
    size: null,

    onSelectPage() {},

    disablePrev: Ember.computed.equal('page', 1),
    disableNext: Ember.computed('lastPage', 'page', function () {
      return this.lastPage === this.page;
    }),
    lastPage: Ember.computed('results', 'size', function () {
      return Math.ceil(this.results.length / this.size);
    }),
    pages: Ember.computed('lastPage', 'page', 'totalPages', function () {
      const {
        lastPage,
        page
      } = this.getProperties('lastPage', 'page');
      return lastPage <= 7 ? this.simplePages(lastPage) : this.complexPages(lastPage, page);
    }),
    actions: {
      selectPage(value) {
        this.onSelectPage(this.page + value);
      }

    },

    simplePages(lastPage) {
      const pageNumbers = [];

      for (let i = 1; i <= lastPage; i++) {
        pageNumbers.push(i);
      }

      return pageNumbers;
    },

    complexPages(lastPage, page) {
      if (page <= 4) {
        return [1, 2, 3, 4, 5, '...', lastPage];
      }

      if (page >= lastPage - 3) {
        return [1, '...', lastPage - 4, lastPage - 3, lastPage - 2, lastPage - 1, lastPage];
      }

      return [1, '...', page - 1, page, page + 1, page + 2, '...', lastPage];
    }

  });

  _exports.default = _default;
});