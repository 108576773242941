define("ilios/components/school-curriculum-inventory-institution-manager", ["exports", "ember-cp-validations", "ilios-common/mixins/validation-error-display"], function (_exports, _emberCpValidations, _validationErrorDisplay) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const Validations = (0, _emberCpValidations.buildValidations)({
    name: [(0, _emberCpValidations.validator)('presence', {
      presence: true
    }), (0, _emberCpValidations.validator)('length', {
      max: 100
    })],
    aamcCode: [(0, _emberCpValidations.validator)('number', {
      allowString: true,
      integer: true,
      gte: 1,
      lte: 99999
    })],
    addressStreet: [(0, _emberCpValidations.validator)('presence', {
      presence: true
    }), (0, _emberCpValidations.validator)('length', {
      max: 100
    })],
    addressCity: [(0, _emberCpValidations.validator)('presence', {
      presence: true
    }), (0, _emberCpValidations.validator)('length', {
      max: 100
    })],
    addressStateOrProvince: [(0, _emberCpValidations.validator)('presence', {
      presence: true
    }), (0, _emberCpValidations.validator)('length', {
      max: 50
    })],
    addressZipCode: [(0, _emberCpValidations.validator)('presence', {
      presence: true
    }), (0, _emberCpValidations.validator)('length', {
      max: 10
    })],
    addressCountryCode: [(0, _emberCpValidations.validator)('presence', {
      presence: true
    }), (0, _emberCpValidations.validator)('length', {
      max: 2
    })]
  });

  var _default = Ember.Component.extend(_validationErrorDisplay.default, Validations, {
    store: Ember.inject.service(),
    "data-test-school-curriculum-inventory-institution-manager": true,
    classNames: ["school-curriculum-inventory-institution-manager"],
    isSaving: false,
    name: null,
    aamcCode: null,
    addressStreet: null,
    addressCity: null,
    addressStateOrProvince: null,
    addressZipCode: null,
    addressCountryCode: null,

    didReceiveAttrs() {
      this._super(...arguments);

      if (Ember.isPresent(this.institution)) {
        this.setProperties({
          name: this.institution.get("name"),
          aamcCode: this.institution.get("aamcCode"),
          addressStreet: this.institution.get("addressStreet"),
          addressCity: this.institution.get("addressCity"),
          addressStateOrProvince: this.institution.get("addressStateOrProvince"),
          addressZipCode: this.institution.get("addressZipCode"),
          addressCountryCode: this.institution.get("addressCountryCode")
        });
      } else {
        this.setProperties({
          name: "",
          aamcCode: "",
          addressStreet: "",
          addressCity: "",
          addressStateOrProvince: "",
          addressZipCode: "",
          addressCountryCode: ""
        });
      }
    },

    actions: {
      async save() {
        this.set('isSaving', true);
        this.send('addErrorDisplaysFor', ['name', 'aamcCode', 'addressStreet', 'addressCity', 'addressStateOrProvince', 'addressZipCode', 'addressCountryCode']);
        let {
          validations
        } = await this.validate();

        if (validations.get('isInvalid')) {
          this.set('isSaving', false);
          return;
        }

        let institution = this.institution;

        if (!Ember.isPresent(institution)) {
          institution = this.store.createRecord('curriculum-inventory-institution');
        }

        institution.set("name", this.name);
        institution.set("aamcCode", this.aamcCode);
        institution.set("addressStreet", this.addressStreet);
        institution.set("addressCity", this.addressCity);
        institution.set("addressStateOrProvince", this.addressStateOrProvince);
        institution.set("addressZipCode", this.addressZipCode);
        institution.set("addressCountryCode", this.addressCountryCode);
        await this.save(institution);
        this.send('clearErrorDisplay');
        this.set('isSaving', false);
        this.manage(false);
      },

      cancel() {
        this.manage(false);
      }

    }
  });

  _exports.default = _default;
});