define("ilios/templates/program-year/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "y9TFNj10",
    "block": "{\"symbols\":[],\"statements\":[[5,\"programyear-details\",[],[[\"@program\",\"@canUpdate\",\"@programYear\",\"@pyObjectiveDetails\",\"@pyTaxonomyDetails\",\"@pyCompetencyDetails\",\"@managePyCompetencies\",\"@pyStewardDetails\",\"@managePyStewards\",\"@setPyObjectiveDetails\",\"@setPyTaxonomyDetails\",\"@setPyCompetencyDetails\",\"@setManagePyCompetencies\",\"@setPyStewardDetails\",\"@setManagePyStewards\"],[[28,\"await\",[[24,[\"model\",\"program\"]]],null],[22,\"canUpdate\"],[22,\"model\"],[22,\"pyObjectiveDetails\"],[22,\"pyTaxonomyDetails\"],[22,\"pyCompetencyDetails\"],[22,\"managePyCompetencies\"],[22,\"pyStewardDetails\"],[22,\"managePyStewards\"],[28,\"action\",[[23,0,[]],[28,\"mut\",[[24,[\"pyObjectiveDetails\"]]],null]],null],[28,\"action\",[[23,0,[]],[28,\"mut\",[[24,[\"pyTaxonomyDetails\"]]],null]],null],[28,\"action\",[[23,0,[]],[28,\"mut\",[[24,[\"pyCompetencyDetails\"]]],null]],null],[28,\"action\",[[23,0,[]],[28,\"mut\",[[24,[\"managePyCompetencies\"]]],null]],null],[28,\"action\",[[23,0,[]],[28,\"mut\",[[24,[\"pyStewardDetails\"]]],null]],null],[28,\"action\",[[23,0,[]],[28,\"mut\",[[24,[\"managePyStewards\"]]],null]],null]]]]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ilios/templates/program-year/index.hbs"
    }
  });

  _exports.default = _default;
});