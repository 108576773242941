define("ilios/components/school-session-types-expanded", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    classNames: ['school-session-types-expanded'],
    tagName: 'section',
    canCreate: false,
    canDelete: false,
    canUpdate: false,
    managedSessionTypeId: null,
    school: null,
    isManaging: Ember.computed.notEmpty('managedSessionTypeId'),
    isCollapsible: Ember.computed('isManaging', 'school.session-types.length', async function () {
      const school = this.school;
      const isManaging = this.isManaging;
      const sessionTypes = await school.get('sessionTypes');
      return sessionTypes.get('length') && !isManaging;
    }),
    sessionTypes: Ember.computed('school.sessionTypes.[]', async function () {
      const school = this.school;
      return await school.get('sessionTypes');
    }),
    managedSessionType: Ember.computed('managedSessionTypeId', async function () {
      const managedSessionTypeId = this.managedSessionTypeId;
      const sessionTypes = await this.sessionTypes;
      const sessionType = sessionTypes.findBy('id', managedSessionTypeId);
      return sessionType;
    }),
    actions: {
      async collapse() {
        const isCollapsible = this.isCollapsible;
        const collapse = this.collapse;
        const setSchoolManagedSessionType = this.setSchoolManagedSessionType;

        if (isCollapsible) {
          collapse();
          setSchoolManagedSessionType(null);
        }
      },

      cancel() {
        const setSchoolManagedSessionType = this.setSchoolManagedSessionType;
        setSchoolManagedSessionType(null);
      },

      toggleSchoolNewSessionType() {
        let schoolNewSessionType = this.schoolNewSessionType;
        let setSchoolNewSessionType = this.setSchoolNewSessionType;
        setSchoolNewSessionType(!schoolNewSessionType);
      }

    },
    save: (0, _emberConcurrency.task)(function* (title, calendarColor, assessment, assessmentOption, aamcMethod, isActive) {
      const store = this.store;
      const sessionType = store.createRecord('sessionType');
      const closeComponent = this.setSchoolNewSessionType;
      const school = this.school;
      let aamcMethods = [];

      if (aamcMethod) {
        aamcMethods.pushObject(aamcMethod);
      }

      sessionType.setProperties({
        school,
        title,
        calendarColor,
        assessment,
        assessmentOption,
        aamcMethods,
        active: isActive
      });
      yield sessionType.save();
      closeComponent(false);
    })
  });

  _exports.default = _default;
});