define("ilios/controllers/curriculum-inventory-report/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    actions: {
      removeSequenceBlock(block) {
        let report = this.model;
        block.destroyRecord().then(() => {
          report.reload();
        });
      }

    }
  });

  _exports.default = _default;
});