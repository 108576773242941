define("ilios/templates/search", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "6jkgG7Ld",
    "block": "{\"symbols\":[],\"statements\":[[1,[28,\"page-title\",[[28,\"t\",[\"general.search\"],null]],null],false],[0,\"\\n\"],[5,\"global-search\",[],[[\"@page\",\"@query\",\"@onQuery\",\"@onSelectPage\",\"@ignoredSchoolIds\",\"@setIgnoredSchoolIds\",\"@selectedYear\",\"@setSelectedYear\"],[[23,0,[\"page\"]],[23,0,[\"query\"]],[28,\"action\",[[23,0,[]],\"setQuery\"],null],[28,\"action\",[[23,0,[]],[28,\"mut\",[[23,0,[\"page\"]]],null]],null],[23,0,[\"ignoredSchoolIdsArray\"]],[28,\"action\",[[23,0,[]],\"setIgnoredSchools\"],null],[23,0,[\"selectedYearInt\"]],[28,\"action\",[[23,0,[]],[28,\"mut\",[[23,0,[\"selectedYear\"]]],null]],null]]]]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ilios/templates/search.hbs"
    }
  });

  _exports.default = _default;
});