define("ilios/templates/components/recently-updated-display", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "SQo3m/GF",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[24,[\"recentlyUpdated\"]]],null,{\"statements\":[[0,\"  \"],[5,\"fa-icon\",[],[[\"@icon\"],[\"exclamation-circle\"]]],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ilios/templates/components/recently-updated-display.hbs"
    }
  });

  _exports.default = _default;
});