define("ilios/templates/components/new-competency", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "IzPRcFMU",
    "block": "{\"symbols\":[],\"statements\":[[7,\"input\",true],[11,\"value\",[22,\"title\"]],[11,\"oninput\",[28,\"action\",[[23,0,[]],[28,\"mut\",[[24,[\"title\"]]],null]],[[\"value\"],[\"target.value\"]]]],[11,\"onkeyup\",[28,\"action\",[[23,0,[]],\"addErrorDisplayFor\",\"title\"],null]],[11,\"placeholder\",[28,\"t\",[\"general.title\"],null]],[10,\"type\",\"text\"],[8],[9],[0,\"\\n\"],[7,\"button\",false],[12,\"class\",\"save text\"],[3,\"action\",[[23,0,[]],[28,\"perform\",[[24,[\"save\"]]],null]]],[8],[0,\"\\n\"],[4,\"if\",[[24,[\"save\",\"isRunning\"]]],null,{\"statements\":[[0,\"    \"],[5,\"loading-spinner\",[],[[],[]]],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[1,[28,\"t\",[\"general.add\"],null],false],[0,\"\\n\"]],\"parameters\":[]}],[9],[0,\"\\n\"],[4,\"if\",[[28,\"and\",[[28,\"get\",[[28,\"get\",[[28,\"get\",[[28,\"get\",[[23,0,[]],\"validations\"],null],\"attrs\"],null],\"title\"],null],\"isInvalid\"],null],[28,\"is-in\",[[24,[\"showErrorsFor\"]],\"title\"],null]],null]],null,{\"statements\":[[0,\"  \"],[7,\"span\",true],[10,\"class\",\"validation-error-message\"],[8],[0,\"\\n    \"],[1,[28,\"get\",[[28,\"get\",[[28,\"get\",[[28,\"get\",[[23,0,[]],\"validations\"],null],\"attrs\"],null],\"title\"],null],\"message\"],null],false],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ilios/templates/components/new-competency.hbs"
    }
  });

  _exports.default = _default;
});