define("ilios/templates/components/flash-messages", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "WAR8XFx0",
    "block": "{\"symbols\":[\"f\",\"component\",\"flash\"],\"statements\":[[4,\"each\",[[24,[\"flashMessages\",\"arrangedQueue\"]]],null,{\"statements\":[[0,\"  \"],[5,\"flash-message\",[],[[\"@flash\"],[[23,1,[]]]],{\"statements\":[[0,\"\\n    \"],[1,[28,\"t\",[[23,3,[\"message\"]]],null],false],[0,\"\\n  \"]],\"parameters\":[2,3]}],[0,\"\\n\"]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ilios/templates/components/flash-messages.hbs"
    }
  });

  _exports.default = _default;
});