define("ilios/translations/fr", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "errors": {
      "accepted": "{description} doit être accepté",
      "after": "{description} doit être après {after}",
      "before": "{description} doit être avant {before}",
      "blank": "{description} ne doit pas être blanc",
      "collection": "{description} doit être un collection",
      "confirmation": "{description} ne correspond pas de {on}",
      "date": "{description} doit être une date valide",
      "description": "Ce champ",
      "email": "{description} doit être une e-mail valide",
      "empty": "{description} ne doit pas être vide",
      "equalTo": "{description} doit être égale à {is}",
      "even": "{description} doit être une chiffre pair",
      "exclusion": "{description}est reservé",
      "greaterThan": "{description} doit être supérieure à {gt}",
      "greaterThanOrEqualTo": "{description} doit être supérieure ou ègale à {gte}",
      "inclusion": "{description} ne figure pas dans la liste",
      "invalid": "{description} est invalide",
      "lessThan": "{description} doit être inférieure à {lt}",
      "lessThanOrEqualTo": "{description} doit être supérieure ou inférieure à {lte}",
      "notANumber": "{description} doit contenir une valeur numérique",
      "notAnInteger": "{description} doit être un entier",
      "odd": "{description} doit étre une chiffre impair",
      "onOrAfter": "{description} doit être sur ou après {onOrAfter}",
      "onOrBefore": "{description} doit être en ou avant {onOrBefore}",
      "otherThan": "{description} doit être une valeur autre de {value}",
      "phone": "{description} doit être une téléphone valide",
      "positive": "{description} doit être une chiffre positif",
      "present": "{description} doit rester vide",
      "required": "{description} est obligatoire",
      "singular": "{description} ne doit pas être un collection",
      "tooLong": "{description} est trop long (maximum de {max} caractères)",
      "tooShort": "{description} est trop court (minimum de {min} caractères)",
      "url": "{description} doit être une URL valide",
      "wrongDateFormat": "{description} doit être en format de {format}",
      "wrongLength": "{description} n’est pas à la bonne longueur (soit ainsi {is} caractères)"
    },
    "general": {
      "aamcMethod": "AAMC Méthode",
      "aamcSchoolId": "Identifiant d'école AAMC (\"Identifiant d'institution\")",
      "academicLevel": "Niveau scolaire",
      "academicLevels": "Niveaux scolaire",
      "academicYear": "Année Scolaire",
      "accessAllMaterialsForThisCourse": "Accéder à tout le matériel d'étude pour ce cours",
      "accountEnabled": "Compte Activé",
      "actions": "Actions",
      "activate": "Activer",
      "active": "Actif",
      "activeFilters": "Filtres actifs",
      "activities": "Activités",
      "add": "Ajouté",
      "addCohort": "Ajouté cohorte",
      "addNew": "Ajouté Neuf",
      "admin": "Admin",
      "administrator": "Administrateur",
      "administratorCount": "Il y a {count, plural, =1 { un administrateur} other {# administrateurs}}",
      "administratorEmail": "Courriel d'administrateur",
      "administrators": "Administrateurs",
      "advancedOptions": "Options avancées",
      "all": "Tous",
      "allAcademicYears": "Toutes Années Scolaires",
      "allCourses": "Tous les Cours",
      "allEvents": "Tous les Événements",
      "allOtherMembers": "Tous les autres membres du {topLevelGroupTitle}",
      "allReports": "Tous Rapports",
      "allSchools": "Toutes les écoles",
      "allVocabularies": "Touts vocabulaires",
      "allWeeks": "Toutes les Semaines",
      "anything": "Quelque chose",
      "apiVersionMismatch": "API  conflit de version",
      "apiVersionMismatchDetails": "Il s'agit généralement d'une erreur temporaire dans votre navigateur; Ilios est en mode lecture seule jusqu'à ce qu'il soit résolu. Si la mise à jour ne vous aide pas, veuillez aviser un administrateur ou le propriétaire de la technologie du système de cette erreur. Vous pouvez également essayer un autre navigateur.",
      "assessment": "Évaluation",
      "assessmentMethods": "Méthodes d'Évaluation",
      "assessmentOption": "Option d'Évaluation",
      "assessments": "Évaluations",
      "assignedTerms": "Termes assignées",
      "associatedCourses": "Cours Associés",
      "associatedGroups": "Groupes Associés",
      "associatedWith": "associée de",
      "attendanceIsRequired": "Présence requise",
      "attendanceIs_Required_": "Présence <strong><em>requise</em></strong>",
      "attendanceRequired": "Participation Requise",
      "attribute": "Attribut",
      "author": "Auteur",
      "autoUpdatingSeconds": "{count, plural, =1 { Mise à jour automatique en 1 seconde...} other { Mise à jour automatique en # secondes...}}",
      "available": "Disponible",
      "availableCohorts": "Cohortes Disponsibles",
      "availableLearnerGroups": "Groupes d'apprenants disponsibles",
      "averageHoursOfInstructionPerWeek": "Nombre moyen d'heures d'enseignement par semaine",
      "back": "Retour",
      "backToAdminDashboard": "Rétour au Page Admin",
      "backToCalendar": "Retour au Calendrier",
      "backToCourse": "Retour au Cours",
      "backToCourses": "Retour à liste",
      "backToDashboard": "Retour à tableau de bord",
      "backToDetails": "Retour à détails de la Séance",
      "backToInstructorGroups": "Retourner à liste des groupes des instructeurs",
      "backToProgramYear": "Retour à Année de Diplôme",
      "backToProgramYears": "Retour à Années de Diplôme",
      "backToPrograms": "Retour à Diplôme",
      "backToReport": "Retour à rapport",
      "backToReports": "Retour à rapports",
      "backToSchools": "Retour à liste d'écoles",
      "backToSessionList": "Retour à Liste du Séances",
      "backToTableOfContents": "Retour à la table des matières",
      "backToTitle": "Retour à {title}",
      "bad": "Mal",
      "badCredentials": "Nom d'utilisateur ou mot de passe incorrect",
      "calendar": "Calendrier",
      "calendarOff": "Calendrier Éteindé",
      "calendarOn": "Calendrier Allumé",
      "campusId": "Campus ID",
      "canNotContinueWithInvalidRecords": "Ne peut pas continuer avec ces rapports invalides, fixez s'il vous plaît et retéléchargez le fichier.",
      "cancel": "Annul",
      "childSequenceOrder": "Ordre séquentiel des enfants",
      "chooseCohortTitle": "Choisi objectif mère pour",
      "citation": "Citation",
      "city": "Ville",
      "classOf": "Classe de {year}",
      "clearDates": "Supprimer dates",
      "clearErrors": "Supprîme les Erreurs et Retour à Ilios",
      "clearFilters": "Effacerez les Filtres",
      "clerkship": "Stage",
      "clerkshipSequenceBlocks": "Blocs de séquence de stage",
      "clerkshipType": "Genre de Stage",
      "clickForMore": "Cliquez ici pour en savoir plus",
      "clickToAddDescription": "Cliquez pour ajouter une description",
      "clickToAddTermDescription": "Cliquez pour ajouter des notices descriptives",
      "clickToEdit": "Clicquez pour modifier",
      "close": "Fermé",
      "cohort": "Cohorte",
      "cohortMembersNotInGroup": "Membres de la Cohorte sans affectation à {groupTitle} ",
      "cohorts": "Cohortes",
      "cohortsManageTitle": "Manager des Cohortes",
      "collapseDetail": "Masquer Détails",
      "color": "Couleur",
      "competencies": "Compétences",
      "competenciesManageTitle": "Manager des Compétences",
      "competency": "Compétence",
      "competencyCount": "{count, plural, =1 {Il y a 1 compétence} other {Il y a de # compétences}}",
      "completeSessions": "Séances complète: prêt à publier",
      "confirmLogout": "Vous avez réussi accomplir une fermeture de session en Ilios. Afin de nous aider à protéger votre compte et de la securité des autres, s'il vous plait fermer toutes les fenêtres du navigateur.",
      "confirmObjectiveRemoval": "Êtes vous sûr de vouloir supprimer cet objectif?",
      "confirmObjectiveRemovalMultipleSessionObjectives": "Vous êtes sur le point de supprimer cet objectif de cours, qui est lié à {count} objectifs de séance. Cela supprimera également ces liens. Êtes-vous certain de vouloir continuer?",
      "confirmRemoval": "Êtes-vous sûr vous voulez supprimer cette matériel d'étude?",
      "confirmRemove": "Êtes-vous sûr vous voulez supprimer cette offre avec {learnerGroupCount} groupes d'apprenants?  Ça action ne peut pas être défait.",
      "confirmRemoveCourse": "Êtes-vous sûr vous voulez supprimer ce cours, avec {publishedOfferingCount} offres publié? Cela supprimera toutes sessions et offres pour ce cours, et ne peut pas être défait.",
      "confirmRemoveInstructorGroup": "Êtes-vous sûr vous voulez supprimer cette groupe des instructeurs, avec {instructorCount} membres? Ça action ne peut pas être défait.",
      "confirmRemoveLearnerGroup": "Êtes-vous sûr vous voulez supprimer cette groupe d'apprenants, avec {subgroupCount} sous-groupes? Ça action ne peut pas être défait.",
      "confirmRemoveProgram": "Êtes-vous certain que vous voulez supprimer ce diplôme? Cette action ne peut être annulée.",
      "confirmRemoveProgramYear": "Êtes-vous certain que vous voulez supprimer cette année de diplôme? Cette action va supprimer tous les cohortes et groupes des étudiants associées, et liaisons avec {courseCount} des cours, et ne peut être annulée.",
      "confirmSessionRemoval": "Êtes vous sûr de vouloir supprimer cette séance?",
      "confirmVocabularyRemoval": "Êtes vous sûr de vouloir supprimer cette vocabulaire?",
      "connectionLost": "Connexion perdu",
      "contentAuthor": "Auteur",
      "continue": "Continuez",
      "copiedSuccessfully": "Copié avec succès",
      "copy": "Copie",
      "copyIcsFeedUrl": "Copier mon lien ICS",
      "copyLink": "Copier le lien",
      "copySession": "Copier Séance",
      "copySessionSummary": "Vous êtes sur le point la copie sur cette séance (sans données de calendrier) dans le cours choisi. La nouvelle seéance inclura tous les objectifs, des termes et les matériels d'étude. Continuer le clic \"oui\". Retourner à votre clic de session actuel \"annul\".",
      "copySuccess": "Copie achevée avec succès",
      "copyWithLearners": "Copiér avec étudiants",
      "copyWithoutLearners": "Copiér sans étudiants",
      "copyrightAgreement": "Le fichier J'envoie est ma propre ou j'avoir la permission expresse de reproduire et / ou distribuer cet article, ou l'utilisation de celui-ci ici a été jugée admissible par la doctrine juridique de l'utilisation équitable. Ce fichier ne contient pas protégé information sur la santé, et l'utilisation de celui-ci ici est en conformité avec le gouvernement et l'Université des politiques sur le droit d'auteur et à la sécurité de l'information et les directives de mon institution pour la conduite professionnelle.",
      "copyrightPermission": "Droit d’Auteur",
      "copyrightRationale": "Raison du droit d'auteur",
      "couldNotFindUserCampusId": "Ne pouvait pas trouver utilisateur avec le Campus ID {campusId}",
      "countAsOneOffering": "Comptent pour une offre",
      "country": "Pays",
      "course": "Cours",
      "courseDirector": "Directeur de Cours",
      "courseLeadership": "Dirigeants de Cours",
      "courseLearningMaterials": "Matériels d'Étude du Cours",
      "courseLevels": "Niveaux de Cours",
      "courseObjectives": "Objectifs du Cours",
      "courseOrType": "Cours / Type",
      "courseRollover": "Roulement Cours",
      "courseRolloverSuccess": "Roulement achevée avec succès",
      "courseRolloverSummary": "Vous êtes sur le point le roulement ce cours dans l'année universitaire choisie, avec toutes les options choisies. Continuer, cliquer \"done\". Pour retourner à votre cours actuel, le clic \"annul\"",
      "courseRolloverYearUnavailable": "Cours déja existant",
      "courseTitle": "Titre de Cours",
      "courseTitleFilterPlaceholder": "Filtre par titre de cours",
      "courseTitlePlaceholder": "Ajoutez titre par ce cours",
      "courseVisualizations": "Cours Visualisations",
      "courses": "Les Cours",
      "coursesAndSessions": "Des Cours et Séances",
      "create": "Créez un nouvel utilisateur",
      "createBulk": "Envoyez utilisateurs multiples",
      "createNew": "Créer Neuf",
      "createNewGroup": "Créez Ce Groupe",
      "currentCompetency": "Compétence Actuelle",
      "currentSchool": "Écôle Actuelle",
      "currentlySearchingPrompt": "Recherchent...",
      "curriculumInventory": "Inventaire des programmes d'études",
      "curriculumInventoryInstitutionalInfo": "Inventaire des programmes d'études informations institutionnelles",
      "curriculumInventoryReport": "Rapport d'inventaire des programmes d'études",
      "curriculumInventoryReportLeadership": "Dirigeants de Rapport d'inventaire des programmes d'études",
      "curriculumInventoryReportRollover": "Roulement Rapport d'inventaire des programmes d'études",
      "curriculumInventoryReportRolloverSuccess": "Roulement achevée avec succès",
      "curriculumInventoryReportRolloverSummary": "Cette action permet de copier la structure de ce rapport qui doit être utilisé dans votre année cible. Il ne sera pas copier des informations de cours, seule la structure du bloc de séquence de niveau supérieur. Vous aurez besoin d'ajouter l'information de cours approprié une fois que vous avez roulé sur la structure du rapport existant.",
      "curriculumInventoryReports": "Rapports d'inventaire des programmes d'études",
      "dashboard": "Tableau de Bord",
      "date": "Date",
      "dateTime": "Date/Temps",
      "day": "Jour",
      "defaultInstructors": "Instructeurs par Défaut",
      "defaultInstructorsNotLoaded": "(Instructeurs par défaut ne seront pas chargés: s'il vous plaît entrer la valeur souhaitée.)",
      "defaultLocation": "Localisation par Défaut",
      "defaultLocationNotLoaded": "(Localisation par défaut ne sera pas chargé: s'il vous plaît entrer la valeur souhaitée.)",
      "departments": "Facultés",
      "deprecatedAbbreviation": "obs.",
      "description": "Description",
      "detail": "Détail",
      "details": "Détails de Cours",
      "developer": "Administrateur",
      "director": "Directeur",
      "directorCount": "Il y a {count, plural, =1 {un directeur} other {# directeurs}}",
      "directors": "Directeurs",
      "disableUser": "Désactiver compte d’utilisateur",
      "disabled": "désactivée",
      "displayName": "Nom d’Affichage",
      "doesNotMatchUserRecord": "Téléchargé {description} ne correspond pas aux informations dans Ilios, qui est \"{record}\"",
      "domain": "Domaine",
      "done": "Fini",
      "download": "Télécharger",
      "downloadCompetencyMap": "Télécharger la tableau de compétences",
      "downloadResults": "Télécharger les indices",
      "draft": "Provisoire",
      "dueBefore": "Avant {name} ({date})",
      "dueBeforeLink": "Avant <a href='{link}'>{name}</a> ({date})",
      "dueBy": "Avant",
      "duePriorTo": "Dû avant",
      "dueThisDay": "Dû ce jour-là",
      "duration": "Durée",
      "durationHours": "{count, plural, =1 {1 une heure} other {# heures}}",
      "durationInDays": "Durée (en Jours)",
      "durationInYears": "Durée (en Années)",
      "durationMinutes": "{count, plural, =1 {1 une minute} other {# minutes}}",
      "edit": "Edite",
      "educationalYear": "Année scolaire",
      "email": "Email",
      "enabled": "Activé",
      "end": "Terminé",
      "endDate": "Jour Terminé",
      "endTime": "Heure Terminé",
      "ends": "Terminé",
      "error": "Erreur",
      "errorDisplayMessage": "Désolé! Quelque chose s'est passé mal avec votre demande. Essayez s'il vous plaît de rafraîchir ou vérifier en arrière plus tard.",
      "errorSavingAuthentication": "Les comptes ont été créés pour ces utilisateurs, mais ils ne pourront pas à la connexion. Ceci arrive d'habitude quand le nom d'utilisateur existe déjà, mais appartient à un autre utilisateur. Cliquez S'il vous plaît sur chaque utilisateur et créez un nom d'utilisateur et un mot de passe pour eux.",
      "errorSavingUser": "Ces utilisateurs n'avait pas été sauvé. Vérifiez qu'ils n'existent pas déjà.",
      "errors": "Erreurs",
      "etAl": "et al.",
      "exclude": "Exclure",
      "excludeFromSync": "Exclure de Sync",
      "existingGroup": "Groupe Existant ",
      "expandDetail": "Afficher Détails",
      "externalId": "ID de Cours",
      "file": "Fichier",
      "fileSizeError": "Ce dossier est trop grand. Taille maximale est {maxSize}).",
      "fileTypeError": "Mal fichier téléchargé : {fileType} ne semble pas être un fichier correctement délimité.",
      "fileUploadError": "Nous n'avons pas pu télécharger ce dossier. Assurez S'il vous plaît que vous êtes connectés au réseau. Si ce problème persiste merci de nous faire savoir.",
      "filterByNameOrEmail": "Filtre par nom ou email",
      "filterPlaceholder": "Commencez à taper pour filtre la liste",
      "finalErrorDisplayMessage": "Zoinks! Quelque chose ne vas pas du tout. S'il vous plaît rafraîchir votre navigateur et prévenez-nous à support@iliosproject.org si vous continuez à voir ce message.",
      "finalResults": "Résultats Finaux",
      "finalize": "Finaliser",
      "finalizeReportConfirmation": "En finalisant ce rapport, vous le sauverez dans son état actuel à la base de données Ilios comme un objet de référence permanent et éditez pas plus loin sera possible.",
      "finalized": "Finalisé",
      "finalizingReport": "Finalisation rapport, cela peut prendre un certain temps ...",
      "findDirector": "Découvrir Directeur",
      "findInstructorOrGroup": "Trouver Instructeur ou Groupe",
      "firstName": "Prénom",
      "firstOffering": "Premier Offre",
      "for": "Pour",
      "formative": "formative",
      "formerStudent": "Ancien Étudiant",
      "friday": "Vendredi",
      "fullName": "Nom complet",
      "globalSearchPlaceholder": "Trouver des utilisateurs...",
      "go": "Aller",
      "goToUser": "Présentant enregistrement d'utilisateur en Ilios",
      "good": "Bon",
      "groupByCompetencies": "Groupe par Compétences",
      "groupMembers": "Membres du groupe courant",
      "groupName": "Nom de Groupe",
      "groups": "Groupes",
      "hideCalendar": "Cacher Calendrier",
      "hideFilters": "Cachez les Filtres",
      "home": "Accueil",
      "hours": "Heures",
      "icsAdminInstructions": "Pour ajouter le calendrier d'un utilisateur à des services ou applications autre, devront utiliser cette URL. Cette URL comme un mot de passe. Toutes les personnes qui le connaissent pouvez voir le calendrier de cet utilisateur!",
      "icsFeed": "Données de ICS",
      "icsInstructions": "Pour ajouter votre calendrier à des services ou applications autre, utilisez cette URL. Cette URL comme un mot de passe. Toutes les personnes qui le connaissent pouvez voir votre calendrier!",
      "id": "ID",
      "ignore": "Ignorer",
      "ilios": "Ilios",
      "iliosUpdatePending": "hourra! Nous avons fait Ilios mieux. Vous obtiendrez le nouveau truc sur votre connexion prochaine, ou cliquerez pour mettre à jour maintenant.",
      "ilm": "ILM",
      "ilmDue": "ILM - Échéance",
      "inactive": "inactif",
      "include": "Inclure",
      "includedInGrade": "Inclus dans le grade",
      "incompleteSessions": "Séances incomplète: ne peut être pas publiée",
      "independentLearning": "Apprentissage autonome",
      "independentLearningActivities": "Apprentissage autonome Activités",
      "instructionalMethod": "Modalité d'Instruction",
      "instructionalMethods": "Modalités d'Instruction",
      "instructionalNotes": "Notes Didactiques",
      "instructor": "Instructeur",
      "instructorGroup": "Groupe des Instructeurs",
      "instructorGroupTitle": "Titre de Groupe des Instructeurs",
      "instructorGroupTitleFilterPlaceholder": "Filtre par Titre de Groupe des Instructeurs",
      "instructorGroupTitlePlaceholder": "Ajoutez une titre pour cette groupe des instructeurs",
      "instructorGroups": "Groupes des Instructeurs",
      "instructors": "Instructeurs",
      "instructorsManageTitle": "Manager Instructeurs",
      "invalidDatetimes": "Dates/Heures invalides",
      "invalidUsers": "Utilisateurs non valides",
      "invalidateTokens": "Invalider tous les jetons",
      "invalidateTokensConfirmation": "Cette action va effacer tous les jetons de l’API. Vouz devrez générer nouveaux jetons pour toutes des vos applications.  Êtes vous sûr de vouloir le faire?",
      "isSelective": "Est sélectif",
      "isTrack": "Est une piste",
      "itemCode": "Code d'article",
      "language": {
        "en-us": "English (en)",
        "es": "Español (es)",
        "fr": "Français (fr)"
      },
      "lastName": "Nom de famille",
      "lastUpdate": "Dernière mise à jour",
      "learner": "Apprenant",
      "learnerAssignments": "{groupTitle} affectations d'étudiant",
      "learnerGroupTitle": "Titre de groupe d'apprenants",
      "learnerGroupTitleFilterPlaceholder": "Filtre par titre de groupe d'apprenants",
      "learnerGroupTitlePlaceholder": "Ajoutez une titre pour cette groupe d'apprenants",
      "learnerGroups": "Groupes d'Étudiants",
      "learnerGroupsManageTitle": "Manager groupes d'apprenants",
      "learners": "Étudiants",
      "learningMaterial": "Matériel d'Étude",
      "learningMaterialManageTitle": "Manager Matériels d'Étude",
      "learningMaterials": "Matériels d'Étude",
      "learningMaterialsSummary": "Résumé Matériels d'Étude",
      "level": "Niveau",
      "link": "Lien web",
      "linkedCompetencies": "Compétences Liés",
      "linkedCompetencyCount": "{count, plural, =1 {1 présente un compétence líé} other {# présentes des compétences líés}}",
      "listByPriority": "Liste par priorité",
      "lmDescriptorTitle": "Choisi MeSH pour les matériels d'étude",
      "loading": "Chargement Ilios",
      "loadingEvents": "Rassembler les événements...",
      "loadingMaterials": "Chargement Matériels...",
      "loadingSessionTypes": "Chargement des genres de session...",
      "location": "Locale",
      "login": "Login",
      "logo": "Ilios Logo",
      "logout": "Déconnexion",
      "makeRecurring": "Activité Récurrente",
      "manage": "Administrer",
      "manageAPITokens": "Gérer des jetons de l’API",
      "manageCompetencies": "Manager des Compétences",
      "manageInstitutionalInfo": "Manager les informations institutionnelles du CIR",
      "manageLeadership": "Gérer Dirigeants",
      "manageSessionAttributes": "Gérer Attributs de Séance",
      "manageUsers": "Gérer les utilisateurs",
      "manageUsersSummaryTitle": "utilisateurs d'Ilios",
      "manageVocabularies": "Manager vocabulaires",
      "markAsScheduled": "Faire à prévu",
      "matchGroups": "Départagez les Groupes ",
      "materials": "Matériels",
      "matriculationYear": "Année de immatriculation",
      "maximum": "Maximum",
      "members": "Membres",
      "menu": "Ilios Menu",
      "mesh": "MeSH",
      "meshCount": "{count, plural, =1 {1 a des MeSH} other {# ont MeSH}}",
      "meshManageTitle": "Manager MeSH",
      "meshSearchPlaceholder": "Rechercher MeSH",
      "meshTerm": "Terme de MeSH",
      "meshTerms": "Termes de MeSH",
      "middleName": "Deuxième Prénom",
      "minimum": "Minimum",
      "minutes": "Minutes",
      "missingCohortMessage": "S'il vous plait ajouter au moins un cohort à ce cours.",
      "missingCompetenciesMessage": "S'il vous plaît ajouter au moins une compétence pour cette année de diplôme.",
      "missingCourseObjectivesMessage": "S'il vous plait ajouter au  moins d'objectif au cours.",
      "missingItems": "Objets Manquants",
      "missingPassword": "Nécessite un mot de passe",
      "missingRequiredUsername": "'Nom d'Utilisateur' est requis pour login; Cet utilisateur ne pourra pas login jusqu'à qu'un Nom d'Utilisateur s'affiche.",
      "missingUsername": "Nécessite un nom d'utilisateur",
      "modifyUserPassword": "Cliquez ici pour remettre à zéro votre mot de passe.",
      "monday": "Lundi",
      "month": "Mois",
      "moreInputRequiredPrompt": "Continuez à taper...",
      "moveToGroup": "{count, plural, =1 {Placer l’étudiant à {groupTitle}} other {Placer # étudiants à {groupTitle}}}",
      "multiDay": "de Plusieurs Jours",
      "multiday": "Plusieurs Jours",
      "multidayEvents": "Événements de plusieurs jours",
      "multiple": "Multiple",
      "multipleGroups": "Plusieurs groupes",
      "multipleUsersFoundWithCampusId": "Plusieurs utilisateurs trouvés avec le Campus ID  {campusId}",
      "mustBeSameDayOfWeekAsCurrentStartDate": "Doit être le même jour de la semaine que le courant date de début",
      "myCalendar": "Mon Calendrier",
      "myCourses": "Mes Cours",
      "myMaterials": "Mes Matériels d'étude",
      "myProfile": "Mon profil",
      "myReports": "Mes Rapports",
      "mySchedule": "Mon Calendrier (liste)",
      "name": "Nom",
      "narrative": "narrative",
      "newCourse": "Cours neuf",
      "newCurriculumInventoryReport": "Nouveau Rapport d'inventaire des Programmes d'études",
      "newDomain": "Domaine nouvelle",
      "newInstructorGroup": "Groupe des Instructeurs nouveau",
      "newLearnerGroup": "Groupe d'apprenants nouvelle",
      "newObjective": "Nouvel Objectif",
      "newObjectiveSaved": "Nouvel objective sauvé",
      "newOffering": "Nouvelle Offre",
      "newProgram": "Diplôme Nouveau",
      "newProgramYear": "Année de diplôme neuf",
      "newReport": "Nouveau rapport",
      "newSchool": "École nouvelle",
      "newSequenceBlock": "Nouveau bloc de séquence",
      "newSession": "Session neuf",
      "newTerm": "Nouveau terme",
      "newToken": "Nouveau jeton",
      "newUpdates": "Nouvelles mises à jour",
      "newUser": "Nouvel utilisateur",
      "newUsersCreatedSuccessfully": "Nouveaux utilisateurs crées avec succès",
      "newUsersCreatedWarning": "Certains utilisateurs ont été créés, mais il y avait des erreurs.",
      "next": "Proch.",
      "no": "Non",
      "noAccountExists": "Votre compte d'utilisateur {accountName} ne correspond pas à ceux des utilisateur enregistre en Ilios. Si vous avez besoin d'aide, n'hesitez pas à communiquer avec votre administrateur  d'Ilios.",
      "noAssociatedCompetencies": "Nul compétences associées",
      "noAssociatedCompetency": "Nul compétence associée",
      "noAvailableCohorts": "Pas des Cohortes disponsibles",
      "noAvailableLearnerGroups": "Pas de groupes d'étudiants sont disponibles; pas de cohorte est attaché à ce cours.",
      "noCohorts": "Il n'y a aucun des cohortes dans ce cours",
      "noCourseLearningMaterialsAvailable": "Aucun Matériels D'étude de Cours",
      "noCourses": "Il n'y a aucun des cours dans cette école.",
      "noCurriculumInventoryInstitutionalInfo": "Aucune information institutionnelle n'a été configurée pour cette école.",
      "noLearnerGroups": "Pas de groupes des Étudiants",
      "noOfferingLearnerGroups": "Aucun des groupes",
      "noOfferings": "Ce session n'a aucun des offres",
      "noPermissionsInOtherSchools": "Vous n'avez pas les permissions nécessaires pour ajouter cet utilisateur à toute autres écoles",
      "noPopulateGroup": "Non!  Laissez-la vide, j'ajouterai des étudiants moi-même",
      "noPrintDraft": "Cours qui sont au stade de projet ne peuvent pas étre imprimés",
      "noProgramYears": "Il n'y-a pas des années de diplôme dans ca diplôme",
      "noResultsFound": "Aucun Résultat",
      "noSearchResults": "Votre recherche ne retourner pas aucune de resultats",
      "noSearchResultsPrompt": "Aucun Résultat",
      "noSelectedCompetencies": "Il n'y a pas des compétences sélectionné",
      "noSelectedLearnerGroups": "Il n'y a aucune groupes d'apprenants sélectionné",
      "noSequenceBlocks": "Il n'y a aucun blocs séquence dans ce rapport",
      "noSessionLearningMaterialsAvailable": "Aucun Matériels D'étude de Séance",
      "noSessions": "Il n'y a aucun des sessions dans ce cours",
      "noSubSequenceBlocks": "Ce bloc séquence n'y a aucun blocs séquence emboîtée.",
      "noSubgroups": "Il n'éxiste aucune des sous-groupes en cette groupe d'apprenants",
      "noUnselectedCompetencies": "Il n'y a pas des compétences non sélectionné ",
      "noUnselectedLearnerGroupsInCohort": "Il n'y a aucune groupes d'apprenants disponsibles dans cette cohorte",
      "nonClerkshipSequenceBlocks": "Blocs de séquence non stage",
      "nonStudent": "Non étudiant",
      "none": "Nul",
      "notAClerkship": "Pas une stage",
      "notApplicableAbbr": "n.d.",
      "notFoundMessage": "Zut! Je ne pouvais pas trouver cette chôse. S'il vous plaît vérifier votre adresse de la page et essayez à nouveau.",
      "notPublished": "Non Publier",
      "notes": "Notes",
      "numberOfEvents": "Nombre d'événements",
      "numberOfEventsFeaturingThisAsThePrimaryMethod": "Nombre d'événements avec ceci comme méthode principale",
      "numberOfExams": "Nombre d'examens",
      "numberOfFormalInstructionalHoursPerCourse": "Nombre d'heures d'enseignement formel par cours",
      "numberOfFormativeAssessments": "Nombre d'évaluations formatives",
      "numberOfGroups": "Nombre de groupes",
      "numberOfGroupsToGenerate": "Nombre de groupes pour générer.",
      "numberOfNonPrimaryOccurrencesOfThisMethod": "Nombre d'occurrences non primaires de cette méthode",
      "numberOfSummativeAssessments": "Nombre d'évaluations sommatives",
      "objective": "Objectif",
      "objectiveCompetencyManagerTitle": "Sélectionner Compétence Mère pour Objectif",
      "objectiveCount": "Il y a {count, plural, =1 {1 objectif} other {# objectifs}}",
      "objectiveDescriptorTitle": "Choisi MeSH pour l'objectif",
      "objectiveMap": "Tableau Objectifs",
      "objectiveParentTitle": "Choisi Objectifs mères",
      "objectiveParentTitleSingular": "Choisi Objectif mère",
      "objectiveParentsTitle": "Affichez objectifs mères",
      "objectiveTitle": "Objectif de Séance",
      "objectives": "Objectifs",
      "objectivesTotalInstructionalTime": "Objectifs: Totalité du Temps d'Enseignement",
      "objectivesWithNoHours": "Les objectifs de cours suivant ne sont ou liés avec aucune séance, ou liés seulement aux séance sans offrir ou ou des données de durée.",
      "objectivesWithNoLink": "Nul objectifs de course actuellement lié au temps dénseignment.",
      "off": "Éteindre",
      "offering": "Offre",
      "offerings": "Offres",
      "on": "Allumer",
      "openSmallGroupGenerator": "Générateur des petites groupes",
      "optional": "Optionnel",
      "optionalElective": "Optionnel (électif)",
      "orderInSequence": "Ordre dans séquence",
      "ordered": "Ordonnée",
      "other": "Autre",
      "otherId": "Autre ID",
      "otherVisualizations": "Autres Visualisations",
      "overview": "Conception Général",
      "ownedBy": "Possédé par {owner}",
      "owner": "Propriétaire",
      "pagedResultsCount": "Montrant {start} - {end} de {total}",
      "parallel": "Parallèle",
      "parentCount": "{count, plural, =1 {1 a un objectif mère} other {# ont des objectifs mères}}",
      "parentObjectives": "Objectifs Mères",
      "password": "Mot de passe",
      "pendingUpdatesSummary": "{count, plural, =1 {Il y a un utilisateur nécessitant une attention particulière} other {Il y a des # utilisateurs nécessitants une attention particulière}}",
      "pendingUpdatesSummaryTitle": "Mises du répertoire de Campus",
      "pendingUserUpdates": {
        "emailMismatch": "L’address email dans le répertoire ({value}) ne correspond pas à ce email dans ilios ({email}).",
        "filterBy": "Filtre par nom d’utilisateur",
        "missingFromDirectory": "Impossible de trouver l’utilisateur dans le répertoire, s’il vous plaît mettre à jour, désactiver, ou exclure leur compte de synchronization.",
        "updateIlios": "Mettre à jour Ilios"
      },
      "people": "Peuples",
      "perPage": "Par Page",
      "performsNonLearnerFunctions": "Acquitte de Fonctions Non-Apprenant",
      "permissions": "Permissions",
      "permissionsInOtherSchools": "Permissions en Autres Écoles",
      "phone": "Téléphone",
      "physicianCompetencyReferenceSet": "Ensemble de références de compétences de médecin",
      "physicianCompetencyReferenceSetAbbr": "PCRS",
      "populateGroup": "Voulez-vous remplir cette groupe?",
      "preWork": "Préalable",
      "preferredEmail": "Email préféré",
      "prerequisites": "Conditions préalables",
      "prev": "Précéd.",
      "primary": "Première",
      "primaryCohort": "Cohorte Première",
      "primarySchool": "École Première",
      "printSummary": "Empreinte Résumé",
      "program": "Diplôme",
      "programAndCohort": "Diplôme et Cohortes",
      "programCohorts": "Cohortes",
      "programExpectations": "Attentes du programme",
      "programExpectationsId": "Identifiant des attentes du programme",
      "programLeadership": "Direction de Diplôme",
      "programTitle": "Titre de Diplôme",
      "programTitleFilterPlaceholder": "Filtre par Titre de Diplôme",
      "programTitlePlaceholder": "Ajoutez un titre pour ce Diplôme",
      "programTitleShort": "Titre de Diplôme (bref)",
      "programYear": "Année de Diplôme",
      "programYearVisualizations": "Tableau de Matière de Curriculum par Année de Diplôme",
      "programYears": "Années des Diplôme",
      "programs": "Diplômes",
      "promoteToPrimaryCohort": "Promouvoir à Cohorte Primaire",
      "publicationStatus": "Statut de Publication",
      "publishAllConfirmation": "Publier {publishCount}, réserver {scheduleCount}, et ignorer des {ignoreCount} séances",
      "publishAllSessions": "Publier {sessionCount} des séances",
      "publishAsIs": "Publier \"as-is\"",
      "publishCourse": "Publier Cours",
      "publishProgram": "Publier Diplôme",
      "publishProgramYear": "Publier année de diplôme",
      "publishSession": "Publier Session",
      "published": "Publier",
      "publishedSuccessfully": "Publier réussi",
      "read": "Lecture",
      "reconnectNow": "Reconnecter Maintenant",
      "reconnectionSeconds": "{count, plural, =1 {Reconnexion en 1 seconde...} other {Reconnexion en # secondes...}}",
      "refreshIcsFeedKey": "Rafraîchissez la données de ICS clef pour cet utilisateur",
      "refreshTheBrowser": "Rafraîchir votre Navigateur",
      "remove": "Supprîme",
      "removeAll": "Supprimer Tous",
      "removeCohort": "Supprîmer Cohorte",
      "removeLearnerToCohort": "{count, plural, =1 {Renvoyer l’étudiant à {cohort}} other {Renvoyer # étudiants à {cohort}}}",
      "removePrimaryCohort": "Supprîmer Cohorte Primaire",
      "removedSuccessfully": "Supprimé avec succès",
      "reportConfirmRemove": "Êtes-vous sûrs que vous voulez supprimer ce rapport? Cette action ne peut pas être défaite.",
      "reportDescriptionPlaceholder": "Entrez S'il vous plaît dans une description brève de ce rapport.",
      "reportDisplayTitleWithObject": "tous {subject} pour {object} en {school}",
      "reportDisplayTitleWithoutObject": "tous {subject} en {school}",
      "reportMissingInstructor": "Instructeur requis",
      "reportMissingMeshTerm": "terme de MeSH requis",
      "reportMissingObjectForInstructor": "connexion requis quand `instructeur` est le sujet",
      "reportMissingObjectForMeshTerm": "connexion requis quand `terme de MeSH` est le sujet",
      "reportName": "Nom de rapport",
      "reportNamePlaceholder": "Entrez S'il vous plait un nom de rapport.",
      "reportTitle": "Titre du Rapport",
      "required": "Requis",
      "requiredInTrack": "Requis en piste",
      "resourceTypes": "Types de ressources",
      "results": "Resultats",
      "resultsCount": "{count, plural, =1 {1 résultat} other {# résultats}}",
      "returnToPreviousPage": "Retour à la page précédente",
      "reviewMissingItems": "Examiner {count} objets manquants",
      "reviewSessions": "Séances à faire réviser",
      "root": "Root",
      "sampleFile": "Fichier échantillon",
      "saturday": "Samedi",
      "save": "Enregistrer",
      "saved": "Nouvel utilisateur enregistré avec succès",
      "savedSuccessfully": "Réussie Sauvé",
      "scheduled": "Prevu",
      "scheduledSuccessfully": "Faire à prévu réussi",
      "school": "Écôle",
      "schoolLeadership": "Direction des Écoles",
      "schoolName": "Nom de l'école",
      "schools": "Écoles",
      "search": "Rechercher",
      "searchDirectory": "Consultez le répertoire pour les utilisateurs nouvels",
      "searchForIliosUsers": "Trouver des utilisateurs...",
      "searchPlaceholder": "Trouver matériels d'étude",
      "searchUsers": "Rechercher des utilisateurs",
      "secondaryCohorts": "Cohortes Secondaires",
      "select": "Sélectionnez",
      "selectAcademicYear": "Choisissez l’année scolaire",
      "selectCohortsToAttach": "Choisissez le cohorte d'étudiants que vous voulez attacher au cours nouveau",
      "selectCourse": "Choisi cours",
      "selectMeshFor": "Sélectionnez MeSH pour: {title}",
      "selectProgram": "Choisi Diplôme",
      "selectSchool": "Choisi Écôle",
      "selectVocabulary": "Choisi Vocabulaire",
      "selectYear": "Choisi année scolaire",
      "selected": "Sélectionné",
      "selectedLearnerGroups": "Groupes d’Étudiants Selectionées",
      "sequenceBlock": "Bloc séquence",
      "sequenceBlockConfirmRemove": "Êtes-vous sûrs que vous voulez supprimer ce bloc séquence? Cette action ne peut pas être défaite.",
      "sequenceBlockDescriptionPlaceholder": "Entrez S'il vous plaît dans une description pour ceci bloc séquence.",
      "sequenceBlockIsSelective": "Cette bloc séquence a été marquée comme sélective.",
      "sequenceBlockTitlePlaceholder": "Entrez S'il vous plaît dans une titre pour ceci bloc séquence.",
      "sequenceBlocks": "Blocs séquence",
      "sequenceNumber": "No. de séquence",
      "session": "Séance",
      "sessionAdministration": "Administration de Séance",
      "sessionAttributes": "Attributs de Séance",
      "sessionIs": "Type de Séance: {type}",
      "sessionLearningMaterials": "Matériels d'Étude de la Séance",
      "sessionObjectives": "Objectifs de la Séance",
      "sessionTitle": "Titre de Séance",
      "sessionTitleFilterPlaceholder": "Filtre par titre, genre, ou statut",
      "sessionTitlePlaceholder": "Ajoutez un titre pour ce session",
      "sessionType": "Type de Séance",
      "sessionTypes": "Types de Séance",
      "sessionTypesFor": "Types de Séance par {subject}",
      "sessions": "Séances",
      "showCalendar": "Afficher Calendrier",
      "showFilters": "Montrez les Filtres",
      "showLearnerGroupEvents": "Montrez tous les événements pour les groupes d'étudiants affectés",
      "showMore": "Afficher plus",
      "showNotesToStudents": "Afficher notes aux étudiantes",
      "showResultsFor": "Afficher les résultats pour",
      "showSessionEvents": "Montrez tous les autres <em>{sessionTitle}</em> événements",
      "showSubgroupEvents": "Afficher les événements pour tous les sous-groupes",
      "singleDay": "Seule Journée",
      "singleGroup": "Seul groupe",
      "smallGroupMessage": "S'il vous plaît choisir au moins un groupe d'apprenants à joindre à votre offrande de \"small groups\". Si vous souhaitez programmer cette offrande sans groupes, s'il vous plaît choisir le bouton \"offre\" au-dessus.",
      "smallGroups": "Groupes Particulaires",
      "sortMaterials": "Trier les matériaux",
      "sortObjectives": "Trier les objectifs",
      "specialAttireIs_Required_": "Vêtements particuliers est <strong><em>requis</em></strong>",
      "specialAttireRequired": "Vêtements particuliers requis",
      "specialEquipment": "Équipage particulier",
      "specialEquipmentIs_Required_": "Équipage particulier est <strong><em>requis</em></strong>",
      "specialEquipmentRequired": "Équipage particulier requis",
      "start": "Commencer",
      "startDate": "Jour Commencer",
      "startOver": "recommencer",
      "startTime": "Heure Commencer",
      "starts": "Commencer",
      "stateOrProvince": "État ou province",
      "status": "Statut",
      "stewardingSchoolsAndDepartments": "Écôles et Départements intendants",
      "stewardsManageTitle": "Manager des intendants",
      "street": "Rue",
      "strong": "Fort",
      "student": "Étudiant",
      "subTerms": "Sous-termes",
      "subgroupName": "nom de sous-groupe",
      "subgroups": "Sous-groupes",
      "successfullyAddedTerm": "Le terme soit correctement ajouté",
      "successfullyInvalidatedTokens": "Tous les jetons existant ont été invalidés avec succès.",
      "successfullyRemovedTerm": "Le terme soit correctement supprimé",
      "summary": "Résumé",
      "sunday": "Dimanche",
      "supplementalCurriculum": "Supplemental Curriculum",
      "table1ProgramExpectationsMappedToPcrs": "Tableau 1: Attentes du programme mappées sur le PCRS",
      "table2PrimaryInstructionalMethodByNonClerkshipSequenceBlock": "Tableau 2: Méthode d'instruction primaire par bloc de séquence non stage",
      "table3aNonClerkshipSequenceBlockInstructionalTime": "Tableau 3-A: Durée d'instruction du bloc de séquence non stage",
      "table3bClerkshipSequenceBlockInstructionalTime": "Tableau 3-B: Temps d'instruction du bloc de séquence de stage",
      "table4InstructionalMethodCounts": "Tableau 4: Nombre de méthodes pédagogiques",
      "table5NonClerkshipSequenceBlockAssessmentMethods": "Tableau 5: Méthodes d’évaluation du bloc de séquence non stage",
      "table6ClerkshipSequenceBlockAssessmentMethods": "Tableau 6: Méthodes d’évaluation du bloc de séquences stage",
      "table7AllEventsWithAssessmentsTaggedAsFormativeOrSummative": "Tableau 7: Tous les événements avec des évaluations marquées en tant que formatif ou sommatif",
      "table8AllResourceTypes": "Tableau 8: Tous les types de ressources",
      "targetCourse": "Cours Cible",
      "taughtBy": "Dispensé à {instructors}",
      "term": "Terme",
      "termCount": "{count, plural, =1 {Il y a 1 terme} other {Il y a # termes}}",
      "terms": "Termes",
      "termsBySessionType": "Termes par Type de Seance",
      "termsManageTitle": "Manager des Termes",
      "thisReportIsNoLongerAvailable": "Ce rapport n'est plus disponsible.",
      "thursday": "Jeudi",
      "time": "Temps",
      "timedRelease": "Période Limitée",
      "timedReleaseAddEndDate": "Ajouter la date limite",
      "timedReleaseAddStartDate": "Ajouter la date de début",
      "timedReleaseClearEndDate": "Supprimer la date limite",
      "timedReleaseClearStartDate": "Supprimer la date de début",
      "timedReleaseNoSchedule": "Disponible immédiatement quand publié",
      "timedReleaseOnlyEndDate": "Disponible avant {endDate}",
      "timedReleaseOnlyStartDate": "Disponible après {startDate}",
      "timedReleaseStartAndEndDate": "Disponible de {startDate} jusqu'à {endDate}",
      "title": "Tître",
      "today": "Aujourd'hui",
      "token": "Token",
      "tokenInfo": "Jetons de l’API sont utilisés d’y accèder les données de Ilios.  Votre jeton comme un mot de passe: quiconque l’obtenu pouvent apporter des modifications en votre nom.  Lire plus dans les documentation à <a href=\"{apiDocsUrl}\">{apiDocsUrl}</a>.",
      "tooManyResults": "Votre recherche présente plus que les {count} resultats. Pour meilleurs de resultats, essayez votre recherche encore avec plus termes.",
      "total": "Total",
      "totalErrors": "{count, plural, =1 {Il y a une erreur} other {Il y a # erreurs}}",
      "totalIlmTime": "Durée totale par ILM {minutes} Minutes",
      "totalInstructionalTime": "Durée d'enseignement totale {minutes} Minutes",
      "totalTime": "Heures Totales",
      "totalWeeks": "Total de Semaines",
      "transitionErrorMessage": "Désolé, nous ne pouvons pas vous faire demander où vous aller : quelque chose semble être cassé.",
      "tryHarder": "Faire un effort",
      "tuesday": "Mardi",
      "type": "Type",
      "unPublishCourse": "Faire à Dépublier Cours",
      "unPublishProgram": "Faire à Dépublier Diplôme",
      "unPublishProgramYear": "Faire à Dépublier année de diplôme",
      "unPublishSession": "Faire à Dépublier Session",
      "unPublishedSuccessfully": "Faire à Dépublier réussi",
      "unableToReconnect": "Ne pouver reconnecter!",
      "unableToSyncUser": "Incapable de syncroniser le utilisateur de répertoire, veuillez vous assurer le campus ID soit exacte.",
      "unassigned": "Non attribuée",
      "unassignedStudentsConfirmation": "{count, plural, =1 {Attribuez 1 l’utilisateur sélectionné à} other {Attribuez # utilisateurs sélectionnés à}}",
      "unassignedStudentsSummary": "{count, plural, =1 {Il y a un étudiant qui a besoin affectation à une cohorte} other {Il y a # étudiants qui ont besoin affectation à une cohorte}}",
      "unassignedStudentsSummaryTitle": "Étudiants qui ont besoin affectation à une cohorte",
      "undo": "Defaît",
      "universalLocator": "Localisateur universel",
      "unlinkedObjectives": "Ce cours comprend des objectifs non liés",
      "unordered": "Non ordonnée",
      "unsupportedBrowserFailure": "Votre navigateur n'est pas officiellement soutenu par Ilios et ne peut pas compléter cette action. Essayez S'il vous plaît d'utiliser de nouveau un navigateur à jour.",
      "unusedObjectives": "Objectifs non Utilisés",
      "upcomingActivities": "Mes Activités pendant les {days} prochains jours",
      "upcomingMaterials": "Mes Matériels d'Étude pendant les {days} prochains jours",
      "updateNow": "Actualiser affichage",
      "updatePending": "Mise à jour en suspens",
      "updateUserFromDirectory": "Mettez à jour cet enregistrement utilisateur de la répertoire de campus",
      "uploadDate": "Date de dépôt",
      "uploadGroupAssignments": "téléversez travaux des groupes",
      "uploadUsers": "fichier de donnée utilisateurs (csv, tsv, txt)",
      "uploadedGroup": "groupe a été téléversée",
      "url": "URL",
      "userExistsInGroupHierarchy": "L'utilisateur existe déjà dans le groupe de niveau supérieur {groupTitle} ou dans l'un de ses sous-groupes.",
      "userExistsMultipleTimesInUpload": "Cet utilisateur existe déjà dans le téléchargement.",
      "userNotAddableFromDirectory": "Cette utilisateur manque les informations requises, et ne peut pas être ajouté",
      "userNotInGroupCohort": "utilisateur introuvable dans cette groupe de cohorte: {cohortTitle}",
      "userRole": "Rôle d'utilisateur",
      "userRoles": "Rôles des Utilisateurs",
      "username": "Nom d'utilisateur",
      "users": "Utilisateurs",
      "usersCalendar": "Calendrier de {name}",
      "usersSelected": "{count, plural, =1 {1 utilisateur sélectionné} other {# utilisateurs sélectionnés}}",
      "validUntil": "Quand ce jeton devrais arrêter de travailler?",
      "validUsers": "utilisateurs valides",
      "verificationPreviewFor": "Aperçu de vérification pour {name}",
      "view": "Vue",
      "viewAll": "Voir Tous",
      "vocabularies": "Vocabulaires",
      "vocabulary": "Vocabulaire",
      "vocabularyTermsFor": "Termes Vocabulaire par {subject}",
      "waitSaving": "Travaille dessus... un moment...",
      "weak": "Faible",
      "wednesday": "Mercredi",
      "week": "Semaine",
      "weekAtAGlance": "Semaine d'un coup d'œil",
      "weekOf": "Semaine de {date}",
      "weeks": "Semaines",
      "when": "Quand",
      "whichIs": "qui est",
      "whitecoatsSlashSpecialAttire": "Blouses blanches / vêtements particuliers",
      "withXmoreOfferings": "avec {count, plural, =1 {une autre offre} other {# autres offres}}",
      "write": "Écriture",
      "year": "Année",
      "yes": "Oui",
      "yesPopulateGroup": "Oui!  Ajoutez la cohorte entière à mon nouveau groupe",
      "zipCode": "Code postal"
    }
  };
  _exports.default = _default;
});