define("ilios/templates/components/curriculum-inventory-sequence-block-details", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "OfOUkf3E",
    "block": "{\"symbols\":[\"parent\"],\"statements\":[[5,\"curriculum-inventory-sequence-block-header\",[],[[\"@canUpdate\",\"@sequenceBlock\"],[[22,\"canUpdate\"],[22,\"sequenceBlock\"]]]],[0,\"\\n\"],[4,\"if\",[[28,\"is-fulfilled\",[[24,[\"sequenceBlock\",\"allParents\"]]],null]],null,{\"statements\":[[0,\"  \"],[7,\"div\",true],[10,\"class\",\"breadcrumbs\"],[8],[0,\"\\n    \"],[7,\"span\",true],[8],[0,\"\\n      \"],[5,\"link-to\",[],[[\"@route\",\"@model\"],[\"curriculumInventoryReport\",[24,[\"sequenceBlock\",\"report\"]]]],{\"statements\":[[0,\"\\n        \"],[1,[28,\"t\",[\"general.curriculumInventoryReport\"],null],false],[0,\"\\n      \"]],\"parameters\":[]}],[0,\"\\n    \"],[9],[0,\"\\n\"],[4,\"each\",[[28,\"reverse\",[[28,\"await\",[[24,[\"sequenceBlock\",\"allParents\"]]],null]],null]],null,{\"statements\":[[0,\"      \"],[7,\"span\",true],[8],[0,\"\\n        \"],[5,\"link-to\",[],[[\"@route\",\"@model\"],[\"curriculumInventorySequenceBlock\",[23,1,[]]]],{\"statements\":[[0,\"\\n          \"],[1,[23,1,[\"title\"]],false],[0,\"\\n        \"]],\"parameters\":[]}],[0,\"\\n      \"],[9],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"    \"],[7,\"span\",true],[8],[0,\"\\n      \"],[1,[24,[\"sequenceBlock\",\"title\"]],false],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[5,\"curriculum-inventory-sequence-block-overview\",[],[[\"@sequenceBlock\",\"@canUpdate\",\"@sortBy\",\"@setSortBy\"],[[22,\"sequenceBlock\"],[22,\"canUpdate\"],[22,\"sortSessionsBy\"],[22,\"setSortSessionBy\"]]]]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ilios/templates/components/curriculum-inventory-sequence-block-details.hbs"
    }
  });

  _exports.default = _default;
});