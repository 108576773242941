define("ilios/templates/program-year-visualize-competencies", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "NPrWCVmu",
    "block": "{\"symbols\":[],\"statements\":[[1,[28,\"page-title\",[[28,\"t\",[\"general.programs\"],null]],null],false],[0,\"\\n\"],[7,\"section\",true],[10,\"class\",\"program-year-visualize-competencies\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"backtolink\"],[8],[0,\"\\n    \"],[5,\"link-to\",[],[[\"@route\",\"@model\"],[\"program-year-visualizations\",[22,\"model\"]]],{\"statements\":[[0,\"\\n      \"],[1,[28,\"t\",[\"general.otherVisualizations\"],null],false],[0,\"\\n    \"]],\"parameters\":[]}],[0,\"\\n  \"],[9],[0,\"\\n  \"],[7,\"h3\",true],[8],[0,\"\\n    \"],[5,\"link-to\",[],[[\"@route\",\"@model\"],[\"program\",[24,[\"model\",\"program\"]]]],{\"statements\":[[0,\"\\n      \"],[1,[28,\"get\",[[28,\"await\",[[24,[\"model\",\"program\"]]],null],\"title\"],null],false],[0,\"\\n    \"]],\"parameters\":[]}],[0,\"\\n    \"],[5,\"link-to\",[],[[\"@route\",\"@models\"],[\"programYear\",[28,\"array\",[[24,[\"model\",\"program\"]],[24,[\"model\"]]],null]]],{\"statements\":[[0,\"\\n\"],[4,\"if\",[[28,\"get\",[[28,\"await\",[[24,[\"model\",\"cohort\"]]],null],\"title\"],null]],null,{\"statements\":[[0,\"        \"],[1,[28,\"get\",[[28,\"await\",[[24,[\"model\",\"cohort\"]]],null],\"title\"],null],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"        \"],[1,[28,\"t\",[\"general.classOf\"],[[\"year\"],[[28,\"await\",[[24,[\"model\",\"classOfYear\"]]],null]]]],false],[0,\"\\n\"]],\"parameters\":[]}],[0,\"    \"]],\"parameters\":[]}],[0,\"\\n  \"],[9],[0,\"\\n  \"],[5,\"visualizer-program-year-competencies\",[],[[\"@programYear\"],[[22,\"model\"]]]],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ilios/templates/program-year-visualize-competencies.hbs"
    }
  });

  _exports.default = _default;
});