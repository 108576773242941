define("ilios/components/curriculum-inventory-sequence-block-session-manager", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    classNames: ['curriculum-inventory-sequence-block-session-manager', 'resultslist'],
    tagName: 'section',
    excludedSessionsBuffer: null,
    linkedSessionsBuffer: null,
    sessionsBuffer: null,
    sortBy: 'title',
    allSelected: Ember.computed('linkedSessionsBuffer.[]', 'sessionsBuffer.[]', function () {
      const linkedSessions = this.linkedSessionsBuffer;
      const sessions = this.sessionsBuffer;

      if (Ember.isEmpty(linkedSessions) || Ember.isEmpty(sessions) || linkedSessions.length < sessions.length) {
        return false;
      }

      sessions.forEach(session => {
        if (!linkedSessions.includes(session)) {
          return false;
        }
      });
      return true;
    }),
    allExcluded: Ember.computed('excludedSessionsBuffer.[]', 'sessionsBuffer.[]', function () {
      const excludedSessions = this.excludedSessionsBuffer;
      const sessions = this.sessionsBuffer;

      if (Ember.isEmpty(excludedSessions) || Ember.isEmpty(sessions) || excludedSessions.length < sessions.length) {
        return false;
      }

      sessions.forEach(session => {
        if (!excludedSessions.includes(session)) {
          return false;
        }
      });
      return true;
    }),
    someSelected: Ember.computed('allSelected', 'noneSelected', function () {
      const allSelected = this.allSelected;
      const noneSelected = this.noneSelected;
      return !allSelected && !noneSelected;
    }),
    someExcluded: Ember.computed('allExcluded', 'noneExcluded', function () {
      const allExcluded = this.allExcluded;
      const noneExcluded = this.noneExcluded;
      return !allExcluded && !noneExcluded;
    }),
    noneSelected: Ember.computed('linkedSessionsBuffer.[]', 'sessionsBuffer.[]', function () {
      const linkedSessions = this.linkedSessionsBuffer;
      const sessions = this.sessionsBuffer;

      if (Ember.isEmpty(linkedSessions) || Ember.isEmpty(sessions)) {
        return true;
      }

      let isSelected = false;
      linkedSessions.forEach(linkedSession => {
        if (sessions.includes(linkedSession)) {
          isSelected = true;
        }
      });
      return !isSelected;
    }),
    noneExcluded: Ember.computed('excludedSessionsBuffer.[]', 'sessionsBuffer.[]', function () {
      const excludedSessions = this.excludedSessionsBuffer;
      const sessions = this.sessionsBuffer;

      if (Ember.isEmpty(excludedSessions) || Ember.isEmpty(sessions)) {
        return true;
      }

      let isSelected = false;
      excludedSessions.forEach(session => {
        if (sessions.includes(session)) {
          isSelected = true;
        }
      });
      return !isSelected;
    }),
    sortedAscending: Ember.computed('sortBy', function () {
      const sortBy = this.sortBy;
      return sortBy.search(/desc/) === -1;
    }),

    init() {
      this._super(...arguments);

      this.set('linkedSessionsBuffer', []);
      this.set('linkableSessionsBuffer', []);
    },

    didReceiveAttrs() {
      this._super(...arguments);

      const sequenceBlock = this.sequenceBlock;
      const sessions = this.sessions;
      this.loadAttr.perform(sequenceBlock, sessions);
    },

    actions: {
      changeSession(session) {
        let sessions = this.linkedSessionsBuffer;

        if (sessions.includes(session)) {
          sessions.removeObject(session);
        } else {
          sessions.addObject(session);
        }
      },

      excludeSession(session) {
        let sessions = this.excludedSessionsBuffer;

        if (sessions.includes(session)) {
          sessions.removeObject(session);
        } else {
          sessions.addObject(session);
        }
      },

      toggleSelectAll() {
        const allSelected = this.allSelected;

        if (allSelected) {
          // un-select all sessions
          this.set('linkedSessionsBuffer', []);
        } else {
          //select all sessions
          this.set('linkedSessionsBuffer', this.sessionsBuffer.toArray());
        }
      },

      toggleExcludeAll() {
        const allSelected = this.allExcluded;

        if (allSelected) {
          // un-select all sessions
          this.set('excludedSessionsBuffer', []);
        } else {
          //select all sessions
          this.set('excludedSessionsBuffer', this.sessionsBuffer.toArray());
        }
      },

      sortBy(what) {
        const sortBy = this.sortBy;

        if (sortBy === what) {
          what += ':desc';
        }

        this.setSortBy(what);
      },

      close() {
        this.cancel();
      }

    },
    loadAttr: (0, _emberConcurrency.task)(function* (sequenceBlock, sessions) {
      let linkedSessionsBuffer = yield sequenceBlock.get('sessions');
      linkedSessionsBuffer = linkedSessionsBuffer.toArray();
      let excludedSessionsBuffer = yield sequenceBlock.get('excludedSessions');
      excludedSessionsBuffer = excludedSessionsBuffer.toArray();
      let sessionsBuffer = yield sessions;
      this.setProperties({
        linkedSessionsBuffer,
        excludedSessionsBuffer,
        sessionsBuffer
      });
    }),
    saveChanges: (0, _emberConcurrency.task)(function* () {
      let sessions = this.linkedSessionsBuffer;
      let excludedSessions = this.excludedSessionsBuffer;
      yield this.save(sessions, excludedSessions);
    })
  });

  _exports.default = _default;
});