define("ilios/components/ilios-users", ["exports", "ilios-common/utils/query-utils", "ember-concurrency"], function (_exports, _queryUtils, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const DEBOUNCE_TIMEOUT = 250;

  var _default = Ember.Component.extend({
    iliosConfig: Ember.inject.service(),
    store: Ember.inject.service(),
    classNames: ['ilios-users'],
    limit: null,
    offset: null,
    query: null,
    showBulkNewUserForm: false,
    showNewUserForm: false,
    newUserComponent: Ember.computed('iliosConfig.userSearchType', async function () {
      const iliosConfig = this.iliosConfig;
      const userSearchType = await iliosConfig.get('userSearchType');
      return userSearchType === 'ldap' ? 'new-directory-user' : 'new-user';
    }),
    searchForUsers: (0, _emberConcurrency.task)(function* () {
      const query = this.query;
      const q = (0, _queryUtils.cleanQuery)(query);
      yield (0, _emberConcurrency.timeout)(DEBOUNCE_TIMEOUT);
      const {
        store,
        offset,
        limit
      } = this;
      return yield store.query('user', {
        limit,
        q,
        offset,
        'order_by[lastName]': 'ASC',
        'order_by[firstName]': 'ASC'
      });
    }).cancelOn('deactivate').restartable(),

    didReceiveAttrs() {
      this._super(...arguments);

      this.searchForUsers.perform();
    },

    actions: {
      setOffset(offset) {
        if (offset < 0) {
          offset = 0;
        }

        this.setOffset(offset);
      }

    }
  });

  _exports.default = _default;
});