define("ilios/components/user-profile-cohorts", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    currentUser: Ember.inject.service(),
    permissionChecker: Ember.inject.service(),
    store: Ember.inject.service(),
    classNameBindings: [':user-profile-cohorts', ':small-component', 'hasSavedRecently:has-saved:has-not-saved'],
    cohorts: null,
    finishedSetup: false,
    hasSavedRecently: false,
    isManageable: false,
    isManaging: false,
    primaryCohort: null,
    schools: null,
    selectedSchoolId: null,
    user: null,
    selectedSchool: Ember.computed('selectedSchoolId', 'schools.[]', function () {
      const selectedSchoolId = this.selectedSchoolId;
      const schools = this.schools;
      return schools.findBy('id', selectedSchoolId);
    }),
    assignableCohorts: Ember.computed('allCohorts.[]', 'selectedCohorts.[]', async function () {
      const cohorts = await this.allCohorts;
      const selectedCohorts = this.selectedCohorts || [];
      return cohorts.filter(cohort => !selectedCohorts.includes(cohort));
    }),
    assignableCohortsForSelectedSchool: Ember.computed('assignableCohorts.[]', 'selectedSchool', async function () {
      const selectedSchool = this.selectedSchool;
      const assignableCohorts = await this.assignableCohorts;
      return Ember.RSVP.filter(assignableCohorts, async cohort => {
        const school = await cohort.get('school');
        return school.get('id') === selectedSchool.get('id');
      });
    }),
    secondaryCohorts: Ember.computed('primaryCohort', 'selectedCohorts.[]', function () {
      const primaryCohort = this.primaryCohort;
      const selectedCohorts = this.selectedCohorts;

      if (Ember.isEmpty(primaryCohort)) {
        return selectedCohorts;
      }

      return selectedCohorts.filter(cohort => {
        return cohort.get('id') != primaryCohort.get('id');
      });
    }),

    didReceiveAttrs() {
      this._super(...arguments);

      const user = this.user;

      if (Ember.isPresent(user)) {
        this.setup.perform(user);
      }
    },

    actions: {
      removeSelectedCohort(cohort) {
        this.selectedCohorts.removeObject(cohort);
      },

      addSelectedCohort(cohort) {
        this.selectedCohorts.pushObject(cohort);
      }

    },
    setup: (0, _emberConcurrency.task)(function* (user) {
      this.set('finishedSetup', false);
      const store = this.store;
      const currentUser = this.currentUser;
      const permissionChecker = this.permissionChecker;
      const cohorts = yield user.get('cohorts');
      const selectedCohorts = cohorts.toArray();
      const primaryCohort = yield user.get('primaryCohort');
      const sessionUser = yield currentUser.get('model');
      const primarySchool = yield sessionUser.get('school');
      const allCohorts = yield store.findAll('cohort');
      const allSchools = yield store.findAll('school');
      const schoolsWithUpdateUserPermission = yield Ember.RSVP.filter(allSchools.toArray(), async school => {
        return permissionChecker.canUpdateUserInSchool(school);
      });
      this.set('selectedCohorts', selectedCohorts);
      this.set('primaryCohort', primaryCohort);
      this.set('schools', schoolsWithUpdateUserPermission);
      this.set('allCohorts', allCohorts);
      this.set('selectedSchoolId', primarySchool.get('id')); //preload relationships for cohorts to make rendering smoother

      yield Ember.RSVP.all(allCohorts.mapBy('school'));
      this.set('finishedSetup', true);
    }),
    save: (0, _emberConcurrency.task)(function* () {
      const finishedSetup = this.finishedSetup;

      if (!finishedSetup) {
        return;
      }

      const user = this.user;
      const selectedCohorts = this.selectedCohorts;
      const primaryCohort = this.primaryCohort;
      user.set('primaryCohort', primaryCohort);
      let userCohorts = yield user.get('cohorts');
      userCohorts.clear();
      userCohorts.pushObjects(selectedCohorts);
      yield user.save();
      this.setIsManaging(false);
      this.set('hasSavedRecently', true);
      yield (0, _emberConcurrency.timeout)(500);
      this.set('hasSavedRecently', false);
    }).drop()
  });

  _exports.default = _default;
});