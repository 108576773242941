define("ilios/components/new-instructorgroup", ["exports", "ember-cp-validations", "ilios-common/mixins/validation-error-display"], function (_exports, _emberCpValidations, _validationErrorDisplay) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const Validations = (0, _emberCpValidations.buildValidations)({
    title: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('length', {
      max: 60,
      descriptionKey: 'general.title'
    })]
  });

  var _default = Ember.Component.extend(Validations, _validationErrorDisplay.default, {
    store: Ember.inject.service(),
    classNames: ['new-instructorgroup'],
    currentSchool: null,
    isSaving: false,
    title: null,
    actions: {
      save() {
        this.set('isSaving', true);
        this.send('addErrorDisplayFor', 'title');
        this.validate().then(({
          validations
        }) => {
          if (validations.get('isValid')) {
            let instructorGroup = this.store.createRecord('instructorGroup', {
              title: this.title,
              school: this.currentSchool
            });
            this.save(instructorGroup).finally(() => {
              this.set('isSaving', false);
            });
          }
        });
      },

      cancel() {
        this.cancel();
      }

    },

    keyUp(event) {
      const keyCode = event.keyCode;
      const target = event.target;

      if ('text' !== target.type) {
        return;
      }

      if (13 === keyCode) {
        this.send('save');
        return;
      }

      if (27 === keyCode) {
        this.send('cancel');
      }
    }

  });

  _exports.default = _default;
});