define("ilios/components/el-monthly-calendar", ["exports", "elemental-calendar/components/el-monthly-calendar"], function (_exports, _elMonthlyCalendar) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _elMonthlyCalendar.default;
    }
  });
});