define("ilios/components/curriculum-inventory-verification-preview-table6", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    'data-test-curriculum-inventory-verification-preview-table6': true,
    classNames: ['curriculum-inventory-verification-preview-table6'],
    clerkships: Ember.computed('data.rows', 'data.methods', function () {
      const methods = this.data.methods;
      return this.data.rows.map(row => {
        return {
          hasFormativeAssessments: row.has_formative_assessments ? 'Y' : '',
          hasNarrativeAssessments: row.has_narrative_assessments ? 'Y' : '',
          level: row.level,
          methods: methods.map(method => {
            return row.methods[method] ? 'X' : '';
          }),
          title: row.title
        };
      });
    })
  });

  _exports.default = _default;
});