define("ilios/routes/programs", ["exports", "ember-simple-auth/mixins/authenticated-route-mixin"], function (_exports, _authenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    currentUser: Ember.inject.service(),
    queryParams: {
      titleFilter: {
        replace: true
      }
    },

    model() {
      let rsvpDefer = Ember.RSVP.defer();
      let model = {};
      this.get('currentUser.model').then(currentUser => {
        this.store.findAll('school').then(schools => {
          model.schools = schools;
          currentUser.get('school').then(primarySchool => {
            model.primarySchool = primarySchool;
            rsvpDefer.resolve(model);
          });
        });
      });
      return rsvpDefer.promise;
    }

  });

  _exports.default = _default;
});