define("ilios/transitions", ["exports", "ilios/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _default;

  function getDuration(duration) {
    return _environment.default.environment === 'test' ? 0 : duration;
  }

  function _default() {
    this.transition(this.hasClass('horizontal'), this.toValue(true), this.use('toLeft', {
      duration: getDuration(1000)
    }), this.reverse('toRight', {
      duration: getDuration(1000)
    }));
    this.transition(this.hasClass('vertical'), this.toValue(true), this.use('toDown', {
      duration: getDuration(1000)
    }), this.reverse('toUp', {
      duration: getDuration(1000)
    }));
    this.transition(this.hasClass('crossFade'), this.toValue(true), this.use('crossFade', {
      duration: getDuration(1000)
    }));
  }
});