define("ilios/templates/components/learnergroup-bulk-finalize-users", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "nbCd3cSQ",
    "block": "{\"symbols\":[\"obj\"],\"statements\":[[7,\"h3\",true],[8],[0,\"\\n  \"],[1,[28,\"t\",[\"general.finalResults\"],null],false],[0,\"\\n\"],[9],[0,\"\\n\"],[7,\"table\",true],[8],[0,\"\\n  \"],[7,\"thead\",true],[8],[0,\"\\n    \"],[7,\"tr\",true],[8],[0,\"\\n      \"],[7,\"th\",true],[8],[0,\"\\n        \"],[1,[28,\"t\",[\"general.name\"],null],false],[0,\"\\n      \"],[9],[0,\"\\n      \"],[7,\"th\",true],[8],[0,\"\\n        \"],[1,[28,\"t\",[\"general.campusId\"],null],false],[0,\"\\n      \"],[9],[0,\"\\n      \"],[7,\"th\",true],[8],[0,\"\\n        \"],[1,[28,\"t\",[\"general.learnerGroups\"],null],false],[0,\"\\n      \"],[9],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n  \"],[7,\"tbody\",true],[8],[0,\"\\n\"],[4,\"each\",[[24,[\"finalData\"]]],null,{\"statements\":[[0,\"      \"],[7,\"tr\",true],[8],[0,\"\\n        \"],[7,\"td\",true],[8],[0,\"\\n          \"],[1,[23,1,[\"user\",\"fullName\"]],false],[0,\"\\n        \"],[9],[0,\"\\n        \"],[7,\"td\",true],[8],[0,\"\\n          \"],[1,[23,1,[\"user\",\"campusId\"]],false],[0,\"\\n        \"],[9],[0,\"\\n        \"],[7,\"td\",true],[8],[0,\"\\n          \"],[1,[23,1,[\"learnerGroup\",\"title\"]],false],[0,\"\\n        \"],[9],[0,\"\\n      \"],[9],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"],[7,\"button\",false],[12,\"disabled\",[24,[\"save\",\"isRunning\"]]],[3,\"action\",[[23,0,[]],[28,\"perform\",[[24,[\"save\"]]],null]]],[8],[0,\"\\n\"],[4,\"if\",[[24,[\"save\",\"isRunning\"]]],null,{\"statements\":[[0,\"    \"],[5,\"loading-spinner\",[],[[],[]]],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[1,[28,\"t\",[\"general.save\"],null],false],[0,\"\\n\"]],\"parameters\":[]}],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ilios/templates/components/learnergroup-bulk-finalize-users.hbs"
    }
  });

  _exports.default = _default;
});