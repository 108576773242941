define("ilios/components/locale-chooser", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    intl: Ember.inject.service(),
    moment: Ember.inject.service(),
    classNameBindings: [':locale-chooser', 'isOpen'],
    isOpen: false,
    ariaRole: 'menubar',
    'data-test-locale-chooser': true,
    locale: Ember.computed('locales.[]', 'intl.locale', function () {
      const locale = this.intl.get('locale');
      return this.locales.findBy('id', locale[0]);
    }),
    locales: Ember.computed('intl.locales.[]', function () {
      return this.intl.get('locales').uniq().map(locale => {
        return {
          id: locale,
          text: this.intl.t('general.language.' + locale)
        };
      });
    }),
    actions: {
      toggleMenu() {
        const isOpen = this.isOpen;

        if (isOpen) {
          this.set('isOpen', false);
        } else {
          this.openMenuAndSelectTheCurrentLocale();
        }
      },

      changeLocale(id) {
        this.set('isOpen', false);
        this.intl.setLocale(id);
        this.moment.setLocale(id);
        window.document.querySelector('html').setAttribute('lang', id);
      }

    },

    openMenuAndSelectTheCurrentLocale() {
      this.set('isOpen', true);
      Ember.run.schedule('afterRender', () => {
        this.element.querySelector(".menu button[lang=\"".concat(this.locale.id, "\"]")).focus();
      });
    },

    keyDown({
      originalEvent
    }) {
      switch (originalEvent.key) {
        case 'ArrowDown':
          if (originalEvent.target.dataset.level === 'toggle') {
            this.openMenuAndSelectTheCurrentLocale();
          } else {
            if (originalEvent.target.nextElementSibling) {
              originalEvent.target.nextElementSibling.focus();
            } else {
              Ember.run.schedule('afterRender', () => {
                this.element.querySelector('.menu button:nth-of-type(1)').focus();
              });
            }
          }

          break;

        case 'ArrowUp':
          if (originalEvent.target.previousElementSibling) {
            originalEvent.target.previousElementSibling.focus();
          } else {
            this.element.querySelector('.menu button:last-of-type').focus();
          }

          break;

        case 'Escape':
        case 'Tab':
        case 'ArrowRight':
        case 'ArrowLeft':
          this.set('isOpen', false);
          break;
      }

      return true;
    }

  });

  _exports.default = _default;
});