define("ilios/models/assessment-option", ["exports", "ilios-common/models/assessment-option"], function (_exports, _assessmentOption) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _assessmentOption.default;
    }
  });
});