define("ilios/components/update-notification", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['update-notification'],

    /**
     * send a message to update every tab attached to this worker
     * this message is caught by our sw-skip-wait in-repo addon
     */
    async click() {
      if ('serviceWorker' in navigator) {
        const reg = await navigator.serviceWorker.getRegistration();

        if (reg && reg.waiting) {
          reg.waiting.postMessage('skipWaiting');
        }
      }

      const reload = this.reload;

      if (reload) {
        reload();
      }
    }

  });

  _exports.default = _default;
});