define("ilios/controllers/session", ["exports", "ilios-common/mixins/session-controller"], function (_exports, _sessionController) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend(_sessionController.default);

  _exports.default = _default;
});