define("ilios/components/new-competency", ["exports", "ember-concurrency", "ember-cp-validations", "ilios-common/mixins/validation-error-display"], function (_exports, _emberConcurrency, _emberCpValidations, _validationErrorDisplay) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const Validations = (0, _emberCpValidations.buildValidations)({
    title: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('length', {
      max: 200
    })]
  });

  var _default = Ember.Component.extend(Validations, _validationErrorDisplay.default, {
    classNames: ['new-competency'],
    title: null,

    keyUp(event) {
      const keyCode = event.keyCode;
      const target = event.target;

      if ('text' !== target.type) {
        return;
      }

      if (13 === keyCode) {
        this.save.perform();
        return;
      }

      if (27 === keyCode) {
        this.send('removeErrorDisplayFor', 'title');
        this.set('title', '');
      }
    },

    save: (0, _emberConcurrency.task)(function* () {
      this.send('addErrorDisplayFor', 'title');
      let {
        validations
      } = yield this.validate();

      if (validations.get('isInvalid')) {
        return;
      }

      yield (0, _emberConcurrency.timeout)(10);
      const title = this.title;
      yield this.add(title);
      this.send('clearErrorDisplay');
      this.set('title', null);
    })
  });

  _exports.default = _default;
});