define("ilios/components/school-session-types-list", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['school-session-types-list'],
    canDelete: false,
    deletedSessionTypes: null,

    init() {
      this._super(...arguments);

      this.set('deletedSessionTypes', []);
    },

    deleteSessionType: (0, _emberConcurrency.task)(function* (sessionType) {
      if (sessionType.get('sessionCount') === 0) {
        this.deletedSessionTypes.pushObject(sessionType.get('id'));
        yield (0, _emberConcurrency.timeout)(10);
        sessionType.deleteRecord();
        yield sessionType.save();
        this.deletedSessionTypes.removeObject(sessionType.get('id'));
      }
    }).drop()
  });

  _exports.default = _default;
});