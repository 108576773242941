define("ilios/templates/session-type-visualize-vocabularies", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "7nMp8WdV",
    "block": "{\"symbols\":[],\"statements\":[[1,[28,\"page-title\",[[28,\"t\",[\"general.coursesAndSessions\"],null]],null],false],[0,\"\\n\"],[7,\"section\",true],[10,\"class\",\"session-type-visualize-vocabularies\"],[8],[0,\"\\n  \"],[7,\"h2\",true],[10,\"class\",\"clickable\"],[8],[0,\"\\n    \"],[5,\"link-to\",[],[[\"@route\",\"@model\",\"@query\"],[\"school\",[24,[\"model\",\"school\"]],[28,\"hash\",null,[[\"schoolSessionTypeDetails\"],[true]]]]],{\"statements\":[[0,\"\\n      \"],[1,[24,[\"model\",\"school\",\"title\"]],false],[0,\"\\n      \"],[1,[24,[\"model\",\"title\"]],false],[0,\"\\n    \"]],\"parameters\":[]}],[0,\"\\n  \"],[9],[0,\"\\n  \"],[7,\"h3\",true],[8],[0,\"\\n    \"],[1,[28,\"t\",[\"general.termsBySessionType\"],null],false],[0,\"\\n  \"],[9],[0,\"\\n  \"],[5,\"visualizer-session-type-vocabularies\",[],[[\"@sessionType\"],[[22,\"model\"]]]],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ilios/templates/session-type-visualize-vocabularies.hbs"
    }
  });

  _exports.default = _default;
});