define("ilios/templates/components/separated-list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Ivfe93tr",
    "block": "{\"symbols\":[\"item\"],\"statements\":[[4,\"each\",[[24,[\"list\"]]],null,{\"statements\":[[0,\"  \"],[1,[23,1,[]],false],[5,\"fa-icon\",[],[[\"@icon\"],[[22,\"icon\"]]]],[0,\"\\n\"]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ilios/templates/components/separated-list.hbs"
    }
  });

  _exports.default = _default;
});