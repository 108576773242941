define("ilios/routes/logout", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    session: Ember.inject.service(),

    beforeModel() {
      const session = this.session;

      if (session.isAuthenticated) {
        return session.invalidate();
      }
    }

  });

  _exports.default = _default;
});