define("ilios/components/loading-bar", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNameBindings: [':loading-bar'],
    attributeBindings: ['ariaHidden:aria-hidden'],
    ariaHidden: 'true',
    progress: 0,
    isLoading: false,
    barWidth: Ember.computed('progress', function () {
      const progress = parseInt(this.progress, 10);
      return Ember.String.htmlSafe("width: ".concat(progress, "%;"));
    }),
    incrementProgress: (0, _emberConcurrency.task)(function* () {
      const removeProgress = this.removeProgress;
      const isLoading = this.isLoading;
      const progress = this.progress;
      const incrementProgress = this.incrementProgress;

      if (isLoading) {
        // as progress goes up we load progressively faster to give the appearance of acceleration
        const velocity = 200 - progress * progress;
        yield (0, _emberConcurrency.timeout)(velocity > 50 ? velocity : 50); //don't ever let the progress get to 100%

        const newProgress = progress === 99 ? 99 : progress + 1;
        this.set('progress', newProgress);
        incrementProgress.perform();
      } else {
        return removeProgress.perform();
      }
    }).restartable().on('didReceiveAttrs'),
    removeProgress: (0, _emberConcurrency.task)(function* () {
      const progress = this.progress;

      if (progress > 0) {
        yield (0, _emberConcurrency.timeout)(1);
        this.set('progress', 100);
        yield (0, _emberConcurrency.timeout)(500);
        this.set('progress', 0);
      }
    }).drop()
  });

  _exports.default = _default;
});