define("ilios/templates/components/global-search-tags", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "qjGIfOu9",
    "block": "{\"symbols\":[\"tag\",\"@tags\"],\"statements\":[[4,\"if\",[[23,2,[]]],null,{\"statements\":[[0,\"  \"],[7,\"div\",true],[10,\"class\",\"global-search-tags\"],[8],[0,\"\\n\"],[4,\"each\",[[23,2,[]]],null,{\"statements\":[[0,\"      \"],[7,\"span\",true],[10,\"class\",\"global-search-tag\"],[8],[0,\"\\n\"],[4,\"if\",[[28,\"eq\",[[23,1,[]],\"meshdescriptors\"],null]],null,{\"statements\":[[0,\"          \"],[1,[28,\"t\",[\"general.mesh\"],null],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[28,\"eq\",[[23,1,[]],\"learningmaterials\"],null]],null,{\"statements\":[[0,\"          \"],[1,[28,\"t\",[\"general.learningMaterials\"],null],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"          \"],[1,[28,\"t\",[[28,\"concat\",[\"general.\",[23,1,[]]],null]],null],false],[0,\"\\n        \"]],\"parameters\":[]}]],\"parameters\":[]}],[0,\"      \"],[9],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"  \"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ilios/templates/components/global-search-tags.hbs"
    }
  });

  _exports.default = _default;
});