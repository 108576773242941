define("ilios/templates/components/curriculum-inventory-verification-preview-table1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "6s+mxwq/",
    "block": "{\"symbols\":[\"row\",\"pcrs\",\"@data\"],\"statements\":[[7,\"h4\",true],[10,\"id\",\"verification-preview-table1\"],[8],[0,\"\\n  \"],[1,[28,\"t\",[\"general.table1ProgramExpectationsMappedToPcrs\"],null],false],[0,\"\\n\"],[9],[0,\"\\n\"],[7,\"table\",true],[8],[0,\"\\n  \"],[7,\"thead\",true],[8],[0,\"\\n    \"],[7,\"tr\",true],[8],[0,\"\\n      \"],[7,\"th\",true],[8],[0,\"\\n        \"],[1,[28,\"t\",[\"general.programExpectationsId\"],null],false],[0,\"\\n      \"],[9],[0,\"\\n      \"],[7,\"th\",true],[10,\"colspan\",\"3\"],[8],[0,\"\\n        \"],[1,[28,\"t\",[\"general.programExpectations\"],null],false],[0,\"\\n      \"],[9],[0,\"\\n      \"],[7,\"th\",true],[10,\"colspan\",\"2\"],[8],[0,\"\\n        \"],[1,[28,\"t\",[\"general.physicianCompetencyReferenceSet\"],null],false],[0,\"\\n        (\"],[1,[28,\"t\",[\"general.physicianCompetencyReferenceSetAbbr\"],null],false],[0,\")\\n      \"],[9],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n  \"],[7,\"tbody\",true],[8],[0,\"\\n\"],[4,\"each\",[[23,3,[]]],null,{\"statements\":[[0,\"      \"],[7,\"tr\",true],[8],[0,\"\\n        \"],[7,\"td\",true],[8],[0,\"\\n          \"],[1,[28,\"t\",[\"general.notApplicableAbbr\"],null],false],[0,\"\\n        \"],[9],[0,\"\\n        \"],[7,\"td\",true],[10,\"colspan\",\"3\"],[8],[0,\"\\n          \"],[1,[23,1,[\"title\"]],true],[0,\"\\n        \"],[9],[0,\"\\n        \"],[7,\"td\",true],[10,\"colspan\",\"2\"],[8],[0,\"\\n          \"],[7,\"ul\",true],[8],[0,\"\\n\"],[4,\"each\",[[23,1,[\"pcrs\"]]],null,{\"statements\":[[0,\"              \"],[7,\"li\",true],[8],[0,\"\\n                \"],[1,[23,2,[]],false],[0,\"\\n              \"],[9],[0,\"\\n\"]],\"parameters\":[2]},null],[0,\"          \"],[9],[0,\"\\n        \"],[9],[0,\"\\n      \"],[9],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"  \"],[9],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ilios/templates/components/curriculum-inventory-verification-preview-table1.hbs"
    }
  });

  _exports.default = _default;
});