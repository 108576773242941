define("ilios/initializers/inflector", ["exports", "ember-inflector"], function (_exports, _emberInflector) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.initialize = initialize;
  _exports.default = void 0;

  function initialize() {
    _emberInflector.default.inflector.irregular('vocabulary', 'vocabularies');

    _emberInflector.default.inflector.uncountable('aamc-pcrs');
  }

  var _default = {
    name: 'inflector',
    initialize
  };
  _exports.default = _default;
});