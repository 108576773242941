define("ilios/controllers/users", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    queryParams: {
      offset: 'offset',
      limit: 'limit',
      query: 'filter',
      showNewUserForm: 'addUser',
      showBulkNewUserForm: 'addUsers',
      searchTerms: 'search'
    },
    offset: 0,
    limit: 25,
    query: null,
    showNewUserForm: false,
    showBulkNewUserForm: false,
    searchTerms: null,
    actions: {
      transitionToUser(userId) {
        this.transitionToRoute('user', userId);
      }

    }
  });

  _exports.default = _default;
});