define("ilios/components/school-session-attributes", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    classNames: ['school-session-attributes'],
    canUpdate: false,
    details: false,
    isManaging: false,
    school: null,
    showSessionAttendanceRequired: Ember.computed('school.configurations.[]', 'school.configurations.@each.value', async function () {
      return await this.school.getConfigValue('showSessionAttendanceRequired');
    }),
    showSessionSupplemental: Ember.computed('school.configurations.[]', 'school.configurations.@each.value', async function () {
      return await this.school.getConfigValue('showSessionSupplemental');
    }),
    showSessionSpecialAttireRequired: Ember.computed('school.configurations.[]', 'school.configurations.@each.value', async function () {
      return await this.school.getConfigValue('showSessionSpecialAttireRequired');
    }),
    showSessionSpecialEquipmentRequired: Ember.computed('school.configurations.[]', 'school.configurations.@each.value', async function () {
      return await this.school.getConfigValue('showSessionSpecialEquipmentRequired');
    }),
    actions: {
      async save(newValues) {
        const school = this.school;
        const names = ['showSessionAttendanceRequired', 'showSessionSupplemental', 'showSessionSpecialAttireRequired', 'showSessionSpecialEquipmentRequired'];
        let toSave = [];

        for (let i = 0; i < names.length; i++) {
          const name = names[i];
          const config = await school.setConfigValue(name, newValues.get(name));

          if (config) {
            toSave.pushObject(config);
          }
        }

        try {
          return await Ember.RSVP.all(toSave.invoke('save'));
        } finally {
          this.manage(false);
        }
      }

    }
  });

  _exports.default = _default;
});