define("ilios/components/curriculum-inventory-verification-preview-table4", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    'data-test-curriculum-inventory-verification-preview-table4': true,
    classNames: ['curriculum-inventory-verification-preview-table4'],
    totalNumEventsPrimaryMethod: Ember.computed('data', function () {
      return this.data.reduce((value, row) => {
        return value + row['num_events_primary_method'];
      }, 0);
    }),
    totalNumEventsNonPrimaryMethod: Ember.computed('data', function () {
      return this.data.reduce((value, row) => {
        return value + row['num_events_non_primary_method'];
      }, 0);
    })
  });

  _exports.default = _default;
});