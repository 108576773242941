define("ilios/templates/components/school-session-attributes", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "jeny0z5N",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[28,\"and\",[[28,\"is-fulfilled\",[[24,[\"showSessionAttendanceRequired\"]]],null],[28,\"is-fulfilled\",[[24,[\"showSessionSupplemental\"]]],null],[28,\"is-fulfilled\",[[24,[\"showSessionSpecialAttireRequired\"]]],null],[28,\"is-fulfilled\",[[24,[\"showSessionSpecialEquipmentRequired\"]]],null]],null]],null,{\"statements\":[[4,\"if\",[[24,[\"details\"]]],null,{\"statements\":[[0,\"    \"],[5,\"school-session-attributes-expanded\",[],[[\"@canUpdate\",\"@showSessionAttendanceRequired\",\"@showSessionSupplemental\",\"@showSessionSpecialAttireRequired\",\"@showSessionSpecialEquipmentRequired\",\"@collapse\",\"@isManaging\",\"@manage\",\"@saveAll\"],[[22,\"canUpdate\"],[28,\"await\",[[24,[\"showSessionAttendanceRequired\"]]],null],[28,\"await\",[[24,[\"showSessionSupplemental\"]]],null],[28,\"await\",[[24,[\"showSessionSpecialAttireRequired\"]]],null],[28,\"await\",[[24,[\"showSessionSpecialEquipmentRequired\"]]],null],[22,\"collapse\"],[22,\"isManaging\"],[22,\"manage\"],[28,\"action\",[[23,0,[]],\"save\"],null]]]],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[5,\"school-session-attributes-collapsed\",[],[[\"@showSessionAttendanceRequired\",\"@showSessionSupplemental\",\"@showSessionSpecialAttireRequired\",\"@showSessionSpecialEquipmentRequired\",\"@expand\"],[[28,\"await\",[[24,[\"showSessionAttendanceRequired\"]]],null],[28,\"await\",[[24,[\"showSessionSupplemental\"]]],null],[28,\"await\",[[24,[\"showSessionSpecialAttireRequired\"]]],null],[28,\"await\",[[24,[\"showSessionSpecialEquipmentRequired\"]]],null],[22,\"expand\"]]]],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ilios/templates/components/school-session-attributes.hbs"
    }
  });

  _exports.default = _default;
});