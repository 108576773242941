define("ilios/components/learnergroup-upload-data", ["exports", "ember-concurrency", "papaparse"], function (_exports, _emberConcurrency, _papaparse) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    iliosConfig: Ember.inject.service(),
    intl: Ember.inject.service(),
    classNames: ['learnergroup-upload-data'],
    file: null,
    data: null,
    learnerGroup: null,
    sampleData: Ember.computed(function () {
      const sampleUploadFields = ['First', 'Last', 'CampusID', 'Sub Group Name'];
      const str = sampleUploadFields.join("\t");
      const encoded = window.btoa(str);
      return encoded;
    }),
    validUsers: Ember.computed('data.[]', function () {
      const data = this.data;

      if (!data) {
        return [];
      }

      return data.filterBy('isValid');
    }),
    invalidUsers: Ember.computed('data.[]', function () {
      const data = this.data;

      if (!data) {
        return [];
      }

      return data.filter(obj => !obj.isValid);
    }),
    matchedGroups: Ember.computed('data.[]', async function () {
      const data = this.data;
      const learnerGroup = this.learnerGroup;

      if (!data) {
        return [];
      }

      const uploadedSubGroups = data.mapBy('subGroupName').uniq().filter(str => Ember.isPresent(str));
      const groups = await learnerGroup.get('allDescendants');
      const matchObjects = uploadedSubGroups.map(groupName => {
        const group = groups.findBy('title', groupName);
        return Ember.Object.create({
          name: groupName,
          group
        });
      });
      return matchObjects.filter(obj => Ember.isPresent(obj.get('group')));
    }),

    init() {
      this._super(...arguments);

      this.set('data', []);
    },

    actions: {
      async updateSelectedFile(files) {
        // Check for the various File API support.
        if (window.File && window.FileReader && window.FileList && window.Blob) {
          if (files.length > 0) {
            await this.parseFile.perform(files[0]);
          }
        } else {
          throw new Error('This browser is not supported');
        }
      }

    },
    parseFile: (0, _emberConcurrency.task)(function* (file) {
      const store = this.store;
      const intl = this.intl;
      const learnerGroup = this.learnerGroup;
      const cohort = yield learnerGroup.get('cohort');
      const proposedUsers = yield this.getFileContents(file);
      const data = yield Ember.RSVP.map(proposedUsers, async ({
        firstName,
        lastName,
        campusId,
        subGroupName
      }) => {
        const errors = [];
        const warnings = [];

        if (Ember.isEmpty(firstName)) {
          errors.push(intl.t('errors.required', {
            description: intl.t('general.firstName')
          }));
        }

        if (Ember.isEmpty(lastName)) {
          errors.push(intl.t('errors.required', {
            description: intl.t('general.lastName')
          }));
        }

        if (Ember.isEmpty(campusId)) {
          errors.push(intl.t('errors.required', {
            description: intl.t('general.campusId')
          }));
        }

        let userRecord = null;

        if (errors.length === 0) {
          const users = await store.query('user', {
            filters: {
              campusId,
              enabled: true
            }
          });

          if (users.get('length') === 0) {
            errors.push(intl.t('general.couldNotFindUserCampusId', {
              campusId
            }));
          } else if (users.get('length') > 1) {
            errors.push(intl.t('general.multipleUsersFoundWithCampusId', {
              campusId
            }));
          } else {
            const user = users.get('firstObject');
            const cohorts = await user.get('cohorts');
            const cohortIds = cohorts.mapBy('id');

            if (!cohortIds.includes(cohort.get('id'))) {
              errors.push(intl.t('general.userNotInGroupCohort', {
                cohortTitle: cohort.get('title')
              }));
            }

            if (user.get('firstName') != firstName) {
              warnings.push(intl.t('general.doesNotMatchUserRecord', {
                description: intl.t('general.firstName'),
                record: user.get('firstName')
              }));
            }

            if (user.get('lastName') != lastName) {
              warnings.push(intl.t('general.doesNotMatchUserRecord', {
                description: intl.t('general.lastName'),
                record: user.get('lastName')
              }));
            }

            const topLevelGroup = await learnerGroup.get('topLevelGroup');
            const allUsersInGroupHierarchy = await topLevelGroup.get('allDescendantUsers');

            if (allUsersInGroupHierarchy.includes(user)) {
              errors.push(intl.t('general.userExistsInGroupHierarchy', {
                groupTitle: topLevelGroup.get('title')
              }));
            }

            userRecord = user;
          }
        }

        return {
          firstName,
          lastName,
          campusId,
          subGroupName: typeof subGroupName === 'string' ? subGroupName.trim() : subGroupName,
          userRecord,
          errors,
          warning: warnings.join(', '),
          hasWarning: warnings.length > 0,
          isValid: errors.length === 0
        };
      }); // flag duplicate users as such

      const campusIds = [];
      data.forEach(user => {
        if (campusIds.includes(user.campusId)) {
          user.errors.push(intl.t('general.userExistsMultipleTimesInUpload'));
          user.isValid = false;
        } else {
          campusIds.push(user.campusId);
        }
      });
      this.set('data', data);
    }).restartable(),

    /**
     * Extract the contents of a file into an array of user like objects
     * @param Object file
     *
     * @return array
     **/
    getFileContents(file) {
      return new Ember.RSVP.Promise(resolve => {
        this.set('fileUploadError', false);
        let allowedFileTypes = ['text/plain', 'text/csv', 'text/tab-separated-values'];

        if (!allowedFileTypes.includes(file.type)) {
          this.set('fileUploadError', true);
          throw new Error("Unable to accept files of type ".concat(file.type));
        }

        let ProposedUser = Ember.Object.extend({});

        let complete = ({
          data
        }) => {
          let proposedUsers = data.map(arr => {
            return ProposedUser.create({
              firstName: Ember.isPresent(arr[0]) ? arr[0] : null,
              lastName: Ember.isPresent(arr[1]) ? arr[1] : null,
              campusId: Ember.isPresent(arr[2]) ? arr[2] : null,
              subGroupName: Ember.isPresent(arr[3]) ? arr[3] : null
            });
          });
          const notHeaderRow = proposedUsers.filter(obj => String(obj.firstName).toLowerCase() !== 'first' || String(obj.lastName).toLowerCase() !== 'last');
          const skipEmpty = notHeaderRow.filter(obj => {
            return !(Ember.isEmpty(obj.firstName) && Ember.isEmpty(obj.lastName) && Ember.isEmpty(obj.campusId) && Ember.isEmpty(obj.subGroupName));
          });
          resolve(skipEmpty);
        };

        _papaparse.default.parse(file, {
          complete,
          skipEmptyLines: true
        });
      });
    },

    continue: (0, _emberConcurrency.task)(function* () {
      yield (0, _emberConcurrency.timeout)(10);
      const validUsers = this.validUsers;
      const matchedGroups = yield this.matchedGroups;
      const sendValidUsers = this.sendValidUsers;
      const sendMatchedGroups = this.sendMatchedGroups;
      sendValidUsers(validUsers);
      sendMatchedGroups(matchedGroups);
    })
  });

  _exports.default = _default;
});