define("ilios/templates/components/visualizer-session-type-vocabularies", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "NW57eYJ9",
    "block": "{\"symbols\":[\"chart\"],\"statements\":[[4,\"if\",[[28,\"or\",[[24,[\"isIcon\"]],[28,\"gt\",[[28,\"get\",[[28,\"await\",[[24,[\"vocabulariesWithLinkedTerms\"]]],null],\"length\"],null],0],null]],null]],null,{\"statements\":[[0,\"  \"],[5,\"simple-chart\",[],[[\"@name\",\"@isIcon\",\"@data\",\"@click\",\"@hover\",\"@leave\"],[\"pie\",[22,\"isIcon\"],[28,\"await\",[[24,[\"vocabulariesWithLinkedTerms\"]]],null],[28,\"action\",[[23,0,[]],\"donutClick\"],null],[28,\"perform\",[[24,[\"donutHover\"]]],null],[28,\"perform\",[[24,[\"donutHover\"]]],null]]],{\"statements\":[[0,\"\\n\"],[4,\"if\",[[24,[\"tooltipContent\"]]],null,{\"statements\":[[0,\"      \"],[6,[23,1,[\"tooltip\"]],[],[[\"@title\"],[[22,\"tooltipTitle\"]]],{\"statements\":[[0,\"\\n        \"],[1,[22,\"tooltipContent\"],false],[0,\"\\n      \"]],\"parameters\":[]}],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"  \"]],\"parameters\":[1]}],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[7,\"div\",true],[10,\"class\",\"with-hours\"],[8],[0,\"\\n    \"],[7,\"h4\",true],[8],[0,\"\\n      \"],[5,\"fa-icon\",[[12,\"class\",\"meh-o\"]],[[\"@icon\"],[\"meh\"]]],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ilios/templates/components/visualizer-session-type-vocabularies.hbs"
    }
  });

  _exports.default = _default;
});