define("ilios/routes/course-materials", ["exports", "ember-simple-auth/mixins/authenticated-route-mixin", "ilios-common/mixins/course-materials-route"], function (_exports, _authenticatedRouteMixin, _courseMaterialsRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, _courseMaterialsRoute.default);

  _exports.default = _default;
});