define("ilios/templates/session-type-visualize-terms", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "1vbc9pV+",
    "block": "{\"symbols\":[],\"statements\":[[1,[28,\"page-title\",[[28,\"t\",[\"general.coursesAndSessions\"],null]],null],false],[0,\"\\n\"],[7,\"section\",true],[10,\"class\",\"session-type-visualize-terms\"],[8],[0,\"\\n  \"],[7,\"h2\",true],[8],[0,\"\\n    \"],[5,\"link-to\",[],[[\"@route\",\"@model\"],[\"session-type-visualize-vocabularies\",[24,[\"model\",\"sessionType\"]]]],{\"statements\":[[0,\"\\n      \"],[1,[24,[\"model\",\"sessionType\",\"title\"]],false],[0,\"\\n    \"]],\"parameters\":[]}],[0,\"\\n    >\\n    \"],[1,[24,[\"model\",\"vocabulary\",\"title\"]],false],[0,\"\\n  \"],[9],[0,\"\\n  \"],[7,\"h3\",true],[8],[0,\"\\n    \"],[1,[28,\"t\",[\"general.terms\"],null],false],[0,\"\\n  \"],[9],[0,\"\\n  \"],[5,\"visualizer-session-type-terms\",[],[[\"@sessionType\",\"@vocabulary\"],[[24,[\"model\",\"sessionType\"]],[24,[\"model\",\"vocabulary\"]]]]],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ilios/templates/session-type-visualize-terms.hbs"
    }
  });

  _exports.default = _default;
});