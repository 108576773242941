define("ilios/components/curriculum-inventory-sequence-block-details", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    'data-test-curriculum-inventory-sequence-block-details': true,
    sequenceBlock: null,
    canUpdate: false
  });

  _exports.default = _default;
});