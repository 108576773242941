define("ilios/templates/print-course", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "O0FIzwVW",
    "block": "{\"symbols\":[],\"statements\":[[5,\"print-course\",[],[[\"@course\",\"@includeUnpublishedSessions\"],[[22,\"model\"],[28,\"and\",[[24,[\"unpublished\"]],[24,[\"canViewUnpublished\"]]],null]]]]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ilios/templates/print-course.hbs"
    }
  });

  _exports.default = _default;
});