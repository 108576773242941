define("ilios/components/curriculum-inventory-sequence-block-header", ["exports", "ember-cp-validations", "ilios-common/mixins/validation-error-display"], function (_exports, _emberCpValidations, _validationErrorDisplay) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const Validations = (0, _emberCpValidations.buildValidations)({
    blockTitle: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('length', {
      min: 3,
      max: 200
    })]
  });

  var _default = Ember.Component.extend(Validations, _validationErrorDisplay.default, {
    store: Ember.inject.service(),
    classNames: ['curriculum-inventory-sequence-block-header'],
    canUpdate: false,
    report: null,
    reportName: null,
    publishTarget: Ember.computed.alias('sequenceBlock'),

    didReceiveAttrs() {
      this._super(...arguments);

      this.set('blockTitle', this.get('sequenceBlock.title'));
    },

    actions: {
      async changeTitle() {
        const block = this.sequenceBlock;
        const newTitle = this.blockTitle;
        this.send('addErrorDisplayFor', 'blockTitle');
        const {
          validations
        } = await this.validate();

        if (validations.isValid) {
          this.send('removeErrorDisplayFor', 'blockTitle');
          block.set('title', newTitle);
          const newBlock = await block.save();
          this.set('blockTitle', newBlock.get('title'));
          this.set('sequenceBlock', newBlock);
        } else {
          await Ember.RSVP.reject();
        }
      },

      revertTitleChanges() {
        const block = this.sequenceBlock;
        this.set('blockTitle', block.get('title'));
      }

    }
  });

  _exports.default = _default;
});