define("ilios/routes/pending-user-updates", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    currentUser: Ember.inject.service(),
    permissionChecker: Ember.inject.service(),
    store: Ember.inject.service(),
    queryParams: {
      filter: {
        replace: true
      }
    },

    async model() {
      const currentUser = this.currentUser;
      const store = this.store;
      const permissionChecker = this.permissionChecker;
      const allSchools = await store.findAll('school');
      const schools = await Ember.RSVP.filter(allSchools.toArray(), async school => {
        return permissionChecker.canUpdateUserInSchool(school);
      });
      const user = await currentUser.get('model');
      const primarySchool = await user.get('school');
      return {
        primarySchool,
        schools
      };
    },

    setupController(controller, model) {
      this._super(controller, model);

      controller.set('deletedUpdates', []);
      controller.set('sortSchoolsBy', ['title']);
      controller.set('updatesBeingSaved', []);
    }

  });

  _exports.default = _default;
});