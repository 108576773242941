define("ilios/templates/components/competency-title-editor", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "9sqpbitA",
    "block": "{\"symbols\":[\"isSaving\"],\"statements\":[[4,\"if\",[[24,[\"canUpdate\"]]],null,{\"statements\":[[0,\"  \"],[5,\"editable-field\",[],[[\"@value\",\"@save\",\"@close\",\"@saveOnEnter\",\"@closeOnEscape\"],[[22,\"title\"],[28,\"perform\",[[23,0,[\"save\"]]],null],[28,\"action\",[[23,0,[]],\"revert\"],null],true,true]],{\"statements\":[[0,\"\\n    \"],[7,\"input\",true],[11,\"value\",[22,\"title\"]],[11,\"oninput\",[28,\"action\",[[23,0,[]],[28,\"mut\",[[24,[\"title\"]]],null]],[[\"value\"],[\"target.value\"]]]],[11,\"disabled\",[23,1,[]]],[11,\"onkeyup\",[28,\"action\",[[23,0,[]],\"addErrorDisplayFor\",\"title\"],null]],[10,\"type\",\"text\"],[8],[9],[0,\"\\n  \"]],\"parameters\":[1]}],[0,\"\\n\"],[4,\"if\",[[28,\"and\",[[28,\"get\",[[28,\"get\",[[28,\"get\",[[28,\"get\",[[23,0,[]],\"validations\"],null],\"attrs\"],null],\"title\"],null],\"isInvalid\"],null],[28,\"is-in\",[[24,[\"showErrorsFor\"]],\"title\"],null]],null]],null,{\"statements\":[[0,\"    \"],[7,\"span\",true],[10,\"class\",\"validation-error-message\"],[8],[0,\"\\n      \"],[1,[28,\"get\",[[28,\"get\",[[28,\"get\",[[28,\"get\",[[23,0,[]],\"validations\"],null],\"attrs\"],null],\"title\"],null],\"message\"],null],false],[0,\"\\n    \"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[1,[22,\"title\"],false],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ilios/templates/components/competency-title-editor.hbs"
    }
  });

  _exports.default = _default;
});