define("ilios/templates/course/publishall", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "A8kVh81a",
    "block": "{\"symbols\":[],\"statements\":[[5,\"link-to\",[],[[\"@route\",\"@model\"],[\"course\",[22,\"model\"]]],{\"statements\":[[0,\"\\n  \"],[1,[28,\"t\",[\"general.backToTitle\"],[[\"title\"],[[24,[\"model\",\"title\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]}],[0,\"\\n\"],[5,\"publish-all-sessions\",[],[[\"@sessions\",\"@saved\"],[[24,[\"model\",\"sessions\"]],[28,\"action\",[[23,0,[]],\"returnToList\"],null]]]]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ilios/templates/course/publishall.hbs"
    }
  });

  _exports.default = _default;
});