define("ilios/components/learnergroup-summary", ["exports", "ember-concurrency", "ember-cp-validations", "ilios-common/mixins/validation-error-display"], function (_exports, _emberConcurrency, _emberCpValidations, _validationErrorDisplay) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const Validations = (0, _emberCpValidations.buildValidations)({
    location: [(0, _emberCpValidations.validator)('length', {
      allowBlank: true,
      min: 2,
      max: 100
    })]
  });

  var _default = Ember.Component.extend(Validations, _validationErrorDisplay.default, {
    classNames: ['learnergroup-summary'],
    tagName: 'section',
    canCreate: false,
    canDelete: false,
    canUpdate: false,
    cohortTitle: null,
    currentGroupsSaved: 0,
    isBulkAssigning: false,
    isEditing: false,
    isSaving: false,
    learnerGroup: null,
    learnerGroupId: null,
    learnerGroupTitle: null,
    location: null,
    manageInstructors: false,
    sortUsersBy: '',
    topLevelGroupTitle: null,
    totalGroupsToSave: 0,
    treeGroups: Ember.computed('learnerGroup.topLevelGroup.allDescendants.[]', async function () {
      const topLevelGroup = await this.learnerGroup.topLevelGroup;
      const treeGroups = [topLevelGroup];
      const allDescendants = await topLevelGroup.allDescendants;
      treeGroups.pushObjects(allDescendants);
      return treeGroups;
    }),

    didReceiveAttrs() {
      this._super(...arguments);

      const learnerGroup = this.learnerGroup;

      if (Ember.isPresent(learnerGroup)) {
        this.set('location', learnerGroup.get('location'));
        this.set('learnerGroupId', learnerGroup.get('id'));
        this.set('learnerGroupTitle', learnerGroup.get('title'));
        learnerGroup.get('cohort').then(cohort => {
          this.set('cohortTitle', cohort.get('title'));
        });
        learnerGroup.get('topLevelGroup').then(topLevelGroup => {
          this.set('topLevelGroupTitle', topLevelGroup.get('title'));
        });
        this.createUsersToPassToManager.perform();
        this.createUsersToPassToCohortManager.perform();
      }
    },

    actions: {
      async changeLocation() {
        const learnerGroup = this.learnerGroup;
        const newLocation = this.location;
        this.send('addErrorDisplayFor', 'location');
        const {
          validations
        } = await this.validate();

        if (validations.isValid) {
          this.send('removeErrorDisplayFor', 'location');
          learnerGroup.set('location', newLocation);
          const newLearnerGroup = await learnerGroup.save();
          this.set('location', newLearnerGroup.location);
          this.set('learnerGroup', newLearnerGroup);
        } else {
          await Ember.RSVP.reject();
        }
      },

      revertLocationChanges() {
        const learnerGroup = this.learnerGroup;
        this.set('location', learnerGroup.get('location'));
      },

      saveInstructors(newInstructors, newInstructorGroups) {
        const learnerGroup = this.learnerGroup;
        learnerGroup.set('instructors', newInstructors.toArray());
        learnerGroup.set('instructorGroups', newInstructorGroups.toArray());
        this.set('manageInstructors', false);
        return learnerGroup.save();
      },

      manageInstructors() {
        const canUpdate = this.canUpdate;
        const manageInstructors = this.manageInstructors;

        if (canUpdate) {
          this.set('manageInstructors', !manageInstructors);
        }
      }

    },
    addUserToGroup: (0, _emberConcurrency.task)(function* (user) {
      const learnerGroup = this.learnerGroup;
      const topLevelGroup = yield learnerGroup.get('topLevelGroup');
      let removeGroups = yield topLevelGroup.removeUserFromGroupAndAllDescendants(user);
      let addGroups = yield learnerGroup.addUserToGroupAndAllParents(user);
      let groups = [].concat(removeGroups).concat(addGroups);
      yield Ember.RSVP.all(groups.invoke('save'));
      yield this.createUsersToPassToManager.perform();
      yield this.createUsersToPassToCohortManager.perform();
    }).enqueue(),
    removeUserToCohort: (0, _emberConcurrency.task)(function* (user) {
      const topLevelGroup = yield this.learnerGroup.get('topLevelGroup');
      let groups = yield topLevelGroup.removeUserFromGroupAndAllDescendants(user);
      yield Ember.RSVP.all(groups.invoke('save'));
      yield this.createUsersToPassToManager.perform();
      yield this.createUsersToPassToCohortManager.perform();
    }).enqueue(),
    addUsersToGroup: (0, _emberConcurrency.task)(function* (users) {
      const learnerGroup = this.learnerGroup;
      const topLevelGroup = yield learnerGroup.get('topLevelGroup');
      let groupsToSave = [];

      for (let i = 0; i < users.length; i++) {
        let user = users[i];
        let removeGroups = yield topLevelGroup.removeUserFromGroupAndAllDescendants(user);
        let addGroups = yield learnerGroup.addUserToGroupAndAllParents(user);
        groupsToSave.pushObjects(removeGroups);
        groupsToSave.pushObjects(addGroups);
      }

      yield Ember.RSVP.all(groupsToSave.uniq().invoke('save'));
      yield this.createUsersToPassToManager.perform();
      yield this.createUsersToPassToCohortManager.perform();
    }).enqueue(),
    removeUsersToCohort: (0, _emberConcurrency.task)(function* (users) {
      const topLevelGroup = yield this.learnerGroup.get('topLevelGroup');
      let groupsToSave = [];

      for (let i = 0; i < users.length; i++) {
        let user = users[i];
        let removeGroups = yield topLevelGroup.removeUserFromGroupAndAllDescendants(user);
        groupsToSave.pushObjects(removeGroups);
      }

      yield Ember.RSVP.all(groupsToSave.uniq().invoke('save'));
      yield this.createUsersToPassToManager.perform();
      yield this.createUsersToPassToCohortManager.perform();
    }).enqueue(),
    createUsersToPassToManager: (0, _emberConcurrency.task)(function* () {
      const isEditing = this.isEditing;
      const learnerGroup = this.learnerGroup;
      let users;

      if (isEditing) {
        let topLevelGroup = yield learnerGroup.get('topLevelGroup');
        users = yield topLevelGroup.get('allDescendantUsers');
      } else {
        users = yield learnerGroup.get('usersOnlyAtThisLevel');
      }

      const treeGroups = yield this.treeGroups;
      return yield Ember.RSVP.map(users.toArray(), async user => {
        let lowestGroupInTree = await user.getLowestMemberGroupInALearnerGroupTree(treeGroups);
        return Ember.ObjectProxy.create({
          content: user,
          lowestGroupInTree,
          //special sorting property
          lowestGroupInTreeTitle: lowestGroupInTree.get('title')
        });
      });
    }),
    createUsersToPassToCohortManager: (0, _emberConcurrency.task)(function* () {
      const learnerGroup = this.learnerGroup;
      const cohort = yield learnerGroup.get('cohort');
      const topLevelGroup = yield learnerGroup.get('topLevelGroup');
      const currentUsers = yield topLevelGroup.get('allDescendantUsers');
      const users = yield cohort.get('users');
      let filteredUsers = users.filter(user => !currentUsers.includes(user));
      return filteredUsers;
    })
  });

  _exports.default = _default;
});