define("ilios/components/ilios-navigation", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    intl: Ember.inject.service(),
    currentUser: Ember.inject.service(),
    tagName: 'nav',
    classNameBindings: [':ilios-navigation', 'expanded'],
    expanded: false,
    attributeBindings: ['ariaLabel:aria-label'],
    ariaLabel: Ember.computed('intl.locale', function () {
      return this.intl.t('general.primary');
    })
  });

  _exports.default = _default;
});