define("ilios/components/curriculum-inventory-verification-preview-header", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    'data-test-curriculum-inventory-verification-preview-header': true,
    classNames: ['curriculum-inventory-verification-preview-header']
  });

  _exports.default = _default;
});