define("ilios/components/unassigned-students-summary", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    currentUser: Ember.inject.service(),
    store: Ember.inject.service(),
    classNameBindings: [':unassigned-students-summary', ':small-component', 'alert'],
    tagName: 'div',
    schoolId: null,
    schools: null,
    alert: Ember.computed.gt('unassignedStudentsProxy.length', 0),
    selectedSchool: Ember.computed('currentUser', 'schoolId', async function () {
      const schools = this.schools;
      const currentUser = this.currentUser;
      const schoolId = this.schoolId;

      if (schoolId) {
        return schools.findBy('id', schoolId);
      }

      const user = await currentUser.get('model');
      const school = await user.get('school');
      const defaultSchool = schools.findBy('id', school.get('id'));

      if (defaultSchool) {
        return defaultSchool;
      }

      return schools.get('firstObject');
    }),
    unassignedStudents: Ember.computed('selectedSchool', async function () {
      const school = await this.selectedSchool;
      return await this.store.query('user', {
        filters: {
          cohorts: null,
          enabled: true,
          roles: [4],
          school: school.id
        }
      });
    }),
    //temporary solution until the classNameBindings can be promise aware
    unassignedStudentsProxy: Ember.computed('unassignedStudents', function () {
      let ap = Ember.ArrayProxy.extend(Ember.PromiseProxyMixin);
      return ap.create({
        promise: this.unassignedStudents
      });
    }),
    actions: {
      changeSelectedSchool(schoolId) {
        this.set('schoolId', schoolId);
      }

    }
  });

  _exports.default = _default;
});