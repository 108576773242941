define("ilios/components/school-session-types-collapsed", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['school-session-types-collapsed'],
    tagName: 'section',
    school: null,
    sessionTypes: Ember.computed('school.sessionTypes.[]', async function () {
      const school = this.school;

      if (!school) {
        return [];
      }

      return await school.get('sessionTypes');
    }),
    instructionalMethods: Ember.computed('sessionTypes.[]', async function () {
      const sessionTypes = await this.sessionTypes;
      return sessionTypes.filterBy('assessment', false);
    }),
    assessmentMethods: Ember.computed('sessionTypes.[]', async function () {
      const sessionTypes = await this.sessionTypes;
      return sessionTypes.filterBy('assessment');
    })
  });

  _exports.default = _default;
});