define("ilios/routes/user", ["exports", "ember-simple-auth/mixins/authenticated-route-mixin"], function (_exports, _authenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    store: Ember.inject.service(),
    currentUser: Ember.inject.service(),
    permissionChecker: Ember.inject.service(),
    iliosConfig: Ember.inject.service(),
    canUpdate: false,

    /**
    * Prefetch user relationship data to smooth loading
    **/
    async afterModel(user) {
      const permissionChecker = this.permissionChecker;
      const obj = await Ember.RSVP.hash({
        cohorts: user.get('cohorts'),
        learnerGroups: user.get('learnerGroups'),
        roles: user.get('roles'),
        schools: user.get('schools')
      });
      await Ember.RSVP.all([Ember.RSVP.all(obj.cohorts.mapBy('school')), Ember.RSVP.all(obj.learnerGroups.mapBy('school'))]);
      const canUpdate = permissionChecker.canUpdateUser(user);
      this.set('canUpdate', canUpdate);
      const userSearchType = await this.iliosConfig.userSearchType;

      if (userSearchType !== 'ldap') {
        await emberAutoImportDynamic("zxcvbn");
      }
    },

    setupController(controller, model) {
      this._super(controller, model);

      controller.set('canUpdate', this.canUpdate);
    },

    actions: {
      loading(transition) {
        let controller = this.controllerFor('user');
        controller.set('isLoading', true);
        transition.promise.finally(() => {
          controller.set('isLoading', false);
        });
        return true;
      }

    }
  });

  _exports.default = _default;
});