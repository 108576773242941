define("ilios/adapters/school", ["exports", "ilios-common/adapters/ilios"], function (_exports, _ilios) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _ilios.default.extend({
    /**
     * Don't reload school records if we have any of them
     * in the store already. Schools rarely change, but they
     * are needed all the time, we just don't need to go
     * back to the API once they are loaded.
     */
    shouldReloadAll(store, snapshotRecordArray) {
      return !snapshotRecordArray.length;
    },

    shouldBackgroundReloadAll() {
      return false;
    }

  });

  _exports.default = _default;
});