define("ilios/components/programyear-list", ["exports", "ember-concurrency", "moment"], function (_exports, _emberConcurrency, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    currentUser: Ember.inject.service(),
    permissionChecker: Ember.inject.service(),
    store: Ember.inject.service(),
    classNames: ['programyear-list'],
    canCreate: false,
    editorOn: false,
    itemsToSave: null,
    program: null,
    programYears: null,
    saved: false,
    savedItems: null,
    savedProgramYear: null,
    existingStartYears: Ember.computed.mapBy('programYears', 'startYear'),
    sortedContent: Ember.computed('programYears.[]', async function () {
      const programYears = await this.programYears;

      if (Ember.isEmpty(programYears)) {
        return [];
      }

      return programYears.toArray().sortBy('academicYear');
    }),
    proxiedProgramYears: Ember.computed('sortedContent.[]', async function () {
      const permissionChecker = this.permissionChecker;
      const currentUser = this.currentUser;
      const programYears = await this.sortedContent;
      return programYears.map(programYear => {
        return ProgramYearProxy.create({
          content: programYear,
          currentUser,
          permissionChecker
        });
      });
    }),
    availableAcademicYears: Ember.computed('existingStartYears.[]', {
      get() {
        let firstYear = parseInt((0, _moment.default)().subtract(5, 'years').format('YYYY'), 10);
        let years = [];

        for (let i = 0; i < 10; i++) {
          years.pushObject(firstYear + i);
        }

        return years.filter(year => {
          return !this.existingStartYears.includes(year.toString());
        }).map(startYear => {
          return {
            label: "".concat(startYear, " - ").concat(startYear + 1),
            value: startYear
          };
        });
      }

    }).readOnly(),
    actions: {
      toggleEditor() {
        if (this.editorOn) {
          this.send('cancel');
        } else {
          this.setProperties({
            editorOn: true,
            saved: false
          });
        }
      },

      cancel() {
        this.set('editorOn', false);
      },

      remove(programYearProxy) {
        programYearProxy.set('showRemoveConfirmation', true);
      },

      confirmRemove(programYearProxy) {
        const programYear = programYearProxy.get('content');
        programYear.deleteRecord();
        programYear.save();
      },

      cancelRemove(programYearProxy) {
        programYearProxy.set('showRemoveConfirmation', false);
      },

      unlockProgramYear(programYearProxy) {
        programYearProxy.get('userCanUnLock').then(permission => {
          if (permission) {
            Ember.run(() => {
              programYearProxy.set('isSaving', true);
            });
            this.unlock(programYearProxy.get('content')).then(() => {
              programYearProxy.set('isSaving', false);
            });
          }
        });
      },

      lockProgramYear(programYearProxy) {
        programYearProxy.get('userCanLock').then(permission => {
          if (permission) {
            Ember.run(() => {
              programYearProxy.set('isSaving', true);
            });
            this.lock(programYearProxy.get('content')).then(() => {
              programYearProxy.set('isSaving', false);
            });
          }
        });
      },

      async activateProgramYear(programYearProxy) {
        const permission = await programYearProxy.get('userCanActivate');

        if (permission) {
          Ember.run(() => {
            programYearProxy.set('isSaving', true);
          });
          await this.activate(programYearProxy.get('content'));
          programYearProxy.set('isSaving', false);
        }
      }

    },

    resetSaveItems() {
      this.set('itemsToSave', 100);
      this.set('savedItems', 0);
    },

    incrementSavedItems() {
      this.set('savedItems', this.savedItems + 1);
    },

    save: (0, _emberConcurrency.task)(function* (startYear) {
      const programYears = yield this.sortedContent;
      const latestProgramYear = programYears.get('lastObject');
      const program = this.program;
      const store = this.store;
      let itemsToSave = 0;
      this.resetSaveItems();
      let newProgramYear = store.createRecord('program-year', {
        program,
        startYear,
        published: true,
        publishedAsTbd: false
      });
      this.incrementSavedItems();

      if (latestProgramYear) {
        const directors = yield latestProgramYear.get('directors');
        itemsToSave++;
        this.incrementSavedItems();
        const competencies = yield latestProgramYear.get('competencies');
        itemsToSave++;
        this.incrementSavedItems();
        const terms = yield latestProgramYear.get('terms');
        itemsToSave++;
        this.incrementSavedItems();
        const stewards = yield latestProgramYear.get('stewards');
        itemsToSave++;
        this.incrementSavedItems();
        newProgramYear.get('directors').pushObjects(directors.toArray());
        newProgramYear.get('competencies').pushObjects(competencies.toArray());
        newProgramYear.get('terms').pushObjects(terms.toArray());
        newProgramYear.get('stewards').pushObjects(stewards.toArray());
      }

      let savedProgramYear = yield newProgramYear.save();
      itemsToSave++;
      this.incrementSavedItems();

      if (latestProgramYear) {
        const relatedObjectives = yield latestProgramYear.get('objectives');
        const objectives = relatedObjectives.sortBy('id').toArray();
        itemsToSave += objectives.length;
        this.set('itemsToSave', itemsToSave);

        for (let i = 0; i < objectives.length; i++) {
          let objectiveToCopy = objectives[i];
          let ancestor = yield objectiveToCopy.get('ancestor');

          if (Ember.isEmpty(ancestor)) {
            ancestor = objectiveToCopy;
          }

          let newObjective = store.createRecord('objective', objectiveToCopy.getProperties(['title', 'position']));
          let props = yield Ember.RSVP.hash(objectiveToCopy.getProperties('meshDescriptors', 'competency'));
          newObjective.setProperties(props);
          newObjective.set('programYears', [savedProgramYear]);
          newObjective.set('ancestor', ancestor);
          yield newObjective.save();
          this.incrementSavedItems();
        }
      }

      this.set('itemsToSave', itemsToSave);
      this.setProperties({
        saved: true,
        savedProgramYear: newProgramYear
      });
      this.send('cancel');
    }).drop()
  });

  _exports.default = _default;
  const ProgramYearProxy = Ember.ObjectProxy.extend({
    content: null,
    currentUser: null,
    isSaving: false,
    permissionChecker: null,
    showRemoveConfirmation: false,
    userCanDelete: Ember.computed('content', 'currentUser.model.programYears.[]', async function () {
      const programYear = this.content;
      const permissionChecker = this.permissionChecker;
      const cohort = await programYear.get('cohort');
      const cohortUsers = cohort.hasMany('users').ids();

      if (cohortUsers.length > 0) {
        return false;
      }

      return permissionChecker.canDeleteProgramYear(programYear);
    }),
    userCanLock: Ember.computed('content', 'currentUser.model.programYears.[]', async function () {
      const programYear = this.content;
      const permissionChecker = this.permissionChecker;
      return permissionChecker.canLockProgramYear(programYear);
    }),
    userCanUnLock: Ember.computed('content', 'currentUser.model.programYears.[]', async function () {
      const programYear = this.content;
      const permissionChecker = this.permissionChecker;
      return permissionChecker.canUnlockProgramYear(programYear);
    }),
    userCanActivate: Ember.computed('content.locked', 'content.archived', 'content.isScheduled', 'content.isPublished', async function () {
      const programYear = this.content;
      const permissionChecker = this.permissionChecker;
      const canBeUpdated = await permissionChecker.canUpdateProgramYear(programYear);
      const isNotFullyPublished = programYear.get('isScheduled') || !programYear.get('isPublished');
      return isNotFullyPublished && canBeUpdated;
    })
  });
});