define("ilios/validators/async-exclusion", ["exports", "ember-cp-validations/validators/base"], function (_exports, _base) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    Promise
  } = Ember.RSVP;

  var _default = _base.default.extend({
    validate(value, options) {
      let promise = options['in'];

      if (Ember.isEmpty(promise)) {
        return true;
      }

      return new Promise(resolve => {
        promise.then(excluded => {
          if (excluded.includes(value)) {
            resolve(this.createErrorMessage('exclusion', value, options));
          } else {
            resolve(true);
          }
        });
      });
    }

  });

  _exports.default = _default;
});