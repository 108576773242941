define("ilios/templates/verification-preview", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "URbec+jX",
    "block": "{\"symbols\":[],\"statements\":[[1,[28,\"page-title\",[[28,\"t\",[\"general.curriculumInventoryReports\"],null]],null],false],[0,\"\\n\"],[7,\"div\",true],[10,\"class\",\"backtolink\"],[8],[0,\"\\n  \"],[5,\"link-to\",[],[[\"@route\",\"@model\"],[\"curriculumInventoryReport\",[23,0,[\"model\"]]]],{\"statements\":[[0,\"\\n    \"],[1,[28,\"t\",[\"general.backToReport\"],null],false],[0,\"\\n  \"]],\"parameters\":[]}],[0,\"\\n\"],[9],[0,\"\\n\"],[5,\"curriculum-inventory-verification-preview-header\",[],[[\"@report\"],[[23,0,[\"model\"]]]]],[0,\"\\n\"],[5,\"curriculum-inventory-verification-preview\",[],[[\"@report\"],[[23,0,[\"model\"]]]]]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ilios/templates/verification-preview.hbs"
    }
  });

  _exports.default = _default;
});