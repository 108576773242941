define("ilios/templates/learner-group", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ZxN2Hpux",
    "block": "{\"symbols\":[],\"statements\":[[1,[28,\"page-title\",[[28,\"t\",[\"general.learnerGroups\"],null]],null],false],[0,\"\\n\"],[5,\"learnergroup-summary\",[],[[\"@setIsEditing\",\"@canCreate\",\"@canUpdate\",\"@canDelete\",\"@setIsBulkAssigning\",\"@sortUsersBy\",\"@setSortUsersBy\",\"@learnerGroup\",\"@isEditing\",\"@isBulkAssigning\"],[[28,\"action\",[[23,0,[]],[28,\"mut\",[[24,[\"isEditing\"]]],null]],null],[22,\"canCreate\"],[22,\"canUpdate\"],[22,\"canDelete\"],[28,\"action\",[[23,0,[]],[28,\"mut\",[[24,[\"isBulkAssigning\"]]],null]],null],[22,\"sortUsersBy\"],[28,\"action\",[[23,0,[]],[28,\"mut\",[[24,[\"sortUsersBy\"]]],null]],null],[22,\"model\"],[22,\"isEditing\"],[22,\"isBulkAssigning\"]]]]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ilios/templates/learner-group.hbs"
    }
  });

  _exports.default = _default;
});