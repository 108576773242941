define("ilios/components/program-list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const ProgramProxy = Ember.ObjectProxy.extend({
    showRemoveConfirmation: false,
    canDelete: Ember.computed('content.{curriculumInventoryReports,programYears}.[]', async function () {
      const program = this.content;
      const permissionChecker = this.permissionChecker;
      const hasCiReports = program.hasMany('curriculumInventoryReports').ids().length > 0;
      const hasProgramYears = program.hasMany('programYears').ids().length > 0;
      const canDelete = await permissionChecker.canDeleteProgram(program);
      return !hasCiReports && !hasProgramYears && canDelete;
    }),
    canActivate: Ember.computed('content.{isPublished,isScheduled}', async function () {
      const program = this.content;
      const permissionChecker = this.permissionChecker;
      const canBeUpdated = await permissionChecker.canUpdateProgram(program);
      const isNotFullyPublished = program.get('isScheduled') || !program.get('isPublished');
      return isNotFullyPublished && canBeUpdated;
    })
  });

  var _default = Ember.Component.extend({
    permissionChecker: Ember.inject.service(),
    programs: null,
    query: null,

    activate() {},

    edit() {},

    remove() {},

    proxiedPrograms: Ember.computed('programs.[]', function () {
      const permissionChecker = this.permissionChecker;
      const programs = this.programs;

      if (!programs) {
        return [];
      }

      return programs.map(program => {
        return ProgramProxy.create({
          content: program,
          permissionChecker
        });
      });
    }),
    actions: {
      edit(programProxy) {
        this.edit(programProxy.get('content'));
      },

      remove(programProxy) {
        this.remove(programProxy.get('content'));
      },

      activate(programProxy) {
        this.activate(programProxy.get('content'));
      },

      cancelRemove(programProxy) {
        programProxy.set('showRemoveConfirmation', false);
      },

      confirmRemove(programProxy) {
        programProxy.set('showRemoveConfirmation', true);
      }

    }
  });

  _exports.default = _default;
});