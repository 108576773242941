define("ilios/components/programyear-competencies", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['programyear-competencies'],
    canUpdate: false,
    isManaging: null,
    isSaving: false,
    programYear: null,
    selectedCompetencies: null,
    competencies: Ember.computed('programYear.program.school.competencies.[]', async function () {
      const program = await this.programYear.program;
      const school = await program.school;
      return await school.competencies;
    }),
    domains: Ember.computed('competencies.[]', async function () {
      const competencies = await this.competencies;
      const domains = await Ember.RSVP.all(competencies.mapBy('domain'));
      return domains.uniq();
    }),
    competenciesWithSelectedChildren: Ember.computed('competencies.[]', 'selectedCompetencies.[]', async function () {
      const selectedCompetencies = this.selectedCompetencies;
      const competencies = await this.competencies;
      return await Ember.RSVP.filter(competencies.toArray(), async competency => {
        const children = await competency.treeChildren;
        const selectedChildren = children.filter(c => selectedCompetencies.includes(c));
        return selectedChildren.length > 0;
      });
    }),

    init() {
      this._super(...arguments);

      this.set('selectedCompetencies', []);
      this.loadSelectedCompetencies.perform();
    },

    didUpdateAttrs() {
      this._super(...arguments);

      this.loadSelectedCompetencies.perform();
    },

    actions: {
      cancel() {
        this.loadSelectedCompetencies.perform();
        this.setIsManaging(false);
      },

      addCompetencyToBuffer(competency) {
        let selectedCompetencies = this.selectedCompetencies.toArray();
        selectedCompetencies.addObject(competency);
        competency.get('children').then(children => {
          selectedCompetencies.addObjects(children.toArray());
        });
        this.set('selectedCompetencies', selectedCompetencies);
      },

      removeCompetencyFromBuffer(competency) {
        let selectedCompetencies = this.selectedCompetencies.toArray();
        selectedCompetencies.removeObject(competency);
        competency.get('children').then(children => {
          selectedCompetencies.removeObjects(children.toArray());
        });
        this.set('selectedCompetencies', selectedCompetencies);
      },

      collapse() {
        this.get('programYear.competencies').then(competencies => {
          if (competencies.get('length')) {
            this.collapse();
          }
        });
      }

    },
    loadSelectedCompetencies: (0, _emberConcurrency.task)(function* () {
      const programYear = this.programYear;

      if (programYear) {
        let selectedCompetencies = yield programYear.get('competencies');
        this.set('selectedCompetencies', selectedCompetencies.toArray());
      } else {
        yield (0, _emberConcurrency.timeout)(1000);
      }
    }).restartable(),
    save: (0, _emberConcurrency.task)(function* () {
      yield (0, _emberConcurrency.timeout)(10);
      let programYear = this.programYear;
      let selectedCompetencies = this.selectedCompetencies;
      programYear.set('competencies', selectedCompetencies);

      try {
        yield programYear.save();
      } finally {
        this.flashMessages.success('general.savedSuccessfully');
        this.setIsManaging(false);
        this.expand();
      }
    }).drop()
  });

  _exports.default = _default;
});