define("ilios/templates/components/pagination-links", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "pvlD3DTD",
    "block": "{\"symbols\":[\"pageNumber\",\"@page\",\"@onSelectPage\",\"@size\",\"@results\"],\"statements\":[[4,\"if\",[[28,\"gt\",[[23,5,[\"length\"]],[23,4,[]]],null]],null,{\"statements\":[[0,\"  \"],[7,\"span\",false],[12,\"class\",[29,[\"prev-link link clickable \",[28,\"if\",[[23,0,[\"disablePrev\"]],\"inactive\"],null]]]],[12,\"role\",\"button\"],[3,\"action\",[[23,0,[]],\"selectPage\",-1]],[8],[0,\"\\n    \"],[5,\"fa-icon\",[],[[\"@icon\"],[\"angle-left\"]]],[0,\"\\n    \"],[1,[28,\"t\",[\"general.prev\"],null],false],[0,\"\\n  \"],[9],[0,\"\\n\"],[4,\"each\",[[23,0,[\"pages\"]]],null,{\"statements\":[[4,\"if\",[[28,\"eq\",[[23,1,[]],\"...\"],null]],null,{\"statements\":[[0,\"      \"],[7,\"span\",true],[10,\"class\",\"ellipsis\"],[8],[0,\"\\n        . . .\\n      \"],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"      \"],[7,\"button\",false],[12,\"class\",[29,[\"page-button \",[28,\"if\",[[28,\"eq\",[[23,2,[]],[23,1,[]]],null],\"inactive\"],null]]]],[3,\"action\",[[23,0,[]],[23,3,[]],[23,1,[]]]],[8],[0,\"\\n        \"],[1,[23,1,[]],false],[0,\"\\n      \"],[9],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[1]},null],[0,\"  \"],[7,\"span\",false],[12,\"class\",[29,[\"next-link link clickable \",[28,\"if\",[[23,0,[\"disableNext\"]],\"inactive\"],null]]]],[12,\"role\",\"button\"],[3,\"action\",[[23,0,[]],\"selectPage\",1]],[8],[0,\"\\n    \"],[1,[28,\"t\",[\"general.next\"],null],false],[0,\"\\n    \"],[5,\"fa-icon\",[],[[\"@icon\"],[\"angle-right\"]]],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ilios/templates/components/pagination-links.hbs"
    }
  });

  _exports.default = _default;
});