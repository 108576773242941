define("ilios/mixins/live-search-item", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    title: '',
    isActive: true,
    targetObject: null,
    sortTerm: '',
    isALiveSearchItem: true
  });

  _exports.default = _default;
});