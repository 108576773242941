define("ilios/components/collapsed-stewards", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['collapsed-stewards'],
    tagName: 'section',
    programYear: null,
    schoolData: Ember.computed('programYear.stewards.[]', async function () {
      const programYear = this.programYear;

      if (Ember.isEmpty(programYear)) {
        return [];
      }

      const stewards = await programYear.get('stewards');
      const stewardObjects = await Ember.RSVP.map(stewards.toArray(), async steward => {
        const school = await steward.get('school');
        const department = await steward.get('department');
        const departmentId = Ember.isPresent(department) ? department.get('id') : 0;
        return {
          schoolId: school.get('id'),
          schoolTitle: school.get('title'),
          departmentId
        };
      });
      const schools = stewardObjects.uniqBy('schoolId');
      const schoolData = schools.map(obj => {
        const departments = stewardObjects.filterBy('schoolId', obj.schoolId);
        delete obj.departmentId;
        obj.departmentCount = departments.length;
        return obj;
      });
      return schoolData;
    })
  });

  _exports.default = _default;
});