define("ilios/components/assign-students", ["exports", "ember-concurrency", "moment"], function (_exports, _emberConcurrency, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    flashMessages: Ember.inject.service(),
    store: Ember.inject.service(),
    classNames: ['students'],
    school: null,
    students: null,
    isSaving: false,
    primaryCohortId: null,
    savedUserIds: null,
    selectedUserIds: null,
    cohorts: Ember.computed.reads('loadCohorts.lastSuccessful.value'),
    isLoading: Ember.computed.reads('loadCohorts.isRunning'),
    bestSelectedCohort: Ember.computed('cohorts.[]', 'primaryCohortId', function () {
      const cohorts = this.cohorts;
      const primaryCohortId = this.primaryCohortId;

      if (Ember.isEmpty(cohorts)) {
        return false;
      }

      if (primaryCohortId) {
        const currentCohort = cohorts.find(cohort => cohort.id === primaryCohortId);
        return currentCohort ? currentCohort : false;
      } else {
        return cohorts.lastObject;
      }
    }),
    filteredStudents: Ember.computed('savedUserIds.[]', 'students.[]', function () {
      const savedUserIds = this.savedUserIds;
      const students = this.students;
      return Ember.isPresent(students) ? students.filter(user => !savedUserIds.includes(user.id)) : [];
    }),
    totalUnassignedStudents: Ember.computed('students.length', 'savedUserIds.length', function () {
      const students = this.get('students.length');
      const saved = this.get('savedUserIds.length');
      return students - saved;
    }),
    loadCohorts: (0, _emberConcurrency.task)(function* () {
      let school = this.school;
      let cohorts = yield this.store.query('cohort', {
        filters: {
          schools: [school.get('id')]
        }
      }); //prefetch programYears and programs so that ember data will coalesce these requests.

      let programYears = yield Ember.RSVP.all(cohorts.getEach('programYear'));
      yield Ember.RSVP.all(programYears.getEach('program'));
      cohorts = cohorts.toArray();
      let all = [];

      for (let i = 0; i < cohorts.length; i++) {
        let cohort = cohorts[i];
        let obj = {
          id: cohort.get('id'),
          model: cohort
        };
        let programYear = yield cohort.get('programYear');
        let program = yield programYear.get('program');
        obj.title = program.get('title') + ' ' + cohort.get('title');
        obj.startYear = programYear.get('startYear');
        obj.duration = program.get('duration');
        all.pushObject(obj);
      }

      let lastYear = parseInt((0, _moment.default)().subtract(1, 'year').format('YYYY'), 10);
      return all.filter(obj => {
        let finalYear = parseInt(obj.startYear, 10) + parseInt(obj.duration, 10);
        return finalYear > lastYear;
      });
    }).restartable().on('didReceiveAttrs'),

    init() {
      this._super(...arguments);

      this.setProperties({
        savedUserIds: [],
        selectedUserIds: []
      });
    },

    actions: {
      toggleCheck() {
        const currentlySelected = this.selectedUserIds.length;
        const totalDisplayed = this.filteredStudents.length;
        const selectedUserIds = currentlySelected < totalDisplayed ? this.filteredStudents.mapBy('id') : [];
        this.set('selectedUserIds', selectedUserIds);
      },

      toggleUserSelection(userId) {
        if (this.selectedUserIds.includes(userId)) {
          this.selectedUserIds.removeObject(userId);
        } else {
          this.selectedUserIds.pushObject(userId);
        }
      }

    },
    save: (0, _emberConcurrency.task)(function* () {
      this.set('savedUserIds', []);
      this.set('isSaving', true);
      let ids = this.selectedUserIds;
      let cohort = yield this.bestSelectedCohort;
      let students = this.students;
      let studentsToModify = students.filter(user => {
        return ids.includes(user.get('id'));
      });

      if (!cohort || studentsToModify.length < 1) {
        return;
      }

      studentsToModify.setEach('primaryCohort', cohort.model);

      while (studentsToModify.get('length') > 0) {
        let parts = studentsToModify.splice(0, 3);
        yield Ember.RSVP.all(parts.invoke('save'));
        this.savedUserIds.pushObjects(parts.mapBy('id'));
      }

      this.set('isSaving', false);
      this.set('selectedUserIds', []);
      this.flashMessages.success('general.savedSuccessfully');
    }).drop()
  });

  _exports.default = _default;
});