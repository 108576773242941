define("ilios/components/new-learnergroup-multiple", ["exports", "ember-cp-validations", "ilios-common/mixins/validation-error-display"], function (_exports, _emberCpValidations, _validationErrorDisplay) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const Validations = (0, _emberCpValidations.buildValidations)({
    numSubGroups: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('number', {
      allowString: true,
      integer: true,
      gt: 0,
      lte: 50
    })]
  });

  var _default = Ember.Component.extend(Validations, _validationErrorDisplay.default, {
    classNames: ['form'],
    isSaving: false,
    numSubGroups: null,
    actions: {
      save() {
        this.send('addErrorDisplayFor', 'numSubGroups');
        this.validate().then(({
          validations
        }) => {
          if (validations.get('isValid')) {
            const num = this.numSubGroups;
            this.generateNewLearnerGroups(num);
          }
        });
      }

    },

    keyUp(event) {
      const keyCode = event.keyCode;
      const target = event.target;

      if ('text' !== target.type) {
        return;
      }

      if (13 === keyCode) {
        this.send('save');
        return;
      }

      if (27 === keyCode) {
        this.cancel();
      }
    }

  });

  _exports.default = _default;
});