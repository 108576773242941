define("ilios/routes/session/copy", ["exports", "ilios-common/mixins/session/copy-route"], function (_exports, _copyRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_copyRoute.default, {});

  _exports.default = _default;
});