define("ilios/routes/program/index", ["exports", "ember-simple-auth/mixins/authenticated-route-mixin"], function (_exports, _authenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    permissionChecker: Ember.inject.service(),
    canCreate: false,

    async afterModel(program) {
      const permissionChecker = this.permissionChecker;
      const canCreate = await permissionChecker.canCreateProgramYear(program);
      this.set('canCreate', canCreate);
      await Ember.RSVP.all([program.get('programYears'), program.get('allPublicationIssuesLength')]);
    },

    setupController(controller, model) {
      this._super(controller, model);

      controller.set('canCreate', this.canCreate);
    }

  });

  _exports.default = _default;
});