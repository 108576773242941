define("ilios/components/user-profile-ics", ["exports", "ember-concurrency", "crypto-js/sha256"], function (_exports, _emberConcurrency, _sha) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    iliosConfig: Ember.inject.service(),
    classNameBindings: [':user-profile-ics', ':small-component', 'hasSavedRecently:has-saved:has-not-saved'],
    finishedSetup: false,
    hasSavedRecently: false,
    icsFeedKey: null,
    isManageable: false,
    isManaging: false,
    showCopySuccessMessage: false,
    user: null,
    host: Ember.computed.reads('iliosConfig.apiHost'),
    icsFeedUrl: Ember.computed('icsFeedKey', 'host', function () {
      const icsFeedKey = this.icsFeedKey;
      let host = this.host;

      if (Ember.isPresent(icsFeedKey)) {
        if (Ember.isEmpty(host)) {
          host = window.location.protocol + '//' + window.location.hostname;
          const port = window.location.port;

          if (![80, 443].includes(port)) {
            host += ':' + port;
          }
        }

        return host + '/ics/' + icsFeedKey;
      }

      return null;
    }),

    didReceiveAttrs() {
      this._super(...arguments);

      const user = this.user;

      if (Ember.isPresent(user)) {
        this.set('icsFeedKey', user.get('icsFeedKey'));
      } else {
        this.set('icsFeedKey', null);
      }
    },

    /**
     * Generate a random token from a combination of
     * the userid, a random string and the curent time
     * @param String userId
     * @return String
     */
    randomToken(userId) {
      const now = Date.now();
      const randomValue = Math.random().toString(36).substr(2);
      const hash = (0, _sha.default)(userId + randomValue + now).toString();
      return hash;
    },

    refreshKey: (0, _emberConcurrency.task)(function* () {
      const user = this.user;
      const token = this.randomToken(user.get('id'));
      user.set('icsFeedKey', token);
      yield user.save();
      this.setIsManaging(false);
      this.set('hasSavedRecently', true);
      yield (0, _emberConcurrency.timeout)(500);
      this.set('hasSavedRecently', false);
    }).drop(),
    textCopied: (0, _emberConcurrency.task)(function* () {
      this.set('showCopySuccessMessage', true);
      yield (0, _emberConcurrency.timeout)(3000);
      this.set('showCopySuccessMessage', false);
    }).restartable()
  });

  _exports.default = _default;
});