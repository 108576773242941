define("ilios/components/objective-manage-descriptors", ["exports", "ilios-common/components/objective-manage-descriptors"], function (_exports, _objectiveManageDescriptors) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _objectiveManageDescriptors.default;
    }
  });
});