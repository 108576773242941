define("ilios/components/common-dashboard", ["exports", "ilios-common/components/common-dashboard"], function (_exports, _commonDashboard) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _commonDashboard.default;
    }
  });
});