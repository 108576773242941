define("ilios/components/curriculum-inventory-sequence-block-min-max-editor", ["exports", "ember-cp-validations", "ilios-common/mixins/validation-error-display"], function (_exports, _emberCpValidations, _validationErrorDisplay) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const Validations = (0, _emberCpValidations.buildValidations)({
    minimum: [(0, _emberCpValidations.validator)('number', {
      allowString: true,
      integer: true,
      gte: 0
    })],
    maximum: [(0, _emberCpValidations.validator)('number', {
      dependentKeys: ['model.minimum'],
      allowString: true,
      integer: true,
      gte: Ember.computed('model.minimum', function () {
        const min = this.get('model.minimum') || 0;
        return Math.max(0, min);
      })
    })]
  });

  var _default = Ember.Component.extend(Validations, _validationErrorDisplay.default, {
    classNames: ['curriculum-inventory-sequence-block-min-max-editor'],
    tagName: 'section',
    isSaving: false,
    maximum: null,
    minimum: null,
    sequenceBlock: null,
    actions: {
      save() {
        this.set('isSaving', true);
        this.send('addErrorDisplaysFor', ['minimum', 'maximum']);
        this.validate().then(({
          validations
        }) => {
          if (validations.get('isValid')) {
            const min = this.minimum;
            const max = this.maximum;
            this.save(min, max);
          } else {
            this.set('isSaving', false);
          }
        });
      },

      cancel() {
        this.cancel();
      }

    },

    keyUp(event) {
      const keyCode = event.keyCode;
      const target = event.target;

      if ('text' !== target.type) {
        return;
      }

      if (13 === keyCode) {
        this.send('save');
        return;
      }

      if (27 === keyCode) {
        this.send('cancel');
      }
    }

  });

  _exports.default = _default;
});