define("ilios/components/learnergroup-subgroup-list", ["exports", "ember-concurrency", "ember-pad/utils/pad", "ilios/utils/count-digits", "ilios/utils/clone-learner-group"], function (_exports, _emberConcurrency, _pad, _countDigits, _cloneLearnerGroup) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    flashMessages: Ember.inject.service(),
    intl: Ember.inject.service(),
    store: Ember.inject.service(),
    classNames: ['learnergroup-subgroup-list'],
    tagName: 'section',
    'data-test-learnergroup-subgroup-list': true,
    canCreate: false,
    canDelete: false,
    isSaving: false,
    parentGroup: null,
    saved: false,
    savedGroup: null,
    showNewLearnerGroupForm: false,

    didReceiveAttrs() {
      this._super(...arguments);

      this.setProperties({
        saved: false,
        savedGroup: null
      });
    },

    actions: {
      async saveNewLearnerGroup(title) {
        const {
          parentGroup,
          store
        } = this;
        const cohort = await parentGroup.cohort;
        const newLearnerGroup = await store.createRecord('learner-group', {
          cohort,
          parent: parentGroup,
          title
        });
        const savedLearnerGroup = await newLearnerGroup.save();
        this.set('showNewLearnerGroupForm', false);
        this.setProperties({
          saved: true,
          savedGroup: savedLearnerGroup
        });
      },

      async generateNewLearnerGroups(num) {
        const {
          parentGroup,
          store
        } = this;
        this.set('currentGroupsSaved', 0);
        this.setProperties({
          isSaving: true,
          totalGroupsToSave: num
        });
        const offset = await parentGroup.subgroupNumberingOffset;
        const cohort = await parentGroup.cohort;
        const groups = [];
        const padBy = (0, _countDigits.default)(offset + parseInt(num, 10));
        const parentTitle = parentGroup.title.substring(0, 60 - 1 - padBy);

        for (let i = 0; i < num; i++) {
          const newGroup = await store.createRecord('learner-group', {
            cohort,
            parent: parentGroup,
            title: "".concat(parentTitle, " ").concat((0, _pad.default)(offset + i, padBy))
          });
          groups.pushObject(newGroup);
        }

        const saveSomeGroups = async groupsToSave => {
          const chunk = groupsToSave.splice(0, 6);
          await Ember.RSVP.all(chunk.invoke('save'));

          if (groupsToSave.length) {
            this.set('currentGroupsSaved', this.currentGroupsSaved + chunk.length);
            await saveSomeGroups(groupsToSave);
          } else {
            this.set('isSaving', false);
            this.set('showNewLearnerGroupForm', false);
            this.flashMessages.success('general.savedSuccessfully');
          }
        };

        await saveSomeGroups(groups);
      },

      removeLearnerGroup(learnerGroup) {
        return learnerGroup.destroyRecord();
      }

    },
    copyGroup: (0, _emberConcurrency.task)(function* (withLearners, learnerGroup) {
      this.set('saved', false);
      const store = this.store;
      const intl = this.intl;
      const cohort = yield learnerGroup.get('cohort');
      const parentGroup = yield learnerGroup.get('parent');
      const newGroups = yield (0, _cloneLearnerGroup.default)(store, learnerGroup, cohort, withLearners, parentGroup); // indicate that the top group is a copy

      newGroups[0].set('title', newGroups[0].get('title') + " (".concat(intl.t('general.copy'), ")"));
      this.set('totalGroupsToSave', newGroups.length); // save groups one at a time because we need to save in this order so parents are saved before children

      for (let i = 0; i < newGroups.length; i++) {
        yield newGroups[i].save();
        this.set('currentGroupsSaved', i + 1);
      }

      this.set('saved', true);
      this.set('savedGroup', newGroups[0]);
    })
  });

  _exports.default = _default;
});