define("ilios/controllers/assign-students", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const DEBOUNCE_DELAY = 250;

  var _default = Ember.Controller.extend({
    store: Ember.inject.service(),
    queryParams: ['query', 'schoolId'],
    query: '',
    schoolId: null,
    hasMoreThanOneSchool: Ember.computed.gt('model.schools.length', 1),
    primarySchool: Ember.computed.reads('model.primarySchool'),
    schools: Ember.computed.reads('model.schools'),
    selectedSchool: Ember.computed('primarySchool', 'schoolId', 'schools.[]', function () {
      const primarySchool = this.primarySchool;
      const schoolId = this.schoolId;

      if (Ember.isPresent(schoolId)) {
        const school = this.schools.findBy('id', schoolId);
        return school ? school : primarySchool;
      } else {
        return primarySchool;
      }
    }),
    unassignedStudents: Ember.computed('selectedSchool', async function () {
      const filters = {
        cohorts: null,
        enabled: true,
        school: this.schoolId,
        roles: [4]
      };
      return await this.store.query('user', {
        filters
      });
    }),
    filteredUnassignedStudents: Ember.computed('query', 'unassignedStudents', async function () {
      const query = this.query;
      const students = await this.unassignedStudents;
      const sortedStudents = students.sortBy('lastName', 'firstName');
      return Ember.isPresent(query) ? this.filterStudents(sortedStudents, query) : sortedStudents;
    }),
    setQuery: (0, _emberConcurrency.task)(function* (q) {
      yield (0, _emberConcurrency.timeout)(DEBOUNCE_DELAY);
      this.set('query', q);
    }).restartable(),

    filterStudents(students, query) {
      return students.filter(student => {
        const regex = new RegExp(query, "i");
        return student.fullName.search(regex) > -1;
      });
    }

  });

  _exports.default = _default;
});