define("ilios/components/global-search", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const MIN_INPUT = 3;

  var _default = Ember.Component.extend({
    iliosConfig: Ember.inject.service(),
    intl: Ember.inject.service(),
    iliosSearch: Ember.inject.service('search'),
    store: Ember.inject.service(),
    page: null,
    query: null,
    selectedYear: null,
    ignoredSchoolIds: null,
    size: 10,
    yearOptions: null,

    onQuery() {},

    onSelectPage() {},

    setIgnoredSchoolIds() {},

    setSelectedYear() {},

    isLoading: Ember.computed.reads('search.isRunning'),
    hasResults: Ember.computed.reads('results.length'),
    results: Ember.computed.reads('search.lastSuccessful.value'),
    allSchools: Ember.computed.reads('loadSchools.lastSuccessful.value'),
    ignoredSchoolTitles: Ember.computed('ignoredSchoolIds.[]', 'allSchools.[]', function () {
      if (!this.ignoredSchoolIds) {
        return [];
      }

      return this.ignoredSchoolIds.map(id => {
        const school = this.allSchools.findBy('id', id);
        return school ? school.title : '';
      });
    }),
    filteredResults: Ember.computed('results.[]', 'selectedYear', 'ignoredSchoolTitles.[]', function () {
      if (this.results) {
        const yearFilteredResults = this.results.filter(course => this.selectedYear ? course.year === this.selectedYear : true);
        return yearFilteredResults.filter(course => !this.ignoredSchoolTitles.includes(course.school));
      } else {
        return [];
      }
    }),
    paginatedResults: Ember.computed('filteredResults.[]', 'page', 'size', function () {
      const {
        page,
        size
      } = this.getProperties('page', 'size');
      return this.filteredResults.slice(page * size - size, page * size);
    }),
    schoolOptions: Ember.computed('allSchools.[]', 'results.[]', function () {
      if (this.results && this.results.length && this.allSchools && this.allSchools.length) {
        const emptySchools = this.allSchools.map(({
          id,
          title
        }) => {
          return {
            id,
            title,
            results: 0
          };
        }).sortBy('title');
        const options = this.results.reduce((set, course) => {
          let schoolOption = set.findBy('title', course.school);
          schoolOption.results++;
          return set;
        }, emptySchools);
        return options;
      } else {
        return [];
      }
    }),
    search: (0, _emberConcurrency.task)(function* () {
      this.onQuery(this.query);
      const {
        courses
      } = yield this.iliosSearch.forCurriculum(this.query);
      this.setUpYearFilter(courses.mapBy('year'));
      return courses;
    }).observes('query').restartable(),

    init() {
      this._super(...arguments);

      this.loadSchools.perform();

      if (this.query && this.query.length >= MIN_INPUT) {
        this.search.perform();
      }
    },

    actions: {
      setSelectedYear(year) {
        this.setSelectedYear(year ? parseInt(year, 10) : null);
        this.onSelectPage(1);
      },

      toggleSchoolSelection(id) {
        let ignoredSchoolIds = this.ignoredSchoolIds ? [...this.ignoredSchoolIds] : [];

        if (ignoredSchoolIds.includes(id)) {
          ignoredSchoolIds.removeObject(id);
        } else {
          ignoredSchoolIds.pushObject(id);
        }

        this.onSelectPage(1);
        this.setIgnoredSchoolIds(ignoredSchoolIds);
      }

    },
    loadSchools: (0, _emberConcurrency.task)(function* () {
      const schools = yield this.store.findAll('school');
      return schools;
    }),

    setUpYearFilter(years) {
      const yearOptions = years.uniq().sort().reverse();
      this.set('yearOptions', yearOptions);
    }

  });

  _exports.default = _default;
});