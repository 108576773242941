define("ilios/components/program-overview", ["exports", "ember-cp-validations", "ilios-common/mixins/validation-error-display"], function (_exports, _emberCpValidations, _validationErrorDisplay) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const Validations = (0, _emberCpValidations.buildValidations)({
    shortTitle: [(0, _emberCpValidations.validator)('length', {
      min: 2,
      max: 10,
      allowBlank: true
    })]
  });

  var _default = Ember.Component.extend(Validations, _validationErrorDisplay.default, {
    classNames: ['program-overview'],
    canUpdate: false,
    duration: null,
    program: null,
    shortTitle: null,
    durationOptions: Ember.computed({
      get() {
        const arr = [];

        for (let i = 1; i <= 10; i++) {
          arr.pushObject(Ember.Object.create({
            id: i,
            title: i
          }));
        }

        return arr;
      }

    }),

    didReceiveAttrs() {
      this._super(...arguments);

      this.set('shortTitle', this.get('program.shortTitle'));
      this.set('duration', this.program.get('duration'));
    },

    actions: {
      async changeShortTitle() {
        const program = this.program;
        const newTitle = this.shortTitle;
        this.send('addErrorDisplayFor', 'shortTitle');
        const {
          validations
        } = await this.validate();

        if (validations.isValid) {
          this.send('removeErrorDisplayFor', 'shortTitle');
          program.set('shortTitle', newTitle);
          const newProgram = await program.save();
          this.set('shortTitle', newProgram.shortTitle);
          this.set('program', newProgram);
        } else {
          await Ember.RSVP.reject();
        }
      },

      revertShortTitleChanges() {
        const program = this.program;
        this.set('shortTitle', program.get('shortTitle'));
      },

      changeDuration() {
        const program = this.program;
        let duration = this.duration; // If duration isn't changed it means the default of 1 was selected

        duration = duration == null ? 1 : duration;
        program.set('duration', duration);
        program.save();
      },

      revertDurationChanges() {
        this.set('duration', this.program.get('duration'));
      }

    }
  });

  _exports.default = _default;
});