define("ilios/components/school-vocabulary-term-manager", ["exports", "ember-concurrency", "ember-cp-validations", "ilios-common/mixins/validation-error-display"], function (_exports, _emberConcurrency, _emberCpValidations, _validationErrorDisplay) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const Validations = (0, _emberCpValidations.buildValidations)({
    newTermTitle: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('length', {
      min: 1,
      max: 200
    }), (0, _emberCpValidations.validator)('async-exclusion', {
      dependentKeys: ['model.term.children.@each.title'],
      in: Ember.computed('model.term.children.@each.title', async function () {
        const term = this.get('model.term');

        if (Ember.isPresent(term)) {
          const children = await term.children;
          return children.mapBy('title');
        }

        return [];
      }),
      descriptionKey: 'general.term'
    })],
    termTitle: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('length', {
      min: 1,
      max: 200
    }), (0, _emberCpValidations.validator)('async-exclusion', {
      dependentKeys: ['model.vocabulary.@each.terms'],
      in: Ember.computed('model.vocabulary.@each.terms', async function () {
        const vocabulary = this.get('model.vocabulary');

        if (Ember.isPresent(vocabulary)) {
          const terms = await vocabulary.terms;
          return terms.mapBy('title');
        }

        return [];
      }),
      descriptionKey: 'general.term'
    })]
  });

  var _default = Ember.Component.extend(Validations, _validationErrorDisplay.default, {
    store: Ember.inject.service(),
    flashMessages: Ember.inject.service(),
    term: null,
    canUpdate: false,
    canDelete: false,
    canCreate: false,
    newTerm: null,
    vocabulary: null,
    newTermTitle: null,
    isSavingNewTerm: false,
    description: null,
    termTitle: null,
    isActive: null,
    classNames: ['school-vocabulary-term-manager'],
    'data-test-school-vocabulary-term-manager': true,
    sortedTerms: Ember.computed('term.children.[]', 'newTerm', async function () {
      if (!this.term) {
        return [];
      }

      const terms = await this.term.get('children');
      return terms.filterBy('isNew', false).filterBy('isDeleted', false).sortBy('title');
    }),
    allParents: Ember.computed('term.allParents.[]', async function () {
      if (Ember.isPresent(this.term)) {
        return await this.term.allParents;
      }

      return [];
    }),

    didReceiveAttrs() {
      this._super(...arguments);

      if (this.term) {
        this.set('description', this.term.description);
        this.set('termTitle', this.term.title);
        this.set('isActive', this.term.active);
      }
    },

    actions: {
      async changeTermTitle() {
        this.send('addErrorDisplayFor', 'termTitle');
        await this.validate();

        if (this.validations.attrs.termTitle.isValid) {
          this.send('removeErrorDisplayFor', 'termTitle');
          this.term.set('title', this.termTitle);
          return this.term.save();
        }

        return false;
      },

      revertTermTitleChanges() {
        this.send('removeErrorDisplayFor', 'termTitle');
        this.set('termTitle', this.term.title);
      },

      async changeTermDescription() {
        this.term.set('description', this.description);
        return this.term.save();
      },

      revertTermDescriptionChanges() {
        this.set('description', this.term.description);
      },

      async createTerm() {
        this.send('addErrorDisplayFor', 'newTermTitle');
        this.set('isSavingNewTerm', true);

        try {
          await this.validate();

          if (this.validations.attrs.newTermTitle.isValid) {
            this.send('removeErrorDisplayFor', 'newTermTitle');
            let title = this.newTermTitle;
            let term = this.store.createRecord('term', {
              title,
              parent: this.term,
              vocabulary: this.vocabulary,
              active: true
            });
            const newTerm = await term.save();
            this.set('newTermTitle', null);
            this.set('newTerm', newTerm);
            return true;
          }
        } finally {
          this.set('isSavingNewTerm', false);
        }
      },

      async deleteTerm() {
        const parent = await this.term.parent;
        let goTo = Ember.isEmpty(parent) ? null : parent.id;
        this.manageTerm(goTo);
        this.term.deleteRecord();
        await this.term.save();
        this.flashMessages.success('general.successfullyRemovedTerm');
      },

      clearVocabAndTerm() {
        this.manageVocabulary(null);
        this.manageTerm(null);
      }

    },

    keyUp(event) {
      const keyCode = event.keyCode;
      const target = event.target;

      if ('text' !== target.type) {
        return;
      }

      if (13 === keyCode) {
        this.send('createTerm');
      }
    },

    changeIsActive: (0, _emberConcurrency.task)(function* (isActive) {
      this.term.set('active', isActive);
      yield this.term.save();
      this.set('isActive', this.term.active);
    }).drop()
  });

  _exports.default = _default;
});