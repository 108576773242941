define("ilios/routes/session", ["exports", "ember-simple-auth/mixins/authenticated-route-mixin", "ilios-common/mixins/session-route"], function (_exports, _authenticatedRouteMixin, _sessionRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, _sessionRoute.default, {});

  _exports.default = _default;
});