define("ilios/templates/curriculum-inventory-sequence-block", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "pLWHKsbO",
    "block": "{\"symbols\":[],\"statements\":[[1,[28,\"page-title\",[[28,\"t\",[\"general.curriculumInventoryReports\"],null]],null],false],[0,\"\\n\"],[5,\"curriculum-inventory-sequence-block-details\",[],[[\"@sequenceBlock\",\"@canUpdate\",\"@sortSessionsBy\",\"@setSortSessionBy\"],[[22,\"model\"],[22,\"canUpdate\"],[22,\"sortSessionsBy\"],[28,\"action\",[[23,0,[]],[28,\"mut\",[[24,[\"sortSessionsBy\"]]],null]],null]]]],[0,\"\\n\"],[5,\"curriculum-inventory-sequence-block-list\",[],[[\"@report\",\"@parent\",\"@canUpdate\",\"@sequenceBlocks\",\"@remove\"],[[28,\"await\",[[24,[\"model\",\"report\"]]],null],[22,\"model\"],[22,\"canUpdate\"],[28,\"await\",[[24,[\"model\",\"children\"]]],null],[28,\"action\",[[23,0,[]],\"removeChildSequenceBlock\"],null]]]]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ilios/templates/curriculum-inventory-sequence-block.hbs"
    }
  });

  _exports.default = _default;
});