define("ilios/components/programyear-objective-list-item", ["exports", "ilios-common/mixins/objective-list-item"], function (_exports, _objectiveListItem) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_objectiveListItem.default, {
    programYear: null,
    expanded: true,
    classNames: ['objective-list-item']
  });

  _exports.default = _default;
});