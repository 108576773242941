define("ilios/translations/en-us", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "errors": {
      "accepted": "{description} must be accepted",
      "after": "{description} must be after {after}",
      "before": "{description} must be before {before}",
      "blank": "{description} can not be blank",
      "collection": "{description} must be a collection",
      "confirmation": "{description} does not match {on}",
      "date": "{description} must be a valid date",
      "description": "This field",
      "email": "{description} must be a valid email address",
      "empty": "{description} can not be empty",
      "equalTo": "{description} must be equal to {is}",
      "even": "{description} must be even",
      "exclusion": "{description} is a duplicate",
      "greaterThan": "{description} must be greater than {gt}",
      "greaterThanOrEqualTo": "{description} must be greater than or equal to {gte}",
      "inclusion": "{description} is not included in the list",
      "invalid": "{description} is invalid",
      "lessThan": "{description} must be less than {lt}",
      "lessThanOrEqualTo": "{description} must be less than or equal to {lte}",
      "notANumber": "{description} must be a number",
      "notAnInteger": "{description} must be an integer",
      "odd": "{description} must be odd",
      "onOrAfter": "{description} must be on or after {onOrAfter}",
      "onOrBefore": "{description} must be on or before {onOrBefore}",
      "otherThan": "{description} must be other than {value}",
      "phone": "{description} must be a valid phone number",
      "positive": "{description} must be positive",
      "present": "{description} must be blank",
      "required": "{description} is required",
      "singular": "{description} can not be a collection",
      "tooLong": "{description} is too long (maximum is {max} characters)",
      "tooShort": "{description} is too short (minimum is {min} characters)",
      "url": "{description} must be a valid url",
      "wrongDateFormat": "{description} must be in the format of {format}",
      "wrongLength": "{description} is the wrong length (should be {is} characters)"
    },
    "general": {
      "aamcMethod": "AAMC Method",
      "aamcSchoolId": "AAMC School ID (e.g. \"Institution ID\")",
      "academicLevel": "Academic Level",
      "academicLevels": "Academic Levels",
      "academicYear": "Academic Year",
      "accessAllMaterialsForThisCourse": "Access All Materials for this Course",
      "accountEnabled": "Account Enabled",
      "actions": "Actions",
      "activate": "Activate",
      "active": "Active",
      "activeFilters": "Active Filters",
      "activities": "Activities",
      "add": "Add",
      "addCohort": "Add Cohort",
      "addNew": "Add New",
      "admin": "Admin",
      "administrator": "Administrator",
      "administratorCount": "There {count, plural, =1 {is 1 administrator} other {are # administrators}}",
      "administratorEmail": "Administrator Email",
      "administrators": "Administrators",
      "advancedOptions": "Advanced Options",
      "all": "All",
      "allAcademicYears": "All Academic Years",
      "allCourses": "All Courses",
      "allEvents": "All Events",
      "allOtherMembers": "All other members of {topLevelGroupTitle}",
      "allReports": "All Reports",
      "allSchools": "All Schools",
      "allVocabularies": "All Vocabularies",
      "allWeeks": "All Weeks",
      "anything": "Anything",
      "apiVersionMismatch": "API Version Mismatch",
      "apiVersionMismatchDetails": "This is usually a temporary error in your browser; Ilios is in read-only mode until it resolved. If updating does not help, please notify an administrator or technical owner of the system regarding this error. You may also wish to try a different browser.",
      "assessment": "Assessment",
      "assessmentMethods": "Assessment Methods",
      "assessmentOption": "Assessment Option",
      "assessments": "Assessments",
      "assignedTerms": "Assigned Terms",
      "associatedCourses": "Associated Courses",
      "associatedGroups": "Associated Groups",
      "associatedWith": "Associated with",
      "attendanceIsRequired": "Attendance is required",
      "attendanceIs_Required_": "Attendance is <strong><em>required</em></strong>",
      "attendanceRequired": "Attendance Required",
      "attribute": "Attribute",
      "author": "Author",
      "autoUpdatingSeconds": "{count, plural, =1 {Auto updating in 1 second...} other {Auto updating in # seconds...}}",
      "available": "Available",
      "availableCohorts": "Available Cohorts",
      "availableLearnerGroups": "Available Learner Groups",
      "averageHoursOfInstructionPerWeek": "Average Hours of Instruction Per Week",
      "back": "Back",
      "backToAdminDashboard": "Back to Admin Dashboard",
      "backToCalendar": "Back to Calendar",
      "backToCourse": "Back to Course",
      "backToCourses": "Back to Courses List",
      "backToDashboard": "Back to Dashboard",
      "backToDetails": "Back to Session Details",
      "backToInstructorGroups": "Back to Instructor Groups List",
      "backToProgramYear": "Back to Program Year",
      "backToProgramYears": "Back to Program Years",
      "backToPrograms": "Back to Programs",
      "backToReport": "Back to Report",
      "backToReports": "Back to Reports",
      "backToSchools": "Back to Schools List",
      "backToSessionList": "Back to Session List",
      "backToTableOfContents": "Back to Table of Contents",
      "backToTitle": "Back to {title}",
      "bad": "Bad",
      "badCredentials": "Incorrect username or password",
      "calendar": "Calendar",
      "calendarOff": "Calendar Off",
      "calendarOn": "Calendar On",
      "campusId": "Campus ID",
      "canNotContinueWithInvalidRecords": "Cannot continue with these invalid records, please fix and re-upload the file.",
      "cancel": "Cancel",
      "childSequenceOrder": "Child Sequence Order",
      "chooseCohortTitle": "Select Parent For",
      "citation": "Citation",
      "city": "City",
      "classOf": "Class of {year}",
      "clearDates": "Clear Dates",
      "clearErrors": "Clear Errors and Return to Ilios",
      "clearFilters": "Clear Filters",
      "clerkship": "Clerkship",
      "clerkshipSequenceBlocks": "Clerkship Sequence Blocks",
      "clerkshipType": "Clerkship Type",
      "clickForMore": "Click for more information",
      "clickToAddDescription": "Click to add a description.",
      "clickToAddTermDescription": "Click to add descriptive notes",
      "clickToEdit": "Click to edit",
      "close": "Close",
      "cohort": "Cohort",
      "cohortMembersNotInGroup": "Cohort Members NOT assigned to {groupTitle} ",
      "cohorts": "Cohorts",
      "cohortsManageTitle": "Manage Cohorts",
      "collapseDetail": "Hide Details",
      "color": "Color",
      "competencies": "Competencies",
      "competenciesManageTitle": "Manage Competencies",
      "competency": "Competency",
      "competencyCount": "{count, plural, =1 {There is 1 competency} other {There are # competencies}}",
      "completeSessions": "Sessions Complete: ready to publish",
      "confirmLogout": "You have successfully logged out of Ilios. To help protect your account and the security of others, please close all browser windows.",
      "confirmObjectiveRemoval": "Are you sure you want to delete this objective?",
      "confirmObjectiveRemovalMultipleSessionObjectives": "You are about to delete this course objective, which is linked to {count} session objectives. This will delete those relationships as well. Are you sure you want to continue?",
      "confirmRemoval": "Are you sure you want to remove this learning material?",
      "confirmRemove": "Are you sure you want to delete this offering with {learnerGroupCount} learner groups? This action cannot be undone.",
      "confirmRemoveCourse": "Are you sure you want to delete this course, with {publishedOfferingCount} published offerings? This action will remove all sessions and offerings for this course, and cannot be undone.",
      "confirmRemoveInstructorGroup": "Are you sure you want to delete this instructor group, with {instructorCount} instructors? This action cannot be undone.",
      "confirmRemoveLearnerGroup": "Are you sure you want to delete this learner group, with {subgroupCount} subgroups? This action cannot be undone.",
      "confirmRemoveProgram": "Are you sure you want to delete this program? This action cannot be undone.",
      "confirmRemoveProgramYear": "Are you sure you want to delete this program year? Doing so will also remove its associated student cohort and groups, and associations with {courseCount} course(s), and cannot be undone.",
      "confirmSessionRemoval": "Are you sure you want to delete this session?",
      "confirmVocabularyRemoval": "Are you sure you want to delete this vocabulary?",
      "connectionLost": "Connection Lost.",
      "contentAuthor": "Content Author",
      "continue": "Continue",
      "copiedSuccessfully": "Copied Successfully",
      "copy": "Copy",
      "copyIcsFeedUrl": "Copy My ICS Link",
      "copyLink": "Copy link",
      "copySession": "Copy Session",
      "copySessionSummary": "You are about to copy over this session (without schedule data) into the selected course. The new session will include all objectives, terms, and learning materials.  To continue click \"done\".  To return to your current session click \"cancel\".",
      "copySuccess": "Copy Completed Successfully",
      "copyWithLearners": "Copy With Learners",
      "copyWithoutLearners": "Copy Without Learners",
      "copyrightAgreement": "The file I am uploading is my own or I have express permission to reproduce and/or distribute this item, or use of it here has been assessed as allowable by the legal doctrine of Fair Use. This file does not contain protected health information, and use of it here is in compliance with Government and University policies on copyright and information security and my institution’s guidelines for professional conduct.",
      "copyrightPermission": "Copyright Permission",
      "copyrightRationale": "Copyright Rationale",
      "couldNotFindUserCampusId": "Could not find a user with the campusId {campusId}",
      "countAsOneOffering": "Count as one offering",
      "country": "Country",
      "course": "Course",
      "courseDirector": "Course Director",
      "courseLeadership": "Course Leadership",
      "courseLearningMaterials": "Course Learning Materials",
      "courseLevels": "Course Levels",
      "courseObjectives": "Course Objectives",
      "courseOrType": "Course / Type",
      "courseRollover": "Rollover Course",
      "courseRolloverSuccess": "Course-Rollover Completed Successfully",
      "courseRolloverSummary": "You are about to roll over this course into the selected academic year, along with all selected options. To continue, click “done”. To return to your current course, click “cancel”",
      "courseRolloverYearUnavailable": "Course Already Exists",
      "courseTitle": "Course Title",
      "courseTitleFilterPlaceholder": "Filter by course title",
      "courseTitlePlaceholder": "Enter a title for this course",
      "courseVisualizations": "Course Visualizations",
      "courses": "Courses",
      "coursesAndSessions": "Courses and Sessions",
      "create": "Create New User",
      "createBulk": "Upload Multiple Users",
      "createNew": "Create New",
      "createNewGroup": "Create This Group",
      "currentCompetency": "Current Competency",
      "currentSchool": "Current School",
      "currentlySearchingPrompt": "searching...",
      "curriculumInventory": "Curriculum Inventory",
      "curriculumInventoryInstitutionalInfo": "Curriculum Inventory Institutional Information",
      "curriculumInventoryReport": "Curriculum Inventory Report",
      "curriculumInventoryReportLeadership": "Curriculum Inventory Report Leadership",
      "curriculumInventoryReportRollover": "Rollover Report",
      "curriculumInventoryReportRolloverSuccess": "Report-Rollover Completed Successfully",
      "curriculumInventoryReportRolloverSummary": "This action will copy the structure of this report to be used in your target year. It will not copy course information, only the higher level sequence block structure. You will need to add the appropriate course information once you have rolled over the existing report structure.",
      "curriculumInventoryReports": "Curriculum Inventory Reports",
      "dashboard": "Dashboard",
      "date": "Date",
      "dateTime": "Date/Time",
      "day": "Day",
      "defaultInstructors": "Default Instructors",
      "defaultInstructorsNotLoaded": "(Default Instructors will not be loaded: please enter desired value.)",
      "defaultLocation": "Default Location",
      "defaultLocationNotLoaded": "(Default Location will not be loaded: please enter desired value.)",
      "departments": "Departments",
      "deprecatedAbbreviation": "depr.",
      "description": "Description",
      "detail": "Detail",
      "details": "Course Details",
      "developer": "Developer",
      "director": "Director",
      "directorCount": "There {count, plural, =1 {is 1 director} other {are # directors}}",
      "directors": "Directors",
      "disableUser": "Disable User",
      "disabled": "disabled",
      "displayName": "Display Name",
      "doesNotMatchUserRecord": "Uploaded {description} does not match the information in Ilios, which is \"{record}\"",
      "domain": "Domain",
      "done": "Done",
      "download": "Download",
      "downloadCompetencyMap": "Download Competency Map",
      "downloadResults": "Download Results",
      "draft": "Draft",
      "dueBefore": "Due Before {name} ({date})",
      "dueBeforeLink": "Due Before <a href='{link}'>{name}</a> ({date})",
      "dueBy": "Due By",
      "duePriorTo": "Due prior to",
      "dueThisDay": "Due this day",
      "duration": "Duration",
      "durationHours": "{count, plural, =1 {1 hour} other {# hours}}",
      "durationInDays": "Duration (in Days)",
      "durationInYears": "Duration (in Years)",
      "durationMinutes": "{count, plural, =1 {1 minute} other {# minutes}}",
      "edit": "Edit",
      "educationalYear": "Educational Year",
      "email": "Email",
      "enabled": "Enabled",
      "end": "End",
      "endDate": "End Date",
      "endTime": "End Time",
      "ends": "Ends",
      "error": "Error",
      "errorDisplayMessage": "Sorry! Something went wrong with your request. Please try refreshing or check back later.",
      "errorSavingAuthentication": "Accounts were created for these users, but they will not be able to login.  This usually happens when the username already exists, but belongs to another user.  Please click on each user in the list and create a username and password for them.",
      "errorSavingUser": "These users were unable to be saved.  Please verify that they do not already exist.",
      "errors": "Errors",
      "etAl": "et al.",
      "exclude": "Exclude",
      "excludeFromSync": "Exclude From Sync",
      "existingGroup": "Existing Group",
      "expandDetail": "Show Details",
      "externalId": "Course ID",
      "file": "File",
      "fileSizeError": "This file is too large.  Maximum size is {maxSize}.",
      "fileTypeError": "Bad file type: {fileType} does not appear to be a properly delimited file.",
      "fileUploadError": "We were unable to upload this file.  Please ensure you are connected to the network.  If this problem persists please let us know.",
      "filterByNameOrEmail": "Filter by name or email",
      "filterPlaceholder": "Start typing to filter list",
      "finalErrorDisplayMessage": "Zoinks! Something is very wrong. Please refresh your browser and let us know at support@iliosproject.org if you continue to see this message.",
      "finalResults": "Final Results",
      "finalize": "Finalize",
      "finalizeReportConfirmation": "By finalizing this report, you will be saving it in its current state to the Ilios database as a permanent reference object, and no further edits will be possible.",
      "finalized": "Finalized",
      "finalizingReport": "Finalizing report, this may take a while...",
      "findDirector": "Find Director",
      "findInstructorOrGroup": "Find Instructor or Group",
      "firstName": "First Name",
      "firstOffering": "First Offering",
      "for": "For",
      "formative": "Formative",
      "formerStudent": "Former Student",
      "friday": "Friday",
      "fullName": "Full Name",
      "globalSearchPlaceholder": "Find Users...",
      "go": "Go",
      "goToUser": "Show user record in ilios",
      "good": "Good",
      "groupByCompetencies": "Group by Competencies",
      "groupMembers": "Members of current group",
      "groupName": "Group Name",
      "groups": "Groups",
      "hideCalendar": "Hide Calendar",
      "hideFilters": "Hide Filters",
      "home": "Home",
      "hours": "Hours",
      "icsAdminInstructions": "To add a user's Ilios calendar to another application or service, they will need to use this URL.  This URL is like a password. Anyone who knows it can view this user's calendar!",
      "icsFeed": "ICS Feed",
      "icsInstructions": "To add your Ilios calendar to another application or service, use this URL.  This URL is like a password. Anyone who knows it can view your calendar!",
      "id": "ID",
      "ignore": "Ignore",
      "ilios": "Ilios",
      "iliosUpdatePending": "Huzzah! We've made Ilios better. You will get the new stuff on your next login, or click to update now.",
      "ilm": "ILM",
      "ilmDue": "ILM - Due",
      "inactive": "inactive",
      "include": "Include",
      "includedInGrade": "Included in Grade",
      "incompleteSessions": "Sessions Incomplete: cannot publish",
      "independentLearning": "Independent Learning",
      "independentLearningActivities": "Independent Learning Activities",
      "instructionalMethod": "Instructional Method",
      "instructionalMethods": "Instructional Methods",
      "instructionalNotes": "Instructional Notes",
      "instructor": "Instructor",
      "instructorGroup": "Instructor Group",
      "instructorGroupTitle": "Instructor Group Title",
      "instructorGroupTitleFilterPlaceholder": "Filter by Instructor Group Title",
      "instructorGroupTitlePlaceholder": "Enter a title for this instructor group",
      "instructorGroups": "Instructor Groups",
      "instructors": "Instructors",
      "instructorsManageTitle": "Manage Instructors",
      "invalidDatetimes": "Invalid dates/times",
      "invalidUsers": "Invalid Users",
      "invalidateTokens": "Invalidate All Tokens",
      "invalidateTokensConfirmation": "This will invalidate all existing API tokens and you will need to generate new ones for any applications you have.  Are you sure you want to do this?",
      "isSelective": "Is Selective",
      "isTrack": "Is Track",
      "itemCode": "Item Code",
      "language": {
        "en-us": "English (en)",
        "es": "Español (es)",
        "fr": "Français (fr)"
      },
      "lastName": "Last Name",
      "lastUpdate": "Last Update",
      "learner": "Learner",
      "learnerAssignments": "{groupTitle} Learner Assignments",
      "learnerGroupTitle": "Learner Group Title",
      "learnerGroupTitleFilterPlaceholder": "Filter by Learner Group Title",
      "learnerGroupTitlePlaceholder": "Enter a title for this learner group",
      "learnerGroups": "Learner Groups",
      "learnerGroupsManageTitle": "Manage Learner Groups",
      "learners": "Learners",
      "learningMaterial": "Learning Material",
      "learningMaterialManageTitle": "Manage Learning Material",
      "learningMaterials": "Learning Materials",
      "learningMaterialsSummary": "Learning Materials Summary",
      "level": "Level",
      "link": "Web Link",
      "linkedCompetencies": "Linked Competencies",
      "linkedCompetencyCount": "{count, plural, =1 {1 has a linked competency} other {# have linked competencies}}",
      "listByPriority": "List by Priority",
      "lmDescriptorTitle": "Select MeSH Descriptors for Learning Materials",
      "loading": "Loading Ilios",
      "loadingEvents": "Loading Events",
      "loadingMaterials": "Loading Materials...",
      "loadingSessionTypes": "Loading Session Types...",
      "location": "Location",
      "login": "Login",
      "logo": "Ilios Logo",
      "logout": "Logout",
      "makeRecurring": "Make Recurring",
      "manage": "Manage",
      "manageAPITokens": "Manage API Tokens",
      "manageCompetencies": "Manage Competencies",
      "manageInstitutionalInfo": "Manage CIR Institutional Info",
      "manageLeadership": "Manage Leadership",
      "manageSessionAttributes": "Manage Session Attributes",
      "manageUsers": "Manage Users",
      "manageUsersSummaryTitle": "Ilios Users",
      "manageVocabularies": "Manage Vocabularies",
      "markAsScheduled": "Mark as Scheduled",
      "matchGroups": "Match Groups",
      "materials": "Materials",
      "matriculationYear": "Matriculation Year",
      "maximum": "Maximum",
      "members": "Members",
      "menu": "Ilios Menu",
      "mesh": "MeSH",
      "meshCount": "{count, plural, =1 {1 has MeSH} other {# have MesH}}",
      "meshManageTitle": "Manage MeSH",
      "meshSearchPlaceholder": "Search MeSH",
      "meshTerm": "MeSH Term",
      "meshTerms": "MeSH Terms",
      "middleName": "Middle Name",
      "minimum": "Minimum",
      "minutes": "Minutes",
      "missingCohortMessage": "Please add at least one cohort to this course.",
      "missingCompetenciesMessage": "Please add at least one competency to this program year.",
      "missingCourseObjectivesMessage": "Please add at least one objective to the course.",
      "missingItems": "Missing Items",
      "missingPassword": "Password required",
      "missingRequiredUsername": "'Username' is required for login; this user will be unable to login until a username is entered.",
      "missingUsername": "Username required",
      "modifyUserPassword": "Click here to reset password.",
      "monday": "Monday",
      "month": "Month",
      "moreInputRequiredPrompt": "keep typing...",
      "moveToGroup": "{count, plural, =1 {Move learner to {groupTitle}} other {Move # learners to {groupTitle}}}",
      "multiDay": "Multi Day",
      "multiday": "Multiday",
      "multidayEvents": "Multiday Events",
      "multiple": "Multiple",
      "multipleGroups": "Multiple Groups",
      "multipleUsersFoundWithCampusId": "Multiple users found with the campusId {campusId}",
      "mustBeSameDayOfWeekAsCurrentStartDate": "Must be same day of week as current start date",
      "myCalendar": "My Calendar",
      "myCourses": "My Courses",
      "myMaterials": "My Materials",
      "myProfile": "My Profile",
      "myReports": "My Reports",
      "mySchedule": "My Schedule",
      "name": "Name",
      "narrative": "Narrative",
      "newCourse": "New Course",
      "newCurriculumInventoryReport": "New Curriculum Inventory Report",
      "newDomain": "New Domain",
      "newInstructorGroup": "New Instructor Group",
      "newLearnerGroup": "New Learner Group",
      "newObjective": "New Objective",
      "newObjectiveSaved": "New Objective Saved",
      "newOffering": "New Offering",
      "newProgram": "New Program",
      "newProgramYear": "New Program Year",
      "newReport": "New Report",
      "newSchool": "New School",
      "newSequenceBlock": "New Sequence Block",
      "newSession": "New Session",
      "newTerm": "New Term",
      "newToken": "New Token",
      "newUpdates": "new updates",
      "newUser": "New User",
      "newUsersCreatedSuccessfully": "New users created successfully",
      "newUsersCreatedWarning": "Some users were created, but there were errors.",
      "next": "Next",
      "no": "No",
      "noAccountExists": "Your account {accountName} does not match any user records in Ilios. If you need further assistance, please contact your school’s Ilios administrator.",
      "noAssociatedCompetencies": "No Associated Competencies",
      "noAssociatedCompetency": "No Associated Competency",
      "noAvailableCohorts": "No available cohorts",
      "noAvailableLearnerGroups": "No learner groups available; No cohort is attached to this course.",
      "noCohorts": "There are no cohorts in this course",
      "noCourseLearningMaterialsAvailable": "No Course Learning Materials Available",
      "noCourses": "There are no courses in this school",
      "noCurriculumInventoryInstitutionalInfo": "No institutional information has been configured for this school.",
      "noLearnerGroups": "No learner groups",
      "noOfferingLearnerGroups": "No Groups",
      "noOfferings": "This session has no offerings",
      "noPermissionsInOtherSchools": "You do not have the necessary permissions to modify access for this user in any other schools.",
      "noPopulateGroup": "No!  Leave it empty, I'll add students myself.",
      "noPrintDraft": "Courses which are in draft status cannot be printed",
      "noProgramYears": "There are no program years in this program",
      "noResultsFound": "No Results Found",
      "noSearchResults": "Your search did not return any results",
      "noSearchResultsPrompt": "no results",
      "noSelectedCompetencies": "There are no selected competencies",
      "noSelectedLearnerGroups": "There are no selected learner groups",
      "noSequenceBlocks": "There are no sequence blocks in this report.",
      "noSessionLearningMaterialsAvailable": "No Session Learning Materials Available",
      "noSessions": "There are no sessions in this course",
      "noSubSequenceBlocks": "This sequence block has no nested sequence blocks.",
      "noSubgroups": "There are no subgroups in this learner group",
      "noUnselectedCompetencies": "There are no unselected competencies",
      "noUnselectedLearnerGroupsInCohort": "There are no unselected learner groups in this cohort",
      "nonClerkshipSequenceBlocks": "Non-Clerkship Sequence Blocks",
      "nonStudent": "Non-Student",
      "none": "None",
      "notAClerkship": "Not a Clerkship",
      "notApplicableAbbr": "n/a",
      "notFoundMessage": "Rats! I couldn't find that. Please check your page address, and try again.",
      "notPublished": "Not Published",
      "notes": "Notes",
      "numberOfEvents": "Number of Events",
      "numberOfEventsFeaturingThisAsThePrimaryMethod": "Number of Events Featuring This as the Primary Method",
      "numberOfExams": "Number of Exams",
      "numberOfFormalInstructionalHoursPerCourse": "Number of Formal Instructional Hours Per Course",
      "numberOfFormativeAssessments": "Number of Formative Assessments",
      "numberOfGroups": "Number of Groups",
      "numberOfGroupsToGenerate": "Number of groups to generate.",
      "numberOfNonPrimaryOccurrencesOfThisMethod": "Number of Non-Primary Occurrences of This Method",
      "numberOfSummativeAssessments": "Number of Summative Assessments",
      "objective": "Objective",
      "objectiveCompetencyManagerTitle": "Select Parent Competency for Objective",
      "objectiveCount": "There {count, plural, =1 {is 1 objective} other {are # objectives}}",
      "objectiveDescriptorTitle": "Select MeSH Descriptors for Objective",
      "objectiveMap": "Objective Map",
      "objectiveParentTitle": "Select Parent Objectives",
      "objectiveParentTitleSingular": "Select Parent Objective",
      "objectiveParentsTitle": "Select Parent Objectives",
      "objectiveTitle": "Session Objective",
      "objectives": "Objectives",
      "objectivesTotalInstructionalTime": "Objectives: Total Instructional Time",
      "objectivesWithNoHours": "The following course objectives are either not linked to any session, or only linked to sessions without offering or duration data.",
      "objectivesWithNoLink": "No Course Objectives Currently Linked to Instructional Time.",
      "off": "Off",
      "offering": "Offering",
      "offerings": "Offerings",
      "on": "On",
      "openSmallGroupGenerator": "Open Offering Small Group Generator",
      "optional": "Optional",
      "optionalElective": "Optional (elective)",
      "orderInSequence": "Order in Sequence",
      "ordered": "Ordered",
      "other": "Other",
      "otherId": "Other ID",
      "otherVisualizations": "Other Visualizations",
      "overview": "Overview",
      "ownedBy": "owned by {owner}",
      "owner": "Owner",
      "pagedResultsCount": "Showing {start} - {end} of {total}",
      "parallel": "Parallel",
      "parentCount": "{count, plural, =1 {1 has a parent} other {# have parents}}",
      "parentObjectives": "Parent Objectives",
      "password": "Password",
      "pendingUpdatesSummary": "{count, plural, =1 {There is one user needing attention} other {There are # users needing attention}}",
      "pendingUpdatesSummaryTitle": "Updates from the Campus Directory",
      "pendingUserUpdates": {
        "emailMismatch": "The email address in the directory ({value}) does not match the email in ilios ({email}).",
        "filterBy": "Filter by user name",
        "missingFromDirectory": "Unable to find user in the directory, please update, disable, or exclude their account from syncronization.",
        "updateIlios": "Update Ilios"
      },
      "people": "People",
      "perPage": "Per Page",
      "performsNonLearnerFunctions": "Performs Non-Learner Functions",
      "permissions": "Permissions",
      "permissionsInOtherSchools": "Permissions in Other Schools",
      "phone": "Phone",
      "physicianCompetencyReferenceSet": "Physician Competency Reference Set",
      "physicianCompetencyReferenceSetAbbr": "PCRS",
      "populateGroup": "Do you want to auto-populate this group?",
      "preWork": "Pre-work",
      "preferredEmail": "Preferred Email",
      "prerequisites": "Prerequisites",
      "prev": "Prev",
      "primary": "Primary",
      "primaryCohort": "Primary Cohort",
      "primarySchool": "Primary School",
      "printSummary": "Print Summary",
      "program": "Program",
      "programAndCohort": "Program/Cohort",
      "programCohorts": "Program Cohorts",
      "programExpectations": "Program Expectations",
      "programExpectationsId": "Program Expectations ID",
      "programLeadership": "Program Leadership",
      "programTitle": "Program Title",
      "programTitleFilterPlaceholder": "Filter by Program Title",
      "programTitlePlaceholder": "Enter a title for this program",
      "programTitleShort": "Program Title (short)",
      "programYear": "Program Year",
      "programYearVisualizations": "Program Year Visualizations",
      "programYears": "Program Years",
      "programs": "Programs",
      "promoteToPrimaryCohort": "Promote to primary cohort",
      "publicationStatus": "Publication Status",
      "publishAllConfirmation": "Publish {publishCount}, schedule {scheduleCount}, and ignore {ignoreCount} sessions",
      "publishAllSessions": "Publish {sessionCount} sessions",
      "publishAsIs": "Publish As-is",
      "publishCourse": "Publish Course",
      "publishProgram": "Publish Program",
      "publishProgramYear": "Publish Program Year",
      "publishSession": "Publish Session",
      "published": "Published",
      "publishedSuccessfully": "Published Successfully",
      "read": "Read",
      "reconnectNow": "Reconnect Now",
      "reconnectionSeconds": "{count, plural, =1 {Reconnecting in 1 second...} other {Reconnecting in # seconds...}}",
      "refreshIcsFeedKey": "Refresh the ICS Feed key for this user",
      "refreshTheBrowser": "Refresh the Browser",
      "remove": "Remove",
      "removeAll": "Remove All",
      "removeCohort": "Remove Cohort",
      "removeLearnerToCohort": "{count, plural, =1 {Remove learner to {cohort}} other {Remove # learners to {cohort}}}",
      "removePrimaryCohort": "Remove Primary Cohort",
      "removedSuccessfully": "Removed Successfully",
      "reportConfirmRemove": "Are you sure you want to delete this report? This action cannot be undone.",
      "reportDescriptionPlaceholder": "Please enter a brief description about this report.",
      "reportDisplayTitleWithObject": "All {subject} for {object} in {school}",
      "reportDisplayTitleWithoutObject": "All {subject} in {school}",
      "reportMissingInstructor": "Instructor is Required",
      "reportMissingMeshTerm": "MeSH Term is Required",
      "reportMissingObjectForInstructor": "Association is required when Instructor is the subject",
      "reportMissingObjectForMeshTerm": "Association is required when MeSH Term is the subject",
      "reportName": "Report Name",
      "reportNamePlaceholder": "Please enter a report name.",
      "reportTitle": "Report Title",
      "required": "Required",
      "requiredInTrack": "Required In Track",
      "resourceTypes": "Resource Types",
      "results": "Results",
      "resultsCount": "{count, plural, =1 {1 result} other {# results}}",
      "returnToPreviousPage": "Return to Previous Page",
      "reviewMissingItems": "Review {count} Missing Items",
      "reviewSessions": "Sessions Requiring Review",
      "root": "Root",
      "sampleFile": "Sample File",
      "saturday": "Saturday",
      "save": "Save",
      "saved": "New User Saved Successfully",
      "savedSuccessfully": "Saved Successfully",
      "scheduled": "Scheduled",
      "scheduledSuccessfully": "Scheduled Successfully",
      "school": "School",
      "schoolLeadership": "School Leadership",
      "schoolName": "School Name",
      "schools": "Schools",
      "search": "Search",
      "searchDirectory": "Search directory for new users",
      "searchForIliosUsers": "Find Ilios Users...",
      "searchPlaceholder": "Find Learning Material",
      "searchUsers": "Search for users",
      "secondaryCohorts": "Secondary Cohorts",
      "select": "Select",
      "selectAcademicYear": "Select Academic Year",
      "selectCohortsToAttach": "Select the cohort of students you wish to attach to the new course",
      "selectCourse": "Select a Course",
      "selectMeshFor": "Select MeSH for: {title}",
      "selectProgram": "Select a Program",
      "selectSchool": "Select School",
      "selectVocabulary": "Select Vocabulary",
      "selectYear": "Select Educational Year",
      "selected": "Selected",
      "selectedLearnerGroups": "Selected Learner Groups",
      "sequenceBlock": "Sequence Block",
      "sequenceBlockConfirmRemove": "Are you sure you want to delete this sequence block? This action cannot be undone.",
      "sequenceBlockDescriptionPlaceholder": "Please enter a description for this sequence block.",
      "sequenceBlockIsSelective": "This sequence block has been marked as a selective.",
      "sequenceBlockTitlePlaceholder": "Please enter a title for this sequence block.",
      "sequenceBlocks": "Sequence Blocks",
      "sequenceNumber": "Sequence #",
      "session": "Session",
      "sessionAdministration": "Session Administration",
      "sessionAttributes": "Session Attributes",
      "sessionIs": "Session Type: {type}",
      "sessionLearningMaterials": "Session Learning Materials",
      "sessionObjectives": "Session Objectives",
      "sessionTitle": "Session Title",
      "sessionTitleFilterPlaceholder": "Filter by title, type, or status",
      "sessionTitlePlaceholder": "Enter a title for this session",
      "sessionType": "Session Type",
      "sessionTypes": "Session Types",
      "sessionTypesFor": "Session Types for {subject}",
      "sessions": "Sessions",
      "showCalendar": "Show Calendar",
      "showFilters": "Show Filters",
      "showLearnerGroupEvents": "Show all events for assigned learner groups",
      "showMore": "Show more",
      "showNotesToStudents": "Show Notes to Students",
      "showResultsFor": "Show Results For",
      "showSessionEvents": "Show all other <em>{sessionTitle}</em> events",
      "showSubgroupEvents": "Show events for all subgroups",
      "singleDay": "Single Day",
      "singleGroup": "Single Group",
      "smallGroupMessage": "Please select at least one learner group to attach to your small group offering. If you wish to schedule this offering without groups, please select the 'offering' button above.",
      "smallGroups": "Small Groups",
      "sortMaterials": "Sort Materials",
      "sortObjectives": "Sort Objectives",
      "specialAttireIs_Required_": "Special Attire is <strong><em>required</em></strong>",
      "specialAttireRequired": "Special Attire Required",
      "specialEquipment": "Special Equipment",
      "specialEquipmentIs_Required_": "Special Equipment is <strong><em>required</em></strong>",
      "specialEquipmentRequired": "Special Equipment Required",
      "start": "Start",
      "startDate": "Start Date",
      "startOver": "Start Over",
      "startTime": "Start Time",
      "starts": "Starts",
      "stateOrProvince": "State or Province",
      "status": "Status",
      "stewardingSchoolsAndDepartments": "Stewarding Schools and Departments",
      "stewardsManageTitle": "Manage Stewards",
      "street": "Street",
      "strong": "Strong",
      "student": "Student",
      "subTerms": "Sub-terms",
      "subgroupName": "Subgroup Name",
      "subgroups": "Subgroups",
      "successfullyAddedTerm": "Term Added Successfully",
      "successfullyInvalidatedTokens": "All existing tokens were successfully invalidated",
      "successfullyRemovedTerm": "Term Deleted Successfully",
      "summary": "Summary",
      "sunday": "Sunday",
      "supplementalCurriculum": "Supplemental Curriculum",
      "table1ProgramExpectationsMappedToPcrs": "Table 1: Program Expectations Mapped to PCRS",
      "table2PrimaryInstructionalMethodByNonClerkshipSequenceBlock": "Table 2: Primary Instructional Method by Non-Clerkship Sequence Block",
      "table3aNonClerkshipSequenceBlockInstructionalTime": "Table 3-A: Non-Clerkship Sequence Block Instructional Time",
      "table3bClerkshipSequenceBlockInstructionalTime": "Table 3-B: Clerkship Sequence Block Instructional Time",
      "table4InstructionalMethodCounts": "Table 4: Instructional Method Counts",
      "table5NonClerkshipSequenceBlockAssessmentMethods": "Table 5: Non-Clerkship Sequence Block Assessment Methods",
      "table6ClerkshipSequenceBlockAssessmentMethods": "Table 6: Clerkship Sequence Block Assessment Methods",
      "table7AllEventsWithAssessmentsTaggedAsFormativeOrSummative": "Table 7: All Events with Assessments Tagged as Formative or Summative",
      "table8AllResourceTypes": "Table 8: All Resource Types",
      "targetCourse": "Target Course",
      "taughtBy": "Taught By {instructors}",
      "term": "Term",
      "termCount": "{count, plural, =1 {There is 1 term} other {There are # terms}}",
      "terms": "Terms",
      "termsBySessionType": "Terms by Session Type",
      "termsManageTitle": "Manage Terms",
      "thisReportIsNoLongerAvailable": "This report is no longer available.",
      "thursday": "Thursday",
      "time": "Time",
      "timedRelease": "Timed Release",
      "timedReleaseAddEndDate": "Add End Date",
      "timedReleaseAddStartDate": "Add Start Date",
      "timedReleaseClearEndDate": "Clear End Date",
      "timedReleaseClearStartDate": "Clear Start Date",
      "timedReleaseNoSchedule": "Available immediately when published",
      "timedReleaseOnlyEndDate": "Available until {endDate}",
      "timedReleaseOnlyStartDate": "Available: {startDate}",
      "timedReleaseStartAndEndDate": "Available: {startDate} and available until {endDate}",
      "title": "Title",
      "today": "Today",
      "token": "Token",
      "tokenInfo": "API Tokens are used to programmatically access Ilios data.  Your token is like a password - anyone who has it can make changes on your behalf.  Read more in the documentation at <a href=\"{apiDocsUrl}\">{apiDocsUrl}</a>.",
      "tooManyResults": "Search returned more than {count} results.  To see better results try your search again with more terms.",
      "total": "Total",
      "totalErrors": "{count, plural, =1 {There is an error} other {There are # errors}}",
      "totalIlmTime": "Total ILM Time {minutes} Minutes",
      "totalInstructionalTime": "Total Instructional Time {minutes} Minutes",
      "totalTime": "Total time",
      "totalWeeks": "Total Weeks",
      "transitionErrorMessage": "Sorry, we can’t get you where you asked to go: something seems to be broken...",
      "tryHarder": "Try Harder",
      "tuesday": "Tuesday",
      "type": "Type",
      "unPublishCourse": "UnPublish Course",
      "unPublishProgram": "UnPublish Program",
      "unPublishProgramYear": "UnPublish Program Year",
      "unPublishSession": "UnPublish Session",
      "unPublishedSuccessfully": "UnPublished Successfully",
      "unableToReconnect": "Unable to Reconnect!",
      "unableToSyncUser": "Unable to sync user from directory, please ensure the Campus ID is correct.",
      "unassigned": "Unassigned",
      "unassignedStudentsConfirmation": "{count, plural, =1 {Assign 1 selected user to} other {Assign # selected users to}}",
      "unassignedStudentsSummary": "{count, plural, =1 {There is one student needing assignment to a cohort} other {There are # students needing assignment to a cohort}}",
      "unassignedStudentsSummaryTitle": "Students Requiring Cohort Assignment",
      "undo": "Undo",
      "universalLocator": "Universal Locator",
      "unlinkedObjectives": "This course has unlinked objective(s)",
      "unordered": "Unordered",
      "unsupportedBrowserFailure": "Your browser is not officially supported by Ilios and cannot complete this action. Please try again using an up-to-date browser.",
      "unusedObjectives": "Unused Objectives",
      "upcomingActivities": "My Activities for the next {days} days",
      "upcomingMaterials": "My Learning Materials for the next {days} days",
      "updateNow": "Update Now",
      "updatePending": "Update Pending",
      "updateUserFromDirectory": "Update this user record from the campus directory.",
      "uploadDate": "Upload date",
      "uploadGroupAssignments": "Upload Group Assignments",
      "uploadUsers": "File with user data (csv, tsv, txt)",
      "uploadedGroup": "Uploaded Group",
      "url": "URL",
      "userExistsInGroupHierarchy": "User already exists in top-level group {groupTitle} or one of its subgroups.",
      "userExistsMultipleTimesInUpload": "This user already exists in the upload.",
      "userNotAddableFromDirectory": "This user is missing required information and cannot be added",
      "userNotInGroupCohort": "User is not in this group's cohort: {cohortTitle}",
      "userRole": "User Role",
      "userRoles": "User Roles",
      "username": "Username",
      "users": "Users",
      "usersCalendar": "{name}'s Calendar",
      "usersSelected": "{count, plural, =1 {1 user selected} other {# users selected}}",
      "validUntil": "When should this token stop working?",
      "validUsers": "Valid Users",
      "verificationPreviewFor": "Verification Preview for {name}",
      "view": "View",
      "viewAll": "View All",
      "vocabularies": "Vocabularies",
      "vocabulary": "Vocabulary",
      "vocabularyTermsFor": "Vocabulary Terms for {subject}",
      "waitSaving": "saving... one moment...",
      "weak": "Weak",
      "wednesday": "Wednesday",
      "week": "Week",
      "weekAtAGlance": "Week at a Glance",
      "weekOf": "Week of {date}",
      "weeks": "Weeks",
      "when": "When",
      "whichIs": "which is",
      "whitecoatsSlashSpecialAttire": "Whitecoats / special attire",
      "withXmoreOfferings": "with {count, plural, =1 {one more offering} other {# more offerings}}",
      "write": "Write",
      "year": "Year",
      "yes": "Yes",
      "yesPopulateGroup": "Yes!  Add entire cohort to my new group.",
      "zipCode": "ZIP Code"
    }
  };
  _exports.default = _default;
});