define("ilios/templates/components/myreports-list-item", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "5ZtrYfiO",
    "block": "{\"symbols\":[\"@onReportSelect\"],\"statements\":[[4,\"unless\",[[24,[\"loadTitle\",\"isRunning\"]]],null,{\"statements\":[[4,\"if\",[[24,[\"reporttitle\"]]],null,{\"statements\":[[0,\"    \"],[7,\"span\",false],[12,\"class\",\"link clickable\"],[3,\"action\",[[23,0,[]],[23,1,[]],[24,[\"report\",\"id\"]]]],[8],[0,\"\\n      \"],[5,\"fa-icon\",[],[[\"@icon\"],[\"external-link-square-alt\"]]],[0,\"\\n      \"],[1,[22,\"reporttitle\"],false],[0,\"\\n    \"],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[7,\"span\",true],[8],[0,\"\\n      \"],[1,[28,\"t\",[\"general.thisReportIsNoLongerAvailable\"],null],false],[0,\"\\n    \"],[9],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ilios/templates/components/myreports-list-item.hbs"
    }
  });

  _exports.default = _default;
});