define("ilios/controllers/curriculum-inventory-sequence-block", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    queryParams: ['sortSessionsBy'],
    sortSessionsBy: 'title',
    canUpdate: false,
    actions: {
      removeChildSequenceBlock(block) {
        let parent = this.model;
        block.destroyRecord().then(() => {
          parent.reload().then(parent => {
            parent.get('children').then(children => {
              children.invoke('reload');
            });
          });
        });
      }

    }
  });

  _exports.default = _default;
});