define("ilios/mixins/newuser", ["exports", "ilios-common/mixins/validation-error-display", "ember-concurrency", "moment"], function (_exports, _validationErrorDisplay, _emberConcurrency, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    filter
  } = Ember.RSVP;

  var _default = Ember.Mixin.create(_validationErrorDisplay.default, {
    store: Ember.inject.service(),
    currentUser: Ember.inject.service(),
    flashMessages: Ember.inject.service(),
    permissionChecker: Ember.inject.service(),

    init() {
      this._super(...arguments);

      this.loadCohorts.perform();
    },

    firstName: null,
    middleName: null,
    lastName: null,
    campusId: null,
    otherId: null,
    email: null,
    username: null,
    password: null,
    phone: null,
    schoolId: null,
    primaryCohortId: null,
    isSaving: false,
    nonStudentMode: true,
    schools: Ember.computed(async function () {
      const permissionChecker = this.permissionChecker;
      const store = this.store;
      const schools = await store.findAll('school', {
        reload: true
      });
      return filter(schools.toArray(), async school => {
        return permissionChecker.canCreateUser(school);
      });
    }),
    bestSelectedSchool: Ember.computed('schoolId', 'schools.[]', async function () {
      const schoolId = this.schoolId;
      const schools = await this.schools;

      if (schoolId) {
        const currentSchool = schools.findBy('id', schoolId);

        if (currentSchool) {
          return currentSchool;
        }
      }

      const user = await this.currentUser.model;
      return user.school;
    }),
    bestSelectedCohort: Ember.computed('bestSelectedSchool.cohorts.[]', 'primaryCohortId', async function () {
      const primaryCohortId = this.primaryCohortId;
      const school = await this.bestSelectedSchool;
      const cohorts = await school.cohorts;

      if (primaryCohortId) {
        const currentCohort = cohorts.findBy('id', primaryCohortId);

        if (currentCohort) {
          return currentCohort;
        }
      }

      return cohorts.lastObject;
    }),
    cohorts: Ember.computed.oneWay('loadCohorts.lastSuccessful.value'),
    loadCohorts: (0, _emberConcurrency.task)(function* () {
      let school = yield this.bestSelectedSchool;
      let cohorts = yield this.store.query('cohort', {
        filters: {
          schools: [school.get('id')]
        }
      }); //prefetch programYears and programs so that ember data will coalesce these requests.

      let programYears = yield Ember.RSVP.all(cohorts.getEach('programYear'));
      yield Ember.RSVP.all(programYears.getEach('program'));
      cohorts = cohorts.toArray();
      let all = [];

      for (let i = 0; i < cohorts.length; i++) {
        let cohort = cohorts[i];
        let obj = {
          id: cohort.get('id')
        };
        let programYear = yield cohort.get('programYear');
        let program = yield programYear.get('program');
        obj.title = program.get('title') + ' ' + cohort.get('title');
        obj.startYear = programYear.get('startYear');
        obj.duration = program.get('duration');
        all.pushObject(obj);
      }

      let lastYear = parseInt((0, _moment.default)().subtract(1, 'year').format('YYYY'), 10);
      return all.filter(obj => {
        let finalYear = parseInt(obj.startYear, 10) + parseInt(obj.duration, 10);
        return finalYear > lastYear;
      });
    }).restartable(),
    save: (0, _emberConcurrency.task)(function* () {
      this.send('addErrorDisplaysFor', ['firstName', 'middleName', 'lastName', 'campusId', 'otherId', 'email', 'phone', 'username', 'password']);
      let {
        validations
      } = yield this.validate();

      if (validations.get('isInvalid')) {
        return;
      }

      const {
        firstName,
        middleName,
        lastName,
        campusId,
        otherId,
        email,
        phone,
        username,
        password,
        store,
        nonStudentMode
      } = this;
      const roles = yield store.findAll('user-role');
      const school = yield this.bestSelectedSchool;
      const primaryCohort = yield this.bestSelectedCohort;
      let user = this.store.createRecord('user', {
        firstName,
        middleName,
        lastName,
        campusId,
        otherId,
        email,
        phone,
        school,
        enabled: true,
        root: false
      });

      if (!nonStudentMode) {
        user.set('primaryCohort', primaryCohort);
        let studentRole = roles.findBy('title', 'Student');
        user.set('roles', [studentRole]);
      }

      user = yield user.save();
      let authentication = this.store.createRecord('authentication', {
        user,
        username,
        password
      });
      yield authentication.save();
      this.flashMessages.success('general.saved');
      this.transitionToUser(user.get('id'));
      this.send('clearErrorDisplay');
    }).drop(),
    actions: {
      setSchool(id) {
        this.set('schoolId', id);
        this.loadCohorts.perform();
      },

      setPrimaryCohort(id) {
        this.set('primaryCohortId', id);
      }

    }
  });

  _exports.default = _default;
});