define("ilios/components/error-display", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['error-display'],
    errors: null,
    isOffline: false,
    now: null,
    showDetails: true,
    is404: Ember.computed('errors.[]', function () {
      const errors = this.errors;

      if (Ember.isPresent(errors)) {
        const firstError = errors.get('firstObject');

        if (Ember.isPresent(firstError)) {
          return firstError.statusCode === '404';
        }
      }

      return false;
    }),

    didReceiveAttrs() {
      this._super(...arguments);

      this.set('now', new Date());

      if (!navigator.onLine) {
        this.set('isOffline', true);
      }
    },

    actions: {
      toggleDetails() {
        this.set('showDetails', !this.showDetails);
      },

      refresh() {
        window.location.reload();
      }

    }
  });

  _exports.default = _default;
});