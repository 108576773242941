define("ilios/routes/search", ["exports", "ember-simple-auth/mixins/authenticated-route-mixin"], function (_exports, _authenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    currentUser: Ember.inject.service(),
    iliosConfig: Ember.inject.service(),

    async beforeModel() {
      const searchEnabled = await this.iliosConfig.searchEnabled;

      if (!searchEnabled || !this.currentUser.performsNonLearnerFunction) {
        this.transitionTo('dashboard');
      }
    }

  });

  _exports.default = _default;
});