define("ilios/components/learnergroup-header", ["exports", "ember-concurrency", "ember-cp-validations", "ilios-common/mixins/validation-error-display"], function (_exports, _emberConcurrency, _emberCpValidations, _validationErrorDisplay) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const Validations = (0, _emberCpValidations.buildValidations)({
    title: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('length', {
      min: 3,
      max: 60
    })]
  });

  var _default = Ember.Component.extend(Validations, _validationErrorDisplay.default, {
    classNames: ['learnergroup-header'],
    tagName: 'header',
    'data-test-learnergroup-header': true,
    canUpdate: false,
    learnerGroup: null,
    title: null,

    didReceiveAttrs() {
      this._super(...arguments);

      const learnerGroup = this.learnerGroup;

      if (learnerGroup) {
        this.set('title', learnerGroup.get('title'));
      }
    },

    actions: {
      revertTitleChanges() {
        const learnerGroup = this.learnerGroup;
        this.set('title', learnerGroup.get('title'));
      }

    },
    changeTitle: (0, _emberConcurrency.task)(function* () {
      const learnerGroup = this.learnerGroup;
      const newTitle = this.title;
      this.send('addErrorDisplayFor', 'title');
      const {
        validations
      } = yield this.validate();

      if (validations.get('isValid')) {
        this.send('removeErrorDisplayFor', 'title');
        learnerGroup.set('title', newTitle);
        yield learnerGroup.save();
        this.set('title', learnerGroup.get('title'));
      } else {
        throw false;
      }
    })
  });

  _exports.default = _default;
});