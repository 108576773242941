define("ilios/routes/weeklyevents", ["exports", "ilios-common/routes/weeklyevents"], function (_exports, _weeklyevents) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _weeklyevents.default;
    }
  });
});