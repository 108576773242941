define("ilios/controllers/dashboard", ["exports", "ilios-common/mixins/dashboard-controller"], function (_exports, _dashboardController) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend(_dashboardController.default, {
    queryParams: ['report', 'reportYear'],
    report: null,
    reportYear: '',
    selectedReport: Ember.computed('report', async function () {
      if (!this.report) {
        return null;
      }

      const report = this.store.peekRecord('report', this.report);
      return report ? report : await this.store.findRecord('report', this.report);
    })
  });

  _exports.default = _default;
});