define("ilios/routes/instructor-group", ["exports", "ember-simple-auth/mixins/authenticated-route-mixin"], function (_exports, _authenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    permissionChecker: Ember.inject.service(),
    canUpdate: false,

    async afterModel(instructorGroup) {
      const permissionChecker = this.permissionChecker;
      const canUpdate = await permissionChecker.canUpdateInstructorGroup(instructorGroup);
      this.set('canUpdate', canUpdate);
    },

    setupController(controller, model) {
      this._super(controller, model);

      controller.set('canUpdate', this.canUpdate);
    }

  });

  _exports.default = _default;
});