define("ilios/components/course-director-manager", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['course-director-manager'],
    'data-test-course-director-manager': true,
    course: null,
    directors: null,
    actions: {
      addDirector(user) {
        this.directors.pushObject(user);
      },

      removeDirector(user) {
        this.directors.removeObject(user);
      }

    },
    saveChanges: (0, _emberConcurrency.task)(function* () {
      yield (0, _emberConcurrency.timeout)(10); //small timeout so spinner has time to load

      const directors = this.directors;
      yield this.save(directors);
    }).drop()
  });

  _exports.default = _default;
});