define("ilios/components/my-profile", ["exports", "moment", "ember-concurrency", "ember-pad/utils/pad"], function (_exports, _moment, _emberConcurrency, _pad) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    commonAjax: Ember.inject.service(),
    flashMessages: Ember.inject.service(),
    iliosConfig: Ember.inject.service(),
    session: Ember.inject.service(),
    classNames: ['my-profile'],
    expiresAt: null,
    generatedJwt: null,
    maxDate: null,
    minDate: null,
    user: null,
    host: Ember.computed.reads('iliosConfig.apiHost'),
    namespace: Ember.computed.reads('iliosConfig.apiNameSpace'),
    apiDocsUrl: Ember.computed('host', 'namespace', function () {
      let apiPath = '/' + this.namespace;
      let host = this.host ? this.host : window.location.protocol + '//' + window.location.host;
      let docPath = host + apiPath.replace('v1', 'doc');
      return docPath;
    }),

    init() {
      this._super(...arguments);

      this.reset();
    },

    actions: {
      nothing() {//noop action to pass to profile components
      },

      tokenCopied() {
        const flashMessages = this.flashMessages;
        flashMessages.success('general.copiedSuccessfully');
      },

      reset() {
        this.reset();
      },

      selectExpiresAtDate(selectedDate) {
        this.set('expiresAt', selectedDate);
      }

    },

    reset() {
      let midnightToday = (0, _moment.default)().hour(23).minute(59).second(59);
      let twoWeeksFromNow = midnightToday.clone().add(2, 'weeks');
      let oneYearFromNow = midnightToday.clone().add(1, 'year');
      this.set('minDate', midnightToday.toDate());
      this.set('maxDate', oneYearFromNow.toDate());
      this.set('expiresAt', twoWeeksFromNow.toDate());
      this.set('generatedJwt', null);
    },

    createNewToken: (0, _emberConcurrency.task)(function* () {
      yield (0, _emberConcurrency.timeout)(10); //small delay to allow rendering the spinner

      let selection = this.expiresAt;
      let expiresAt = (0, _moment.default)(selection).hour(23).minute(59).second(59);
      const now = (0, _moment.default)();
      const days = (0, _pad.padStart)(expiresAt.diff(now, 'days'), 2, '0');
      const hours = (0, _pad.padStart)((0, _moment.default)().hours(23).diff(now, 'hours'), 2, '0');
      const minutes = (0, _pad.padStart)((0, _moment.default)().minutes(59).diff(now, 'minutes'), 2, '0');
      const seconds = (0, _pad.padStart)((0, _moment.default)().seconds(59).diff(now, 'seconds'), 2, '0');
      let interval = "P".concat(days, "DT").concat(hours, "H").concat(minutes, "M").concat(seconds, "S");
      const commonAjax = this.commonAjax;
      let url = '/auth/token?ttl=' + interval;
      let data = yield commonAjax.request(url);
      this.set('generatedJwt', data.jwt);
    }),
    invalidateTokens: (0, _emberConcurrency.task)(function* () {
      yield (0, _emberConcurrency.timeout)(10); //small delay to allow rendering the spinner

      const commonAjax = this.commonAjax;
      let url = '/auth/invalidatetokens';
      let data = yield commonAjax.request(url);

      if (Ember.isPresent(data.jwt)) {
        const flashMessages = this.flashMessages;
        const session = this.session;
        let authenticator = 'authenticator:ilios-jwt';
        session.authenticate(authenticator, {
          jwt: data.jwt
        });
        flashMessages.success('general.successfullyInvalidatedTokens');
        this.toggleShowInvalidateTokens();
      }
    })
  });

  _exports.default = _default;
});