define("ilios/templates/components/new-learnergroup", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "h7azG0Pd",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"detail-content\"],[8],[0,\"\\n\"],[4,\"if\",[[24,[\"multiModeSupported\"]]],null,{\"statements\":[[0,\"    \"],[7,\"div\",true],[10,\"class\",\"multi-mode-chooser\"],[8],[0,\"\\n      \"],[7,\"label\",true],[8],[0,\"\\n        \"],[1,[28,\"t\",[\"general.createNew\"],null],false],[0,\":\\n      \"],[9],[0,\"\\n      \"],[5,\"click-choice-buttons\",[],[[\"@buttonContent1\",\"@buttonContent2\",\"@firstChoicePicked\",\"@toggle\"],[[28,\"t\",[\"general.singleGroup\"],null],[28,\"t\",[\"general.multipleGroups\"],null],[22,\"singleMode\"],[28,\"action\",[[23,0,[]],[28,\"mut\",[[24,[\"singleMode\"]]],null]],null]]]],[0,\"\\n    \"],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[7,\"h4\",true],[8],[0,\"\\n      \"],[1,[28,\"t\",[\"general.newLearnerGroup\"],null],false],[0,\"\\n    \"],[9],[0,\"\\n\"]],\"parameters\":[]}],[4,\"if\",[[24,[\"singleMode\"]]],null,{\"statements\":[[0,\"    \"],[5,\"new-learnergroup-single\",[],[[\"@save\",\"@cancel\",\"@fillModeSupported\"],[[22,\"save\"],[22,\"cancel\"],[22,\"fillModeSupported\"]]]],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[5,\"new-learnergroup-multiple\",[],[[\"@generateNewLearnerGroups\",\"@cancel\"],[[28,\"action\",[[23,0,[]],\"generateNewLearnerGroups\"],null],[22,\"cancel\"]]]],[0,\"\\n\"]],\"parameters\":[]}],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ilios/templates/components/new-learnergroup.hbs"
    }
  });

  _exports.default = _default;
});