define("ilios/templates/components/connection-status", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Tj1Ya73H",
    "block": "{\"symbols\":[],\"statements\":[[4,\"liquid-if\",[[24,[\"unableToReconnect\"]]],[[\"class\"],[\"crossFade\"]],{\"statements\":[[0,\"  \"],[7,\"span\",true],[8],[0,\"\\n    \"],[5,\"fa-icon\",[],[[\"@icon\"],[\"exclamation-triangle\"]]],[0,\"\\n    \"],[1,[28,\"t\",[\"general.unableToReconnect\"],null],false],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[7,\"span\",true],[8],[0,\"\\n    \"],[5,\"fa-icon\",[],[[\"@icon\"],[\"exclamation-circle\"]]],[0,\"\\n    \"],[1,[28,\"t\",[\"general.connectionLost\"],null],false],[0,\"\\n\"],[4,\"unless\",[[24,[\"stopAttemptingToReconnect\"]]],null,{\"statements\":[[0,\"      \"],[1,[28,\"t\",[\"general.reconnectionSeconds\"],[[\"count\"],[[24,[\"timer\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"  \"],[9],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"buttons\"],[8],[0,\"\\n    \"],[7,\"button\",false],[3,\"action\",[[23,0,[]],[28,\"perform\",[[24,[\"reconnect\"]],true],null]]],[8],[0,\"\\n      \"],[1,[28,\"t\",[\"general.reconnectNow\"],null],false],[0,\"\\n    \"],[9],[0,\"\\n\"],[4,\"unless\",[[24,[\"stopAttemptingToReconnect\"]]],null,{\"statements\":[[0,\"      \"],[7,\"button\",false],[3,\"action\",[[23,0,[]],[28,\"toggle\",[\"stopAttemptingToReconnect\",[23,0,[]]],null]]],[8],[0,\"\\n        \"],[1,[28,\"t\",[\"general.ignore\"],null],false],[0,\"\\n      \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"  \"],[9],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ilios/templates/components/connection-status.hbs"
    }
  });

  _exports.default = _default;
});