define("ilios/components/new-curriculum-inventory-report", ["exports", "ember-cp-validations", "ilios-common/mixins/validation-error-display"], function (_exports, _emberCpValidations, _validationErrorDisplay) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const Validations = (0, _emberCpValidations.buildValidations)({
    name: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('length', {
      max: 60,
      descriptionKey: 'general.reportName'
    })]
  });

  var _default = Ember.Component.extend(Validations, _validationErrorDisplay.default, {
    store: Ember.inject.service(),
    classNames: ['new-curriculum-inventory-report'],
    currentProgram: null,
    isSaving: false,
    selectedYear: null,
    title: null,
    years: null,

    didReceiveAttrs() {
      this._super(...arguments);

      let years = [];
      const currentYear = new Date().getFullYear();

      for (let i = currentYear - 5, n = currentYear + 5; i <= n; i++) {
        let title = i + ' - ' + (i + 1);
        let year = Ember.Object.create({
          'id': i,
          'title': title
        });
        years.pushObject(year);
      }

      const selectedYear = years.findBy('id', currentYear);
      this.setProperties({
        years,
        selectedYear,
        isSaving: false
      });
    },

    actions: {
      save() {
        this.set('isSaving', true);
        this.send('addErrorDisplayFor', 'name');
        this.validate().then(({
          validations
        }) => {
          if (validations.get('isValid')) {
            let year = parseInt(this.selectedYear.get('id'), 10);
            let report = this.store.createRecord('curriculumInventoryReport', {
              name: this.name,
              program: this.currentProgram,
              year: year,
              startDate: new Date(year, 6, 1),
              endDate: new Date(year + 1, 5, 30),
              description: this.description
            });
            this.save(report).finally(() => {
              this.set('isSaving', false);
            });
          } else {
            this.set('isSaving', false);
          }
        });
      },

      cancel() {
        this.cancel();
      }

    },

    keyUp(event) {
      const keyCode = event.keyCode;
      const target = event.target;

      if ('text' !== target.type) {
        return;
      }

      if (13 === keyCode) {
        this.send('save');
        return;
      }

      if (27 === keyCode) {
        this.send('cancel');
      }
    }

  });

  _exports.default = _default;
});