define("ilios/templates/components/locale-chooser", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "wybz4oHK",
    "block": "{\"symbols\":[\"loc\"],\"statements\":[[5,\"click-outside\",[],[[\"@onClickOutside\"],[[28,\"action\",[[23,0,[]],[28,\"mut\",[[24,[\"isOpen\"]]],null],false],null]]],{\"statements\":[[0,\"\\n  \"],[7,\"button\",false],[12,\"aria-label\",[28,\"t\",[[28,\"concat\",[\"general.language.\",[24,[\"locale\",\"id\"]]],null]],null]],[12,\"role\",\"menuitem\"],[12,\"class\",\"toggle\"],[12,\"aria-haspopup\",\"true\"],[12,\"aria-expanded\",[28,\"if\",[[24,[\"isOpen\"]],\"true\",\"false\"],null]],[12,\"data-level\",\"toggle\"],[3,\"action\",[[23,0,[]],\"toggleMenu\"]],[8],[0,\"\\n    \"],[5,\"fa-icon\",[],[[\"@icon\"],[\"globe\"]]],[0,\"\\n    \"],[7,\"span\",true],[8],[0,\"\\n      \"],[1,[28,\"t\",[[28,\"concat\",[\"general.language.\",[24,[\"locale\",\"id\"]]],null]],null],false],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"],[4,\"if\",[[24,[\"isOpen\"]]],null,{\"statements\":[[0,\"    \"],[7,\"div\",true],[10,\"class\",\"menu\"],[10,\"role\",\"menu\"],[8],[0,\"\\n\"],[4,\"each\",[[24,[\"locales\"]]],null,{\"statements\":[[0,\"        \"],[7,\"button\",false],[12,\"role\",\"menuitemradio\"],[12,\"lang\",[23,1,[\"id\"]]],[12,\"tabindex\",\"-1\"],[12,\"aria-checked\",[28,\"if\",[[28,\"eq\",[[24,[\"locale\",\"id\"]],[23,1,[\"id\"]]],null],\"true\",\"false\"],null]],[12,\"data-level\",\"item\"],[3,\"action\",[[23,0,[]],\"changeLocale\",[23,1,[\"id\"]]]],[8],[0,\"\\n          \"],[1,[23,1,[\"text\"]],false],[0,\"\\n        \"],[9],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"    \"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ilios/templates/components/locale-chooser.hbs"
    }
  });

  _exports.default = _default;
});