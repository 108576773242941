define("ilios/helpers/is-equal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.isEqual = isEqual;
  _exports.default = void 0;
  const {
    once
  } = Ember.run;

  function isEqual(params) {
    const validate = Ember.isBlank(params) || Ember.isBlank(params[0]) || Ember.isBlank(params[1]);
    return validate ? false : params[0] === params[1];
  }

  var _default = Ember.Helper.extend({
    value1: null,
    value2: null,

    compute([value1 = null, value2 = null]) {
      this.setProperties({
        value1,
        value2
      });
      return isEqual([value1, value2]);
    },

    /* eslint ember/no-observers: 0 */
    recomputeOnArrayChange: Ember.observer('value1', 'value2', function () {
      once(this, () => {
        this.recompute();
      });
    })
  });

  _exports.default = _default;
});