define("ilios/templates/components/course-director-manager", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "nBsamgBO",
    "block": "{\"symbols\":[\"user\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"actions\"],[8],[0,\"\\n  \"],[7,\"button\",false],[12,\"class\",\"bigadd\"],[3,\"action\",[[23,0,[]],[28,\"perform\",[[24,[\"saveChanges\"]]],null]]],[8],[0,\"\\n    \"],[5,\"fa-icon\",[],[[\"@icon\",\"@spin\"],[[28,\"if\",[[24,[\"saveChanges\",\"isRunning\"]],\"spinner\",\"check\"],null],[28,\"if\",[[24,[\"saveChanges\",\"isRunning\"]],true,false],null]]]],[0,\"\\n  \"],[9],[0,\"\\n  \"],[7,\"button\",false],[12,\"class\",\"bigcancel\"],[3,\"action\",[[23,0,[]],[24,[\"close\"]]]],[8],[0,\"\\n    \"],[5,\"fa-icon\",[],[[\"@icon\"],[\"undo\"]]],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"],[7,\"div\",true],[10,\"class\",\"selected-directors\"],[8],[0,\"\\n  \"],[7,\"ul\",true],[8],[0,\"\\n\"],[4,\"each\",[[28,\"sort-by\",[\"lastName\",[24,[\"directors\"]]],null]],null,{\"statements\":[[0,\"      \"],[7,\"li\",false],[3,\"action\",[[23,0,[]],\"removeDirector\",[23,1,[]]]],[8],[0,\"\\n        \"],[1,[23,1,[\"fullName\"]],false],[0,\"\\n        \"],[5,\"fa-icon\",[[12,\"class\",\"remove\"]],[[\"@icon\"],[\"times\"]]],[0,\"\\n      \"],[9],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"],[5,\"user-search\",[],[[\"@addUser\",\"@roles\",\"@currentlyActiveUsers\"],[[28,\"action\",[[23,0,[]],\"addDirector\"],null],\"1\",[22,\"directors\"]]]]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ilios/templates/components/course-director-manager.hbs"
    }
  });

  _exports.default = _default;
});