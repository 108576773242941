define("ilios/components/curriculum-inventory-report-overview", ["exports", "ember-cp-validations", "ilios-common/mixins/validation-error-display", "ilios-common/utils/scroll-to"], function (_exports, _emberCpValidations, _validationErrorDisplay, _scrollTo) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const Validations = (0, _emberCpValidations.buildValidations)({
    description: [(0, _emberCpValidations.validator)('length', {
      min: 0,
      max: 21844,
      allowBlank: true
    })],
    startDate: [(0, _emberCpValidations.validator)('date', {
      dependentKeys: ['model.endDate'],
      onOrBefore: Ember.computed.reads('model.endDate')
    })],
    endDate: [(0, _emberCpValidations.validator)('date', {
      dependentKeys: ['model.startDate'],
      onOrAfter: Ember.computed.reads('model.startDate')
    })]
  });

  var _default = Ember.Component.extend(Validations, _validationErrorDisplay.default, {
    currentUser: Ember.inject.service(),
    permissionChecker: Ember.inject.service(),
    router: Ember.inject.service(),
    routing: Ember.inject.service('-routing'),
    classNames: ['curriculum-inventory-report-overview'],
    tagName: 'section',
    canUpdate: false,
    currentRoute: '',
    description: null,
    endDate: null,
    report: null,
    startDate: null,
    year: null,
    yearOptions: null,
    yearLabel: Ember.computed('year', function () {
      const year = this.year;
      return year + ' - ' + (parseInt(year, 10) + 1);
    }),
    showRollover: Ember.computed('report.program', 'currentUser', 'routing.currentRouteName', async function () {
      const routing = this.routing;

      if (routing.get('currentRouteName') === 'curriculumInventoryReport.rollover') {
        return false;
      }

      const permissionChecker = this.permissionChecker;
      const report = this.report;
      const program = await report.get('program');
      const school = await program.get('school');
      return await permissionChecker.canCreateCurriculumInventoryReport(school);
    }),

    didReceiveAttrs() {
      this._super(...arguments);

      const report = this.report;
      const description = report.get('description');
      const currentYear = new Date().getFullYear();
      const year = report.get('year');
      const yearLabel = report.get('yearLabel');
      const startDate = report.get('startDate');
      const endDate = report.get('endDate');
      let yearOptions = [];
      yearOptions.pushObject(Ember.Object.create({
        'id': year,
        'title': yearLabel
      }));

      for (let i = currentYear - 5, n = currentYear + 5; i <= n; i++) {
        if (i != year) {
          yearOptions.pushObject(Ember.Object.create({
            'id': i,
            'title': i + ' - ' + (i + 1)
          }));
        }
      }

      yearOptions = yearOptions.uniq().sortBy('title');
      this.setProperties({
        description,
        yearOptions,
        startDate,
        endDate,
        year
      });
    },

    actions: {
      async changeStartDate() {
        const {
          report,
          startDate
        } = this.getProperties('report', 'startDate');
        this.send('addErrorDisplayFor', 'startDate');
        const {
          validations
        } = await this.validate();

        if (validations.isValid) {
          this.send('removeErrorDisplayFor', 'startDate');
          report.set('startDate', startDate);
          const newCourse = await report.save();
          this.set('startDate', newCourse.startDate);
          this.set('report', newCourse);
        } else {
          await Ember.RSVP.reject();
        }
      },

      revertStartDateChanges() {
        const report = this.report;
        this.set('startDate', report.get('startDate'));
      },

      async changeEndDate() {
        const {
          endDate,
          report
        } = this.getProperties('endDate', 'report');
        this.send('addErrorDisplayFor', 'endDate');
        const {
          validations
        } = await this.validate();

        if (validations.isValid) {
          this.send('removeErrorDisplayFor', 'endDate');
          report.set('endDate', endDate);
          const newCourse = await report.save();
          this.set('endDate', newCourse.endDate);
          this.set('report', newCourse);
        } else {
          await Ember.RSVP.reject();
        }
      },

      revertEndDateChanges() {
        const report = this.report;
        this.set('endDate', report.get('endDate'));
      },

      changeYear() {
        let report = this.report;
        let year = this.year;
        report.set('year', year);
        report.save();
      },

      revertYearChanges() {
        this.set('year', this.report.get('year'));
      },

      async changeDescription() {
        const newDescription = this.description;
        const report = this.report;
        this.send('addErrorDisplayFor', 'description');
        const {
          validations
        } = await this.validate();

        if (validations.isValid) {
          this.send('removeErrorDisplayFor', 'description');
          report.set('description', newDescription);
          const newReport = await report.save();
          this.set('description', newReport.description);
          this.set('report', newReport);
        } else {
          await Ember.RSVP.reject();
        }
      },

      revertDescriptionChanges() {
        const report = this.report;
        this.set('description', report.get('description'));
      },

      transitionToRollover() {
        this.router.transitionTo('curriculumInventoryReport.rollover', this.report);
        (0, _scrollTo.default)('.rollover-form');
      }

    }
  });

  _exports.default = _default;
});