define("ilios/components/curriculum-inventory-verification-preview", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    commonAjax: Ember.inject.service(),
    iliosConfig: Ember.inject.service(),
    'data-test-curriculum-inventory-verification-preview': true,
    classNames: ['curriculum-inventory-verification-preview'],
    report: null,
    tables: null,
    tocId: 'verification-preview-toc',
    host: Ember.computed.reads('iliosConfig.apiHost'),
    namespace: Ember.computed.reads('iliosConfig.apiNameSpace'),

    didInsertElement() {
      this._super(...arguments);

      this.loadTables.perform();
    },

    loadTables: (0, _emberConcurrency.task)(function* () {
      const commonAjax = this.commonAjax;
      const reportId = this.report.id;
      const host = this.host ? this.host : '';
      const namespace = this.namespace;
      const url = host + '/' + namespace + "/curriculuminventoryreports/".concat(reportId, "/verificationpreview");
      const data = yield commonAjax.request(url);
      this.set('tables', data.preview);
    })
  });

  _exports.default = _default;
});