define("ilios/components/simple-chart-donut", ["exports", "ember-simple-charts/components/simple-chart-donut"], function (_exports, _simpleChartDonut) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _simpleChartDonut.default;
    }
  });
});