define("ilios/templates/components/curriculum-inventory-sequence-block-header", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "FZolN/DQ",
    "block": "{\"symbols\":[\"isSaving\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"title\"],[8],[0,\"\\n\"],[4,\"if\",[[24,[\"canUpdate\"]]],null,{\"statements\":[[0,\"    \"],[5,\"editable-field\",[],[[\"@tagName\",\"@value\",\"@save\",\"@close\",\"@saveOnEnter\",\"@closeOnEscape\"],[\"h2\",[22,\"blockTitle\"],[28,\"action\",[[23,0,[]],\"changeTitle\"],null],[28,\"action\",[[23,0,[]],\"revertTitleChanges\"],null],true,true]],{\"statements\":[[0,\"\\n      \"],[7,\"input\",true],[11,\"value\",[22,\"blockTitle\"]],[11,\"oninput\",[28,\"action\",[[23,0,[]],[28,\"mut\",[[24,[\"blockTitle\"]]],null]],[[\"value\"],[\"target.value\"]]]],[11,\"disabled\",[23,1,[]]],[11,\"onkeyup\",[28,\"action\",[[23,0,[]],\"addErrorDisplayFor\",\"blockTitle\"],null]],[10,\"type\",\"text\"],[8],[9],[0,\"\\n\"],[4,\"if\",[[28,\"and\",[[28,\"get\",[[28,\"get\",[[28,\"get\",[[28,\"get\",[[23,0,[]],\"validations\"],null],\"attrs\"],null],\"blockTitle\"],null],\"isInvalid\"],null],[28,\"is-in\",[[24,[\"showErrorsFor\"]],\"blockTitle\"],null]],null]],null,{\"statements\":[[0,\"        \"],[7,\"span\",true],[10,\"class\",\"validation-error-message\"],[8],[0,\"\\n          \"],[1,[28,\"get\",[[28,\"get\",[[28,\"get\",[[28,\"get\",[[23,0,[]],\"validations\"],null],\"attrs\"],null],\"blockTitle\"],null],\"message\"],null],false],[0,\"\\n        \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"    \"]],\"parameters\":[1]}],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[7,\"h2\",true],[8],[0,\"\\n      \"],[5,\"fa-icon\",[],[[\"@icon\"],[\"lock\"]]],[0,\"\\n      \"],[1,[24,[\"sequenceBlock\",\"title\"]],false],[0,\"\\n    \"],[9],[0,\"\\n\"]],\"parameters\":[]}],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ilios/templates/components/curriculum-inventory-sequence-block-header.hbs"
    }
  });

  _exports.default = _default;
});