define("ilios/templates/course-visualize-instructors", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Zd9TvFRr",
    "block": "{\"symbols\":[],\"statements\":[[7,\"section\",true],[10,\"class\",\"course-visualize-instructors\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"backtolink\"],[8],[0,\"\\n    \"],[5,\"link-to\",[],[[\"@route\",\"@model\"],[\"course-visualizations\",[22,\"model\"]]],{\"statements\":[[0,\"\\n      \"],[1,[28,\"t\",[\"general.otherVisualizations\"],null],false],[0,\"\\n    \"]],\"parameters\":[]}],[0,\"\\n  \"],[9],[0,\"\\n  \"],[7,\"h2\",true],[10,\"class\",\"clickable\"],[8],[0,\"\\n    \"],[5,\"link-to\",[],[[\"@route\",\"@model\"],[\"course\",[22,\"model\"]]],{\"statements\":[[0,\"\\n      \"],[1,[24,[\"model\",\"title\"]],false],[0,\"\\n      \"],[1,[24,[\"model\",\"academicYear\"]],false],[0,\"\\n    \"]],\"parameters\":[]}],[0,\"\\n  \"],[9],[0,\"\\n  \"],[7,\"h3\",true],[8],[0,\"\\n    \"],[1,[28,\"t\",[\"general.instructors\"],null],false],[0,\"\\n  \"],[9],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"filter\"],[8],[0,\"\\n    \"],[7,\"input\",true],[11,\"value\",[22,\"name\"]],[11,\"placeholder\",[28,\"t\",[\"general.filterPlaceholder\"],null]],[11,\"oninput\",[28,\"perform\",[[24,[\"setName\"]]],[[\"value\"],[\"target.value\"]]]],[10,\"type\",\"search\"],[8],[9],[0,\"\\n  \"],[9],[0,\"\\n  \"],[5,\"visualizer-course-instructors\",[],[[\"@filter\",\"@course\"],[[22,\"name\"],[22,\"model\"]]]],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ilios/templates/course-visualize-instructors.hbs"
    }
  });

  _exports.default = _default;
});