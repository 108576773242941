define("ilios/components/ilios-header", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    currentUser: Ember.inject.service(),
    session: Ember.inject.service(),
    pageTitleList: Ember.inject.service(),
    router: Ember.inject.service(),
    iliosConfig: Ember.inject.service(),
    classNames: ['ilios-header'],
    tagName: 'header',
    ariaRole: 'banner',
    title: null,
    'data-test-ilios-header': true,
    showSearch: Ember.computed('currentUser.performsNonLearnerFunction', 'session.isAuthenticated', 'router.currentRouteName', 'iliosConfig.searchEnabled', async function () {
      const searchEnabled = await this.iliosConfig.searchEnabled;
      return searchEnabled && this.session.isAuthenticated && this.router.currentRouteName !== 'search' && this.currentUser.performsNonLearnerFunction;
    }),

    /**
     * We have to use an observer here
     * otherwise we get errors when the property is double set
     */
    titleChangeObserver: Ember.on('init', Ember.observer('pageTitleList.sortedTokens.[]', function () {
      const pageTitleList = this.pageTitleList;

      const setTitle = function setTitle() {
        const tokens = pageTitleList.get('sortedTokens');

        if (tokens.length) {
          this.set('title', tokens[0].title);
        }
      };

      Ember.run.once(this, setTitle);
    })),
    actions: {
      search(q) {
        this.router.transitionTo('search', {
          queryParams: {
            q
          }
        });
      }

    }
  });

  _exports.default = _default;
});