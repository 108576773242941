define("ilios/models/mesh-previous-indexing", ["exports", "ilios-common/models/mesh-previous-indexing"], function (_exports, _meshPreviousIndexing) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _meshPreviousIndexing.default;
    }
  });
});