define("ilios/helpers/pad-start", ["exports", "ember-pad/helpers/pad-start"], function (_exports, _padStart) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _padStart.default;
    }
  });
  Object.defineProperty(_exports, "padStart", {
    enumerable: true,
    get: function get() {
      return _padStart.padStart;
    }
  });
});