define("ilios/components/program-details", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['program-details'],
    tagName: 'section',
    'data-test-program-details': true,
    canUpdate: false,
    program: null
  });

  _exports.default = _default;
});