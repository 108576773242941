define("ilios/components/visualizer-program-year-competencies", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    intl: Ember.inject.service(),
    classNameBindings: ['isIcon::not-icon', ':visualizer-program-year-competencies'],
    isIcon: false,
    programYear: null,
    tooltipCourses: null,
    tooltipSessions: null,
    tooltipTitle: null,
    programYearName: Ember.computed('programYear.acdemicYear', 'programYear.cohort.{title,classOfYear}', async function () {
      const intl = this.intl;
      const programYear = this.programYear;
      const cohort = await programYear.get('cohort');
      const title = cohort.get('title');
      const year = await cohort.get('classOfYear');
      const classOfYear = intl.t('classOfYear', {
        year
      });
      return title ? title : classOfYear;
    }),
    objectiveObjects: Ember.computed('programYear.objectives.[]', async function () {
      const programYear = this.programYear;

      const buildTree = async function buildTree(parent) {
        const children = await parent.get('children');
        const childrenTree = await Ember.RSVP.map(children.toArray(), buildTree);
        const courses = await parent.get('courses');
        const sessions = await parent.get('sessions');
        const courseTitles = courses.mapBy('title');
        const sessionTitles = sessions.mapBy('title');
        const rhett = {
          name: parent.get('title'),
          children: childrenTree,
          meta: {
            courseTitles,
            sessionTitles
          }
        };
        return rhett;
      };

      const objectives = await programYear.get('objectives');
      const objectivesWithCompetency = await Ember.RSVP.filter(objectives.toArray(), async objective => {
        const competency = await objective.get('competency');
        return !!competency;
      });
      const objectiveObjects = await Ember.RSVP.map(objectivesWithCompetency, async objective => {
        const obj = await buildTree(objective);
        const competency = await objective.get('competency');
        obj.competencyId = competency.get('id');
        return obj;
      });
      return objectiveObjects;
    }),
    competencyObjects: Ember.computed('programYear.competencies.[]', 'objectiveObjects.[]', async function () {
      const programYear = this.programYear;
      const objectiveObjects = await this.objectiveObjects;
      const competencies = await programYear.get('competencies');
      const competencyObjects = await Ember.RSVP.map(competencies.toArray(), async competency => {
        const domain = await competency.get('domain');
        const domainId = domain.get('id');
        const competencyId = competency.get('id');
        const competencyTitle = competency.get('title');
        return {
          domainId,
          name: competencyTitle,
          children: objectiveObjects.filterBy('competencyId', competencyId)
        };
      });
      return competencyObjects;
    }),
    domainObjects: Ember.computed('programYear.competencies.[]', 'competencyObjects.[]', async function () {
      const programYear = this.programYear;
      const competencies = await programYear.get('competencies');
      const competencyObjects = await this.competencyObjects;
      const domains = await Ember.RSVP.map(competencies.toArray(), async competency => competency.get('domain'));
      const domainObjects = domains.uniq().map(domain => {
        const id = domain.get('id');
        const name = domain.get('title');
        const domainCompetencyObjects = competencyObjects.filterBy('domainId', id);
        const children = domainCompetencyObjects.reduce((arr, {
          domainId,
          name,
          children
        }) => {
          if (id === domainId) {
            arr.pushObjects(children);
          } else {
            arr.pushObject({
              name,
              children
            });
          }

          return arr;
        }, []);
        return {
          name,
          children,
          meta: {}
        };
      });
      return domainObjects;
    }),
    data: Ember.computed('domainObjects.[]', async function () {
      const name = await this.programYearName;
      const children = await this.domainObjects;
      return {
        name,
        children,
        meta: {}
      };
    }),
    nodeHover: (0, _emberConcurrency.task)(function* (obj) {
      yield (0, _emberConcurrency.timeout)(100);
      const isIcon = this.isIcon;

      if (isIcon || Ember.isEmpty(obj) || obj.empty) {
        this.set('tooltipTitle', null);
        this.set('tooltipCourses', null);
        this.set('tooltipSessions', null);
        return;
      }

      const {
        name,
        children,
        meta
      } = obj;

      const getCourseTitles = (courseTitles, {
        children,
        meta
      }) => {
        courseTitles.pushObjects(meta.courseTitles || []);
        return children.reduce(getCourseTitles, courseTitles);
      };

      const allCourseTitles = children.reduce(getCourseTitles, meta.courseTitles || []);

      const getSessionTitles = (sessionTitles, {
        children,
        meta
      }) => {
        sessionTitles.pushObjects(meta.sessionTitles || []);
        return children.reduce(getSessionTitles, sessionTitles);
      };

      const allSessionTitles = children.reduce(getSessionTitles, meta.sessionTitles || []);
      this.set('tooltipTitle', Ember.String.htmlSafe(name));
      this.set('tooltipCourses', allCourseTitles.uniq());
      this.set('tooltipSessions', allSessionTitles.uniq());
    }).restartable()
  });

  _exports.default = _default;
});