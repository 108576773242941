define("ilios/templates/components/ilios-header", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "30vSVqv0",
    "block": "{\"symbols\":[\"@title\"],\"statements\":[[5,\"link-to\",[],[[\"@route\",\"@classNames\",\"@title\"],[\"dashboard\",\"logo\",[28,\"t\",[\"general.dashboard\"],null]]],{\"statements\":[[0,\"\\n  \"],[7,\"h1\",true],[10,\"class\",\"visually-hidden\"],[8],[0,\"\\n    \"],[1,[28,\"t\",[\"general.ilios\"],null],false],[0,\"\\n  \"],[9],[0,\"\\n  \"],[7,\"span\",true],[10,\"class\",\"image\"],[8],[9],[0,\"\\n\"]],\"parameters\":[]}],[0,\"\\n\"],[4,\"if\",[[23,1,[]]],null,{\"statements\":[[0,\"  \"],[7,\"h1\",true],[8],[0,\"\\n    \"],[1,[23,1,[]],false],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[7,\"div\",true],[10,\"class\",\"tools\"],[8],[0,\"\\n\"],[4,\"if\",[[28,\"await\",[[24,[\"showSearch\"]]],null]],null,{\"statements\":[[0,\"    \"],[5,\"global-search-box\",[],[[\"@search\"],[[28,\"action\",[[23,0,[]],\"search\"],null]]]],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"  \"],[5,\"locale-chooser\",[],[[],[]]],[0,\"\\n\"],[4,\"if\",[[24,[\"session\",\"isAuthenticated\"]]],null,{\"statements\":[[0,\"    \"],[5,\"user-menu\",[],[[],[]]],[0,\"\\n\"]],\"parameters\":[]},null],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ilios/templates/components/ilios-header.hbs"
    }
  });

  _exports.default = _default;
});