define("ilios/templates/schools", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "YLtrF9O/",
    "block": "{\"symbols\":[],\"statements\":[[1,[28,\"page-title\",[[28,\"t\",[\"general.schools\"],null]],null],false],[5,\"school-list\",[],[[\"@schools\"],[[22,\"model\"]]]]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ilios/templates/schools.hbs"
    }
  });

  _exports.default = _default;
});