define("ilios/templates/components/learnergroup-bulk-group-matcher", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "G32cdh0W",
    "block": "{\"symbols\":[\"learnerGroup\"],\"statements\":[[7,\"td\",true],[8],[0,\"\\n  \"],[7,\"span\",true],[8],[0,\"\\n    \"],[1,[22,\"groupName\"],false],[0,\"\\n  \"],[9],[0,\"\\n\"],[4,\"if\",[[24,[\"noGroupWithThisName\"]]],null,{\"statements\":[[0,\"    \"],[7,\"button\",false],[3,\"action\",[[23,0,[]],[24,[\"createGroup\"]],[24,[\"groupName\"]]]],[8],[0,\"\\n      \"],[1,[28,\"t\",[\"general.createNewGroup\"],[[\"title\"],[[24,[\"groupName\"]]]]],false],[0,\"\\n    \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[9],[0,\"\\n\"],[7,\"td\",true],[8],[0,\"\\n  \"],[7,\"select\",true],[11,\"onchange\",[28,\"action\",[[23,0,[]],\"matchGroup\"],[[\"value\"],[\"target.value\"]]]],[11,\"id\",[29,[\"sub-group-\",[22,\"elementId\"]]]],[8],[0,\"\\n    \"],[7,\"option\",true],[10,\"value\",\"null\"],[11,\"selected\",[28,\"is-equal\",[null,[24,[\"matchedGroupId\"]]],null]],[8],[9],[0,\"\\n\"],[4,\"each\",[[24,[\"groups\"]]],null,{\"statements\":[[0,\"      \"],[7,\"option\",true],[11,\"value\",[23,1,[\"id\"]]],[11,\"selected\",[28,\"is-equal\",[[23,1,[\"id\"]],[24,[\"matchedGroupId\"]]],null]],[8],[0,\"\\n        \"],[1,[23,1,[\"title\"]],false],[0,\"\\n      \"],[9],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"  \"],[9],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ilios/templates/components/learnergroup-bulk-group-matcher.hbs"
    }
  });

  _exports.default = _default;
});