define("ilios/components/curriculum-inventory-sequence-block-dates-duration-editor", ["exports", "ember-cp-validations", "ilios-common/mixins/validation-error-display"], function (_exports, _emberCpValidations, _validationErrorDisplay) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const Validations = (0, _emberCpValidations.buildValidations)({
    duration: [(0, _emberCpValidations.validator)('number', {
      allowString: true,
      integer: true,
      gte: 0,
      lte: 1200
    })],
    startDate: [(0, _emberCpValidations.validator)('presence', {
      presence: true,
      dependentKeys: ['model.duration'],
      disabled: Ember.computed.gt('model.duration', 0)
    })],
    endDate: [(0, _emberCpValidations.validator)('date', {
      dependentKeys: ['model.startDate', 'model.duration'],
      after: Ember.computed.reads('model.startDate'),
      disabled: Ember.computed('model.duration', 'model.startDate', function () {
        return this.get('model.duration') > 0 && !this.get('model.startDate');
      })
    }), (0, _emberCpValidations.validator)('presence', {
      presence: true,
      dependentKeys: ['model.startDate', 'model.duration'],
      disabled: Ember.computed('model.duration', 'model.startDate', function () {
        return this.get('model.duration') > 0 && !this.get('model.startDate');
      })
    })]
  });

  var _default = Ember.Component.extend(Validations, _validationErrorDisplay.default, {
    classNames: ['curriculum-inventory-sequence-block-dates-duration-editor'],
    tagName: 'section',
    duration: null,
    endDate: null,
    isSaving: false,
    sequenceBlock: null,
    startDate: null,

    didReceiveAttrs() {
      this._super(...arguments);

      const sequenceBlock = this.sequenceBlock;
      const startDate = sequenceBlock.get('startDate');
      const endDate = sequenceBlock.get('endDate');
      const duration = sequenceBlock.get('duration');
      this.setProperties({
        startDate,
        endDate,
        duration
      });
    },

    actions: {
      changeStartDate(startDate) {
        this.set('startDate', startDate);
      },

      changeEndDate(endDate) {
        this.set('endDate', endDate);
      },

      save() {
        this.set('isSaving', true);
        this.send('addErrorDisplaysFor', ['duration', 'startDate', 'endDate']);
        this.validate().then(({
          validations
        }) => {
          if (validations.get('isValid')) {
            const startDate = this.startDate;
            const endDate = this.endDate;
            const duration = this.duration;
            this.save(startDate, endDate, duration);
          } else {
            this.set('isSaving', false);
          }
        });
      },

      cancel() {
        this.cancel();
      }

    },

    keyUp(event) {
      const keyCode = event.keyCode;
      const target = event.target;

      if ('text' !== target.type) {
        return;
      }

      if (13 === keyCode) {
        this.send('save');
        return;
      }

      if (27 === keyCode) {
        this.send('cancel');
      }
    }

  });

  _exports.default = _default;
});