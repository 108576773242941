define("ilios/components/school-session-attributes-collapsed", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['school-session-attributes-collapsed'],
    tagName: 'section',
    showSessionAttendanceRequired: false,
    showSessionSpecialAttireRequired: false,
    showSessionSpecialEquipmentRequired: false,
    showSessionSupplemental: false
  });

  _exports.default = _default;
});