define("ilios/components/school-list", ["exports", "ember-cp-validations", "ilios-common/mixins/validation-error-display"], function (_exports, _emberCpValidations, _validationErrorDisplay) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const Validations = (0, _emberCpValidations.buildValidations)({
    title: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('length', {
      max: 60,
      descriptionKey: 'general.title'
    })],
    iliosAdministratorEmail: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('length', {
      max: 100
    }), (0, _emberCpValidations.validator)('format', {
      type: 'email'
    })]
  });

  var _default = Ember.Component.extend(_validationErrorDisplay.default, Validations, {
    currentUser: Ember.inject.service(),
    store: Ember.inject.service(),
    classNames: ['school-list'],
    tagName: 'section',
    iliosAdministratorEmail: null,
    isSavingNewSchool: false,
    newSchool: null,
    schools: null,
    showNewSchoolForm: false,
    sortSchoolsBy: null,
    title: null,
    sortedSchools: Ember.computed.sort('schools', 'sortSchoolsBy'),

    init() {
      this._super(...arguments);

      this.set('sortSchoolsBy', ['title']);
    },

    actions: {
      toggleNewSchoolForm() {
        this.set('showNewSchoolForm', !this.showNewSchoolForm);
        this.set('newSchool', null);
        this.set('title', null);
        this.set('iliosAdministratorEmail', null);
      },

      hideNewSchoolForm() {
        this.set('showNewSchoolForm', false);
        this.set('title', null);
        this.set('iliosAdministratorEmail', null);
      },

      createNewSchool() {
        this.set('isSavingNewSchool', true);
        this.send('addErrorDisplayFor', 'title');
        this.send('addErrorDisplayFor', 'iliosAdministratorEmail');
        this.validate().then(({
          validations
        }) => {
          if (validations.get('isValid')) {
            const title = this.title;
            const iliosAdministratorEmail = this.iliosAdministratorEmail;
            let newSchool = this.store.createRecord('school', {
              title,
              iliosAdministratorEmail
            });
            newSchool.save().then(school => {
              this.set('newSchool', school);
            }).finally(() => {
              this.send('clearErrorDisplay');
              this.set('title', null);
              this.set('iliosAdministratorEmail', null);
              this.set('showNewSchoolForm', false);
              this.set('isSavingNewSchool', false);
            });
          } else {
            this.set('isSavingNewSchool', false);
          }
        });
      }

    },

    keyUp(event) {
      const keyCode = event.keyCode;
      const target = event.target;

      if ('text' !== target.type) {
        return;
      }

      if (13 === keyCode) {
        this.send('createNewSchool');
        return;
      }

      if (27 === keyCode) {
        this.send('hideNewSchoolForm');
      }
    }

  });

  _exports.default = _default;
});