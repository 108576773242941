define("ilios/components/dashboard-mycourses", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    currentUser: Ember.inject.service(),
    classNames: ['dashboard-mycourses'],
    tagName: 'div',
    listOfCourses: Ember.computed('currentUser.activeRelatedCoursesInThisYearAndLastYear.[]', function () {
      return this.currentUser.get('activeRelatedCoursesInThisYearAndLastYear').then(courses => {
        return courses.sortBy('startDate');
      });
    }),
    canEditCourses: Ember.computed('currentUser.performsNonLearnerFunction', async function () {
      return this.currentUser.get('performsNonLearnerFunction');
    })
  });

  _exports.default = _default;
});