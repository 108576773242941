define("ilios/components/competency-title-editor", ["exports", "ember-concurrency", "ember-cp-validations", "ilios-common/mixins/validation-error-display"], function (_exports, _emberConcurrency, _emberCpValidations, _validationErrorDisplay) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const Validations = (0, _emberCpValidations.buildValidations)({
    title: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('length', {
      max: 200
    })]
  });

  var _default = Ember.Component.extend(Validations, _validationErrorDisplay.default, {
    classNames: ['competency-title-editor'],
    tagName: 'span',
    canUpdate: false,
    competency: null,
    title: null,

    didReceiveAttrs() {
      this._super(...arguments);

      const competency = this.competency;

      if (Ember.isPresent(competency)) {
        this.set('title', competency.get('title'));
      }
    },

    actions: {
      revert() {
        this.set('title', null);
        const competency = this.competency;

        if (Ember.isPresent(competency)) {
          this.set('title', competency.get('title'));
        }
      }

    },
    save: (0, _emberConcurrency.task)(function* () {
      this.send('addErrorDisplayFor', 'title');
      const {
        validations
      } = yield this.validate();

      if (validations.isValid) {
        const {
          competency,
          title
        } = this.getProperties('competency', 'title');

        if (Ember.isPresent(competency)) {
          competency.set('title', title);
        }

        this.send('clearErrorDisplay');
      } else {
        return Ember.RSVP.reject();
      }
    })
  });

  _exports.default = _default;
});