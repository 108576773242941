define("ilios/components/session-offerings", ["exports", "ilios-common/components/session-offerings"], function (_exports, _sessionOfferings) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _sessionOfferings.default;
    }
  });
});