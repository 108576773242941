define("ilios/instance-initializers/hide-loading-screen", ["exports", "ilios/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.initialize = initialize;
  _exports.default = void 0;
  const userConfig = _environment.default['ember-load'] || {};

  function initialize() {
    let instance = arguments[1] || arguments[0];
    let container = arguments[1] ? arguments[0] : instance.container;

    if (Ember.View) {
      let ApplicationView = container.lookupFactory ? container.lookupFactory('view:application') : instance.resolveRegistration('view:application');
      ApplicationView.reopen({
        didInsertElement() {
          this._super(...arguments);

          let loadingIndicatorClass = userConfig.loadingIndicatorClass || 'ember-load-indicator';
          document.querySelectorAll(".".concat(loadingIndicatorClass)).forEach(e => e.remove());
        }

      });
    }
  }

  var _default = {
    name: 'hide-loading-screen-instance',
    initialize
  };
  _exports.default = _default;
});