define("ilios/components/instructorgroup-header", ["exports", "ember-cp-validations", "ilios-common/mixins/validation-error-display"], function (_exports, _emberCpValidations, _validationErrorDisplay) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const Validations = (0, _emberCpValidations.buildValidations)({
    title: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('length', {
      min: 3,
      max: 60
    })]
  });

  var _default = Ember.Component.extend(Validations, _validationErrorDisplay.default, {
    store: Ember.inject.service(),
    classNames: ['instructorgroup-header'],
    canUpdate: false,
    title: null,

    didReceiveAttrs() {
      this._super(...arguments);

      this.set('title', this.get('instructorGroup.title'));
    },

    actions: {
      async changeTitle() {
        const group = this.instructorGroup;
        const newTitle = this.title;
        this.send('addErrorDisplayFor', 'title');
        const {
          validations
        } = await this.validate();

        if (validations.isValid) {
          this.send('removeErrorDisplayFor', 'title');
          group.set('title', newTitle);
          const newGroup = await group.save();
          this.set('title', newGroup.title);
          this.set('instructorGroup', newGroup);
        } else {
          await Ember.RSVP.reject();
        }
      },

      revertTitleChanges() {
        const group = this.instructorGroup;
        this.set('title', group.get('title'));
      }

    }
  });

  _exports.default = _default;
});