define("ilios/models/school-config", ["exports", "ilios-common/models/school-config"], function (_exports, _schoolConfig) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _schoolConfig.default;
    }
  });
});