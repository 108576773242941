define("ilios/routes/admin-dashboard", ["exports", "ember-simple-auth/mixins/authenticated-route-mixin"], function (_exports, _authenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    store: Ember.inject.service(),
    permissionChecker: Ember.inject.service(),
    currentUser: Ember.inject.service(),

    beforeModel() {
      const currentUser = this.currentUser;
      const performsNonLearnerFunction = currentUser.get('performsNonLearnerFunction');

      if (!performsNonLearnerFunction) {
        this.transitionTo('dashboard');
      }
    },

    async model() {
      const store = this.store;
      const permissionChecker = this.permissionChecker;
      const schools = await store.findAll('school');
      const schoolsWithCreateUserPermission = await Ember.RSVP.filter(schools.toArray(), async school => {
        return permissionChecker.canCreateUser(school);
      });
      const canCreate = schoolsWithCreateUserPermission.length > 0;
      const schoolsWithUpdateUserPermission = await Ember.RSVP.filter(schools.toArray(), async school => {
        return permissionChecker.canUpdateUserInSchool(school);
      });
      return {
        canCreate,
        schoolsWithUpdateUserPermission
      };
    }

  });

  _exports.default = _default;
});