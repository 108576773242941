define("ilios/components/curriculum-inventory-verification-preview-table7", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    'data-test-curriculum-inventory-verification-preview-table7': true,
    classNames: ['curriculum-inventory-verification-preview-table7'],
    totalNumSummativeAssessments: Ember.computed('data', function () {
      return this.data.reduce((value, row) => {
        return value + row['num_summative_assessments'];
      }, 0);
    }),
    totalNumFormativeAssessments: Ember.computed('data', function () {
      return this.data.reduce((value, row) => {
        return value + row['num_formative_assessments'];
      }, 0);
    })
  });

  _exports.default = _default;
});