define("ilios/components/visualizer-session-type-terms", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNameBindings: ['isIcon::not-icon', ':visualizer-session-type-terms'],
    tagName: 'span',
    isIcon: false,
    sessionType: null,
    tooltipContent: null,
    tooltipTitle: null,
    vocabulary: null,
    data: Ember.computed('sessionType.sessions.[]', 'vocabulary', async function () {
      const sessionType = this.sessionType;
      const vocabulary = this.vocabulary;
      const sessions = await sessionType.get('sessions');
      const terms = await Ember.RSVP.map(sessions.toArray(), async session => {
        const sessionTerms = await session.get('terms');
        const course = await session.get('course');
        const courseTerms = await course.get('terms');
        const sessionTermsInThisVocabulary = await Ember.RSVP.filter(sessionTerms.toArray(), async term => {
          const termVocab = await term.get('vocabulary');
          return termVocab.get('id') === vocabulary.get('id');
        });
        const courseTermsInThisVocabulary = await Ember.RSVP.filter(courseTerms.toArray(), async term => {
          const termVocab = await term.get('vocabulary');
          return termVocab.get('id') === vocabulary.get('id');
        });
        const sessionTermsObjects = await sessionTermsInThisVocabulary.map(term => {
          return {
            term,
            session,
            course: null
          };
        });
        const courseTermsObjects = await courseTermsInThisVocabulary.map(term => {
          return {
            term,
            course,
            session: null
          };
        });
        return [].concat(sessionTermsObjects.toArray()).concat(courseTermsObjects.toArray());
      });
      let flat = terms.reduce((flattened, obj) => {
        return flattened.pushObjects(obj.toArray());
      }, []);
      let termObjects = {};

      for (let i = 0; i < flat.length; i++) {
        const {
          term,
          session,
          course
        } = flat[i];
        const id = term.get('id');

        if (typeof termObjects[id] !== "undefined") {
          termObjects[id].data++;
        } else {
          termObjects[id] = {
            data: 1,
            meta: {
              term: term.get('title'),
              courses: [],
              sessions: []
            }
          };
        }

        if (session) {
          termObjects[id].meta.sessions.pushObject(session.get('title'));
        }

        if (course) {
          termObjects[id].meta.courses.pushObject(course.get('title'));
        }
      }

      const termData = [];
      Object.keys(termObjects).forEach(key => {
        termData.push(termObjects[key]);
      });
      const totalLinks = termData.mapBy('data').reduce((total, count) => total + count, 0);
      const data = termData.map(obj => {
        const percent = (obj.data / totalLinks * 100).toFixed(1);
        obj.label = "".concat(percent, "%");
        return obj;
      });
      return data;
    }),
    donutHover: (0, _emberConcurrency.task)(function* (obj) {
      yield (0, _emberConcurrency.timeout)(100);
      const isIcon = this.isIcon;

      if (isIcon || Ember.isEmpty(obj) || obj.empty) {
        this.set('tooltipTitle', null);
        this.set('tooltipContent', null);
        return;
      }

      const {
        meta
      } = obj;
      const title = Ember.String.htmlSafe(meta.term);
      const sessions = meta.sessions.uniq().sort().join();
      const courses = meta.courses.uniq().sort().join();
      this.set('tooltipTitle', title);
      this.set('tooltipContent', {
        sessions,
        courses
      });
    }).restartable()
  });

  _exports.default = _default;
});