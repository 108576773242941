define("ilios/services/server-variables", ["exports", "ember-cli-server-variables/services/server-variables", "ilios/config/environment"], function (_exports, _serverVariables, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _serverVariables.default.extend({
    env: Ember.computed(function () {
      return _environment.default;
    })
  });

  _exports.default = _default;
});