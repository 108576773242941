define("ilios/templates/admin-dashboard", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "q14D4sMF",
    "block": "{\"symbols\":[],\"statements\":[[1,[28,\"page-title\",[[28,\"t\",[\"general.admin\"],null]],null],false],[0,\"\\n\"],[7,\"div\",true],[10,\"class\",\"admin-dashboard\"],[8],[0,\"\\n  \"],[5,\"manage-users-summary\",[],[[\"@canCreate\"],[[24,[\"model\",\"canCreate\"]]]]],[0,\"\\n\"],[4,\"if\",[[28,\"gt\",[[24,[\"model\",\"schoolsWithUpdateUserPermission\",\"length\"]],0],null]],null,{\"statements\":[[0,\"    \"],[5,\"pending-updates-summary\",[],[[\"@schools\"],[[24,[\"model\",\"schoolsWithUpdateUserPermission\"]]]]],[0,\"\\n    \"],[5,\"unassigned-students-summary\",[],[[\"@schools\"],[[24,[\"model\",\"schoolsWithUpdateUserPermission\"]]]]],[0,\"\\n\"]],\"parameters\":[]},null],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ilios/templates/admin-dashboard.hbs"
    }
  });

  _exports.default = _default;
});