define("ilios/templates/components/visualizer-program-year-competencies", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "fKvEj8cV",
    "block": "{\"symbols\":[\"chart\"],\"statements\":[[4,\"if\",[[28,\"or\",[true,[24,[\"isIcon\"]],[28,\"gt\",[[28,\"get\",[[28,\"await\",[[24,[\"data\"]]],null],\"length\"],null],0],null]],null]],null,{\"statements\":[[0,\"  \"],[5,\"simple-chart\",[],[[\"@name\",\"@isIcon\",\"@data\",\"@hover\",\"@leave\"],[\"tree\",[22,\"isIcon\"],[28,\"await\",[[24,[\"data\"]]],null],[28,\"perform\",[[24,[\"nodeHover\"]]],null],[28,\"perform\",[[24,[\"nodeHover\"]]],null]]],{\"statements\":[[0,\"\\n\"],[4,\"if\",[[24,[\"tooltipTitle\"]]],null,{\"statements\":[[0,\"      \"],[6,[23,1,[\"tooltip\"]],[],[[\"@title\"],[[22,\"tooltipTitle\"]]],{\"statements\":[[0,\"\\n        \"],[7,\"div\",true],[8],[0,\"\\n          \"],[7,\"h5\",true],[8],[0,\"\\n            \"],[1,[28,\"t\",[\"general.courses\"],null],false],[0,\"\\n            (\"],[1,[24,[\"tooltipCourses\",\"length\"]],false],[0,\")\\n          \"],[9],[0,\"\\n          \"],[1,[28,\"truncate\",[[28,\"join\",[\", \",[24,[\"tooltipCourses\"]]],null],200,true],null],false],[0,\"\\n        \"],[9],[0,\"\\n        \"],[7,\"div\",true],[8],[0,\"\\n          \"],[7,\"h5\",true],[8],[0,\"\\n            \"],[1,[28,\"t\",[\"general.sessions\"],null],false],[0,\"\\n            (\"],[1,[24,[\"tooltipSessions\",\"length\"]],false],[0,\")\\n          \"],[9],[0,\"\\n          \"],[1,[28,\"truncate\",[[28,\"join\",[\", \",[24,[\"tooltipSessions\"]]],null],200,true],null],false],[0,\"\\n        \"],[9],[0,\"\\n      \"]],\"parameters\":[]}],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"  \"]],\"parameters\":[1]}],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ilios/templates/components/visualizer-program-year-competencies.hbs"
    }
  });

  _exports.default = _default;
});