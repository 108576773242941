define("ilios/components/school-competencies-manager", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['school-competencies-manager'],
    canCreate: false,
    canDelete: false,
    canUpdate: false,
    competencies: null,
    domains: Ember.computed('competencies.[]', function () {
      let competencies = this.competencies;

      if (Ember.isEmpty(competencies)) {
        return [];
      }

      let domains = competencies.filterBy('isDomain');
      let objs = domains.uniq().map(domain => {
        if (!domain.get('id')) {
          return {
            domain,
            competencies: []
          };
        }

        let domainCompetencies = competencies.filter(competency => competency.belongsTo('parent').id() === domain.get('id'));
        return {
          domain,
          competencies: domainCompetencies.sortBy('title')
        };
      });
      return objs.sortBy('domain.title');
    }),
    actions: {
      changeCompetencyTitle(value, competency) {
        competency.set('title', value);
      }

    }
  });

  _exports.default = _default;
});