define("ilios/templates/components/instructorgroup-header", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "w6R2gOzb",
    "block": "{\"symbols\":[\"isSaving\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"title\"],[8],[0,\"\\n  \"],[7,\"h4\",true],[10,\"class\",\"school-title\"],[8],[0,\"\\n    \"],[1,[24,[\"instructorGroup\",\"school\",\"title\"]],false],[0,\"\\n    >\\n  \"],[9],[0,\"\\n\"],[4,\"if\",[[24,[\"canUpdate\"]]],null,{\"statements\":[[0,\"    \"],[5,\"editable-field\",[],[[\"@value\",\"@save\",\"@close\",\"@saveOnEnter\",\"@closeOnEscape\"],[[22,\"title\"],[28,\"action\",[[23,0,[]],\"changeTitle\"],null],[28,\"action\",[[23,0,[]],\"revertTitleChanges\"],null],true,true]],{\"statements\":[[0,\"\\n      \"],[7,\"input\",true],[11,\"value\",[22,\"title\"]],[11,\"oninput\",[28,\"action\",[[23,0,[]],[28,\"mut\",[[24,[\"title\"]]],null]],[[\"value\"],[\"target.value\"]]]],[11,\"disabled\",[23,1,[]]],[11,\"onkeyup\",[28,\"action\",[[23,0,[]],\"addErrorDisplayFor\",\"title\"],null]],[10,\"type\",\"text\"],[8],[9],[0,\"\\n\"],[4,\"if\",[[28,\"and\",[[28,\"get\",[[28,\"get\",[[28,\"get\",[[28,\"get\",[[23,0,[]],\"validations\"],null],\"attrs\"],null],\"title\"],null],\"isInvalid\"],null],[28,\"is-in\",[[24,[\"showErrorsFor\"]],\"title\"],null]],null]],null,{\"statements\":[[0,\"        \"],[7,\"span\",true],[10,\"class\",\"validation-error-message\"],[8],[0,\"\\n          \"],[1,[28,\"get\",[[28,\"get\",[[28,\"get\",[[28,\"get\",[[23,0,[]],\"validations\"],null],\"attrs\"],null],\"title\"],null],\"message\"],null],false],[0,\"\\n        \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"    \"]],\"parameters\":[1]}],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[7,\"h4\",true],[8],[0,\"\\n      \"],[1,[22,\"title\"],false],[0,\"\\n    \"],[9],[0,\"\\n\"]],\"parameters\":[]}],[9],[0,\"\\n\"],[7,\"span\",true],[10,\"class\",\"info\"],[8],[0,\"\\n  \"],[1,[28,\"t\",[\"general.members\"],null],false],[0,\":\\n  \"],[1,[24,[\"instructorGroup\",\"users\",\"length\"]],false],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ilios/templates/components/instructorgroup-header.hbs"
    }
  });

  _exports.default = _default;
});