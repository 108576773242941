define("ilios/components/pending-single-user-update", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['pending-single-user-updates'],
    isSaving: false,
    user: null,
    updates: Ember.computed.reads('user.pendingUserUpdates'),
    actions: {
      updateEmailAddress(update) {
        this.set('isSaving', true);
        let user = this.user;
        user.set('email', update.get('value'));
        user.save().then(() => {
          update.deleteRecord();
          update.save().then(() => {
            this.set('isSaving', false);
            this.flashMessages.success('general.savedSuccessfully');
          });
        });
      },

      disableUser() {
        this.set('isSaving', true);
        let user = this.user;
        user.set('enabled', false);
        user.save().then(() => {
          user.get('pendingUserUpdates').then(updates => {
            updates.invoke('deleteRecord');
            Ember.RSVP.all(updates.invoke('save')).then(() => {
              this.set('isSaving', false);
              this.flashMessages.success('general.savedSuccessfully');
            });
          });
        });
      },

      excludeFromSync() {
        this.set('isSaving', true);
        let user = this.user;
        user.set('userSyncIgnore', true);
        user.save().then(() => {
          user.get('pendingUserUpdates').then(updates => {
            updates.invoke('deleteRecord');
            Ember.RSVP.all(updates.invoke('save')).then(() => {
              this.set('isSaving', false);
              this.flashMessages.success('general.savedSuccessfully');
            });
          });
        });
      }

    }
  });

  _exports.default = _default;
});