define("ilios/utils/offering-date-block", ["exports", "ilios-common/utils/offering-date-block"], function (_exports, _offeringDateBlock) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _offeringDateBlock.default;
    }
  });
});