define("ilios/templates/components/school-session-type-manager", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "HeC8IkK5",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[28,\"and\",[[28,\"is-fulfilled\",[[24,[\"readonlySessionType\"]]],null],[28,\"await\",[[24,[\"readonlySessionType\"]]],null]],null]],null,{\"statements\":[[0,\"  \"],[7,\"div\",true],[10,\"class\",\"session-type-title\"],[8],[0,\"\\n    \"],[1,[24,[\"sessionType\",\"title\"]],false],[0,\"\\n  \"],[9],[0,\"\\n  \"],[5,\"school-session-type-form\",[],[[\"@title\",\"@calendarColor\",\"@assessment\",\"@isActive\",\"@selectedAssessmentOptionId\",\"@selectedAamcMethodId\",\"@canEditTitle\",\"@canEditAamcMethod\",\"@canEditCalendarColor\",\"@canEditAssessment\",\"@canEditAssessmentOption\",\"@canEditActive\",\"@canUpdate\",\"@save\",\"@close\"],[[28,\"get\",[[28,\"await\",[[24,[\"readonlySessionType\"]]],null],\"title\"],null],[28,\"get\",[[28,\"await\",[[24,[\"readonlySessionType\"]]],null],\"calendarColor\"],null],[28,\"get\",[[28,\"await\",[[24,[\"readonlySessionType\"]]],null],\"assessment\"],null],[28,\"get\",[[28,\"await\",[[24,[\"readonlySessionType\"]]],null],\"isActive\"],null],[28,\"get\",[[28,\"await\",[[24,[\"readonlySessionType\"]]],null],\"selectedAssessmentOptionId\"],null],[28,\"get\",[[28,\"await\",[[24,[\"readonlySessionType\"]]],null],\"selectedAamcMethodId\"],null],[28,\"and\",[[24,[\"canUpdate\"]],[28,\"eq\",[[24,[\"sessionType\",\"sessionCount\"]],0],null]],null],[22,\"canUpdate\"],[22,\"canUpdate\"],[28,\"and\",[[24,[\"canUpdate\"]],[28,\"eq\",[[24,[\"sessionType\",\"sessionCount\"]],0],null]],null],[28,\"and\",[[24,[\"canUpdate\"]],[28,\"eq\",[[24,[\"sessionType\",\"sessionCount\"]],0],null]],null],[22,\"canUpdate\"],[22,\"canUpdate\"],[28,\"perform\",[[24,[\"save\"]]],null],[22,\"close\"]]]],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[5,\"loading-spinner\",[],[[],[]]],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ilios/templates/components/school-session-type-manager.hbs"
    }
  });

  _exports.default = _default;
});