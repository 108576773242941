define("ilios/controllers/course/index", ["exports", "ilios-common/mixins/course/index-controller"], function (_exports, _indexController) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend(_indexController.default);

  _exports.default = _default;
});