define("ilios/components/login-form", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    session: Ember.inject.service(),
    classNames: ['login-form'],
    errors: null,
    noAccountExistsError: false,
    noAccountExistsAccount: null,
    username: null,
    password: null,
    authenticate: (0, _emberConcurrency.task)(function* () {
      try {
        this.set('errors', []);
        const credentials = this.getProperties('username', 'password');
        const session = this.session;
        const authenticator = 'authenticator:ilios-jwt';
        yield session.authenticate(authenticator, credentials);
      } catch (error) {
        const keys = error.keys.map(key => {
          return 'general.' + key;
        });
        this.set('error', {
          'message': error.message,
          'keys': keys
        });
      }
    })
  });

  _exports.default = _default;
});