define("ilios/translations/es", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "errors": {
      "accepted": "{description} debe ser aceptado",
      "after": "{description} necesita estar después de {after}",
      "before": "{description} necesita estar antes de {before}",
      "blank": "{description} no puede estar en blanco",
      "collection": "{description} necesita ser una colección",
      "confirmation": "{description} no coincide con {on}",
      "date": "{description} necesita estar una fecha válida",
      "description": "Este campo",
      "email": "{description} debe ser una dirección válida de correo electrónico",
      "empty": "{description} no puede estar vacío",
      "equalTo": "{description} debe ser igual a {is}",
      "even": "{description} debe ser número par",
      "exclusion": "{description} es reservado",
      "greaterThan": "{description} debe ser mayor que {gt}",
      "greaterThanOrEqualTo": "{description} debe ser mayor que o igual a {gte}",
      "inclusion": "{description} no está incluido en la lista",
      "invalid": "{description} es inválido",
      "lessThan": "{description} debe ser menos que {lt}",
      "lessThanOrEqualTo": "{description} debe ser menos que o igual a {lte}",
      "notANumber": "{description} no es un número",
      "notAnInteger": "{description} debe ser un numero entero",
      "odd": "{description} debe ser número impar",
      "onOrAfter": "{description} debe estar en o después de {onOrAfter}",
      "onOrBefore": "{description} debe estar en o antes de {onOrBefore}",
      "otherThan": "{description} Debe ser otra cosa que {value}",
      "phone": "{description} debe ser un número de teléfono válido",
      "positive": "{description} debe ser un número positivo",
      "present": "{description} debe estar en blanco",
      "required": "{description} se requiere",
      "singular": "{description} no se puede ser una colección",
      "tooLong": "{description} es demasiado largo (el máximo es {max} caracteres)",
      "tooShort": "{description} es demasiado corto (el mínimo es {min} caracteres)",
      "url": "{description} debe ser una dirección URL válida",
      "wrongDateFormat": "{description} necesita ser en el formato de {format}",
      "wrongLength": "{description} es la longitud incorrecta (debe ser {is} caracteres)"
    },
    "general": {
      "aamcMethod": "Método de AAMC",
      "aamcSchoolId": "Identificación de la escuela AAMC (\"ID de la institución\")",
      "academicLevel": "Nivel Académico",
      "academicLevels": "Niveles Académicos",
      "academicYear": "Año Academico",
      "accessAllMaterialsForThisCourse": "Acceso Todos los Materiales para este Curso",
      "accountEnabled": "Cuenta Activa",
      "actions": "Acciones",
      "activate": "Activar",
      "active": "Activo",
      "activeFilters": "Filtros Activos",
      "activities": "Actividades",
      "add": "Agregue",
      "addCohort": "Agregar Cohorte",
      "addNew": "Agregue Nuevo",
      "admin": "Admin",
      "administrator": "Administrador",
      "administratorCount": "Hay {count, plural, =1 {1 administrador} other {# administradores}}",
      "administratorEmail": "Correo Eelectrónico del Administrador",
      "administrators": "Administradores",
      "advancedOptions": "Opciones Avanzadas",
      "all": "Todos",
      "allAcademicYears": "Todos Los Años Académicos",
      "allCourses": "Todos Los Cursos",
      "allEvents": "Todos los Eventos",
      "allOtherMembers": "Todos otros miembros de {topLevelGroupTitle}",
      "allReports": "todos reportes",
      "allSchools": "Todas las Escuelas",
      "allVocabularies": "Todos los Vocabularios",
      "allWeeks": "Todas las Semanas",
      "anything": "Caulquier Cosa",
      "apiVersionMismatch": "Incompatibilidad de versión de API",
      "apiVersionMismatchDetails": "Este interfaz de usuario no corresponde a la versión del API con el cual se comunica. Por favor notifique a un administrador o el dueño técnico del sistema con respecto a este error <em>inmediatamente</em>, no puede trabajar con Ilios hasta que esto ha sido resuelto",
      "assessment": "Evaluación",
      "assessmentMethods": "Métodos de Evaluación",
      "assessmentOption": "Opción de Evaluación",
      "assessments": "Evaluacións",
      "assignedTerms": "Términos asignados",
      "associatedCourses": "Cursos Asociados",
      "associatedGroups": "Grupos Associados",
      "associatedWith": "Ssociado con",
      "attendanceIsRequired": "Se require asistencia",
      "attendanceIs_Required_": "Se <strong><em>require</em></strong> asistencia",
      "attendanceRequired": "Asistencia Requerida",
      "attribute": "Atributo",
      "author": "Autor",
      "autoUpdatingSeconds": "{count, plural, =1 {Actualización automática en 1 segundo...} other {Actualización automática en # segundos...}}",
      "available": "Disponible",
      "availableCohorts": "Clases de La Graduación Disponibles",
      "availableLearnerGroups": "Grupos de Aprendedores Disponibles",
      "averageHoursOfInstructionPerWeek": "Promedio de horas de instrucción por semana",
      "back": "Volver",
      "backToAdminDashboard": "Atrás a Panel de Control",
      "backToCalendar": "Atrás a Calendario",
      "backToCourse": "Volver al Curso",
      "backToCourses": "Atrás a la Lista de Cursos",
      "backToDashboard": "Detrás a Tablero",
      "backToDetails": "Atrás a Las Detalles de la Sesión",
      "backToInstructorGroups": "Atrás a la Lista de Grupos de Instructores",
      "backToProgramYear": "Atrás a Año de Programa",
      "backToProgramYears": "Atrás a Años de Programas",
      "backToPrograms": "Atrás a Programas",
      "backToReport": "Atrás a Reporte",
      "backToReports": "Atrás a Reportes",
      "backToSchools": "Atrás a la Lista de Escuelas",
      "backToSessionList": "Atrás a La Lista de Sesiones",
      "backToTableOfContents": "Volver a la tabla de contenido",
      "backToTitle": "Atrás a {title}",
      "bad": "Malo",
      "badCredentials": "Incorrecta contraseña o nombre de usuario",
      "calendar": "Calendario",
      "calendarOff": "Calendario Apagado",
      "calendarOn": "Calendario en Uso",
      "campusId": "Campus ID",
      "canNotContinueWithInvalidRecords": "No se puede continuar con estos registros no válidos, por favor, corrija y vuelva a subir el archivo.",
      "cancel": "Cancelar",
      "childSequenceOrder": "Orden de Secuencia de Los Hijos",
      "chooseCohortTitle": "Seleccione Matriz Para",
      "citation": "Citación",
      "city": "Ciudad",
      "classOf": "Clase de {year}",
      "clearDates": "Borrar Fechas",
      "clearErrors": "Borrar los Errores y Volver a Ilios",
      "clearFilters": "Borrar Filtros",
      "clerkship": "Rotación",
      "clerkshipSequenceBlocks": "Bloques de secuencia de rotación",
      "clerkshipType": "Tipo de Rotación",
      "clickForMore": "Haga clic para más información",
      "clickToAddDescription": "Haga clic para añadir una descripción",
      "clickToAddTermDescription": "Haga clic para agregar notas descriptivas",
      "clickToEdit": "Hacer clic para editar",
      "close": "Cerrar",
      "cohort": "Clase de la Graduación",
      "cohortMembersNotInGroup": "Miembros de la Clase NO Asignados a {groupTitle} ",
      "cohorts": "Clases de la Graduación",
      "cohortsManageTitle": "Administrar Clases de La Graduación",
      "collapseDetail": "Esconder Detalles",
      "color": "Color",
      "competencies": "Competencias",
      "competenciesManageTitle": "Maneje Competencias",
      "competency": "Competencia",
      "competencyCount": "{count, plural, =1 {Hay 1 competencia} other {Hay # competencias}}",
      "completeSessions": "Sesiónes Completas: Listo a Publicar",
      "confirmLogout": "Usted ha cerrado la aplicación Ilios con éxito. Para asistir con la protección de su cuenta y la seguridad de otros, favor de cerrar todas las ventanas del navegador.",
      "confirmObjectiveRemoval": "¿Está seguro que desea eliminar este objetivo?",
      "confirmObjectiveRemovalMultipleSessionObjectives": "Está a punto de eliminar este objetivo de curso, que está vinculado a {count} objetivos de sesión. Esto eliminará también esas relaciones. ¿Está seguro que desea continuar?",
      "confirmRemoval": "¿Está seguro que desea eliminar este material de aprendizaje?",
      "confirmRemove": "¿Estás seguro que quieres borrar este ofrecimiento con {learnerGroupCount} grupos de aprendedores? Esta acción no se puede deshacer.",
      "confirmRemoveCourse": " ¿Estás seguro que quieres borrar este curso, con {publishedOfferingCount} ofrecimientos publicados? Esta acción borrará todas las sesiones y ofrecimientos para este curso y no se puede deshacer.",
      "confirmRemoveInstructorGroup": "¿Estás seguro que quieres remover este grupo de instructores, con {instructorCount} instructores? Esta acción no se puede deshacer.",
      "confirmRemoveLearnerGroup": "¿Estás seguro que quieres borrar este grupo de aprendedores, con {subgroupCount} grupos subordinados? Esta acción no se puede deshacer.",
      "confirmRemoveProgram": "¿Estás seguro que quieres borrar este programa? Esta accion no se puede deshacer.",
      "confirmRemoveProgramYear": "¿Está seguro de que desea eliminar este año del programa?   Hacer tan también quitará su cohorte estudiantil asociada y grupos y asociaciones con {courseCount} curso(s), y no se puede deshacer.",
      "confirmSessionRemoval": "¿Está seguro que desea eliminar este sessión?",
      "confirmVocabularyRemoval": "¿Está seguro que desea eliminar este vocabulario?",
      "connectionLost": "Conexión Perdida.",
      "contentAuthor": "Autor del Contenido",
      "continue": "Continuar",
      "copiedSuccessfully": "Copiado con Éxito",
      "copy": "Copiar",
      "copyIcsFeedUrl": "Copiar Mi Enlace ICS",
      "copyLink": "Copiar el enlace",
      "copySession": "Copiar una Sesión",
      "copySessionSummary": "Usted va a copiar esta sesión (sin datos del horario) en el curso seleccionado. La sesión nueva va a incluir todos los objectivos, terminos, y materiales de aprendizaje.  Para continuar haga click en \"cumplido\".  Para regresar a su sesión corriente, haga click en \"cancelar\".",
      "copySuccess": "La Copia se ha Completado con Éxito",
      "copyWithLearners": "Copiar Con Alumnos",
      "copyWithoutLearners": "Copiar Sin Alumnos",
      "copyrightAgreement": "El archivo que estoy subiendo es mío o tengo permiso expreso para reproducir y / o distribuir este artículo, o su uso aquí ha sido evaluado como permisible por la doctrina del uso justo. Este archivo no contiene ninguna información protegida de la salud, y su utilización aquí está en conformidad con las políticas del gobierno y Universidad sobre derechos de autor y de seguridad de la información y las directrices de mi institución para la conducta profesional.",
      "copyrightPermission": "Permisos de Derechos de Autor",
      "copyrightRationale": "Justificación para Derechos de Autor",
      "couldNotFindUserCampusId": "No se pudo encontrar un usuario con el campusId {campusId}",
      "countAsOneOffering": "Cuenta como un Ofrecimiento",
      "country": "País",
      "course": "Curso",
      "courseDirector": "Director del Curso",
      "courseLeadership": "Liderazgo del Curso",
      "courseLearningMaterials": "Materiales de Aprendizaje del Curso",
      "courseLevels": "Niveles de Curso",
      "courseObjectives": "Objetivo del Curso",
      "courseOrType": "Curso / Tipo",
      "courseRollover": "Copiar Curso",
      "courseRolloverSuccess": "Curso Copiado con Éxito",
      "courseRolloverSummary": "Va a rodar este curso en el seleccionado año académico, junto con todas las opciones seleccionadas.. Para continuar, haga clic en \"done\". Para volver a su curso corriente, haga clic en \"cancel\"",
      "courseRolloverYearUnavailable": "El Curso Ya Existe",
      "courseTitle": "Titulo de Curso",
      "courseTitleFilterPlaceholder": "Aplicar un filtro por título de curso",
      "courseTitlePlaceholder": "Entre en un título para este curso",
      "courseVisualizations": "Visualizaciones de Cursos",
      "courses": "Cursos",
      "coursesAndSessions": "Cursos y Sesiones",
      "create": "Crear Nuevo Usuario",
      "createBulk": "Subir Múltiples Usuarios.",
      "createNew": "Crear Nuevo",
      "createNewGroup": "Crear Este Grupo",
      "currentCompetency": "Competencia Actual",
      "currentSchool": "Escuela Actual",
      "currentlySearchingPrompt": "buscando...",
      "curriculumInventory": "Inventario de plan de Estudios",
      "curriculumInventoryInstitutionalInfo": "Inventario de plan de Estudios Información Institucional",
      "curriculumInventoryReport": "Informe de Inventario de Plan de Estudios",
      "curriculumInventoryReportLeadership": "Liderazgo de Inventario de plan de Estudios",
      "curriculumInventoryReportRollover": "Copiar Inventario de plan de Estudios",
      "curriculumInventoryReportRolloverSuccess": "Inventario Copiado con Éxito",
      "curriculumInventoryReportRolloverSummary": "Esta acción copiará la estructura de este informe para ser utilizado en su año objetivo. No va a copiar la información del curso, sólo la estructura de bloque de secuencia de nivel superior. Usted tendrá que añadir la información del curso apropiado una vez que han rodado sobre la estructura del informe existente.",
      "curriculumInventoryReports": "Informes de Inventario de Plan de Estudios",
      "dashboard": "Tablero",
      "date": "Fecha",
      "dateTime": "Fecha/Hora",
      "day": "Día",
      "defaultInstructors": "Instructores Predeterminados",
      "defaultInstructorsNotLoaded": "(Instructores predeterminados no se cargarán: por favor introduzca el valor deseado.)",
      "defaultLocation": "Ubicación Predeterminada",
      "defaultLocationNotLoaded": "(Ubicación predterminada no se cargará: por favor introduzca el valor deseado.)",
      "departments": "Departmentos",
      "deprecatedAbbreviation": "obs.",
      "description": "Descripción",
      "detail": "Detalle",
      "details": "Detalles de Curso",
      "developer": "Desarrollador",
      "director": "Director",
      "directorCount": "Hay {count, plural, =1 {1 director} other {# directores}}",
      "directors": "Directores",
      "disableUser": "Desactivar el usuario",
      "disabled": "desactivado",
      "displayName": "Nombre de Mostrar",
      "doesNotMatchUserRecord": "Cargado {description} no coincide con la información en Ilios, cuál es \"{record}\"",
      "domain": "Dominio",
      "done": "Acabado",
      "download": "Descargar",
      "downloadCompetencyMap": "Descargar el mapa de competencias",
      "downloadResults": "Descargar Resultados",
      "draft": "Borrador",
      "dueBefore": "Debido antes de {name} ({date})",
      "dueBeforeLink": "Debido antes de <a href='{link}'>{name}</a> ({date})",
      "dueBy": "Debido Por",
      "duePriorTo": "Debido antes de",
      "dueThisDay": "Debido por esta fecha",
      "duration": "Duración",
      "durationHours": "{count, plural, =1 {un hora} other {# horas}}",
      "durationInDays": "Duración (en Días)",
      "durationInYears": "Duración (en Años)",
      "durationMinutes": "{count, plural, =1 {1 minudo} other {# minudos}}",
      "edit": "Editar",
      "educationalYear": "Año Escolar",
      "email": "Correo",
      "enabled": "Activa",
      "end": "Termino",
      "endDate": "Fecha de Cumplir",
      "endTime": "Hora de Cumplir",
      "ends": "Termina",
      "error": "Error",
      "errorDisplayMessage": "Lo sentimos! Algo salió mal con su pedido. Por favor, trate de actualizar la página o revise de nuevo más tarde.",
      "errorSavingAuthentication": "Las cuentas fueron creadas para estos usuarios, pero no podrán iniciar sesión.  Esto generalmente sucede cuando el nombre de usuario ya existe, pero pertenece a otro usuario.  Por favor seleccione cada usuario y crear un nombre de usuario y contraseña.",
      "errorSavingUser": "Estos usuarios fueron incapaces de salvarse. Por favor, compruebe que no existen.",
      "errors": "Errores",
      "etAl": "et al.",
      "exclude": "Excluir",
      "excludeFromSync": "Excluir de la sincronización",
      "existingGroup": "Grupo Existente",
      "expandDetail": "Enseñar Detalles",
      "externalId": "Identificación del Curso",
      "file": "Archivo",
      "fileSizeError": "Este archivo es demasiado grande.  El tamaño máximo es {maxSize}.",
      "fileTypeError": "Archivo mal subido: {fileType} no parece ser válido ficha separado archivo.",
      "fileUploadError": "No pudimos subir este archivo.   Por favor, asegúrese de que está conectado a la red.  Si este problema persiste, por favor avísenos.",
      "filterByNameOrEmail": "Aplicar un Filtro por nombre o email",
      "filterPlaceholder": "Empieza marcando para aplicar un filtro",
      "finalErrorDisplayMessage": "¡Zoinks! Algo es muy incorrecto. Por favor refresque su navegador y avísenos en support@iliosproject.org si sigue viendo este mensaje. ",
      "finalResults": "Resultados Finales",
      "finalize": "Finalizar",
      "finalizeReportConfirmation": "Finalizando este informe, lo salvará en su estado actual a la base de datos de Ilios como un objeto de la referencia permanente, y ninguna otra edición será posible.",
      "finalized": "Finalizado",
      "finalizingReport": "Finalizando informe, esto puede tardar un tiempo ...",
      "findDirector": "Buscar Director",
      "findInstructorOrGroup": "Buscar Instructor o Grupo",
      "firstName": "Nombre",
      "firstOffering": "Ofrecimiento Primero",
      "for": "Para",
      "formative": "formativa",
      "formerStudent": "Antiguo Estudiante",
      "friday": "Viernes",
      "fullName": "Nombre completo",
      "globalSearchPlaceholder": "Encontrar Usuarios...",
      "go": "Vaya",
      "goToUser": "Mostrar registro de usuario en Ilios",
      "good": "Bueno",
      "groupByCompetencies": "Agrupe por Competencias",
      "groupMembers": "Miembros de grupo corriente",
      "groupName": "Nombre De Grupo",
      "groups": "Grupos",
      "hideCalendar": "Esconda Calendario",
      "hideFilters": "Esconde Filtros",
      "home": "Inicio",
      "hours": "Horas",
      "icsAdminInstructions": "Para agregar el calendario de Ilios de un usuario a otra aplicación o servicio, será necesario utilizar esta URL.  esta dirección URL es como una contraseña. ¡Quien lo sabe puede ver el calendario de este usuario!",
      "icsFeed": "Fuente de ICS",
      "icsInstructions": "Para agregar tu calendario a otra aplicación o servicio, utiliza esta URL.  Esta URL es como una contraseña.  ¡Cualquiera persona que la sepa puede ver tu calendario!",
      "id": "ID",
      "ignore": "Ignorar",
      "ilios": "Ilios",
      "iliosUpdatePending": "¡Huzzah, hemos hecho Ilios mejor! Obtendrá las cosas nuevas en su próximo inicio de sesión, o haga clic para actualizar ahora.",
      "ilm": "ILM",
      "ilmDue": "AI - Debido Por",
      "inactive": "inactivo",
      "include": "Incluir",
      "includedInGrade": "Incluido en Grado",
      "incompleteSessions": "Sesiónes Incompletas: No se pueden publicar",
      "independentLearning": "Aprendizaje Independiente",
      "independentLearningActivities": "Aprendizaje Independiente Actividades",
      "instructionalMethod": "Método de Instrucción",
      "instructionalMethods": "Métodos de Instrucción",
      "instructionalNotes": "Notes para la Instrucción",
      "instructor": "Instructor",
      "instructorGroup": "Grupo de Instructores",
      "instructorGroupTitle": "Titulo de Grupo de Instructores",
      "instructorGroupTitleFilterPlaceholder": "Aplicar un Filtro por Título de Grupo de Instructor",
      "instructorGroupTitlePlaceholder": "Entre en un Título para este grupo de Instructores",
      "instructorGroups": "Grupos de Instructores",
      "instructors": "Instructores",
      "instructorsManageTitle": "Administrar Instructores",
      "invalidDatetimes": "Fecha/Hora Inválida",
      "invalidUsers": "Usuarios no Válidos",
      "invalidateTokens": "Invalida Todos los Tokens",
      "invalidateTokensConfirmation": "Esto invalidará todas los tokens de API existentes y ustedes tendrán que generar nuevos tokens para cualquier aplicación que tengan. ¿Está seguro que desea hacer esto?",
      "isSelective": "Es selectivo",
      "isTrack": "Es la Pista",
      "itemCode": "Código de artículo",
      "language": {
        "en-us": "English (en)",
        "es": "Español (es)",
        "fr": "Français (fr)"
      },
      "lastName": "Apellido",
      "lastUpdate": "Última actualización",
      "learner": "Aprendedor",
      "learnerAssignments": "{groupTitle} Asignaciónes de Aprendedores",
      "learnerGroupTitle": "Título de Grupo de Instructores",
      "learnerGroupTitleFilterPlaceholder": "Aplicar un Filtro por Título de Grupo de Aprendedor",
      "learnerGroupTitlePlaceholder": "Entre en un Título para este grupo de aprendedores",
      "learnerGroups": "Grupos de Aprendedores",
      "learnerGroupsManageTitle": "Administrar Grupos de Aprendedores",
      "learners": "Aprendedores",
      "learningMaterial": "Material de Aprendizaje",
      "learningMaterialManageTitle": "Administrar Materiales de Aprendizaje",
      "learningMaterials": "Materiales de Aprendizaje",
      "learningMaterialsSummary": "Resumen de Materiales de Aprendizaje",
      "level": "Nivel",
      "link": "Enlace de la Web",
      "linkedCompetencies": "Competencias Vinculadas",
      "linkedCompetencyCount": "{count, plural, =1 {1 tiene una competencia vinculada} other {# tienen competencias vinculadas}}",
      "listByPriority": "Lista por prioridad",
      "lmDescriptorTitle": "Seleccione Descriptores de MeSH para Materiales de Aprendizaje",
      "loading": "Marcando Ilios",
      "loadingEvents": "Cargando eventos...",
      "loadingMaterials": "Carga de Materiales...",
      "loadingSessionTypes": "Cargando Tipos de Sesión...",
      "location": "Ubicación",
      "login": "Inicio",
      "logo": "Logo de Ilios",
      "logout": "Cerrar aplicación",
      "makeRecurring": "Hacer Recurrente",
      "manage": "Manejar",
      "manageAPITokens": "Maneje Tokens de la API",
      "manageCompetencies": "Maneje Competencias",
      "manageInstitutionalInfo": "Administrar información institucional del CIR",
      "manageLeadership": "Maneje el Liderazgo",
      "manageSessionAttributes": "Maneje Atributos de la Sesión",
      "manageUsers": "Administrar Usuarios",
      "manageUsersSummaryTitle": "Usuarios de Ilios",
      "manageVocabularies": "Maneje Vocabularios",
      "markAsScheduled": "Marque Como Programado",
      "matchGroups": "Igualar Grupos",
      "materials": "Materiales",
      "matriculationYear": "Año de Inscripción",
      "maximum": "Máximo",
      "members": "Miembros",
      "menu": "El Menú de Ilios",
      "mesh": "MeSH",
      "meshCount": "{count, plural, =1 {1 tiene MeSH} other {# tienen MeSH}}",
      "meshManageTitle": "Administrar MeSH",
      "meshSearchPlaceholder": "Buscar MeSH",
      "meshTerm": "Término de MeSH",
      "meshTerms": "Terminos de MeSH",
      "middleName": "Segundo Nombre",
      "minimum": "Mínimo",
      "minutes": "Minutos",
      "missingCohortMessage": "Favor de agregar por lo menos un Clase de La Graduación a este curso.",
      "missingCompetenciesMessage": "Favor de agregar por lo menos una competencia para este año de programa.",
      "missingCourseObjectivesMessage": "Favor de agregar por lo menos un objetivo al Curso.",
      "missingItems": "Artículos Faltantes",
      "missingPassword": "Contraseña requirida",
      "missingRequiredUsername": "'Nombre de Usuario' se requiere para la entrada al sistema; este usuario será incapaz a la entrada al sistema hasta que un username sea entrado",
      "missingUsername": "Nombre de usaurio requirido",
      "modifyUserPassword": "Haga clic aquí para restablecer la contraseña.",
      "monday": "Lunes",
      "month": "Mes",
      "moreInputRequiredPrompt": "sigue marcando...",
      "moveToGroup": "{count, plural, =1 {Mueva estudiante a {groupTitle}} other {Mueva # estudiantes a {groupTitle}}}",
      "multiDay": "Días Múltiples",
      "multiday": "de Días Múltiples",
      "multidayEvents": "Eventos de Varios Días",
      "multiple": "Múltiple ",
      "multipleGroups": "Múltiples grupos",
      "multipleUsersFoundWithCampusId": "Varios usuarios encontrados con el campusId {campusId}",
      "mustBeSameDayOfWeekAsCurrentStartDate": "Debe ser el mismo día de la semana en curso como fecha de inicio",
      "myCalendar": "Mi Calendario",
      "myCourses": "Mis Cursos",
      "myMaterials": "Mis Materiales",
      "myProfile": "Mi Perfil",
      "myReports": "Mis Reportes",
      "mySchedule": "Mi Horario",
      "name": "Nombre",
      "narrative": "narrativa",
      "newCourse": "Curso Nuevo",
      "newCurriculumInventoryReport": "Nuevo Informe de Inventario de Plan de Estudios",
      "newDomain": "Nuevo Dominio",
      "newInstructorGroup": "Nuevo Grupo de Instructores",
      "newLearnerGroup": "Nuevo Grupo de Aprendedores",
      "newObjective": "Nuevo Objetivo",
      "newObjectiveSaved": "Nuevo Objetivo Guardado",
      "newOffering": "Nueva Oferta",
      "newProgram": "Programa Nuevo",
      "newProgramYear": "Nuevo Año Del Programa",
      "newReport": "Nuevo Reporte",
      "newSchool": "Nueva Escuela",
      "newSequenceBlock": "Nuevo Bloque de Secuencia",
      "newSession": "Sesión Nueva",
      "newTerm": "Nuevo Termino",
      "newToken": "Nuevo Símbolo (Token)",
      "newUpdates": "Nuevas actualizaciones",
      "newUser": "Nuevo Usuario",
      "newUsersCreatedSuccessfully": "Nuevos usuarios creados con éxito",
      "newUsersCreatedWarning": "Algunos usuarios fueron creados, pero hubo errores.",
      "next": "Siguiente",
      "no": "No",
      "noAccountExists": "Su cuenta {accountName} no coincide con los registros de Ilios. Si usted necesita más asistencia, póngase en contacto con su administrador de Ilios",
      "noAssociatedCompetencies": "No Competencias Asociadas",
      "noAssociatedCompetency": "No Competencia Asociada",
      "noAvailableCohorts": "Ningun Clase de La Graduación Disponible",
      "noAvailableLearnerGroups": "No grupo de aprendedores disponible; Este curso no contiene ninguna clase de la graduación.",
      "noCohorts": "No hay ningún clase de la graduación en este curso",
      "noCourseLearningMaterialsAvailable": "Ningunos Materiales Didácticos del Curso Disponibles",
      "noCourses": "No hay ningún curso en esta escuela",
      "noCurriculumInventoryInstitutionalInfo": "No se ha configurado información institucional para esta escuela.",
      "noLearnerGroups": "No Grupos de Aprendedores",
      "noOfferingLearnerGroups": "Ningunos Grupos",
      "noOfferings": "Esta sesión no tiene ningunos ofrecimientos",
      "noPermissionsInOtherSchools": "No tiene los permisos necesarios de añadir a este usuario a cualquier otra escuela.",
      "noPopulateGroup": "¡No! Déjelo vacío. Voy a agregar alumnos yo mismo.",
      "noPrintDraft": "Cursos que se encuentran en estado borrador no se puede imprimir",
      "noProgramYears": "No hay años de programa en este programa",
      "noResultsFound": "Ningunos Resultados Encontrados",
      "noSearchResults": "Su búsqueda no regresó ningunos resultados",
      "noSearchResultsPrompt": "no resultados",
      "noSelectedCompetencies": "No hay competencias seleccionadas",
      "noSelectedLearnerGroups": "No hay grupos de aprendedores seleccionados",
      "noSequenceBlocks": "No hay bloques de secuencia en este informe.",
      "noSessionLearningMaterialsAvailable": "Ningunos Materiales Didácticos de la Sesión Disponibles",
      "noSessions": "No hay ningún sesión en este curso",
      "noSubSequenceBlocks": "Este bloque de la secuencia no tiene bloques de secuencia anidadas.",
      "noSubgroups": "No hay ningunos grupos subordinados en este grupo de aprendedores",
      "noUnselectedCompetencies": "No hay competencias disponibles para selección",
      "noUnselectedLearnerGroupsInCohort": "No hay grupos de aprendedores no seleccionados en este Clase de La Graduación",
      "nonClerkshipSequenceBlocks": "Bloques de secuencia que no son de la rotación",
      "nonStudent": "No estudiante",
      "none": "Ningún",
      "notAClerkship": "No una Rotación",
      "notApplicableAbbr": "n/d",
      "notFoundMessage": "Malditas! Éso no puede encontrar. Favor de verificar la dirección de la página, y trata otra vez.",
      "notPublished": "No Publicado",
      "notes": "Notas",
      "numberOfEvents": "Numero de eventos",
      "numberOfEventsFeaturingThisAsThePrimaryMethod": "Número de eventos con esto como método principal",
      "numberOfExams": "Numero de Exámenes",
      "numberOfFormalInstructionalHoursPerCourse": "Número de horas formales de instrucción por curso",
      "numberOfFormativeAssessments": "Número de evaluaciones formativas",
      "numberOfGroups": "Número de grupos",
      "numberOfGroupsToGenerate": "número de grupos para generar.",
      "numberOfNonPrimaryOccurrencesOfThisMethod": "Número de ocurrencias no primarias de este método",
      "numberOfSummativeAssessments": "Número de evaluaciones sumativas",
      "objective": "Objetivo",
      "objectiveCompetencyManagerTitle": "Seleccione Competencia Principal para Objetivo",
      "objectiveCount": "Hay {count, plural, =1 {1 objectivo} other {# objectivos}}",
      "objectiveDescriptorTitle": "Seleccione Descriptores de MeSH para Objetivo",
      "objectiveMap": "Mapa de Objetivos",
      "objectiveParentTitle": "Seleccione Objetivos Principales",
      "objectiveParentTitleSingular": "Seleccione Objetivo Principale",
      "objectiveParentsTitle": "Seleccione Objetivos Matrices",
      "objectiveTitle": "Objetivos de la Sesión",
      "objectives": "Objetivos",
      "objectivesTotalInstructionalTime": "Objetivos: Tiempo de Instrucción Total",
      "objectivesWithNoHours": "Los siguientes objetivos del curso no están vinculados a ninguna sesión, o están vinculados sólo a las sesiones sin datos de duración.",
      "objectivesWithNoLink": "No hay objetivos del curso actualmente vinculados a tiempo de instrucción.",
      "off": "Apagado",
      "offering": "Ofrecimiento",
      "offerings": "Ofrecimientos",
      "on": "Encendido",
      "openSmallGroupGenerator": "Abrir el Generador de Ofrecimientos para Grupos Pequeños",
      "optional": "Opcional",
      "optionalElective": "Opcional (electiva)",
      "orderInSequence": "Orden en la Secuencia",
      "ordered": "Ordenado",
      "other": "Otro",
      "otherId": "Otro ID",
      "otherVisualizations": "Otras Visualizaciones",
      "overview": "Visión Amplia",
      "ownedBy": "propiedad de {owner}",
      "owner": "Dueño",
      "pagedResultsCount": "Mostrando {start} - {end} de {total}",
      "parallel": "Paralelo",
      "parentCount": "{count, plural, =1 {1 tiene un Principal objectivo} other {# tienen Objectivos Principales}}",
      "parentObjectives": "Objetivos de Matriz",
      "password": "Contraseña",
      "pendingUpdatesSummary": "{count, plural, =1 {Hay un usuario que necesita atención} other {Hay # usuarios que necesitan atención}}",
      "pendingUpdatesSummaryTitle": "Actualizaciones del Directorio del Campus",
      "pendingUserUpdates": {
        "emailMismatch": "La dirección de correo electrónico en el directorio ({value}) does not match the email in Ilios ({email}).",
        "filterBy": "Filtro por nombre de uauario",
        "missingFromDirectory": "No se puede encontrar el usuario en el directorio, por favor actualice, desactive, o excluya su cuenta de la sincronización.",
        "updateIlios": "Actualice Ilios"
      },
      "people": "Gente",
      "perPage": "Por Página",
      "performsNonLearnerFunctions": "Performe Funciones de Non-Aprendedor",
      "permissions": "Permisos",
      "permissionsInOtherSchools": "Permisos en Otras Escuelas",
      "phone": "Teléfono",
      "physicianCompetencyReferenceSet": "Conjunto de referencia de competencias médicas",
      "physicianCompetencyReferenceSetAbbr": "PCRS",
      "populateGroup": "¿Quieres auto-llenar este grupo?",
      "preWork": "Pre Trabajo",
      "preferredEmail": "Email preferido",
      "prerequisites": "Prerrequisitos",
      "prev": "Anterior",
      "primary": "Principal",
      "primaryCohort": "Clase de la Graduación Principal",
      "primarySchool": "Escuela Primaria",
      "printSummary": "Sumario Printado",
      "program": "Programa",
      "programAndCohort": "Programa/Clase de La Graduación",
      "programCohorts": "Clases de La Graduación",
      "programExpectations": "Expectativas del programa",
      "programExpectationsId": "ID de expectativas del programa",
      "programLeadership": "Liderazgo del Programa",
      "programTitle": "Titulo de Programa",
      "programTitleFilterPlaceholder": "Filtrar por Titulo de Programa",
      "programTitlePlaceholder": "Meter un titulo para este programa",
      "programTitleShort": "Titulo de Programa (corto)",
      "programYear": "Año de Programa",
      "programYearVisualizations": "Visualizaciones de Año de Programa",
      "programYears": "Años de Programa",
      "programs": "Programas",
      "promoteToPrimaryCohort": "Ascender al Principal Cohorte",
      "publicationStatus": "Estado de Publicación",
      "publishAllConfirmation": "Publique {publishCount}, Programe {scheduleCount}, y ignore {ignoreCount} sesiónes",
      "publishAllSessions": "Publique {sessionCount} sesiónes",
      "publishAsIs": "Publique Como Es",
      "publishCourse": "Publique Curso",
      "publishProgram": "Publique El Programa",
      "publishProgramYear": "Publique Año de Programa",
      "publishSession": "Publique La Sesión",
      "published": "Publicado",
      "publishedSuccessfully": "Publicado con Éxito",
      "read": "Leer",
      "reconnectNow": "Volver a conectar ahora",
      "reconnectionSeconds": "{count, plural, =1 {Reconectando en 1 segundo...} other {Reconectando en # segundos...}}",
      "refreshIcsFeedKey": "Refresque la llave de la Fuente de ICS para este usuario.",
      "refreshTheBrowser": "Refresque el Navegador",
      "remove": "Remover",
      "removeAll": "Quitar Todos",
      "removeCohort": "Remover Cohorte",
      "removeLearnerToCohort": "{count, plural, =1 {Quite estudiante a {cohort}} other {Quite # estudiantes a {cohort}}}",
      "removePrimaryCohort": "Remover Principal Cohorte",
      "removedSuccessfully": "Eliminado con éxito",
      "reportConfirmRemove": "¿Está seguro que desea eliminar este informe? Esta acción no se puede deshacer.",
      "reportDescriptionPlaceholder": "Por favor entre en una breve descripción sobre este informe.",
      "reportDisplayTitleWithObject": "Todos {subject} para {object} en {school}",
      "reportDisplayTitleWithoutObject": "Todos {subject} en {school}",
      "reportMissingInstructor": "Instructor es Necesario",
      "reportMissingMeshTerm": "Término de MeSH es Necesario",
      "reportMissingObjectForInstructor": "Asociación es Necesario Cuando Instructor es el Sujeto",
      "reportMissingObjectForMeshTerm": "Asociación es Necesario Cuando Término de MeSH es el Sujeto",
      "reportName": "Nombre de Informe",
      "reportNamePlaceholder": "Por favor Introduzca un Nombre de Informe",
      "reportTitle": "Título de Informe",
      "required": "Requerido",
      "requiredInTrack": "Requerido en Pista",
      "resourceTypes": "Tipos de recursos",
      "results": "Resultados",
      "resultsCount": "{count, plural, =1 {1 resultado} other {# resultados}}",
      "returnToPreviousPage": "Volver a la Página Anterior",
      "reviewMissingItems": "Revisar {count} Artículos Faltantes",
      "reviewSessions": "Sesiónes a Revisar",
      "root": "Root",
      "sampleFile": "Archivo de Ejemplo",
      "saturday": "Sábado",
      "save": "Guardar",
      "saved": "Nuevo Usuario Salvó con Éxito",
      "savedSuccessfully": "Ahorrado Con Éxito",
      "scheduled": "Programado",
      "scheduledSuccessfully": "Programdo con Éxito",
      "school": "Escuela",
      "schoolLeadership": "Liderazgo Escolar",
      "schoolName": "Nombre de escuela",
      "schools": "Escuelas",
      "search": "Buscar",
      "searchDirectory": "Busque el directorio para nuevos usuarios",
      "searchForIliosUsers": "Encuentre Usuarios de Ilios...",
      "searchPlaceholder": "encontrar material de aprendizaje",
      "searchUsers": "Buscar usuarios",
      "secondaryCohorts": "Clases de la Graduación Secondarias",
      "select": "Selecciona",
      "selectAcademicYear": "Selecciona Año Academico",
      "selectCohortsToAttach": "Seleccione la cohorte de estudiantes que desea adjuntar al nuevo curso",
      "selectCourse": "Seleccionar un curso",
      "selectMeshFor": "Seleccione MeSH para: {title}",
      "selectProgram": "Seleccione un Programa",
      "selectSchool": "Seleccione Escuela",
      "selectVocabulary": "Seleccione un Vocabulario",
      "selectYear": "Seleccione El Año Escolar",
      "selected": "Escogido",
      "selectedLearnerGroups": "Grupos de Aprendizaje Seleccionados",
      "sequenceBlock": "Bloque de Secuencia",
      "sequenceBlockConfirmRemove": "¿Está seguro que desea eliminar este bloque de secuencia? Esta acción no se puede deshacer.",
      "sequenceBlockDescriptionPlaceholder": "Por favor, introduzca una descripción para este bloque de la secuencia.",
      "sequenceBlockIsSelective": "Esta bloque de la secuencia ha sido marcada como selectiva.",
      "sequenceBlockTitlePlaceholder": "Por favor, introduzca un título para esta bloque de la secuencia.",
      "sequenceBlocks": "Bloques de la Secuencia",
      "sequenceNumber": "No. de Secuencia",
      "session": "Sessión",
      "sessionAdministration": "Administración de la Sesión",
      "sessionAttributes": "Atributos de la Sesión",
      "sessionIs": "Tipo de Sesión: {type}",
      "sessionLearningMaterials": "Materiales de Aprendizaje de la Sesión",
      "sessionObjectives": "Objetivos de la Sesión",
      "sessionTitle": "Titulo de Sesión",
      "sessionTitleFilterPlaceholder": "Aplicar un Filtro por titulo, tipo, o estado de la publicación",
      "sessionTitlePlaceholder": "Entre en un titulo para esta sesión",
      "sessionType": "Tipo de Sesión",
      "sessionTypes": "Tipos de Sesiones",
      "sessionTypesFor": "Tipos de Sesiones para {subject}",
      "sessions": "Sesiones",
      "showCalendar": "Muestre Calendario",
      "showFilters": "Enseñe Filtros",
      "showLearnerGroupEvents": "Mostrar todos los eventos para grupos de alumnos asignados",
      "showMore": "Mostrar más",
      "showNotesToStudents": "Mostrar Notas a Los Estudiantes",
      "showResultsFor": "Mostrar Resultados Para",
      "showSessionEvents": "Mostrar todos los <em>{sessionTitle}</em> eventos",
      "showSubgroupEvents": "Mostrar eventos para todos los grupos subordinados",
      "singleDay": "Un Solo Día",
      "singleGroup": "Solo grupo",
      "smallGroupMessage": "Por favor seleccione al menos un grupo de alumnos para adjuntar a su ofrecimiento de grupos pequeños. Si usted desea programar este ofrecimiento sin grupos, favor de seleccionar el botón de 'ofrecimiento' de arriba.",
      "smallGroups": "Grupos Pequeños",
      "sortMaterials": "Clasificar los materiales",
      "sortObjectives": "Clasificar los objetivos",
      "specialAttireIs_Required_": "Se <strong><em>require</em></strong> vestimenta especial",
      "specialAttireRequired": "Vestimenta Especial Requerido",
      "specialEquipment": "Equipo especial",
      "specialEquipmentIs_Required_": "Se <strong><em>require</em></strong> equipo especial",
      "specialEquipmentRequired": "Equipo Especial Requerido",
      "start": "Inicio",
      "startDate": "Fecha de Iniciar",
      "startOver": "Empezar de Nuevo",
      "startTime": "Hora de Iniciar",
      "starts": "Empieza",
      "stateOrProvince": "Estado o Provincia",
      "status": "Estado",
      "stewardingSchoolsAndDepartments": "Escuelas y Departamentos Administrativos",
      "stewardsManageTitle": "Maneje Administradores",
      "street": "Calle",
      "strong": "Fuerte",
      "student": "Estudiante",
      "subTerms": "Términos subordinados",
      "subgroupName": "Nombre del Subgrupo",
      "subgroups": "Grupos Subordinados",
      "successfullyAddedTerm": "Término Agregado Correctamente",
      "successfullyInvalidatedTokens": "Todas los tokens existentes fueron con éxito invalidadas",
      "successfullyRemovedTerm": "Término Eliminado Correctamente",
      "summary": "Resumen",
      "sunday": "Domingo",
      "supplementalCurriculum": "Plan de Estudios No Requerido",
      "table1ProgramExpectationsMappedToPcrs": "Tabla 1: Expectativas del programa asignadas a PCRS",
      "table2PrimaryInstructionalMethodByNonClerkshipSequenceBlock": "Tabla 2: Método de instrucción primario por bloque de secuencia de no rotación",
      "table3aNonClerkshipSequenceBlockInstructionalTime": "Tabla 3-A: Tiempo de instrucción del bloque de secuencia de no rotación",
      "table3bClerkshipSequenceBlockInstructionalTime": "Tabla 3-B: Tiempo de instrucción del bloque de secuencia de la rotación",
      "table4InstructionalMethodCounts": "Tabla 4: recuentos de métodos de instrucción",
      "table5NonClerkshipSequenceBlockAssessmentMethods": "Tabla 5: Métodos de evaluación de bloque de secuencia no rotación",
      "table6ClerkshipSequenceBlockAssessmentMethods": "Tabla 6: Métodos de evaluación del bloque de secuencia de la rotación",
      "table7AllEventsWithAssessmentsTaggedAsFormativeOrSummative": "Tabla 7: Todos los eventos con evaluaciones etiquetadas como formativas o sumativas",
      "table8AllResourceTypes": "Tabla 8: Todos los tipos de recursos",
      "targetCourse": "Curso de Destino",
      "taughtBy": "Ènsenada Por {instructors}",
      "term": "Término",
      "termCount": "{count, plural, =1 {Hay 1 término} other {Hay # términos}}",
      "terms": "Términos",
      "termsBySessionType": "Términos por Tipo de Sesión",
      "termsManageTitle": "Maneje Términos",
      "thisReportIsNoLongerAvailable": "Este informe ya no está disponible.",
      "thursday": "Jueves",
      "time": "Hora",
      "timedRelease": "Lanzamiento Calculado",
      "timedReleaseAddEndDate": "Agregar Fecha de Finalización de Lanzamiento",
      "timedReleaseAddStartDate": "Agregar Fecha de Inicio de Lanzamiento",
      "timedReleaseClearEndDate": "Quitar Fecha de Finalización de Lanzamiento ",
      "timedReleaseClearStartDate": "Quitar Fecha de Inicio de Lanzamiento ",
      "timedReleaseNoSchedule": "Disponible inmediatamente cuando publicado ",
      "timedReleaseOnlyEndDate": "Disponible antes de {endDate}",
      "timedReleaseOnlyStartDate": " Disponible después de {startDate}",
      "timedReleaseStartAndEndDate": " Disponible a partir de {startDate} hasta {endDate}",
      "title": "Titulo",
      "today": "Hoy",
      "token": "Token",
      "tokenInfo": "Tokens de la API se utilizan para obtener acceso a datos de Ilios. Su símbolo (token) es como una contraseña. - cualquier persona que lo tiene puede hacer cambios en su nombre. Lea más en la documentación en <a href=\"{apiDocsUrl}\">{apiDocsUrl}</a>.",
      "tooManyResults": "La búsqueda ha devuelto más de {count} resultados.  Para ver mejores resultados, intente su búsqueda otra vez con condiciones adicionales.",
      "total": "Total",
      "totalErrors": "{count, plural, =1 {Hay un error} other {Hay # errores}}",
      "totalIlmTime": " Tiempo de ILM {minutes} Minutos",
      "totalInstructionalTime": "Tiempo de Instrucción Total {minutes} Minutos",
      "totalTime": "Horas Totales",
      "totalWeeks": "Semanas totales",
      "transitionErrorMessage": "Lo sentimos! No le podemos conseguir donde pidió ir...",
      "tryHarder": "Esforzarse Más",
      "tuesday": "Martes",
      "type": "Tipo",
      "unPublishCourse": "No Publique El Curso",
      "unPublishProgram": "No Publique El Programa",
      "unPublishProgramYear": "No Publique Año de Programa",
      "unPublishSession": "No Publique La Sesión",
      "unPublishedSuccessfully": "No Publicado con Éxito",
      "unableToReconnect": "¡No se puede volver a conectar!",
      "unableToSyncUser": "Incapaz de sincronizar al usuario del directorio, por favor asegure el Campus ID es correcto.",
      "unassigned": "No asignado",
      "unassignedStudentsConfirmation": "{count, plural, =1 {Asignar 1 el usuario seleccionado a} other {Asignar # usuarios seleccionados a}}",
      "unassignedStudentsSummary": "{count, plural, =1 {Hay un estudiante que requiere asignación de cohorte} other {Hay # estudiantes que requieren asignación de cohorte}}",
      "unassignedStudentsSummaryTitle": "Estudiantes que Requieren Asignación de Cohorte",
      "undo": "Deshacer",
      "universalLocator": "Localizador universal de",
      "unlinkedObjectives": "Este curso tiene objetivo(s) no vinculados",
      "unordered": "No Ordenado",
      "unsupportedBrowserFailure": "Su navegador no está soportado oficialmente por Ilios y no puede completar esta acción.  Por favor inténtalo de nuevo utilizando un navegador actualizado.",
      "unusedObjectives": "Objetivos no Utilizados",
      "upcomingActivities": "Mis Actividades para los próximos {days} días",
      "upcomingMaterials": "Mis Materiales de Aprendizaje para los próximos {days} días",
      "updateNow": "Actualizar Ahora ",
      "updatePending": "Actualización Pendiente",
      "updateUserFromDirectory": "Actualizar este registro del usuario del directorio del campus.",
      "uploadDate": "Fecha de carga",
      "uploadGroupAssignments": "Cargar Asignaciones de Grupos",
      "uploadUsers": "Archivo con datos de usuarios (csv, tsv, tx)",
      "uploadedGroup": "Grupo Subido",
      "url": "URL",
      "userExistsInGroupHierarchy": "El usuario ya existe en el grupo de nivel superior {groupTitle} o en uno de sus subgrupos.",
      "userExistsMultipleTimesInUpload": "Este usuario ya existe en la carga.",
      "userNotAddableFromDirectory": "ste usuario está faltando información requerida y no se puede agregar",
      "userNotInGroupCohort": "El usuario no está en la cohorte de este grupo: {cohortTitle}",
      "userRole": "Función de Usuario",
      "userRoles": "Funciones de usuario",
      "username": "Nombre de Usuario",
      "users": "Usuarios",
      "usersCalendar": "Calendario de{name}",
      "usersSelected": "{count, plural, =1 {1 usuario seleccionado} other {# usuarios seleccionados}}",
      "validUntil": "¿Cuándo debe este símbolo (token) deje de funcionar??",
      "validUsers": "Usuarios Válidos",
      "verificationPreviewFor": "Vista previa de verificación para {name}",
      "view": "Vista",
      "viewAll": "Ver Todos",
      "vocabularies": "Vocabularios",
      "vocabulary": "Vocabulario",
      "vocabularyTermsFor": "Términos del Vocabulario para {subject}",
      "waitSaving": "Salvando... un momento...",
      "weak": "Débil",
      "wednesday": "Miércoles",
      "week": "Semana",
      "weekAtAGlance": "Semana de un vistazo",
      "weekOf": "Semana de {date}",
      "weeks": "Semanas",
      "when": "Cuando",
      "whichIs": "cuál es",
      "whitecoatsSlashSpecialAttire": "Abrigos blancos / vestimenta especial",
      "withXmoreOfferings": "con {count, plural, =1 {una ofrenda mas} other {# ofertas más}}",
      "write": "Escribir",
      "year": "Año",
      "yes": "Sí",
      "yesPopulateGroup": "¡Sí! Añada la cohorte entera a mi nuevo grupo.",
      "zipCode": "Código postal"
    }
  };
  _exports.default = _default;
});