define("ilios/components/user-profile", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    currentUser: Ember.inject.service(),
    classNames: ['user-profile'],
    'data-test-user-profile': true,
    canCreate: false,
    canUpdate: false,
    user: null
  });

  _exports.default = _default;
});