define("ilios/components/school-competencies-collapsed", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['school-competencies-collapsed'],
    tagName: 'section',
    school: null,
    competencies: Ember.computed('school.competencies.[]', async function () {
      const school = this.school;
      const competencies = await school.get('competencies');
      return competencies;
    }),
    domains: Ember.computed('school.competencies.[]', async function () {
      const competencies = await this.competencies;
      const domains = competencies.filterBy('isDomain');
      return domains;
    }),
    childCompetencies: Ember.computed('school.competencies.[]', async function () {
      const competencies = await this.competencies;
      const childCompetencies = competencies.filterBy('isNotDomain');
      return childCompetencies;
    })
  });

  _exports.default = _default;
});