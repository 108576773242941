define("ilios/routes/program-year-visualizations", ["exports", "ember-simple-auth/mixins/authenticated-route-mixin"], function (_exports, _authenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    store: Ember.inject.service(),

    /**
     * Prefetch related data to limit network requests
    */
    async afterModel(model) {
      const store = this.store;
      const cohort = await model.get('cohort');
      const courses = cohort.hasMany('courses').ids();
      let promises = [model.get('program'), model.get('competencies'), model.get('objectives')];

      if (courses.length) {
        promises.push(store.query('objective', {
          filters: {
            fullCourses: courses
          }
        }));
      }

      return Ember.RSVP.all(promises);
    }

  });

  _exports.default = _default;
});