define("ilios/components/myreports-list-item", ["exports", "ilios/mixins/report-title", "ember-concurrency"], function (_exports, _reportTitle, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_reportTitle.default, {
    classNames: ['myreports-list-item'],
    tagName: 'span',
    reporttitle: null,

    onReportSelect() {},

    didReceiveAttrs() {
      this._super(...arguments);

      const report = this.report;
      this.loadTitle.perform(report);
    },

    loadTitle: (0, _emberConcurrency.task)(function* (report) {
      const title = yield this.getReportTitle(report);
      this.set('reporttitle', title);
    }).restartable()
  });

  _exports.default = _default;
});