define("ilios/controllers/learner-group", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    queryParams: {
      isEditing: 'edit',
      isBulkAssigning: 'bulkupload',
      sortUsersBy: 'usersBy'
    },
    isEditing: false,
    isBulkAssigning: false,
    sortUsersBy: 'firstName',
    canCreate: false,
    canUpdate: false,
    canDelete: false
  });

  _exports.default = _default;
});