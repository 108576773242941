define("ilios/components/ilios-course-list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const CourseProxy = Ember.ObjectProxy.extend({
    currentUser: null,
    content: null,
    intl: null,
    isSaving: false,
    permissionChecker: null,
    showRemoveConfirmation: false,
    status: Ember.computed('content.{isPublished,isScheduled}', function () {
      const intl = this.intl;
      let course = this.content;
      let translation = 'general.';

      if (course.get('isScheduled')) {
        translation += 'scheduled';
      } else if (course.get('isPublished')) {
        translation += 'published';
      } else {
        translation += 'notPublished';
      }

      return intl.t(translation);
    }),
    userCanDelete: Ember.computed('content', 'content.{archived,locked}', 'currentUser.model.directedCourses.[]', async function () {
      const permissionChecker = this.permissionChecker;
      const course = this.content;

      if (course.get('isPublishedOrScheduled')) {
        return false;
      } else if (course.hasMany('descendants').ids().length > 0) {
        return false;
      }

      return permissionChecker.canDeleteCourse(course);
    }),
    userCanLock: Ember.computed('content', 'content.{archived,locked}', 'currentUser.model.directedCourses.[]', async function () {
      const permissionChecker = this.permissionChecker;
      const course = this.content;
      return permissionChecker.canUpdateCourse(course);
    }),
    userCanUnLock: Ember.computed('content', 'content.{archived,locked}', async function () {
      const permissionChecker = this.permissionChecker;
      const course = this.content;
      return permissionChecker.canUnlockCourse(course);
    })
  });

  var _default = Ember.Component.extend({
    currentUser: Ember.inject.service(),
    intl: Ember.inject.service(),
    permissionChecker: Ember.inject.service(),
    courses: null,
    query: null,
    sortBy: 'title',

    lock() {},

    remove() {},

    setSortBy() {},

    unlock() {},

    proxiedCourses: Ember.computed('courses.[]', function () {
      const intl = this.intl;
      const courses = this.courses;

      if (!courses) {
        return [];
      }

      return courses.map(course => {
        return CourseProxy.create({
          content: course,
          intl,
          currentUser: this.currentUser,
          permissionChecker: this.permissionChecker
        });
      });
    }),
    sortedCourses: Ember.computed('proxiedCourses.[]', 'sortedAscending', 'sortBy', function () {
      let sortBy = this.sortBy;

      if (-1 !== sortBy.indexOf(':')) {
        sortBy = sortBy.split(':', 1)[0];
      }

      let sortedAscending = this.sortedAscending;
      const courses = this.proxiedCourses;
      let sortedCourses = courses.sortBy(sortBy);

      if (!sortedAscending) {
        sortedCourses = sortedCourses.slice().reverse();
      }

      return sortedCourses;
    }),
    sortedAscending: Ember.computed('sortBy', function () {
      return this.sortBy.search(/desc/) === -1;
    }),
    actions: {
      remove(courseProxy) {
        this.remove(courseProxy.get('content'));
      },

      cancelRemove(courseProxy) {
        courseProxy.set('showRemoveConfirmation', false);
      },

      confirmRemove(courseProxy) {
        courseProxy.set('showRemoveConfirmation', true);
      },

      unlockCourse(courseProxy) {
        courseProxy.get('userCanUnLock').then(permission => {
          if (permission) {
            courseProxy.set('isSaving', true);
            this.unlock(courseProxy.get('content')).then(() => {
              courseProxy.set('isSaving', false);
            });
          }
        });
      },

      lockCourse(courseProxy) {
        courseProxy.get('userCanLock').then(permission => {
          if (permission) {
            courseProxy.set('isSaving', true);
            this.lock(courseProxy.get('content')).then(() => {
              courseProxy.set('isSaving', false);
            });
          }
        });
      },

      sortBy(what) {
        const sortBy = this.sortBy;

        if (sortBy === what) {
          what += ':desc';
        }

        this.setSortBy(what);
      }

    }
  });

  _exports.default = _default;
});