define("ilios/components/detail-stewards", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    classNameBindings: [':detail-stewards', ':stewards-manager', 'showCollapsible:collapsible'],
    bufferStewards: null,
    editable: true,
    isManaging: false,
    programYear: null,
    showCollapsible: Ember.computed('isManaging', 'programYear.stewards.[]', function () {
      const isManaging = this.isManaging;
      const programYear = this.programYear;
      const stewardIds = programYear.hasMany('stewards').ids();
      return !isManaging && stewardIds.get('length');
    }),
    stewardsBySchool: Ember.computed('programYear.stewards.[]', async function () {
      const programYear = this.programYear;

      if (Ember.isEmpty(programYear)) {
        return [];
      }

      const stewards = await programYear.get('stewards');
      const stewardObjects = await Ember.RSVP.map(stewards.toArray(), async steward => {
        const school = await steward.get('school');
        const schoolId = Ember.isPresent(school) ? school.get('id') : 0;
        const schoolTitle = Ember.isPresent(school) ? school.get('title') : null;
        const department = await steward.get('department');
        const departmentId = Ember.isPresent(department) ? department.get('id') : 0;
        const departmentTitle = Ember.isPresent(department) ? department.get('title') : null;
        return {
          schoolId,
          schoolTitle,
          departmentId,
          departmentTitle
        };
      });
      const schools = stewardObjects.uniqBy('schoolId');
      const schoolData = schools.map(obj => {
        const departments = stewardObjects.filterBy('schoolId', obj.schoolId);
        const rhett = {
          schoolId: obj.schoolId,
          schoolTitle: obj.schoolTitle,
          departments
        };
        return rhett;
      });
      return schoolData;
    }),
    actions: {
      collapse() {
        const programYear = this.programYear;
        const stewardIds = programYear.hasMany('stewards').ids();

        if (stewardIds.get('length')) {
          this.collapse();
        }
      },

      cancel() {
        this.setProperties({
          bufferStewards: [],
          isManaging: false
        });
      },

      addStewardToBuffer(steward) {
        //copy the array to didReceiveAttrs gets called on detail-steward-manager
        let bufferStewards = this.bufferStewards.toArray();
        bufferStewards.pushObject(steward);
        this.set('bufferStewards', bufferStewards);
      },

      removeStewardFromBuffer(steward) {
        //copy the array to didReceiveAttrs gets called on detail-steward-manager
        let bufferStewards = this.bufferStewards.toArray();
        bufferStewards.removeObject(steward);
        this.set('bufferStewards', bufferStewards);
      }

    },
    save: (0, _emberConcurrency.task)(function* () {
      yield (0, _emberConcurrency.timeout)(10);
      const programYear = this.programYear;
      const bufferStewards = this.bufferStewards;
      let stewards = yield programYear.get('stewards');
      let stewardsToRemove = stewards.filter(steward => !bufferStewards.includes(steward));
      let stewardsToAdd = bufferStewards.filter(steward => !stewards.includes(steward));
      stewardsToAdd.setEach('programYear', programYear);
      yield Ember.RSVP.all(stewardsToAdd.invoke('save'));
      yield Ember.RSVP.all(stewardsToRemove.invoke('destroyRecord'));
      this.setProperties({
        bufferStewards: [],
        isManaging: false
      });
    }),
    manage: (0, _emberConcurrency.task)(function* () {
      yield (0, _emberConcurrency.timeout)(10);
      this.expand();
      const stewards = yield this.get('programYear.stewards');
      this.set('bufferStewards', stewards.toArray());
      this.set('isManaging', true);
    })
  });

  _exports.default = _default;
});