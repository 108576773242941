define("ilios/routes/session-type-visualize-vocabularies", ["exports", "ember-simple-auth/mixins/authenticated-route-mixin"], function (_exports, _authenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    all,
    map
  } = Ember.RSVP;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    store: Ember.inject.service(),

    async afterModel(sessionType) {
      const sessions = await sessionType.get('sessions');
      return await all([sessionType.get('school'), map(sessions.toArray(), s => s.get('terms')), map(sessions.toArray(), s => s.get('course'))]);
    }

  });

  _exports.default = _default;
});