define("ilios/controllers/programs", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    currentUser: Ember.inject.service(),
    intl: Ember.inject.service(),
    permissionChecker: Ember.inject.service(),
    queryParams: {
      schoolId: 'school',
      titleFilter: 'filter'
    },
    deletedProgram: null,
    newProgram: null,
    schoolId: null,
    showNewProgramForm: false,
    titleFilter: null,
    hasMoreThanOneSchool: Ember.computed.gt('model.schools.length', 1),
    filteredPrograms: Ember.computed('titleFilter', 'programs.[]', async function () {
      const titleFilter = this.titleFilter;
      const title = Ember.isBlank(titleFilter) ? '' : titleFilter;
      const programs = await this.programs;
      let filteredPrograms;

      if (Ember.isEmpty(title)) {
        filteredPrograms = programs;
      } else {
        filteredPrograms = programs.filter(program => {
          return Ember.isPresent(program.get('title')) && program.get('title').toLowerCase().includes(title.toLowerCase());
        });
      }

      return filteredPrograms.sortBy('title');
    }),
    selectedSchool: Ember.computed('model.schools.[]', 'schoolId', 'primarySchool', function () {
      const schools = this.get('model.schools');
      const primarySchool = this.get('model.primarySchool');

      if (Ember.isPresent(this.schoolId)) {
        const schoolId = this.schoolId;
        const school = schools.findBy('id', schoolId);

        if (school) {
          return school;
        }
      }

      return primarySchool;
    }),
    programs: Ember.computed('selectedSchool', 'deletedProgram', 'newProgram', async function () {
      let schoolId = this.selectedSchool.get('id');

      if (Ember.isEmpty(schoolId)) {
        return Ember.RSVP.resolve([]);
      }

      return await this.store.query('program', {
        filters: {
          school: schoolId
        }
      });
    }),
    canCreate: Ember.computed('selectedSchool', async function () {
      const permissionChecker = this.permissionChecker;
      const selectedSchool = this.selectedSchool;
      return permissionChecker.canCreateProgram(selectedSchool);
    }),
    actions: {
      toggleEditor() {
        if (this.showNewProgramForm) {
          this.set('showNewProgramForm', false);
        } else {
          this.setProperties({
            showNewProgramForm: true,
            newProgram: null
          });
        }
      },

      editProgram(program) {
        this.transitionToRoute('program', program);
      },

      async removeProgram(program) {
        const school = await this.selectedSchool;
        const programs = await school.get('programs');
        programs.removeObject(program);
        await program.destroyRecord();
        this.set('deletedProgram', program);
        const newProgram = this.newProgram;

        if (newProgram === program) {
          this.set('newProgram', null);
        }
      },

      async saveNewProgram(newProgram) {
        const school = await this.selectedSchool;
        const duration = 4;
        newProgram.setProperties({
          school,
          duration
        });
        const savedProgram = await newProgram.save();
        this.set('showNewProgramForm', false);
        this.set('newProgram', savedProgram);
        const programs = await school.get('programs');
        programs.pushObject(savedProgram);
        return savedProgram;
      },

      async activateProgram(program) {
        program.set('published', true);
        program.set('publishedAsTbd', false);
        await program.save();
      },

      cancel() {
        this.set('showNewProgramForm', false);
      },

      changeSelectedSchool(schoolId) {
        this.set('schoolId', schoolId);
      }

    },
    changeTitleFilter: (0, _emberConcurrency.task)(function* (value) {
      this.set('titleFilter', value);
      yield (0, _emberConcurrency.timeout)(250);
      return value;
    }).restartable()
  });

  _exports.default = _default;
});