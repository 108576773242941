define("ilios/components/dashboard-myreports", ["exports", "ember-concurrency", "ember-lifeline/mixins/dom", "ilios/mixins/report-title", "papaparse", "ilios/utils/create-download-file", "ember-lifeline"], function (_exports, _emberConcurrency, _dom, _reportTitle, _papaparse, _createDownloadFile, _emberLifeline) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_dom.default, _reportTitle.default, {
    currentUser: Ember.inject.service(),
    preserveScroll: Ember.inject.service(),
    reporting: Ember.inject.service(),
    store: Ember.inject.service(),
    classNames: ['dashboard-myreports'],
    tagName: 'div',
    'data-test-dashboard-myreports': true,
    finishedBuildingReport: false,
    myReportEditorOn: false,
    scrollKey: 'reportList',
    selectedReport: null,
    selectedYear: null,
    user: null,

    onReportSelect() {},

    onReportYearSelect() {},

    /**
     * @property sortedReports
     * @type {Ember.computed}
     * @public
     */
    sortedReports: Ember.computed('user.reports.[]', async function () {
      const reports = await this.user.get('reports');
      return reports.sortBy('title');
    }),
    reportResultsList: Ember.computed('selectedReport', 'selectedYear', async function () {
      const report = this.selectedReport;
      const year = this.selectedYear;
      return report ? await this.reporting.getResults(report, year) : [];
    }),
    allAcademicYears: Ember.computed(async function () {
      const store = this.store;
      const years = await store.findAll('academic-year');
      return years;
    }),
    showAcademicYearFilter: Ember.computed('selectedReport', function () {
      const report = this.selectedReport;

      if (!report) {
        return false;
      }

      const subject = report.subject;
      const prepositionalObject = report.prepositionalObject;
      return prepositionalObject != 'course' && ['course', 'session'].includes(subject);
    }),
    selectedReportTitle: Ember.computed('selectedReport', async function () {
      const report = this.selectedReport;
      return this.getReportTitle(report);
    }),

    didReceiveAttrs() {
      this._super(...arguments);

      this.loadAttr.perform();
    },

    didRender() {
      this._super(...arguments);

      const element = document.querySelector('.dashboard-block-body');
      const yPos = this.preserveScroll[this.scrollKey];

      if (yPos > 0) {
        element.scrollTop = yPos;
      }

      if (element) {
        Ember.run.next(() => {
          this.addEventListener('.dashboard-block-body', 'scroll', () => {
            this.preserveScroll.set(this.scrollKey, element.scrollTop);
          });
        });
      }
    },

    actions: {
      toggleEditor() {
        this.set('myReportEditorOn', !this.myReportEditorOn);
      },

      closeEditor() {
        this.set('myReportEditorOn', false);
      },

      deleteReport(report) {
        report.deleteRecord();
        report.save();
      }

    },

    destroy() {
      (0, _emberLifeline.runDisposables)(this);

      this._super(...arguments);
    },

    downloadReport: (0, _emberConcurrency.task)(function* () {
      const report = this.selectedReport;
      const title = yield this.getReportTitle(report);
      const year = this.selectedYear;
      const data = yield this.reporting.getArrayResults(report, year);
      this.set('finishedBuildingReport', true);

      const csv = _papaparse.default.unparse(data);

      (0, _createDownloadFile.default)("".concat(title, ".csv"), csv, 'text/csv');
      yield (0, _emberConcurrency.timeout)(2000);
      this.set('finishedBuildingReport', false);
    }).drop(),
    loadAttr: (0, _emberConcurrency.task)(function* () {
      const user = yield this.currentUser.get('model');
      this.set('user', user);
    })
  });

  _exports.default = _default;
});