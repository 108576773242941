define("ilios/components/curriculum-inventory-report-list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const ReportProxy = Ember.ObjectProxy.extend({
    content: null,
    currentUser: null,
    intl: null,
    isScheduled: false,
    permissionChecker: null,
    showRemoveConfirmation: false,
    isNotPublished: Ember.computed.not('isPublished'),
    isPublished: Ember.computed.alias('isFinalized'),
    userCanDelete: Ember.computed('content', 'content.isFinalized', 'currentUser.model', async function () {
      const permissionChecker = this.permissionChecker;
      const report = this.content;

      if (report.get('isFinalized')) {
        return false;
      }

      return permissionChecker.canDeleteCurriculumInventoryReport(report);
    })
  });

  var _default = Ember.Component.extend({
    currentUser: Ember.inject.service(),
    intl: Ember.inject.service(),
    permissionChecker: Ember.inject.service(),
    program: null,
    sortBy: 'title',

    edit() {},

    remove() {},

    setSortBy() {},

    /**
     * @property proxiedReports
     * @type {Ember.computed}
     * @public
     */
    proxiedReports: Ember.computed('program.curriculumInventoryReports.[]', async function () {
      const currentUser = this.currentUser;
      const intl = this.intl;
      const permissionChecker = this.permissionChecker;
      const program = this.program;
      const reports = await program.get('curriculumInventoryReports');
      return reports.map(report => {
        return ReportProxy.create({
          content: report,
          intl,
          currentUser,
          permissionChecker
        });
      });
    }),
    sortedAscending: Ember.computed('sortBy', function () {
      return this.sortBy.search(/desc/) === -1;
    }),
    actions: {
      edit(proxy) {
        this.edit(proxy.get('content'));
      },

      remove(proxy) {
        this.remove(proxy.get('content'));
      },

      cancelRemove(proxy) {
        proxy.set('showRemoveConfirmation', false);
      },

      confirmRemove(proxy) {
        proxy.set('showRemoveConfirmation', true);
      },

      sortBy(what) {
        const sortBy = this.sortBy;

        if (sortBy === what) {
          what += ':desc';
        }

        this.setSortBy(what);
      }

    }
  });

  _exports.default = _default;
});