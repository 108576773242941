define("ilios/routes/session-type-visualize-terms", ["exports", "ember-simple-auth/mixins/authenticated-route-mixin"], function (_exports, _authenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    all,
    map
  } = Ember.RSVP;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    store: Ember.inject.service(),

    async model(params) {
      const store = this.store;
      const sessionType = await store.find('session-type', params.session_type_id);
      const vocabulary = await store.find('vocabulary', params.vocabulary_id);
      return {
        sessionType,
        vocabulary
      };
    },

    async afterModel(model) {
      const {
        sessionType,
        vocabulary
      } = model;
      const sessions = await sessionType.get('sessions');
      return await all([sessionType.get('school'), vocabulary.get('terms'), map(sessions.toArray(), s => s.get('terms')), map(sessions.toArray(), s => s.get('course'))]);
    }

  });

  _exports.default = _default;
});