define("ilios/components/manage-users-summary", ["exports", "ember-concurrency", "ilios-common/utils/query-utils"], function (_exports, _emberConcurrency, _queryUtils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const DEBOUNCE_MS = 250;
  const MIN_INPUT = 3;

  var _default = Ember.Component.extend({
    iliosConfig: Ember.inject.service(),
    intl: Ember.inject.service(),
    router: Ember.inject.service(),
    search: Ember.inject.service(),
    store: Ember.inject.service(),
    classNames: ['manage-users-summary', 'large-component'],
    tagName: 'section',
    canCreate: false,
    searchValue: null,

    /**
     * Find users using the user API
     * @param {string} q
     */
    async apiSearch(q) {
      let params = {
        q,
        limit: 100,
        'order_by[lastName]': 'ASC',
        'order_by[firstName]': 'ASC'
      };
      return await this.store.query('user', params);
    },

    /**
     * Find users using the search index API
     * @param {string} q
     */
    async indexSearch(q) {
      const {
        users
      } = await this.search.forUsers(q);
      return users;
    },

    searchForUsers: (0, _emberConcurrency.task)(function* (query) {
      const intl = this.intl;
      let q = (0, _queryUtils.cleanQuery)(query);

      if (Ember.isBlank(q)) {
        yield (0, _emberConcurrency.timeout)(1);
        return [];
      }

      yield (0, _emberConcurrency.timeout)(DEBOUNCE_MS);

      if (q.length < MIN_INPUT) {
        return [{
          type: 'text',
          text: intl.t('general.moreInputRequiredPrompt')
        }];
      }

      const searchEnabled = yield this.iliosConfig.searchEnabled;
      const searchResults = searchEnabled ? yield this.indexSearch(q) : yield this.apiSearch(q);

      if (searchResults.length === 0) {
        return [{
          type: 'text',
          text: intl.t('general.noSearchResultsPrompt')
        }];
      }

      let mappedResults = searchResults.map(user => {
        return {
          type: 'user',
          user
        };
      });
      let results = [{
        type: 'summary',
        text: intl.t('general.resultsCount', {
          count: mappedResults.length
        })
      }];
      results.pushObjects(mappedResults);
      return results;
    }).restartable(),
    clickUser: (0, _emberConcurrency.task)(function* ({
      id
    }) {
      yield this.router.transitionTo('user', id, {
        queryParams: {
          isManagingBio: Ember.DEFAULT_VALUE,
          isManagingRoles: Ember.DEFAULT_VALUE,
          isManagingCohorts: Ember.DEFAULT_VALUE,
          isManagingIcs: Ember.DEFAULT_VALUE,
          isManagingSchools: Ember.DEFAULT_VALUE
        }
      });
      this.set('searchValue', null);
      yield this.searchForUsers.perform(null);
    }).drop()
  });

  _exports.default = _default;
});