define("ilios/templates/components/global-search-box", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "L0smkhE7",
    "block": "{\"symbols\":[\"result\"],\"statements\":[[7,\"input\",true],[10,\"class\",\"global-search-input\"],[11,\"value\",[23,0,[\"computedQuery\"]]],[11,\"oninput\",[28,\"action\",[[23,0,[]],[28,\"queue\",[[28,\"action\",[[23,0,[]],[28,\"mut\",[[23,0,[\"internalQuery\"]]],null]],[[\"value\"],[\"target.value\"]]],[28,\"action\",[[23,0,[]],[28,\"mut\",[[23,0,[\"autocompleteSelectedQuery\"]]],null],null],null],[28,\"perform\",[[23,0,[\"autocomplete\"]]],null]],null]],null]],[10,\"type\",\"search\"],[8],[9],[0,\"\\n\"],[7,\"span\",true],[10,\"class\",\"search-icon\"],[11,\"onclick\",[28,\"pipe-action\",[[28,\"action\",[[23,0,[]],\"focus\"],null],[28,\"action\",[[23,0,[]],\"search\"],null]],null]],[8],[0,\"\\n  \"],[5,\"fa-icon\",[[12,\"class\",\"clickable\"]],[[\"@icon\"],[\"search\"]]],[0,\"\\n\"],[9],[0,\"\\n\"],[4,\"if\",[[23,0,[\"hasResults\"]]],null,{\"statements\":[[0,\"  \"],[5,\"click-outside\",[],[[\"@onClickOutside\"],[[28,\"action\",[[23,0,[]],[28,\"mut\",[[23,0,[\"autocompleteResults\"]]],null],null],null]]],{\"statements\":[[0,\"\\n    \"],[7,\"ul\",true],[10,\"class\",\"autocomplete\"],[8],[0,\"\\n\"],[4,\"each\",[[23,0,[\"results\"]]],null,{\"statements\":[[0,\"        \"],[7,\"li\",true],[10,\"class\",\"autocomplete-row\"],[11,\"onclick\",[28,\"pipe-action\",[[28,\"action\",[[23,0,[]],[28,\"mut\",[[23,0,[\"autocompleteSelectedQuery\"]]],null],null],null],[28,\"action\",[[23,0,[]],[28,\"mut\",[[23,0,[\"internalQuery\"]]],null],[23,1,[\"text\"]]],null],[28,\"action\",[[23,0,[]],\"search\"],null]],null]],[8],[0,\"\\n          \"],[5,\"fa-icon\",[],[[\"@icon\"],[\"search\"]]],[0,\"\\n          \"],[7,\"span\",true],[10,\"class\",\"result-text\"],[8],[0,\"\\n            \"],[1,[23,1,[\"text\"]],false],[0,\"\\n          \"],[9],[0,\"\\n        \"],[9],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"    \"],[9],[0,\"\\n  \"]],\"parameters\":[]}],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ilios/templates/components/global-search-box.hbs"
    }
  });

  _exports.default = _default;
});