define("ilios/services/current-user", ["exports", "ilios-common/services/current-user"], function (_exports, _currentUser) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _currentUser.default;
    }
  });
});