define("ilios/components/offering-editor-learnergroups", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['offering-editor-learnergroups'],
    revisedLearnerGroups: Ember.computed('cohort.filteredAvailableLearnerGroups.[]', async function () {
      const cohortId = this.get('cohort.id');
      let learnerGroups = this.learnerGroups[cohortId];

      if (Ember.isPresent(learnerGroups)) {
        return await Ember.RSVP.map(learnerGroups, async group => {
          let groupObject = {};
          let parentTitle = await group.get('allParentTitles');
          groupObject.group = group;

          if (Ember.isEmpty(parentTitle)) {
            groupObject.sortName = group.get('title');
          } else {
            groupObject.sortName = "".concat(parentTitle[0], " > ").concat(group.get('title'));
          }

          return groupObject;
        });
      }

      return [];
    }),
    sortedLearnerGroups: Ember.computed('revisedLearnerGroups.[]', async function () {
      const groups = await this.revisedLearnerGroups;
      return groups.sortBy('sortName');
    }),
    actions: {
      addLearnerGroup(group) {
        const cohortId = this.get('cohort.id');
        this.addLearnerGroup(group, cohortId);
      },

      removeLearnerGroup(group) {
        const cohortId = this.get('cohort.id');
        this.removeLearnerGroup(group, cohortId);
      }

    }
  });

  _exports.default = _default;
});