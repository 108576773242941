define("ilios/utils/create-download-file", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = createDownloadFile;

  function createDownloadFile(title, content, type) {
    let a = document.createElement('a');

    if (URL && 'download' in a) {
      //html5 A[download]
      const blob = new Blob([content], {
        type
      });
      a.href = URL.createObjectURL(blob);
      a.setAttribute('download', title);
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    } else {
      location.href = 'data:application/octet-stream,' + encodeURIComponent(content);
    }
  }
});