define("ilios/templates/components/school-competencies-list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Or+qKqOC",
    "block": "{\"symbols\":[\"domain\",\"competency\"],\"statements\":[[4,\"if\",[[24,[\"domains\",\"length\"]]],null,{\"statements\":[[0,\"  \"],[7,\"ul\",true],[8],[0,\"\\n\"],[4,\"each\",[[28,\"sort-by\",[\"title\",[24,[\"domains\"]]],null]],null,{\"statements\":[[0,\"      \"],[7,\"li\",true],[8],[0,\"\\n        \"],[1,[23,1,[\"title\"]],false],[0,\"\\n        \"],[7,\"ul\",true],[8],[0,\"\\n\"],[4,\"each\",[[28,\"sort-by\",[\"title\",[28,\"await\",[[23,1,[\"children\"]]],null]],null]],null,{\"statements\":[[0,\"            \"],[7,\"li\",true],[8],[0,\"\\n              \"],[1,[23,2,[\"title\"]],false],[0,\"\\n            \"],[9],[0,\"\\n\"]],\"parameters\":[2]},null],[0,\"        \"],[9],[0,\"\\n      \"],[9],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"  \"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ilios/templates/components/school-competencies-list.hbs"
    }
  });

  _exports.default = _default;
});