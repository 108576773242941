define("ilios/components/session-objective-manager", ["exports", "ilios-common/components/session-objective-manager"], function (_exports, _sessionObjectiveManager) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _sessionObjectiveManager.default;
    }
  });
});