define("ilios/templates/components/dashboard-loading", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ZlHfhbUW",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"main-box box\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"controls\"],[8],[9],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"content\"],[8],[9],[0,\"\\n\"],[9],[0,\"\\n\"],[7,\"div\",true],[10,\"class\",\"report-box box\"],[8],[9],[0,\"\\n\"],[7,\"div\",true],[10,\"class\",\"courses-box box\"],[8],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ilios/templates/components/dashboard-loading.hbs"
    }
  });

  _exports.default = _default;
});