define("ilios/components/learnergroup-user-manager", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    currentUser: Ember.inject.service(),
    classNames: ['learnergroup-user-manager'],
    cohortTitle: null,
    filter: '',
    isEditing: false,
    learnerGroupId: null,
    learnerGroupTitle: null,
    selectedUsers: null,
    sortBy: 'firstName',
    users: null,
    usersBeingMoved: null,
    sortedAscending: Ember.computed('sortBy', function () {
      return this.sortBy.search(/desc/) === -1;
    }),
    filteredUsers: Ember.computed('filter', 'users.[]', function () {
      let users = this.users ? this.users : [];
      const filter = this.filter.toLowerCase();

      if (Ember.isEmpty(filter)) {
        return users;
      }

      return users.filter(user => {
        return user.get('firstName').toLowerCase().includes(filter) || user.get('lastName').toLowerCase().includes(filter) || user.get('email').toLowerCase().includes(filter);
      });
    }),
    usersInCurrentGroup: Ember.computed('filteredUsers.[]', 'learnerGroupId', 'isEditing', function () {
      const isEditing = this.isEditing;
      const filteredUsers = this.filteredUsers;

      if (!isEditing) {
        return filteredUsers;
      }

      const learnerGroupId = this.learnerGroupId;
      return filteredUsers.filter(user => {
        return user.get('lowestGroupInTree').get('id') === learnerGroupId;
      });
    }),
    usersNotInCurrentGroup: Ember.computed('filteredUsers.[]', 'learnerGroupId', function () {
      const learnerGroupId = this.learnerGroupId;
      return this.filteredUsers.filter(user => user.get('lowestGroupInTree').get('id') !== learnerGroupId);
    }),

    init() {
      this._super(...arguments);

      this.setProperties({
        selectedUsers: [],
        usersBeingMoved: []
      });
    },

    didRender() {
      this._super(...arguments);

      this.setCheckAllState();
    },

    actions: {
      sortBy(what) {
        const sortBy = this.sortBy;

        if (sortBy === what) {
          what += ':desc';
        }

        this.setSortBy(what);
      },

      toggleUserSelection(user) {
        if (this.selectedUsers.includes(user)) {
          this.selectedUsers.removeObject(user);
        } else {
          this.selectedUsers.pushObject(user);
        }
      },

      toggleUserSelectionAllOrNone() {
        const selectedUsers = this.selectedUsers.get('length');
        const filteredUsers = this.filteredUsers.get('length');

        if (selectedUsers >= filteredUsers) {
          this.selectedUsers.clear();
        } else {
          const users = this.filteredUsers;
          this.selectedUsers.pushObjects(users.mapBy('content'));
        }

        this.setCheckAllState();
      }

    },

    setCheckAllState() {
      const selectedUsers = this.selectedUsers.get('length');
      const filteredUsers = this.filteredUsers.get('length');
      let el = this.$('th:eq(0) input');

      if (selectedUsers === 0) {
        el.prop('indeterminate', false);
        el.prop('checked', false);
      } else if (selectedUsers < filteredUsers) {
        el.prop('indeterminate', true);
        el.prop('checked', false);
      } else {
        el.prop('indeterminate', false);
        el.prop('checked', true);
      }
    },

    addSingleUser: (0, _emberConcurrency.task)(function* (user) {
      this.usersBeingMoved.pushObject(user); //timeout gives the spinner time to render

      yield (0, _emberConcurrency.timeout)(10);
      yield this.addUserToGroup(user);
      this.usersBeingMoved.removeObject(user);
    }),
    removeSingleUser: (0, _emberConcurrency.task)(function* (user) {
      this.usersBeingMoved.pushObject(user); //timeout gives the spinner time to render

      yield (0, _emberConcurrency.timeout)(10);
      yield this.removeUserFromGroup(user);
      this.usersBeingMoved.removeObject(user);
    }),
    addSelectedUsers: (0, _emberConcurrency.task)(function* () {
      const users = this.selectedUsers;
      this.usersBeingMoved.pushObjects(users); //timeout gives the spinner time to render

      yield (0, _emberConcurrency.timeout)(10);
      yield this.addUsersToGroup(users);
      this.usersBeingMoved.removeObjects(users);
      this.set('selectedUsers', []);
    }),
    removeSelectedUsers: (0, _emberConcurrency.task)(function* () {
      const users = this.selectedUsers;
      this.usersBeingMoved.pushObjects(users); //timeout gives the spinner time to render

      yield (0, _emberConcurrency.timeout)(10);
      yield this.removeUsersFromGroup(users);
      this.usersBeingMoved.removeObjects(users);
      this.set('selectedUsers', []);
    })
  });

  _exports.default = _default;
});