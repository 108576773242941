define("ilios/components/school-vocabulary-manager", ["exports", "ember-cp-validations", "ilios-common/mixins/validation-error-display"], function (_exports, _emberCpValidations, _validationErrorDisplay) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const Validations = (0, _emberCpValidations.buildValidations)({
    newTermTitle: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('length', {
      min: 1,
      max: 200
    }), (0, _emberCpValidations.validator)('async-exclusion', {
      dependentKeys: ['model.vocabulary.terms.@each.title'],
      in: Ember.computed('model.vocabulary.terms.@each.title', async function () {
        const vocabulary = this.get('model.vocabulary');

        if (Ember.isPresent(vocabulary)) {
          const terms = await vocabulary.terms;
          return terms.filterBy('isTopLevel', true).mapBy('title');
        }

        return [];
      }),
      descriptionKey: 'general.term'
    })],
    vocabularyTitle: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('length', {
      min: 1,
      max: 200
    }), (0, _emberCpValidations.validator)('async-exclusion', {
      dependentKeys: ['model.vocabulary.schools.@each.vocabularies'],
      in: Ember.computed('model.vocabulary.vocabularies.@each.title', async function () {
        const vocabulary = this.get('model.vocabulary');

        if (Ember.isPresent(vocabulary)) {
          const school = await vocabulary.school;
          const siblingVocabularier = await school.vocabularies;
          return siblingVocabularier.mapBy('title');
        }

        return [];
      }),
      descriptionKey: 'general.vocabulary'
    })]
  });

  var _default = Ember.Component.extend(Validations, _validationErrorDisplay.default, {
    store: Ember.inject.service(),
    vocabulary: null,
    canUpdate: false,
    canCreate: false,
    vocabularyTitle: null,
    isActive: null,
    newTermTitle: null,
    isSavingNewTerm: false,
    newTerm: null,
    classNames: ['school-vocabulary-manager'],
    'data-test-school-vocabulary-manager': true,
    sortedTerms: Ember.computed('vocabulary.terms.[]', 'newTerm', async function () {
      if (!this.vocabulary) {
        return [];
      }

      const terms = await this.vocabulary.terms;
      return terms.filterBy('isTopLevel').filterBy('isNew', false).filterBy('isDeleted', false).sortBy('title');
    }),

    didReceiveAttrs() {
      this._super(...arguments);

      if (this.vocabulary) {
        this.set('vocabularyTitle', this.vocabulary.title);
        this.set('isActive', this.vocabulary.active);
      }
    },

    actions: {
      async changeVocabularyTitle() {
        this.send('addErrorDisplayFor', 'vocabularyTitle');
        await this.validate();

        if (this.validations.attrs.vocabularyTitle.isValid) {
          this.send('removeErrorDisplayFor', 'vocabularyTitle');
          this.vocabulary.set('title', this.vocabularyTitle);
          return this.vocabulary.save();
        }

        return false;
      },

      revertVocabularyTitleChanges() {
        this.send('removeErrorDisplayFor', 'vocabularyTitle');
        this.set('vocabularyTitle', this.vocabulary.title);
      },

      async createTerm() {
        this.send('addErrorDisplayFor', 'newTermTitle');
        this.set('isSavingNewTerm', true);

        try {
          await this.validate();

          if (this.validations.attrs.newTermTitle.isValid) {
            this.send('removeErrorDisplayFor', 'newTermTitle');
            let title = this.newTermTitle;
            const vocabulary = this.vocabulary;
            const store = this.store;
            let term = store.createRecord('term', {
              title,
              vocabulary,
              active: true
            });
            const newTerm = await term.save();
            this.set('newTermTitle', null);
            this.set('newTerm', newTerm);
            return true;
          }
        } finally {
          this.set('isSavingNewTerm', false);
        }
      }

    },

    keyUp(event) {
      const keyCode = event.keyCode;
      const target = event.target;

      if ('text' !== target.type) {
        return;
      }

      if (13 === keyCode) {
        this.send('createTerm');
      }
    }

  });

  _exports.default = _default;
});