define("ilios/components/curriculum-inventory-sequence-block-session-list", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['curriculum-inventory-sequence-block-session-list'],
    tagName: 'section',
    excludedSessions: null,
    linkedSessions: null,
    sessions: null,
    sessionsBuffer: null,
    sortBy: 'title',
    sortedAscending: Ember.computed('sortBy', function () {
      const sortBy = this.sortBy;
      return sortBy.search(/desc/) === -1;
    }),

    didReceiveAttrs() {
      this._super(...arguments);

      const sequenceBlock = this.sequenceBlock;
      const sessions = this.sessions;
      this.loadAttr.perform(sequenceBlock, sessions);
    },

    actions: {
      sortBy(what) {
        const sortBy = this.sortBy;

        if (sortBy === what) {
          what += ':desc';
        }

        this.setSortBy(what);
      }

    },
    loadAttr: (0, _emberConcurrency.task)(function* (sequenceBlock, sessions) {
      const linkedSessions = yield sequenceBlock.get('sessions');
      const excludedSessions = yield sequenceBlock.get('excludedSessions');
      this.setProperties({
        linkedSessions,
        excludedSessions,
        sessionsBuffer: sessions
      });
    })
  });

  _exports.default = _default;
});