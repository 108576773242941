define("ilios/components/new-program", ["exports", "ember-cp-validations", "ilios-common/mixins/validation-error-display"], function (_exports, _emberCpValidations, _validationErrorDisplay) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const Validations = (0, _emberCpValidations.buildValidations)({
    title: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('length', {
      min: 3,
      max: 200,
      descriptionKey: 'general.title'
    })]
  });

  var _default = Ember.Component.extend(_validationErrorDisplay.default, Validations, {
    store: Ember.inject.service(),
    classNames: ['new-program'],
    isSaving: false,
    title: null,
    actions: {
      save() {
        this.set('isSaving', true);
        this.send('addErrorDisplayFor', 'title');
        this.validate().then(({
          validations
        }) => {
          if (validations.get('isValid')) {
            let program = this.store.createRecord('program', {
              title: this.title,
              published: true,
              publishedAsTbd: false
            });
            this.save(program).finally(() => {
              this.set('isSaving', false);
            });
          } else {
            this.set('isSaving', false);
          }
        });
      }

    },

    keyUp(event) {
      const keyCode = event.keyCode;
      const target = event.target;

      if ('text' !== target.type) {
        return;
      }

      if (13 === keyCode) {
        this.send('save');
        return;
      }

      if (27 === keyCode) {
        this.cancel();
      }
    }

  });

  _exports.default = _default;
});