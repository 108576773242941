define("ilios/components/course-search-result", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['course-search-result'],
    tagName: 'li',
    'data-test-course-search-result': true,
    course: null,
    showMore: false,
    courseTags: Ember.computed.reads('course.matchedIn'),
    sessions: Ember.computed('showMore', function () {
      const sessions = this.course.sessions;
      return this.showMore ? sessions : sessions.slice(0, 3);
    })
  });

  _exports.default = _default;
});