define("ilios/components/school-vocabularies-collapsed", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['school-vocabularies-collapsed'],
    tagName: 'section',
    school: null
  });

  _exports.default = _default;
});