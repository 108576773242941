define("ilios/routes/courses", ["exports", "ember-simple-auth/mixins/authenticated-route-mixin"], function (_exports, _authenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    currentUser: Ember.inject.service(),
    store: Ember.inject.service(),
    queryParams: {
      titleFilter: {
        replace: true
      }
    },

    model() {
      let defer = Ember.RSVP.defer();
      let model = {};
      this.get('currentUser.model').then(currentUser => {
        this.store.findAll('school').then(schools => {
          model.schools = schools;
          currentUser.get('school').then(primarySchool => {
            model.primarySchool = primarySchool;
            this.store.findAll('academic-year').then(years => {
              model.years = years.toArray();
              defer.resolve(model);
            });
          });
        });
      });
      return defer.promise;
    },

    setupController(controller, model) {
      this._super(controller, model);

      controller.set('sortSchoolsBy', ['title']);
      controller.set('sortYearsBy', ['title:desc']);
    },

    actions: {
      willTransition() {
        this.controller.set('newCourse', null);
      }

    }
  });

  _exports.default = _default;
});