define("ilios/components/programyear-header", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['programyear-header'],
    canUpdate: false,
    programYear: null,
    actions: {
      async activate(programYear) {
        programYear.set('published', true);
        programYear.set('publishedAsTbd', false);
        await programYear.save();
      }

    }
  });

  _exports.default = _default;
});