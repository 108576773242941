define("ilios/controllers/application", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    apiVersion: Ember.inject.service(),
    currentUser: Ember.inject.service(),
    intl: Ember.inject.service(),
    session: Ember.inject.service(),
    currentlyLoading: false,
    errors: null,
    showErrorDisplay: null,

    init() {
      this._super(...arguments);

      const showErrorDisplay = false;
      const errors = [];
      this.setProperties({
        showErrorDisplay,
        errors
      });
    },

    actions: {
      clearErrors() {
        this.set('errors', []);
        this.set('showErrorDisplay', false);
      }

    },

    addError(error) {
      this.errors.pushObject(error);
      this.set('showErrorDisplay', true);
    }

  });

  _exports.default = _default;
});