define("ilios/routes/curriculum-inventory-sequence-block", ["exports", "ember-simple-auth/mixins/authenticated-route-mixin"], function (_exports, _authenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    all
  } = Ember.RSVP;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    permissionChecker: Ember.inject.service(),
    canUpdate: false,

    async afterModel(model) {
      const permissionChecker = this.permissionChecker;
      const report = await model.get('report');
      const canUpdate = await permissionChecker.canUpdateCurriculumInventoryReport(report);
      this.set('canUpdate', canUpdate); //preload data to speed up rendering later

      return all([model.get('children'), model.get('parent')]);
    },

    setupController(controller, model) {
      this._super(controller, model);

      controller.set('canUpdate', this.canUpdate);
    }

  });

  _exports.default = _default;
});