define("ilios/controllers/myprofile", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    queryParams: {
      showCreateNewToken: 'newtoken',
      showInvalidateTokens: 'invalidatetokens'
    },
    showCreateNewToken: false,
    showInvalidateTokens: false
  });

  _exports.default = _default;
});