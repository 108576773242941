define("ilios/helpers/formatted-date", ["exports", "elemental-calendar/helpers/formatted-date"], function (_exports, _formattedDate) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _formattedDate.default;
    }
  });
  Object.defineProperty(_exports, "formattedDate", {
    enumerable: true,
    get: function get() {
      return _formattedDate.formattedDate;
    }
  });
});