define("ilios/components/leadership-search", ["exports", "ilios-common/components/leadership-search"], function (_exports, _leadershipSearch) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _leadershipSearch.default;
    }
  });
});