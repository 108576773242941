define("ilios/templates/course/rollover", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Ik3GmeAS",
    "block": "{\"symbols\":[],\"statements\":[[5,\"course-rollover\",[],[[\"@course\",\"@visit\"],[[22,\"model\"],[28,\"action\",[[23,0,[]],\"loadCourse\"],null]]]]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ilios/templates/course/rollover.hbs"
    }
  });

  _exports.default = _default;
});