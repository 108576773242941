define("ilios/components/learnergroup-bulk-finalize-users", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    flashMessages: Ember.inject.service(),
    users: null,
    matchedGroups: null,
    learnerGroup: null,
    finalData: Ember.computed('users.[]', 'matchedGroups.[]', 'learnerGroup', function () {
      const users = this.users;
      const learnerGroup = this.learnerGroup;
      const matchedGroups = this.matchedGroups;
      return users.map(obj => {
        let selectedGroup = learnerGroup;

        if (obj.subGroupName) {
          const match = matchedGroups.findBy('name', obj.subGroupName);

          if (match) {
            selectedGroup = match.group;
          }
        }

        return {
          user: obj.userRecord,
          learnerGroup: selectedGroup
        };
      });
    }),
    save: (0, _emberConcurrency.task)(function* () {
      yield (0, _emberConcurrency.timeout)(10);
      const finalData = this.finalData;
      const done = this.done;
      const flashMessages = this.flashMessages;
      const treeGroups = yield Ember.RSVP.map(finalData, async ({
        learnerGroup,
        user
      }) => {
        return learnerGroup.addUserToGroupAndAllParents(user);
      });
      const flat = treeGroups.reduce((flattened, arr) => {
        return flattened.pushObjects(arr);
      }, []);
      const groupsToSave = flat.uniq();
      yield Ember.RSVP.all(groupsToSave.invoke('save'));
      flashMessages.success('general.savedSuccessfully');
      done();
    })
  });

  _exports.default = _default;
});