define("ilios/components/programyear-objective-list", ["exports", "ilios-common/mixins/sortable-objective-list", "ember-concurrency", "fetch"], function (_exports, _sortableObjectiveList, _emberConcurrency, _fetch) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    alias
  } = Ember.computed;

  var _default = Ember.Component.extend(_sortableObjectiveList.default, {
    ajax: Ember.inject.service(),
    iliosConfig: Ember.inject.service(),
    session: Ember.inject.service(),
    classNames: ['programyear-objective-list'],
    editable: false,
    isDownloading: false,
    subject: null,
    expandedObjectiveIds: null,
    programYear: alias('subject'),
    authHeaders: Ember.computed('session.isAuthenticated', function () {
      const session = this.session;
      const {
        jwt
      } = session.data.authenticated;
      let headers = {};

      if (jwt) {
        headers['X-JWT-Authorization'] = "Token ".concat(jwt);
      }

      return new Headers(headers);
    }),

    init() {
      this._super(...arguments);

      this.set('expandedObjectiveIds', []);
    },

    actions: {
      toggleExpand(objectiveId) {
        const expandedObjectiveIds = this.expandedObjectiveIds;

        if (expandedObjectiveIds.includes(objectiveId)) {
          expandedObjectiveIds.removeObject(objectiveId);
        } else {
          expandedObjectiveIds.pushObject(objectiveId);
        }

        this.set('expandedObjectiveIds', expandedObjectiveIds);
      }

    },
    downloadReport: (0, _emberConcurrency.task)(function* (subject) {
      const apiPath = '/' + this.iliosConfig.apiNameSpace;
      const resourcePath = '/programyears/' + subject.get('id') + '/downloadobjectivesmapping';
      const host = this.iliosConfig.get('apiHost') ? this.iliosConfig.get('apiHost') : window.location.protocol + '//' + window.location.host;
      const url = host + apiPath + resourcePath;
      const {
        saveAs
      } = yield emberAutoImportDynamic("file-saver");
      this.set('isDownloading', true);
      const response = yield (0, _fetch.default)(url, {
        headers: this.authHeaders
      });
      const blob = yield response.blob();
      saveAs(blob, 'report.csv');
      this.set('isDownloading', false);
    }).drop()
  });

  _exports.default = _default;
});