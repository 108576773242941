define("ilios/components/learnergroup-instructor-manager", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    learnerGroup: null,
    availableInstructorGroups: Ember.computed.oneWay('learnerGroup.cohort.programYear.program.school.instructorGroups'),

    init() {
      this._super(...arguments);

      const learnerGroup = this.learnerGroup;

      if (Ember.isPresent(learnerGroup)) {
        learnerGroup.get('instructors').then(instructors => {
          this.set('instructors', instructors.toArray());
        });
        learnerGroup.get('instructorGroups').then(instructorGroups => {
          this.set('instructorGroups', instructorGroups.toArray());
        });
      }
    },

    actions: {
      addInstructor(user) {
        this.instructors.pushObject(user);
      },

      addInstructorGroup(instructorGroup) {
        this.instructorGroups.pushObject(instructorGroup);
      },

      removeInstructor(user) {
        this.instructors.removeObject(user);
      },

      removeInstructorGroup(instructorGroup) {
        this.instructorGroups.removeObject(instructorGroup);
      }

    },
    saveChanges: (0, _emberConcurrency.task)(function* () {
      const instructors = this.instructors;
      const instructorGroups = this.instructorGroups;
      yield this.save(instructors, instructorGroups);
    }).drop()
  });

  _exports.default = _default;
});