define("ilios/templates/course-visualize-session-type", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "S0aCGwDk",
    "block": "{\"symbols\":[],\"statements\":[[7,\"section\",true],[10,\"class\",\"course-visualize-session-type\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"backtolink\"],[8],[0,\"\\n    \"],[5,\"link-to\",[],[[\"@route\",\"@model\"],[\"course-visualizations\",[24,[\"model\",\"course\"]]]],{\"statements\":[[0,\"\\n      \"],[1,[28,\"t\",[\"general.otherVisualizations\"],null],false],[0,\"\\n    \"]],\"parameters\":[]}],[0,\"\\n  \"],[9],[0,\"\\n  \"],[7,\"h2\",true],[8],[0,\"\\n    \"],[1,[28,\"t\",[\"general.vocabularyTermsFor\"],[[\"subject\"],[[24,[\"model\",\"sessionType\",\"title\"]]]]],false],[0,\"\\n  \"],[9],[0,\"\\n  \"],[7,\"h3\",true],[10,\"class\",\"clickable\"],[8],[0,\"\\n    \"],[5,\"link-to\",[],[[\"@route\",\"@model\"],[\"course\",[24,[\"model\",\"course\"]]]],{\"statements\":[[0,\"\\n      \"],[1,[24,[\"model\",\"course\",\"title\"]],false],[0,\"\\n      \"],[1,[24,[\"model\",\"course\",\"academicYear\"]],false],[0,\"\\n    \"]],\"parameters\":[]}],[0,\"\\n  \"],[9],[0,\"\\n  \"],[5,\"visualizer-course-session-type\",[],[[\"@course\",\"@sessionType\"],[[24,[\"model\",\"course\"]],[24,[\"model\",\"sessionType\"]]]]],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ilios/templates/course-visualize-session-type.hbs"
    }
  });

  _exports.default = _default;
});