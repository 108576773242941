define("ilios/helpers/browser-timezone", ["exports", "ilios-common/helpers/browser-timezone"], function (_exports, _browserTimezone) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _browserTimezone.default;
    }
  });
  Object.defineProperty(_exports, "browserTimezone", {
    enumerable: true,
    get: function get() {
      return _browserTimezone.browserTimezone;
    }
  });
});