define("ilios/routes/login", ["exports", "ilios/config/environment", "ember-simple-auth/mixins/unauthenticated-route-mixin"], function (_exports, _environment, _unauthenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_unauthenticatedRouteMixin.default, {
    commonAjax: Ember.inject.service(),
    currentUser: Ember.inject.service(),
    iliosConfig: Ember.inject.service(),
    session: Ember.inject.service(),
    noAccountExistsAccount: null,
    noAccountExistsError: false,

    beforeModel(transition) {
      this._super(transition);

      return this.attemptSSOAuth();
    },

    setupController(controller) {
      controller.set('noAccountExistsError', this.noAccountExistsError);
      controller.set('noAccountExistsAccount', this.noAccountExistsAccount);
    },

    async attemptSSOAuth() {
      const iliosConfig = this.iliosConfig;
      const type = await iliosConfig.get('authenticationType');

      if (type === 'form' || type === 'ldap') {
        return;
      }

      if (type === 'shibboleth') {
        return await this.shibbolethAuth();
      }

      if (type === 'cas') {
        return await this.casLogin();
      }
    },

    async casLogin() {
      const iliosConfig = this.iliosConfig;
      const commonAjax = this.commonAjax;
      let currentUrl = [window.location.protocol, '//', window.location.host, window.location.pathname].join('');
      let loginUrl = "/auth/login?service=".concat(currentUrl);
      let queryParams = {};

      if (window.location.search.length > 1) {
        window.location.search.substr(1).split('&').forEach(str => {
          let arr = str.split('=');
          queryParams[arr[0]] = arr[1];
        });
      }

      if (Ember.isPresent(queryParams.ticket)) {
        loginUrl += "&ticket=".concat(queryParams.ticket);
      }

      const response = await commonAjax.request(loginUrl);

      if (response.status === 'redirect') {
        const casLoginUrl = await iliosConfig.itemFromConfig('casLoginUrl');
        await new Ember.RSVP.Promise(() => {
          //this promise never resolves so we don't render anything before the redirect
          window.location.replace("".concat(casLoginUrl, "?service=").concat(currentUrl));
        });
      }

      if (response.status === 'noAccountExists') {
        this.set('noAccountExistsError', true);
        this.set('noAccountExistsAccount', response.userId);
        return;
      }

      if (response.status === 'success') {
        let authenticator = 'authenticator:ilios-jwt';
        this.session.authenticate(authenticator, {
          jwt: response.jwt
        });
      }
    },

    async shibbolethAuth() {
      const iliosConfig = this.iliosConfig;
      const commonAjax = this.commonAjax;
      const loginUrl = '/auth/login';
      const response = await commonAjax.request(loginUrl);
      const status = response.status;

      if (status === 'redirect') {
        let shibbolethLoginUrl = await iliosConfig.itemFromConfig('loginUrl');

        if (_environment.default.redirectAfterShibLogin) {
          let attemptedRoute = encodeURIComponent(window.location.href);
          shibbolethLoginUrl += '?target=' + attemptedRoute;
        }

        await new Ember.RSVP.Promise(() => {
          //this promise never resolves so we don't render anything before the redirect
          window.location.replace(shibbolethLoginUrl);
        });
      }

      if (status === 'noAccountExists') {
        this.set('noAccountExistsError', true);
        this.set('noAccountExistsAccount', response.userId);
        return;
      }

      if (status === 'success') {
        let authenticator = 'authenticator:ilios-jwt';
        this.session.authenticate(authenticator, {
          jwt: response.jwt
        });
      }
    }

  });

  _exports.default = _default;
});