define("ilios/models/curriculum-inventory-academic-level", ["exports", "ilios-common/models/curriculum-inventory-academic-level"], function (_exports, _curriculumInventoryAcademicLevel) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _curriculumInventoryAcademicLevel.default;
    }
  });
});