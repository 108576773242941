define("ilios/templates/curriculum-inventory-report", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "dCks/7+o",
    "block": "{\"symbols\":[],\"statements\":[[1,[28,\"page-title\",[[28,\"t\",[\"general.curriculumInventoryReports\"],null]],null],false],[0,\"\\n\"],[7,\"div\",true],[10,\"class\",\"backtolink\"],[8],[0,\"\\n  \"],[5,\"link-to\",[],[[\"@route\"],[\"curriculumInventoryReports\"]],{\"statements\":[[0,\"\\n    \"],[1,[28,\"t\",[\"general.backToReports\"],null],false],[0,\"\\n  \"]],\"parameters\":[]}],[0,\"\\n\"],[9],[0,\"\\n\"],[5,\"curriculum-inventory-report-details\",[],[[\"@report\",\"@canUpdate\",\"@leadershipDetails\",\"@setLeadershipDetails\",\"@manageLeadership\",\"@setManageLeadership\"],[[22,\"model\"],[28,\"and\",[[28,\"not\",[[24,[\"model\",\"isFinalized\"]]],null],[24,[\"canUpdate\"]]],null],[22,\"leadershipDetails\"],[28,\"action\",[[23,0,[]],[28,\"mut\",[[24,[\"leadershipDetails\"]]],null]],null],[22,\"manageLeadership\"],[28,\"action\",[[23,0,[]],[28,\"mut\",[[24,[\"manageLeadership\"]]],null]],null]]]],[0,\"\\n\"],[1,[22,\"outlet\"],false]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ilios/templates/curriculum-inventory-report.hbs"
    }
  });

  _exports.default = _default;
});