define("ilios/components/user-profile-calendar", ["exports", "ilios-common/mixins/events", "moment"], function (_exports, _events, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_events.default, {
    commonAjax: Ember.inject.service(),
    iliosConfig: Ember.inject.service(),
    classNames: ['user-profile-calendar'],
    date: null,
    user: null,
    namespace: Ember.computed.reads('iliosConfig.apiNameSpace'),
    calendarEvents: Ember.computed('user.id', 'date', async function () {
      const commonAjax = this.commonAjax;
      const user = this.user;
      const date = this.date;
      const from = (0, _moment.default)(date).day(0).hour(0).minute(0).second(0).format('X');
      const to = (0, _moment.default)(date).day(6).hour(23).minute(59).second(59).format('X');
      const namespace = this.namespace;
      let url = '';

      if (namespace) {
        url += '/' + namespace;
      }

      url += '/userevents/' + user.get('id') + '?from=' + from + '&to=' + to;
      const data = await commonAjax.request(url);
      return data.userEvents.map(obj => this.createEventFromData(obj, true)).sortBy('startDate', 'name');
    }),

    init() {
      this._super(...arguments);

      this.set('date', new Date());
    },

    actions: {
      goForward() {
        const date = this.date;
        let newDate = (0, _moment.default)(date).add(1, 'week').toDate();
        this.set('date', newDate);
      },

      goBack() {
        const date = this.date;
        let newDate = (0, _moment.default)(date).subtract(1, 'week').toDate();
        this.set('date', newDate);
      },

      gotoToday() {
        let newDate = (0, _moment.default)().toDate();
        this.set('date', newDate);
      }

    }
  });

  _exports.default = _default;
});