define("ilios/sentry", ["exports", "@sentry/browser", "@sentry/integrations/esm/ember", "ember-cli-app-version/utils/regexp"], function (_exports, Sentry, _ember, _regexp) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.startSentry = startSentry;

  function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

  function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(source, true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(source).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function startSentry(config) {
    const captureErrors = isErrorCaptureEnabled(config);
    const isDevelopmentEnvironment = config.environment !== 'production';
    Sentry.init(_objectSpread({}, config.sentry, {
      integrations: [new _ember.Ember()],
      release: config.APP.version.match(_regexp.versionRegExp)[0],

      beforeSend(event, hint) {
        const error = hint.originalException; //print everything to the console when not in production

        if (isDevelopmentEnvironment && error) {
          console.error(error);
        }

        if (!captureErrors) {
          return null;
        } // ignore aborted route transitions from the Ember.js router


        if (error && error.name === 'TransitionAborted') {
          return null;
        } // ignore aborted ajax calls, these happen when users navigate quickly between routes


        if (error && (error.message === 'The ajax operation was aborted' || error.message === 'The adapter operation was aborted')) {
          return null;
        }

        return event;
      }

    }));
  }
  /**
   * Duplicate the functionality of ember-cli-server-variables since
   * we can't load a service here in the pre-boot setup
   */


  function isErrorCaptureEnabled(config) {
    const varName = 'error-capture-enabled';
    const {
      modulePrefix,
      serverVariables
    } = config;
    const prefix = serverVariables.tagPrefix || modulePrefix;
    const tag = document ? document.querySelector("head meta[name=".concat(prefix, "-").concat(varName, "]")) : null;
    const content = tag ? tag.content : serverVariables.defaults[varName];
    return JSON.parse(content);
  }
});