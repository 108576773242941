define("ilios/templates/components/curriculum-inventory-verification-preview-header", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Ajh+8gWJ",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"title\"],[8],[0,\"\\n  \"],[7,\"h2\",true],[8],[0,\"\\n    \"],[1,[28,\"t\",[\"general.verificationPreviewFor\"],[[\"name\"],[[23,0,[\"report\",\"name\"]]]]],false],[0,\"\\n  \"],[9],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ilios/templates/components/curriculum-inventory-verification-preview-header.hbs"
    }
  });

  _exports.default = _default;
});